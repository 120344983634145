import { Job, PlantListEntry } from '@/types.ts'
import { pluralizedLabel } from '@/lib/pluralize.ts'
import { excludeDeletedItems, formatStringToShortMonthDayYear, groupByKey } from '@/lib/utils.ts'
import { Stack, Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { Check } from 'lucide-react'
import TextField from '@mui/material/TextField'
import { FlatPlantTableCellProps, FlatPlantTableRowProps } from '@/components/ui/flat-plant-table.tsx'
import { getFormattedPlantListEntrySpecs } from '@/components/plant-lists/plant-lists-entry-specs.ts'
import Chip from '@mui/material/Chip'
import theme from '@/theme.ts'

export function JobPhasePlantsTableRows(
  jobData: Job,
  jobPlants: PlantListEntry[],
  filteredPlants: PlantListEntry[],
  selectedPlants: Set<string>,
  quantities: Record<string, number> = {},
  handleQuantityChange: (entry_id: string, quantity: number) => void,
  handleCheckboxChange: (entry: PlantListEntry) => void,
  handleGroupCheckboxChange: (group: string, group_id: string, entries: PlantListEntry[]) => void,
  showSelected: boolean = false,
  allowSelection: boolean = false,
  showQuantity: boolean = true,
  allowQuantityEdit: boolean = false,
  allowRemoval: boolean = false
): FlatPlantTableRowProps[] {
  const rows: FlatPlantTableRowProps[] = []
  const columns = showSelected ? 4 : 3

  const jobPhases = excludeDeletedItems(jobData.phases)
  const filteredJobPlants = jobPlants.filter((entry) => filteredPlants.some((plant) => plant.id === entry.id))
  const filteredSelectedJobPlants = filteredJobPlants.filter((entry) => selectedPlants.has(entry.id))

  const filteredPhaseGroupPlants = groupByKey(filteredJobPlants, 'phase_id')
  const filteredSelectedPhaseGroupPlants = groupByKey(
    filteredSelectedJobPlants.filter((entry) => selectedPlants.has(entry.id)),
    'phase_id'
  )
  const orderedPhaseGroupFilteredPlants = [
    ...jobPhases.map((phase, index) => ({
      id: phase.id,
      name: phase.name,
      number: index + 1,
      expectedStartDate: phase.expected_start_date,
      plants: filteredPhaseGroupPlants[phase.id] || [],
    })),
    ...(filteredPhaseGroupPlants.null
      ? [
          {
            id: null,
            name: null,
            number: null,
            expectedStartDate: null,
            plants: filteredPhaseGroupPlants.null,
          },
        ]
      : []),
  ]

  if (filteredJobPlants.length === 0) return rows

  // job headers if job exists - add condition here to hide job row if not required
  if (jobData) {
    rows.push(
      JobTitlePlantTableRow({
        jobData,
        jobPlants: filteredJobPlants,
        colSpan: columns,
      })
    )
  }

  // create phase headers & rows for each phase plant
  orderedPhaseGroupFilteredPlants.forEach((phaseData) => {
    if (phaseData.plants.length) {
      const groupSelectedPlants = filteredSelectedPhaseGroupPlants[phaseData.id] || []

      // phase title row - add condition here to hide phase row if not required
      if (phaseData?.id) {
        rows.push(
          PhaseTitlePlantTableRow({
            phaseData,
            selectedPlants: groupSelectedPlants,
            handleGroupCheckboxChange,
            colSpan: columns,
          })
        )
      }

      // phase plant rows
      phaseData.plants.forEach((entry: PlantListEntry) => {
        const isPlantSelected = groupSelectedPlants.some((plant) => plant.id === entry.id)

        // create phase plant row cells
        const phaseRowCells: FlatPlantTableCellProps[] = []

        // show selected checkbox if needed
        if (showSelected) {
          allowSelection
            ? phaseRowCells.push({
                node: <Checkbox checked={isPlantSelected} onChange={() => handleCheckboxChange(entry)} />,
              })
            : phaseRowCells.push({ node: <Check /> })
        }

        // show plant name
        phaseRowCells.push({ name: entry.common_name || entry.scientific_name || 'N/A' })

        // show specs
        const specs = getFormattedPlantListEntrySpecs({ entry })
        specs.length
          ? phaseRowCells.push({
              node: (
                <Stack direction="row" sx={{ gap: 1 }}>
                  {specs.map((spec) => (
                    <Chip key={spec} label={spec} />
                  ))}
                </Stack>
              ),
            })
          : phaseRowCells.push({
              name: 'N/A',
            })

        // show quantity if needed
        if (showQuantity) {
          const cellColSpan = allowRemoval ? 2 : 1
          allowQuantityEdit
            ? phaseRowCells.push({
                node: (
                  <TextField
                    type="number"
                    value={quantities[entry.id] || 1}
                    onChange={(e) => handleQuantityChange(entry.id, parseInt(e.target.value))}
                  />
                ),
                colSpan: cellColSpan,
              })
            : phaseRowCells.push({ name: `${quantities[entry.id] || 1}`, colSpan: cellColSpan })
        }

        // create phase row
        const phaseRow: FlatPlantTableRowProps = {
          cells: phaseRowCells,
          rowItemSx: {
            borderBottom: '1px solid',
            borderBottomColor: theme.palette.lightGrey2.main,
          },
        }

        // add phase row to rows
        rows.push(phaseRow)
      })
    }
  })

  return rows
}

interface JobTitlePlantTableRowProps {
  jobData: Job
  jobPlants: PlantListEntry[]
  colSpan: number
}
const JobTitlePlantTableRow = ({ jobData, jobPlants, colSpan }: JobTitlePlantTableRowProps) => {
  if (!jobData) return {} as FlatPlantTableRowProps

  const title = `${pluralizedLabel(jobPlants.length, 'Plant', 'Plants')} in Job #${jobData.id}`
  const expectedStartDate = `Expected Start Date: ${
    formatStringToShortMonthDayYear(jobData.job_date as string) || 'TBD'
  }`

  // create job row
  const jobRow: FlatPlantTableRowProps = {
    cells: [
      {
        node: (
          <Stack direction="row" sx={{ justifyContent: 'space-between', pt: 2 }}>
            <Stack direction="row" sx={{ pl: 2, gap: 1, alignItems: 'center' }}>
              <Typography fontWeight={700} variant="body1">
                {title}
              </Typography>
              <Typography fontWeight={400} variant="body2">
                -
              </Typography>
              <Typography fontWeight={400} variant="body2">
                {jobData.name}
              </Typography>
            </Stack>
            <Typography fontWeight={400} variant="body2">
              {expectedStartDate}
            </Typography>
          </Stack>
        ),
        colSpan: colSpan,
      },
    ],
    rowItemSx: { borderBottom: '2px solid', borderBottomColor: theme.palette.mediumGrey3.main },
  }

  return jobRow
}

interface PhaseTitlePlantTableRowProps {
  phaseData: any
  selectedPlants: PlantListEntry[]
  handleGroupCheckboxChange: (group: string, group_id: string, entries: PlantListEntry[]) => void
  colSpan: number
}
const PhaseTitlePlantTableRow = ({
  phaseData,
  selectedPlants,
  handleGroupCheckboxChange,
  colSpan,
}: PhaseTitlePlantTableRowProps) => {
  if (!phaseData) return {} as FlatPlantTableRowProps

  const isChecked = selectedPlants.length === phaseData.plants.length
  const isIndeterminate = selectedPlants.length !== phaseData.plants.length && Boolean(selectedPlants.length)
  const updatedSelection = isChecked ? [] : phaseData.plants

  // create phase row cells
  const phaseRowCells: FlatPlantTableCellProps[] = [
    {
      node: (
        <Checkbox
          checked={isChecked}
          indeterminate={isIndeterminate}
          onChange={() => handleGroupCheckboxChange('phase', phaseData.id, updatedSelection)}
        />
      ),
    },
    {
      node: (
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Stack direction="row" sx={{ gap: 1 }}>
            <Typography variant="body2" fontWeight={700}>
              Phase {phaseData.number || 'Unallocated'} -
            </Typography>
            <Typography variant="body2">{phaseData.name || 'N/A'}</Typography>
          </Stack>
          <Stack direction="row" sx={{ gap: 1 }}>
            <Typography variant="body2">Expected Start Date:</Typography>
            <Typography variant="body2">{phaseData.expectedStartDate || 'TBD'}</Typography>
          </Stack>
        </Stack>
      ),
      colSpan: colSpan,
    },
  ]

  // create phase row
  const phaseRow: FlatPlantTableRowProps = {
    cells: phaseRowCells,
    rowItemSx: {
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.lightGrey2.main,
    },
  }

  return phaseRow
}
