import api from './api'
import { NewRfpFormData, PlantListEntry, RequestForProposal, SentRFP } from '@/types.ts'

export async function createRfp(data: NewRfpFormData): Promise<RequestForProposal> {
  const response = await api.post('/v1/core/rfp/', data)
  return response.data
}

export async function getRfp(rfpId: string): Promise<RequestForProposal> {
  const response = await api.get(`/v1/core/rfp/${rfpId}`)
  return response.data
}

export async function updateRfp(rfpId: string, data: Partial<RequestForProposal>): Promise<RequestForProposal> {
  const response = await api.put(`/v1/core/rfp/${rfpId}`, data)
  return response.data
}

export async function patchRfp(rfpId: string, data: Partial<RequestForProposal>): Promise<RequestForProposal> {
  const response = await api.patch(`/v1/core/rfp/${rfpId}`, data)
  return response.data
}

export async function deleteRfp(rfpId: string): Promise<string> {
  const response = await api.delete(`/v1/core/rfp/${rfpId}`)
  return response.data
}

export type CreateSentRfpRequest = {
  rfpId: string
  organizationId: string
  directed_organizations: {
    directed_organization_contact: string
    directed_organization_user_contacts: string[]
    notification_subject?: string
    notification_body?: string
    send?: boolean
  }[]
  plants: PlantListEntry[]
}
export async function createSentRfp({
  rfpId,
  organizationId,
  directed_organizations,
  plants,
}: CreateSentRfpRequest): Promise<SentRFP[]> {
  const response = await api.post('/v1/core/sent_rfp/', {
    organization: organizationId,
    rfp_id: rfpId,
    directed_organizations,
    plants,
  })

  // Not sure why the /v1/core/sent_rfp POST endpoint returns an array of SentRFPs
  return response.data
}

export async function getSentRfpsByRfpId(rfpId: string, organizationId: string): Promise<SentRFP[]> {
  const response = await api.get(`/v1/core/sent_rfp/?rfp_id=${rfpId}&organization=${organizationId}`)
  return response.data
}

export type UpdateSentRfpRequest = {
  sentRfpId: string
  data: any
}

export async function updateSentRfp({ sentRfpId, data }: UpdateSentRfpRequest): Promise<SentRFP> {
  const response = await api.patch(`/v1/core/sent_rfp/${sentRfpId}`, data)
  return response.data
}

export async function deleteSentRfp(sentRfpId: string): Promise<string> {
  const response = await api.delete(`/v1/core/sent_rfp/${sentRfpId}`)
  return response.data
}
