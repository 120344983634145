import { Typography, Stack, Chip } from '@mui/material'
import Paper from '@mui/material/Paper'
import { OrganizationLogo } from '@/components/ui/organization-logo.tsx'
import { Organization, OrganizationContact } from '@/types.ts'
import { getOrganizationCardDetails, getOrganizationContactCardDetails } from '@/lib/utils.ts'
import { DomesticAddress } from '@/components/ui/base/domestic-address.tsx'
import Box from '@mui/material/Box'

export interface OrganizationDetailsProps {
  organization?: Organization | null | undefined
  organizationContact?: OrganizationContact | null
  displayLogo?: boolean
  displayAddress?: boolean
  displayVerified?: boolean
}

export const OrganizationDetails = ({
  organization,
  organizationContact,
  displayLogo = true,
  displayVerified = false,
  displayAddress = true,
}: OrganizationDetailsProps) => {
  const orgDetails = organization
    ? getOrganizationCardDetails(organization)
    : organizationContact
      ? getOrganizationContactCardDetails(organizationContact)
      : null

  if (!orgDetails) return null

  return (
    <Paper elevation={0} sx={{ display: 'flex', gap: '20px' }}>
      {displayLogo && (
        <Stack>
          <OrganizationLogo logoFile={orgDetails.small_logo} size={60} />
        </Stack>
      )}

      <Stack>
        <Typography variant="h2" gutterBottom sx={{ fontWeight: 500 }}>
          {orgDetails.name}
        </Typography>

        {displayAddress && <DomesticAddress address={orgDetails.address} />}

        <Typography color="text.secondary">Phone: {orgDetails.phone || 'n/a'}</Typography>

        <Typography color="text.secondary">Email: {orgDetails.email || 'n/a'}</Typography>

        {displayVerified && orgDetails.verified && (
          <Box>
            <Chip
              label="VERIFIED"
              color="primary"
              sx={{ borderRadius: 1, height: 'auto', fontWeight: 700, fontSize: '10px', px: 0, py: 0.2 }}
            />
          </Box>
        )}
      </Stack>
    </Paper>
  )
}
