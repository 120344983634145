import { alpha, Chip, Stack, Typography } from '@mui/material'
import theme from '@/theme.ts'
import { GetPricingFromPlantbidCard } from '@/components/ui/plantbid-get-pricing-card.tsx'
import { FlagSharp } from '@mui/icons-material'
import { SentRfpCardDropdownMenu } from '@/components/rfps/details/sent-rfp-card-dropdown-menu.tsx'
import { RfpStatus, SentRFP } from '@/types.ts'
import { ReactNode } from 'react'
import { getDefaultPlantbidOrganizationId } from '@/lib/utils.ts'

export const RfpVendorInfoCard = ({
  rfpId,
  selectedRfpId,
  rfp,
  info,
  openConfirmationModal,
  onClick,
}: {
  rfpId: string
  selectedRfpId: string | null
  info: ReactNode
  rfp: SentRFP
  openConfirmationModal: (options: any) => Promise<boolean>
  onClick?: () => void
}) => {
  const isSelected = selectedRfpId === rfp.id
  const organizationName = rfp.directed_organization.name || 'N/A'
  const defaultPlantbidOrganizationId = getDefaultPlantbidOrganizationId()

  return (
    <Stack
      sx={{
        py: 1,
        borderTop: `1px solid ${theme.palette.lightGrey2.main}`,
      }}
      key={rfp.id}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: 'flex-start',
          py: 1,
          pl: 2,
          pr: 0.5,
          border: '1px solid',
          borderRadius: theme.borderRadius.md,
          borderColor: isSelected ? alpha(theme.palette.primary.main, 0.08) : 'transparent',
          bgcolor: isSelected ? alpha(theme.palette.primary.main, 0.08) : 'transparent',
          '&:hover': {
            bgcolor: isSelected ? alpha(theme.palette.primary.main, 0.12) : alpha(theme.palette.action.hover, 0.04),
          },
        }}
      >
        <Stack
          sx={{
            flexGrow: 1,
            color: isSelected ? 'primary.main' : 'text.primary',
          }}
        >
          {rfp.directed_organization?.id === defaultPlantbidOrganizationId ? (
            <GetPricingFromPlantbidCard showPopper={false} onClick={onClick} sx={{ pl: 0 }} />
          ) : (
            <Stack onClick={onClick} sx={{ cursor: 'pointer' }}>
              <Typography key={rfp.id} variant="body1" fontWeight={700}>
                {organizationName}
              </Typography>
              {typeof info === 'string' ? (
                <Typography variant="body2" color="text.secondary">
                  {info}
                </Typography>
              ) : (
                info
              )}
            </Stack>
          )}
        </Stack>
        <Stack direction="row" gap={1}>
          {rfp.flagged_as_important_by_customer && (
            <Stack sx={{ py: 1, color: theme.palette.mediumRed1.main }}>
              <FlagSharp />
            </Stack>
          )}
          {rfp.status === RfpStatus.SENT && (
            <Chip
              label="SENT"
              color="primary"
              sx={{ borderRadius: 1, height: 'auto', fontWeight: 700, fontSize: '10px', px: 0, py: 0.1 }}
            />
          )}
          <SentRfpCardDropdownMenu rfpId={rfpId} sentRfp={rfp} openConfirmationModal={openConfirmationModal} />
        </Stack>
      </Stack>
    </Stack>
  )
}
