import { useQuery } from '@tanstack/react-query'
import { useState, Dispatch, SetStateAction, useEffect } from 'react'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { getContacts } from '@/api/contacts.ts'
import SearchBar from '@/components/ui/base/search-bar.tsx'
import { ContactTypes } from '@/constants.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { ElasticsearchResponse, OrganizationContact, PlantListEntry, SentRFP } from '@/types.ts'

import VendorGroups from './vendor-groups'
import { CustomDivider } from '@/components/ui/base/dividers.tsx'
import { VendorPanelSearchResults } from '@/components/rfps/draft/steps/vendor-panel-search-results.tsx'

export default function VendorPanel({
  sentRfps,
  onContactSelect,
  setSentRfp,
  sentRfpId,
  onVendorGroupAddClick,
}: {
  sentRfps: SentRFP[]
  onContactSelect: (contact: OrganizationContact) => void
  setSentRfp: Dispatch<SetStateAction<any>>
  onVendorGroupAddClick: (plants: PlantListEntry[]) => void
  sentRfpId?: string
}) {
  const { selectedOrganization } = useOrganization()
  const [elasticsearchVendorData, setElasticsearchVendorData] =
    useState<ElasticsearchResponse<OrganizationContact> | null>(null)
  const [disabledContacts, setDisabledContacts] = useState<string[]>([])
  const [filteredSentRfps, setFilteredSentRfps] = useState<SentRFP[]>([])
  const [selectedSentRfpId, setSelectedSentRfpId] = useState<string>()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const minimumSearchLength = 3

  const { data: defaultVendorData } = useQuery({
    queryKey: ['vendors', selectedOrganization?.id],
    queryFn: async (): Promise<OrganizationContact[]> => {
      // direct conversion to OrganizationContact[] is not possible - api response need fixing
      return (await getContacts(
        ContactTypes.ORGANIZATION,
        selectedOrganization?.id
      )) as unknown as OrganizationContact[]
    },
    enabled: !!selectedOrganization,
  })

  useEffect(() => {
    setSelectedSentRfpId(sentRfpId)
  }, [sentRfpId])

  // handle sent rfp and elasticsearch result changes
  useEffect(() => {
    const sentRfpData = sentRfps || []
    const sentRfpIds = sentRfpData.map((rfp) => rfp.directed_organization_contact.id)

    // no elasticsearch results and sent rfp data
    if (!elasticsearchVendorData?.items) {
      setFilteredSentRfps(sentRfpData)
      setDisabledContacts(sentRfpIds)
      return
    }

    // filtered/exclude sent rfp & elasticsearch results
    const elasticsearchVendorIds = elasticsearchVendorData.items.map((data) => data.id)
    const matchingSentRfps = sentRfpData.filter((rfp) =>
      elasticsearchVendorIds.includes(rfp.directed_organization_contact.id)
    )
    matchingSentRfps.length > 0 ? setFilteredSentRfps(matchingSentRfps) : setFilteredSentRfps(sentRfpData)
    setDisabledContacts(sentRfpIds)
  }, [sentRfps, elasticsearchVendorData])

  const handleVendorClick = (sentRfp: any) => {
    setSelectedSentRfpId(sentRfp.id)
    setSentRfp(sentRfp)
  }

  return (
    <Stack gap={2}>
      <Typography variant="h3" fontWeight={400}>
        Send RFP to
      </Typography>

      {sentRfps.length > 0 && (
        <VendorGroups
          sentRfps={filteredSentRfps}
          selectedSentRfpId={selectedSentRfpId || null}
          onVendorClick={handleVendorClick}
          onVendorGroupAddClick={onVendorGroupAddClick}
        />
      )}

      <SearchBar
        placeholder="Search..."
        searchUrl={`/v1/core/contact/${ContactTypes.ORGANIZATION}/search/`}
        perPage={10}
        page={1}
        minimumSearchLength={0}
        onSearchValueChange={(value) => setSearchTerm(value)}
        searchType="organization_contacts"
        onSearchResults={(response) => {
          setElasticsearchVendorData(response as ElasticsearchResponse<OrganizationContact>)
        }}
      />

      <CustomDivider size="1.5px" />

      <VendorPanelSearchResults
        contacts={
          searchTerm.length >= minimumSearchLength ? elasticsearchVendorData?.items || [] : defaultVendorData || []
        }
        disabledContacts={disabledContacts}
        minimumSearchLength={minimumSearchLength}
        onClick={onContactSelect}
        searchTerm={searchTerm}
      />
    </Stack>
  )
}
