import { type MouseEvent, useState } from 'react'

import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { CirclePlus } from 'lucide-react'

import NewContactModal from './modals/new-contact-modal.tsx'
import NotImplementedModal from './modals/not-implemented-modal.tsx'
import NewJobModal from '@/components/ui/modals/new-job-modal.tsx'
import CreateEditRfpModal from '@/components/rfps/modals/create-edit-rfp-modal.tsx'

const notImplemented = ['new_message', 'create_quote', 'create_order', 'schedule_delivery', 'new_invoice']

export default function GlobalNewButton() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openModal, setOpenModal] = useState<string | null>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleModalOpen = (modalType: string) => {
    setOpenModal(modalType)
    handleClose()
  }
  const handleModalClose = () => {
    setOpenModal(null)
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClick}
        sx={{
          minWidth: '50px',
          width: '50px',
          height: '50px',
          padding: 0,
        }}
      >
        <CirclePlus />
      </Button>

      <Menu open={open} anchorEl={anchorEl} onClose={handleClose} slotProps={{ paper: { sx: { width: 236 } } }}>
        <MenuItem onClick={() => handleModalOpen('new_message')}>New Message</MenuItem>
        <MenuItem onClick={() => handleModalOpen('add_contact')}>Add Contact</MenuItem>
        <Divider />
        <MenuItem onClick={() => handleModalOpen('create_quote')}>Create Quote</MenuItem>
        <MenuItem onClick={() => handleModalOpen('new_rfp')}>New RFP</MenuItem>
        <Divider />
        <MenuItem onClick={() => handleModalOpen('new_job')}>New Job</MenuItem>
        <MenuItem onClick={() => handleModalOpen('create_order')}>Create Order</MenuItem>
        <MenuItem onClick={() => handleModalOpen('schedule_delivery')}>Schedule Delivery</MenuItem>
        <MenuItem onClick={() => handleModalOpen('new_invoice')}>New Invoice</MenuItem>
      </Menu>

      {openModal === 'add_contact' && <NewContactModal open={true} onClose={handleModalClose} />}

      {openModal === 'new_job' && <NewJobModal open={true} onClose={handleModalClose} />}

      {openModal === 'new_rfp' && <CreateEditRfpModal open={true} onClose={handleModalClose} />}

      {/* Placeholder for other modals */}
      {openModal && notImplemented.includes(openModal) && (
        <NotImplementedModal open={true} onClose={handleModalClose} />
      )}
    </>
  )
}
