import theme from '@/theme.ts'
import { Box, Modal, Stack, Typography } from '@mui/material'
import add_vendor_to_rfp_illustration from '@/assets/add-vendors-to-rfp-illustration.png'
import { AccountCircleOutlined } from '@mui/icons-material'

export const RFPFeatureAddVendorIllustration = ({ open = true, onClose = () => {} }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="add-vendor-modal-title"
      aria-describedby="add-vendor-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        height: '100%',
        width: '100%',
        p: 0,
      }}
      slotProps={{
        backdrop: {
          sx: {
            position: 'absolute',
            boxShadow: '0px 4px 44px 0px rgba(0, 0, 0, 0.2)',
            backgroundColor: 'rgba(255,255,255,0.5)',
          },
        },
      }}
      disableAutoFocus
      disableEnforceFocus
      disablePortal
    >
      <Box
        id="primary"
        sx={{
          width: 400,
          backgroundColor: 'white',
          borderRadius: theme.borderRadius.lg,
          boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.3)',
          zIndex: 1,
          position: 'relative',
        }}
      >
        <Box
          component="img"
          src={add_vendor_to_rfp_illustration}
          alt="add_vendor_to_rfp_modal_illustration"
          sx={{
            pt: 3,
            pl: 3,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '200px',
          }}
        />
        <Stack sx={{ p: 3, gap: 2 }}>
          <Stack direction="row" sx={{ gap: 1 }}>
            <AccountCircleOutlined />
            <Typography variant="body2" fontWeight={700} sx={{ alignContent: 'center' }}>
              Add vendors to this RFP
            </Typography>
          </Stack>
          <Typography variant="body1">
            Once you add vendors you'll be able to customize the plants and quantity for each
          </Typography>
        </Stack>
      </Box>
    </Modal>
  )
}
