/**
 * Tanstack is a double edged sword. When we make generic components, we can't always boldly update
 * the URL without knowing what route we're on without typescript freaking out. This hook is a
 * workaround to allow us to update the search url params without knowing what route we're on.
 * @returns function to update the search searchParam
 */
export const useBoldlySearch = () => {
  const updateSearch = (searchTerm?: string) => {
    const url = new URL(window.location.href)
    const currentSearch = url.searchParams.get('search') || ''

    if (!searchTerm) {
      if (url.searchParams.has('search')) {
        url.searchParams.delete('search')
        history.pushState({}, '', url)
      }
      return
    }

    if (currentSearch !== searchTerm) {
      url.searchParams.set('search', searchTerm)
      history.pushState({}, '', url)
    }
  }

  return updateSearch
}
