import {
  PlantRelatedObjectsTableData,
  PlantRelatedObjectsTableItem,
  RelatedObjectItem,
} from '@/components/rfps/plant-related-objects-table.tsx'
import { Box, Chip, Stack, Typography } from '@mui/material'
import { ArrowLeft, MoveRight, TriangleAlert } from 'lucide-react'
import theme from '@/theme.ts'
import { CustomDivider } from '@/components/ui/base/dividers.tsx'
import IconButton from '@mui/material/IconButton'
import { PrimaryCancelButton } from '@/components/ui/base/buttons/buttons.tsx'
import Button from '@mui/material/Button'
import { PlantListEntry } from '@/types'
import { GlobalEditPlantListEntryChange } from '@/components/ui/modals/global-edit-plant-list-entry-modal.tsx'

interface ReviewPlantDetailsContentProps {
  entry: PlantListEntry
  updatedEntry: PlantListEntry
  specChange: GlobalEditPlantListEntryChange[]
  taxonomyChange: boolean
  relatedObjectData: PlantRelatedObjectsTableData
  setEditMode: (editMode: boolean) => void
  onUpdate: (updatedEntry: PlantListEntry) => void
  allowReview: boolean
  handleClose: () => void
}
export const ReviewPlantDetailsContent = ({
  entry,
  updatedEntry,
  specChange,
  taxonomyChange,
  relatedObjectData,
  setEditMode,
  onUpdate,
  allowReview,
  handleClose,
}: ReviewPlantDetailsContentProps) => {
  const ChangedObjects = ({ item }: { item: PlantRelatedObjectsTableItem }) => {
    return (
      <Stack direction="row" sx={{ gap: 1 }}>
        <TriangleAlert size={20} />
        <RelatedObjectItem item={item} />
      </Stack>
    )
  }

  const submitReviewChanges = () => {
    {
      /* Todo: Implement submit review changes - need to check with backend how this is implemented */
    }
    console.log('Not implemented')
    onUpdate(updatedEntry)
  }

  return (
    <Stack sx={{ p: 4, gap: 2 }}>
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <Stack direction="row" sx={{ gap: 1 }}>
          <Typography variant="h2">{entry.common_name || 'N/A'}</Typography>
          {specChange.length ? (
            <Chip
              size="small"
              label="Spec Change"
              sx={{
                backgroundColor: '#CD8746',
                color: 'white',
                borderRadius: theme.borderRadius.sm,
              }}
            />
          ) : null}
          {taxonomyChange ? (
            <Chip
              size="small"
              label="Taxonomy Change"
              sx={{ backgroundColor: '#CD87C6', color: 'white', borderRadius: theme.borderRadius.sm }}
            />
          ) : null}
        </Stack>
        <Stack sx={{ gap: 1, maxWidth: 500 }}>
          {specChange.map((change) => (
            <Stack
              key={change.label}
              direction="row"
              sx={{
                borderRadius: theme.borderRadius.xl,
                alignItems: 'center',
                gap: 0.5,
                backgroundColor: theme.palette.lightGrey3.main,
                border: `1px solid ${theme.palette.lightGrey2.main}`,
                px: 1,
                py: 0.5,
              }}
            >
              <Typography variant="body2">{change.label}:</Typography>
              <Typography variant="body2" sx={{ textDecoration: 'line-through' }}>
                {change.changedFrom || 'none'}
              </Typography>
              <MoveRight size={12} />
              <Typography variant="body2">{change.changedTo || 'none'}</Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Stack direction="row">
        <Typography variant="body1">
          This item’s specs will be changed from
          {specChange.map((change, index) => (
            <Box component="span" key={index} sx={{ fontWeight: 700 }}>
              {` ${change.label}: ${change.changedFrom || 'none'} to ${change.changedTo || 'none'}${index == specChange.length - 2 ? ' and ' : ', '}`}
            </Box>
          ))}
          in the following documents. Affected contacts will be notified of changes.
        </Typography>
      </Stack>
      <Stack>
        {relatedObjectData.job ? <ChangedObjects item={relatedObjectData.job} /> : null}
        {relatedObjectData.rfp.length
          ? relatedObjectData.rfp.map((item) => <ChangedObjects key={item.id} item={item} />)
          : null}
        {relatedObjectData.quote.length
          ? relatedObjectData.quote.map((item) => <ChangedObjects key={item.id} item={item} />)
          : null}
        {relatedObjectData.order.length
          ? relatedObjectData.order.map((item) => <ChangedObjects key={item.id} item={item} />)
          : null}
      </Stack>
      <Stack sx={{ py: 2 }}>
        <CustomDivider />
      </Stack>
      <Stack direction="row" sx={{ gap: 2, justifyContent: 'space-between' }}>
        <Stack>
          <IconButton
            component="button"
            onClick={() => setEditMode(true)}
            sx={{ gap: 1, borderRadius: theme.borderRadius.sm }}
          >
            <ArrowLeft size={20} />
            <Typography variant="strong">Back</Typography>
          </IconButton>
        </Stack>
        <Stack direction="row" sx={{ gap: 2 }}>
          <PrimaryCancelButton onClick={handleClose}>Cancel & Close</PrimaryCancelButton>
          <Button variant="contained" onClick={submitReviewChanges} disabled={!allowReview} sx={{ px: 3 }}>
            <Typography variant="strong">Publish & Notify Vendors</Typography>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
