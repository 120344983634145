import { OrganizationPermissionTypes } from '@/constants.ts'
import { Organization, User } from '@/types.ts'
import { excludeDeletedItems } from '@/lib/utils.ts'

/**
 * Organization roles and permissions mapping
 */
type OrgRolesPermissions = {
  [organizationId: string]: {
    roles: string[]
    permissions: string[]
  }
}

/**
 * Interface for resources with owner and organization
 */
interface OwnedResource {
  owner?: {
    id: string
    deleted_at: string | null
  }
  organization?: Organization
}

/**
 * Get permissions of user in the organizations accessible to them
 */
export function getUserOrganizationRolePermissions(user: User | undefined): OrgRolesPermissions {
  if (!user) return {}

  // get active memberships
  const memberships = excludeDeletedItems(user.organization_members)

  // extract active roles and permissions from active memberships
  const permissions = memberships.reduce<OrgRolesPermissions>((acc, membership) => {
    const orgId = membership.organization.id
    const activeRoles = excludeDeletedItems(membership.roles)
    const roles = activeRoles.map((role) => role.name)
    const permissions = Array.from(new Set(activeRoles.flatMap((role) => role.permissions)))

    acc[orgId] = { roles, permissions }
    return acc
  }, {})

  return permissions
}

/**
 * Check if user has permission in the organization
 */
export function checkUserOrganizationPermission(
  user: User | undefined,
  organization: Organization | undefined,
  requiredPermissions: OrganizationPermissionTypes[] = [],
  strict: boolean = true
): boolean {
  if (!user || !organization) return false

  // get user permissions in the organization
  const userRolePermissions = getUserOrganizationRolePermissions(user)
  const organizationRolePermissions = userRolePermissions[organization.id] || {}
  const organizationPermissions = organizationRolePermissions.permissions || []

  // no further check required if user has no permissions in the organization or matching organization is not found
  if (organizationPermissions.length === 0) return false

  // user permission matching required permissions
  const requiredPermissionsSet = new Set(requiredPermissions)
  const matchingPermissions = Array.from(requiredPermissionsSet).filter((permission) =>
    organizationPermissions.includes(permission)
  )

  // check permission
  const hasRequiredPermissions = matchingPermissions.length === requiredPermissionsSet.size
  const hasSomePermissions = matchingPermissions.length > 0
  return strict ? hasRequiredPermissions : hasSomePermissions
}

/**
 * Check if resource has a valid owner
 */
export function checkResourceHasValidOwner(resource: OwnedResource): boolean {
  return Boolean(resource?.owner) && resource.owner?.deleted_at === null
}

/**
 * Check if resource has a valid organization
 * - organization in resources does not currently have deleted_at field - for now check if it at least exists
 */
export function checkResourceHasValidOrganization(resource: OwnedResource): boolean {
  return Boolean(resource?.organization)
}

/**
 * Validate resource for further checks
 *
 * Checks enforced:
 *   - resource exists
 *   - resource has valid organization
 *   - resource has valid owner
 */
export function validateResource(resource: OwnedResource | null | undefined, strict: boolean = true): boolean {
  if (!resource) return false

  if (strict) {
    if (!checkResourceHasValidOrganization(resource)) return false
    if (!checkResourceHasValidOwner(resource)) return false
  }

  return true
}

/**
 * Check if user is the owner of the resource
 */
export function checkUserOwnsResource(
  user: User | undefined,
  resource: OwnedResource | null | undefined,
  strict: boolean = true
): boolean {
  // validation
  if (!user || !resource) return false
  if (strict && !validateResource(resource)) return false

  // Check ownership
  return Boolean(resource.owner) && user.id === resource.owner?.id
}

/**
 * Check if user has ownership permission for the resource's organization
 */
export function checkUserHasResourceOwnerPermission(
  user: User | undefined,
  resource: OwnedResource | null | undefined,
  strict: boolean = true
): boolean {
  // validation
  if (!user || !resource) return false
  if (strict && !validateResource(resource)) return false

  // check permission
  const requiredPermissions = [OrganizationPermissionTypes.OWNER]
  return checkUserOrganizationPermission(user, resource.organization, requiredPermissions, strict)
}

/**
 * Check if user has delete permission for a resource
 *
 * Conditions for deletion:
 * - User is the owner of the Resource, or
 * - User has OWNER permission in the organization that owns the resource
 **/
export function checkResourceDeletePermission(
  resource: OwnedResource | null | undefined,
  user: User | undefined,
  strict: boolean = true
): boolean {
  // validation
  if (!resource || !user) return false

  // check permission
  const userIsOwner = checkUserOwnsResource(user, resource, strict)
  const userHasOwnershipPermission = checkUserHasResourceOwnerPermission(user, resource, strict)
  return userIsOwner || userHasOwnershipPermission
}
