import { RFPDetailsProps } from '@/components/rfps/types.ts'
import { formatStringToFullMonthDayYear } from '@/lib/utils.ts'
import { DetailsSection } from '@/components/ui/details-section.tsx'
import { DetailsSectionField, DetailsSectionProps } from '@/types.ts'
import { DetailsSectionText } from '@/components/ui/details-section-text.tsx'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/material'
import { ClearTextIconButton } from '@/components/ui/base/buttons/text-icon-buttons.tsx'
import EditOutlined from '@mui/icons-material/EditOutlined'
import { CommentableElement } from '@/components/ui/commentable-element.tsx'
import { CommentPage } from '@/types/comments.ts'

export const RFPDetails = ({ rfpData, editMode, openEditRfpModal }: RFPDetailsProps) => {
  const quoteData = (): DetailsSectionField => {
    const defaultText = 'TBD'
    const formattedDate = formatStringToFullMonthDayYear(rfpData.quote_needed_by) || defaultText
    const content = (
      <CommentableElement
        pathObject=""
        pathId={rfpData.id}
        targetPath="quote_needed_by"
        itemName="RFP Quote Date"
        targetPage={CommentPage.RFP}
        targetId={rfpData.id}
      >
        <DetailsSectionText text={formattedDate} />
      </CommentableElement>
    )
    return { label: 'Quote Needed By', node: content }
  }

  const detailsDataTitle = (): DetailsSectionField => {
    const content = (
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <Typography variant="body1" sx={{ fontWeight: 700 }}>
          RFP Details
        </Typography>
        {editMode && (
          <Stack direction="row">
            <ClearTextIconButton
              text="Edit"
              onClick={openEditRfpModal}
              startIcon={<EditOutlined />}
              sx={{ p: 0, color: '#ccc' }}
            />
          </Stack>
        )}
      </Stack>
    )
    return { label: 'RFP Details', node: content }
  }

  const phaseData = (): DetailsSectionField => {
    const contentText =
      rfpData.phase === 'bidding'
        ? 'Bidding - Collecting Quotes'
        : rfpData.phase === 'buying'
          ? 'Buying - Ready to Buy Plants'
          : 'TBD'
    const content = (
      <CommentableElement
        pathObject=""
        pathId={rfpData.id}
        targetPath="phase"
        itemName="Phase"
        targetPage={CommentPage.RFP}
        targetId={rfpData.id}
      >
        <DetailsSectionText text={contentText} />
      </CommentableElement>
    )
    return { label: 'RFP Phase', node: content }
  }

  const detailsData = {
    title: detailsDataTitle(),
    fields: [quoteData(), phaseData()],
    defaultNode: null,
  } as DetailsSectionProps

  return DetailsSection(detailsData)
}
