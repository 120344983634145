import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { Comment, CommentThread, CommentSpecifier, TargetPage, CommentPage } from '@/types/comments.ts'
import {
  addCommentToThread as apiAddCommentToThread,
  createCommentThread as apiCreateCommentThread,
  getCommentThreadsByTarget,
  markCommentAsRead,
  resolveCommentThread,
} from '@/api/comments.ts'
import { CommentPost, CommentThreadPost } from '@/api/types/comments.ts'

interface CommentState {
  // Comment mode state
  isCommentMode: boolean
  commentType: 'note' | 'comment' | null
  commentText: string
  setCommentType: (type: 'note' | 'comment' | null) => void
  setCommentMode: (mode: boolean, type: 'note' | 'comment' | null) => void
  setCommentText: (text: string) => void

  // Comment data
  commentThreads: CommentThread[]
  isLoading: boolean
  error: string | null

  // Data fetching
  fetchThreadsForTarget: (page: CommentPage, targetId: string, organizationId: string) => Promise<CommentThread[]>

  // Actions
  createCommentThread: (
    specifier: CommentSpecifier,
    targetPage: TargetPage,
    targetPath: string,
    comment: string,
    organizationId: string,
    userId: string,
    isNote: boolean,
    directedOrganizationId?: string,
    sendToVendor?: boolean
  ) => Promise<CommentThread>
  addCommentToThread: (
    threadId: string,
    comment: string,
    userId: string,
    mentionedUserIds?: string[],
    mentionedDirectedUserIds?: string[]
  ) => Promise<Comment>
  resolveThread: (threadId: string) => Promise<void>
  markAsRead: (threadId: string, commentId: string) => Promise<void>

  // Selectors
  findThreadForSpecifier: (specifier: CommentSpecifier, targetPath: string) => CommentThread | null
  getThreadsForPage: (targetPage: TargetPage) => CommentThread[]
}

export const useCommentStore = create<CommentState>()(
  persist(
    (set, get) => ({
      // Comment mode state
      isCommentMode: false,
      commentType: null,
      commentText: '',
      setCommentType: (type: 'note' | 'comment' | null) => set({ commentType: type }),
      setCommentMode: (mode, type) =>
        set({ isCommentMode: mode, commentType: type, commentText: mode ? get().commentText : '' }),
      setCommentText: (text) => set({ commentText: text }),

      // Comment data
      commentThreads: [],
      isLoading: false,
      error: null,

      // Data fetching
      fetchThreadsForTarget: async (page, targetId, organizationId) => {
        set({ isLoading: true, error: null })
        try {
          const threads = await getCommentThreadsByTarget(page, targetId, organizationId)
          set((state) => {
            // Check if the threads are actually different before updating state
            const currentThreadIds = new Set(state.commentThreads.map((thread) => thread.id))
            const newThreadIds = new Set(threads.map((thread) => thread.id))

            // Only update if there are actual differences in the threads
            if (
              threads.length !== state.commentThreads.length ||
              threads.some((thread) => !currentThreadIds.has(thread.id)) ||
              state.commentThreads.some((thread) => !newThreadIds.has(thread.id))
            ) {
              return {
                commentThreads: mergeThreads(state.commentThreads, threads),
                isLoading: false,
              }
            }

            // If no differences, just update loading state
            return { isLoading: false }
          })
          return threads
        } catch (error) {
          set({
            isLoading: false,
            error: error instanceof Error ? error.message : `Failed to fetch comment threads: ${error}`,
          })
          return []
        }
      },

      // Actions
      createCommentThread: async (
        specifier,
        targetPage,
        targetPath,
        comment,
        organizationId,
        userId,
        isNote,
        directedOrganizationId,
        sendToVendor = false
      ) => {
        set({ isLoading: true, error: null })
        try {
          const threadData: CommentThreadPost = {
            organization: organizationId,
            resolved: false,
            target_path: targetPath,
            target_pages: [targetPage],
            comment_specifier: [specifier],
            directed_organization: isNote ? null : directedOrganizationId,
            send_to_vendor: sendToVendor,
            comments: [
              {
                comment,
                created_by: userId,
                organization_members: [],
                organization_user_contacts: [],
                directed_organization_members: [],
                directed_organization_user_contacts: [],
                unread: true,
              },
            ],
          }
          const newThread = await apiCreateCommentThread(threadData)

          set((state) => ({
            commentThreads: [...state.commentThreads, newThread],
            isLoading: false,
            commentText: '',
          }))
          return newThread
        } catch (error) {
          set({
            isLoading: false,
            error: error instanceof Error ? error.message : `Failed to create comment thread: ${error}`,
          })
          throw error
        }
      },
      addCommentToThread: async (threadId, comment, userId, mentionedUserIds = [], mentionedDirectedUserIds = []) => {
        set({ isLoading: true, error: null })
        try {
          const commentData: CommentPost = {
            comment,
            created_by: userId,
            organization_members: mentionedUserIds,
            organization_user_contacts: [],
            directed_organization_members: mentionedDirectedUserIds,
            directed_organization_user_contacts: [],
            unread: true,
          }
          const updatedThread = await apiAddCommentToThread(threadId, commentData)
          const newComment = updatedThread.comments[updatedThread.comments.length - 1]
          set((state) => ({
            commentThreads: state.commentThreads.map((thread) => (thread.id === threadId ? updatedThread : thread)),
            isLoading: false,
            commentText: '',
          }))
          return newComment
        } catch (error) {
          set({
            isLoading: false,
            error: error instanceof Error ? error.message : `Failed to add comment to thread: ${error}`,
          })
          throw error
        }
      },
      resolveThread: async (threadId) => {
        set({ isLoading: true, error: null })
        try {
          const updatedThread = await resolveCommentThread(threadId, true)
          set((state) => ({
            commentThreads: state.commentThreads.map((thread) => (thread.id === threadId ? updatedThread : thread)),
            isLoading: false,
          }))
        } catch (error) {
          set({
            isLoading: false,
            error: error instanceof Error ? error.message : `Failed to resolve thread: ${error}`,
          })
          throw error
        }
      },
      markAsRead: async (threadId, commentId) => {
        try {
          const updatedThread = await markCommentAsRead(threadId, commentId)
          set((state) => ({
            commentThreads: state.commentThreads.map((thread) => (thread.id === threadId ? updatedThread : thread)),
          }))
        } catch (error) {
          console.error('Failed to mark thread as read: ', error)
        }
      },

      // Selectors
      findThreadForSpecifier: (specifier, targetPath) => {
        return (
          get().commentThreads.find(
            (thread) =>
              thread.target_path === targetPath &&
              thread.comment_specifier.some(
                (s) => s.path_object === specifier.path_object && s.path_id === specifier.path_id
              )
          ) || null
        )
      },
      getThreadsForPage: (targetPage) => {
        return get().commentThreads.filter((thread) =>
          thread.target_pages.some((tp) => tp.page === targetPage.page && tp.target_id === targetPage.target_id)
        )
      },
    }),
    {
      name: 'comment-store',
      partialize: (state) => ({ commentThreads: state.commentThreads }),
    }
  )
)

// Helper function to merge threads and avoid duplicates
function mergeThreads(existingThreads: CommentThread[], newThreads: CommentThread[]) {
  const threadMap = new Map<string, CommentThread>()

  // Add existing threads to the map
  existingThreads.forEach((thread) => {
    threadMap.set(thread.id, thread)
  })

  // Add or update with new threads
  newThreads.forEach((thread) => {
    threadMap.set(thread.id, thread)
  })

  return Array.from(threadMap.values())
}
