import { ListSubheader, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

export type FilterOption = {
  label: string
  value: string
  options?: { label: string; value: string; activeLabel?: string }[]
  multiple?: boolean
  disabled?: string
}

export type FilterValues = Record<string, string | string[]> | undefined

interface FilterPanelProps {
  filters?: FilterOption[]
  filterValues: FilterValues
  onFilterChange: (filter: string, value: string | string[]) => void
}
export default function FilterPanel({ filters = [], filterValues, onFilterChange }: FilterPanelProps) {
  if (!filters.length) {
    return null
  }

  return (
    <Box display="flex" gap={1}>
      {filters.map((filter) => {
        return (
          <Select
            key={filter.value}
            value={filterValues?.[filter.value] || ''}
            onChange={(e) => onFilterChange(filter.value, e.target.value)}
            multiple={filter.multiple}
            displayEmpty
            disabled={filter.disabled ? true : undefined}
            renderValue={(selected) => {
              if (!selected || (Array.isArray(selected) && selected.length === 0)) {
                return filter.label
              }

              const findLabel = (value: string) => {
                const option = filter.options?.find((opt) => opt.value === value)
                return option?.activeLabel || option?.label || value
              }

              const value = Array.isArray(selected) ? selected.map(findLabel).join(', ') : findLabel(selected)
              return filter.disabled ? (
                <Tooltip title={filter.disabled}>
                  <span>{value}</span>
                </Tooltip>
              ) : (
                value
              )
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  borderRadius: '8px',
                  mt: 1,
                  py: 1,
                  border: '1px solid #909090',
                },
              },
            }}
            sx={{
              borderRadius: '8px',
              fontSize: '14px',
              backgroundColor: 'white',
              fontWeight: 700,
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#E0E0E0',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#E0E0E0',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#E0E0E0',
              },
              '& .MuiSelect-select': {
                paddingY: '8px',
              },
            }}
          >
            <ListSubheader
              sx={{
                fontSize: '16px',
                fontWeight: 700,
                lineHeight: '36px',
                color: 'black.main',
                py: 0,
              }}
            >
              {filter.label}
            </ListSubheader>
            {filter.options?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )
      })}
    </Box>
  )
}
