import type { Auth0ContextInterface } from '@auth0/auth0-react'
import { createRootRouteWithContext, Outlet, useSearch } from '@tanstack/react-router'
import { CssBaseline } from '@mui/material'
import React, { Suspense, useEffect } from 'react'
import Box from '@mui/material/Box'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Organization, ToastNotificationMessage } from '@/types'
import { UserInfo } from '@/types'
import { QueryClient } from '@tanstack/react-query'
import { zodValidator } from '@tanstack/zod-adapter'
import { z } from 'zod'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications'
import SideNav from '@/components/ui/side-nav'

const TanStackRouterDevtools = ['production', 'staging'].includes(import.meta.env.VITE_ENV)
  ? () => null // Render nothing in production
  : React.lazy(() =>
      // Lazy load in development
      import('@tanstack/router-devtools').then((res) => ({
        default: res.TanStackRouterDevtools,
        // For Embedded Mode
        // default: res.TanStackRouterDevtoolsPanel
      }))
    )

type RootContext = {
  auth: Auth0ContextInterface
  queryClient: QueryClient
  plantbid: {
    organization?: Organization['id']
    setOrganization: (organization: Organization['id']) => void
    user?: UserInfo
  }
}
/**
 * The context for authenticated routes.
 * Authenticated routes MUST have user and organization
 */
export type AuthenticatedContext = RootContext & {
  plantbid: {
    user: UserInfo
    organization: Organization['id']
  }
}

export const Route = createRootRouteWithContext<RootContext>()({
  component: Root,
  validateSearch: zodValidator(
    z.object({
      toast: z.custom<ToastNotificationMessage>().optional(),
    })
  ),

  loader: async ({ context }) => {
    const { plantbid } = context
    const orgs = plantbid.user?.user.organization_members

    return {
      orgs,
    }
  },
})

function Root() {
  const { toast } = useSearch({ strict: false })
  const { addToastNotification } = useToastNotifications()

  useEffect(() => {
    if (toast) {
      addToastNotification(toast)
      // remove the hidden URL params that drive toast notifications
      window.history.replaceState({}, '', window.location.pathname)
    }
  }, [toast, addToastNotification])

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <SideNav />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          margin: 'auto',
          height: '100vh',
        }}
      >
        <Outlet />
      </Box>
      <Suspense>
        <TanStackRouterDevtools position="top-right" />
        <ReactQueryDevtools initialIsOpen={false} />
      </Suspense>
    </Box>
  )
}
