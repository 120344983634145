import theme from '@/theme'
import { Stack } from '@mui/material'
import Typography, { TypographyProps } from '@mui/material/Typography'
import { Tooltip } from '@mui/material'
import { VisibilityOffOutlined } from '@mui/icons-material'
import EditOutlined from '@mui/icons-material/EditOutlined'

export default function VisibilityCallout({
  visible,
  itemName,
  editable = false,
  typographyVariant = 'body1',
  typographyColor = theme.palette.darkGray.main,
  onClick,
}: {
  visible: boolean
  itemName: string
  editable?: boolean
  typographyVariant?: TypographyProps['variant']
  typographyColor?: TypographyProps['color']
  onClick?: () => void
}) {
  const parentSx = editable
    ? {
        px: 1,
        py: 0.5,
        cursor: 'pointer',
        borderRadius: theme.borderRadius.xs,
        '&:has(.trigger:hover)': {
          backgroundColor: theme.palette.lightGrey3.main,
        },
        '& .trigger:hover + .iconContainer .defaultIcon': {
          display: 'none',
        },
        '& .trigger:hover + .iconContainer .hoverIcon': {
          visibility: 'visible',
          display: 'block',
        },
      }
    : { px: 1 }

  return (
    <Stack direction="row" alignItems="center" gap={1} onClick={onClick} sx={parentSx}>
      <Stack direction="row" alignItems="center" gap={1} className="trigger">
        <Typography fontWeight={400} variant={typographyVariant} color={typographyColor}>
          -
        </Typography>
        <Typography fontWeight={400} variant={typographyVariant} color={typographyColor}>
          {itemName}
        </Typography>
      </Stack>
      <Stack alignItems="center" className="iconContainer">
        {!visible && (
          <Tooltip placement="top" title="Name not visible to vendors">
            <VisibilityOffOutlined className="defaultIcon" sx={{ color: theme.palette.lightGrey2.main }} />
          </Tooltip>
        )}
        <EditOutlined className="hoverIcon" sx={{ display: 'none' }} />
      </Stack>
    </Stack>
  )
}
