import Modal from '@mui/material/Modal'
import { Box, Stack } from '@mui/material'
import theme from '@/theme.ts'
import ModalHeader from '@/components/ui/base/modal-header.tsx'
import { CustomDivider } from '@/components/ui/base/dividers.tsx'
import Button from '@mui/material/Button'
import { useState } from 'react'
import Typography from '@mui/material/Typography'
import TextField from '@/components/ui/base/text-field.tsx'
import { useRfpContext } from '@/components/rfps/context/rfp-context.tsx'
import { useMutation } from '@tanstack/react-query'
import { patchJob } from '@/api/jobs.ts'

export const EditRfpJobPhaseNameModal = ({
  open,
  name,
  job_id,
  phase_id,
  onClose,
  onSubmit,
}: {
  open: boolean
  name: string
  job_id: string
  phase_id?: string
  onClose?: () => void // optional onClose function to execute side effects in parent
  onSubmit?: (name: string) => void // optional onSubmit function to execute side effects in parent
}) => {
  const [updatedName, setUpdatedName] = useState<string>(name)
  const { setActiveJobId, setEditRfpJobPhaseNameModalOpen } = useRfpContext()
  const [loading, setLoading] = useState(false)

  const isJob = job_id && !phase_id
  const targetType = isJob ? 'Job' : 'Phase'

  const patchJobMutation = useMutation({
    mutationFn: async ({ id, name }: { id: string; name: string }) => {
      return await patchJob({ id, name })
    },
    onSuccess: () => {
      setLoading(false)
      onSubmit?.(updatedName)
      handleClose()
    },
    onError: (error) => {
      setLoading(false)
      console.error('Error updating job:', error)
    },
  })

  const handleJobNameUpdate = async () => {
    setLoading(true)
    await patchJobMutation.mutateAsync({ id: job_id, name: updatedName })
  }

  const handlePhaseNameUpdate = () => {
    console.log('update phase', updatedName)
  }

  const handleClose = () => {
    setActiveJobId('')
    setEditRfpJobPhaseNameModalOpen(false)
  }

  // Close modal if no job_id or phase_id
  if (!job_id && !phase_id) return

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition aria-labelledby="edit-rfp-job-phase-name-modal">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          width: { xs: '90%', lg: 800 },
          maxHeight: 900,
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
          borderRadius: theme.borderRadius.lg,
          p: 6,
        }}
      >
        <ModalHeader
          title={`Update ${targetType}`}
          variant="modalHeader3"
          sx={{ fontWeight: 400, pb: 1 }}
          onCloseButtonClick={onClose}
          id="edit-rfp-job-phase-name-modal-title"
        />

        <Stack>
          <Typography variant="body1" color={theme.palette.mediumGrey.main}>
            {`This will update all instance of this ${targetType} across all associated objects`}
          </Typography>
        </Stack>

        <TextField
          variant="outlined"
          placeholder={`${targetType} Name`}
          label={`${targetType} Name`}
          value={updatedName}
          sx={{ width: '50%', my: 4 }}
          onChange={(e) => setUpdatedName(e.target.value)}
        />

        <CustomDivider size="1" color={theme.palette.lightGrey2.main} />

        {/* Action Buttons */}
        <Stack direction="row" sx={{ pt: 2, gap: 2, justifyContent: 'flex-end' }}>
          <Button variant="text" sx={{ color: theme.palette.mediumGrey.main }} onClick={handleClose}>
            Cancel & Close
          </Button>

          <Button
            variant="contained"
            onClick={() => (isJob ? handleJobNameUpdate() : handlePhaseNameUpdate())}
            disabled={name === updatedName}
            loading={loading}
            sx={{
              width: 134,
              '&.Mui-disabled': {
                backgroundColor: theme.palette.mediumGrey.main,
                color: 'white',
              },
            }}
          >
            {`Update ${targetType}`}
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}
