import { PlantListEntry } from '@/types.ts'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import { EllipsisVertical, Trash2 } from 'lucide-react'
import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import PlantItemSpecs from '@/components/plant-list-importer/plant-item-specs.tsx'
import theme from '@/theme.ts'
import IconButton from '@mui/material/IconButton'
import { useRfpContext } from '@/components/rfps/context/rfp-context.tsx'
import { useCommentStore } from '@/stores/comment-store.ts'
import { CommentPage } from '@/types/comments.ts'
import { CommentableElement } from '@/components/ui/commentable-element.tsx'
import { PlantTableCell } from '../ui/base/tables/plant-table'

export default function RfpPlantTableItem({
  plant,
  onUpdate,
  isSelected,
  onSelect,
  onEditEntry,
  allowQuantityChange = false,
  allowSelect = false,
  showMenu = false,
  showDeleted = false,
}: {
  plant: PlantListEntry
  onUpdate: (updatedPlant: PlantListEntry) => void
  isSelected: boolean
  onSelect: (id: string) => void
  onEditEntry: (entry: PlantListEntry) => void
  allowQuantityChange?: boolean
  allowSelect?: boolean
  showMenu?: boolean
  showDeleted?: boolean
}) {
  const { rfpData, sentRfp, sentRfpData } = useRfpContext()
  const targetPage = sentRfp ? CommentPage.SENT_RFP : CommentPage.RFP
  const targetId = sentRfp?.id || rfpData.id
  const { isCommentMode } = useCommentStore()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [quantity, setQuantity] = useState<string | number>(plant.quantity_count.min ?? 1)
  const [isFocused, setIsFocused] = useState(false)

  const handleCheckboxClick = (event: React.MouseEvent, id: string) => {
    if (isCommentMode) {
      event.preventDefault()
      event.stopPropagation()
      return
    }
    onSelect(id)
  }

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isCommentMode) return
    const value = event.target.value
    setQuantity(value)
  }

  const handleQuantityBlur = () => {
    if (isCommentMode) return
    // When leaving the input, ensure we have a valid number (minimum 1)
    const validQuantity = typeof quantity === 'string' && isNaN(Number(quantity)) ? 1 : Math.max(1, Number(quantity))
    setQuantity(validQuantity)

    // Only update if the value is different from the current value
    if (validQuantity !== plant.quantity_count.min) {
      onUpdate({ ...plant, quantity_count: { min: validQuantity, max: null } })
    }
  }

  const handleQuantityKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (isCommentMode) return
    if (event.key !== 'Enter') return

    event.preventDefault()
    let validQuantity = 1
    if (typeof quantity === 'string' && quantity !== '' && !isNaN(parseInt(quantity))) {
      validQuantity = Math.max(1, parseInt(quantity))
    } else if (typeof quantity === 'number') {
      validQuantity = Math.max(1, quantity)
    }

    setQuantity(validQuantity)

    // Only update if the value is different from the current value
    if (validQuantity !== plant.quantity_count.min) {
      onUpdate({ ...plant, quantity_count: { min: validQuantity, max: null } })
    }
  }

  useEffect(() => {
    setQuantity(plant.quantity_count.min ?? 1)
  }, [plant.quantity_count.min])

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (isCommentMode) {
      event.preventDefault()
      event.stopPropagation()
      return
    }
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    if (isCommentMode) return
    setAnchorEl(null)
  }

  const handleEditPlantDetails = (entry: PlantListEntry) => {
    if (isCommentMode) return
    onEditEntry(entry)
    handleMenuClose()
  }

  const handleRemoveFromRFP = () => {
    if (isCommentMode) return
    onUpdate({ ...plant, deleted_at: new Date().toISOString() })
    handleMenuClose()
  }

  // In sent RFP view (showDeleted=true), deletion is based on selection
  // In main RFP view (showDeleted=false), deletion is based on deleted_at flag
  const isDeleted = showDeleted ? !isSelected : !!plant.deleted_at

  const textStyles = isDeleted
    ? {
        color: theme.palette.text.disabled,
        textDecoration: 'line-through',
      }
    : {}

  const disabledTextStyles = isDeleted
    ? {
        color: theme.palette.text.disabled,
      }
    : {}

  return (
    <>
      {allowSelect && (
        <PlantTableCell
          padding="checkbox"
          onClick={(e) => handleCheckboxClick(e, plant.id)}
          sx={{ pointerEvents: isCommentMode ? 'none' : undefined }}
        >
          <Checkbox
            checked={isSelected}
            sx={{
              color: theme.palette.lightGrey2.main,
            }}
          />
        </PlantTableCell>
      )}
      <PlantTableCell>
        <CommentableElement
          pathObject="plants"
          pathId={plant.id}
          targetPath="plants.common_name"
          itemName={(plant.common_name || plant.scientific_name) ?? 'plant name'}
          targetPage={targetPage}
          targetId={targetId}
          directedOrganizationId={sentRfpData?.directed_organization?.id}
        >
          <Box
            sx={{
              display: 'inline-block',
            }}
          >
            <Typography variant="body1" textTransform="capitalize" sx={textStyles}>
              {plant.common_name || plant.scientific_name}
            </Typography>
          </Box>
        </CommentableElement>
      </PlantTableCell>
      <PlantTableCell align="left" sx={{ whiteSpace: 'nowrap', width: '1%' }}>
        <Box sx={textStyles}>
          <PlantItemSpecs entry={plant} disabled={isDeleted} targetPage={targetPage} targetId={targetId} />
        </Box>
      </PlantTableCell>
      <PlantTableCell align="center" sx={{ whiteSpace: 'nowrap', width: '1%' }}>
        <CommentableElement
          pathObject="plants"
          pathId={plant.id}
          targetPath="plants.quantity_count.min"
          itemName="Quantity"
          targetPage={targetPage}
          targetId={targetId}
          directedOrganizationId={sentRfpData?.directed_organization?.id}
        >
          <Box
            sx={{
              display: 'inline-block',
            }}
          >
            {allowQuantityChange ? (
              <TextField
                type="number"
                value={quantity}
                onChange={handleQuantityChange}
                onBlur={() => {
                  setIsFocused(false)
                  handleQuantityBlur()
                }}
                onFocus={() => setIsFocused(true)}
                onKeyDown={handleQuantityKeyDown}
                variant="outlined"
                size="small"
                disabled={isDeleted}
                label={isFocused ? 'Press Enter to save' : undefined}
                inputProps={{
                  min: 1,
                }}
                sx={{
                  width: '130px',
                  '& .MuiInputBase-input': {
                    cursor: isCommentMode ? 'inherit !important' : undefined,
                    ...disabledTextStyles,
                  },
                  pointerEvents: isCommentMode ? 'none' : undefined,
                }}
              />
            ) : (
              <Typography variant="body1" sx={disabledTextStyles}>
                {plant.quantity_count.min}
              </Typography>
            )}
          </Box>
        </CommentableElement>
      </PlantTableCell>
      <PlantTableCell align="right" padding="none" sx={{ whiteSpace: 'nowrap', width: '1%' }}>
        {showMenu && (
          <IconButton
            sx={{
              px: 0,
              py: 1,
              borderRadius: theme.borderRadius.sm,
              border: '1px solid #ccc',
              backgroundColor: theme.palette.lightGrey3.main,
              cursor: isCommentMode ? 'inherit' : 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
              pointerEvents: isCommentMode ? 'none' : undefined,
            }}
            onClick={handleMenuOpen}
          >
            <EllipsisVertical />
          </IconButton>
        )}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => handleEditPlantDetails(plant)}>
            <Typography variant="body1">Edit Details</Typography>
          </MenuItem>
          <MenuItem>
            <Typography variant="body1">Flag Item as Important</Typography>
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleRemoveFromRFP}>
            <Trash2 style={{ marginRight: '8px' }} />
            <Typography variant="body1">Remove from RFP</Typography>
          </MenuItem>
        </Menu>
      </PlantTableCell>
    </>
  )
}
