import { type SxProps } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useDebounce } from '@uidotdev/usehooks'
import { useEffect, useState, useRef } from 'react'

import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'

import { Search, X } from 'lucide-react'

import { search } from '@/api/elasticsearch'
import { useOrganization } from '@/contexts/hooks/useOrganization'
import type { ElasticsearchResponse } from '@/types'
import InputAdornment from '@mui/material/InputAdornment'
import { useSearch } from '@tanstack/react-router'
import { useBoldlySearch } from '@/hooks/useBoldlySearch'

export interface SearchBarProps<T> {
  placeholder: string
  searchUrl: string
  onSearchResults?: (response: ElasticsearchResponse<T> | undefined) => void
  onSearchValueChange?: (value: string) => void
  debounceMs?: number
  minimumSearchLength?: number
  searchQueryKey?: (value: string) => any[]
  page?: number
  perPage?: number
  requiredFields?: Record<any, any>
  variant?: 'standard' | 'outlined' | 'filled'
  optionalFields?: Record<any, any>
  value?: string
  searchType?: '' | 'organization_contacts'
  sx?: SxProps
}
export default function SearchBar<T>({
  placeholder,
  searchUrl,
  onSearchResults,
  onSearchValueChange,
  debounceMs = 300,
  minimumSearchLength = 3,
  searchQueryKey,
  page = 1,
  perPage = 10,
  requiredFields,
  optionalFields = {},
  variant = 'outlined',
  value = '',
  searchType = '',
  sx,
}: SearchBarProps<T>) {
  const inputRef = useRef<HTMLInputElement>(null)
  const { selectedOrganization } = useOrganization()
  const [searchValue, setSearchValue] = useState(value)
  const debouncedSearchValue = useDebounce(searchValue, debounceMs)
  const searchParams = useSearch({
    strict: false,
  })
  const updateSearch = useBoldlySearch()
  const queryKey = searchQueryKey
    ? searchQueryKey(debouncedSearchValue)
    : ['search', searchUrl, debouncedSearchValue, page, perPage]

  const { data: searchResponse, isLoading: isSearching } = useQuery({
    queryKey,
    queryFn: () => {
      return search<T>(
        searchUrl,
        debouncedSearchValue,
        page,
        perPage,
        requiredFields,
        searchTypeOptionalFields(debouncedSearchValue, searchType, optionalFields),
        selectedOrganization?.id
      )
    },
    enabled: debouncedSearchValue.length >= minimumSearchLength,
    // staleTime: 1000 * 60 * 5, // 5 minutes
    retry: false,
  })

  useEffect(() => {
    if (inputRef.current && inputRef.current.value.length < minimumSearchLength) {
      onSearchResults?.(undefined)
      // set search, navigate on the actual route e.g. rfps.index.tsx
      searchParams.search = undefined
    } else if (searchResponse) {
      onSearchResults?.(searchResponse)
      searchParams.search = debouncedSearchValue
    }
    onSearchValueChange?.(debouncedSearchValue)
  }, [searchResponse, onSearchResults, inputRef, searchParams, debouncedSearchValue, minimumSearchLength])

  const handleClearSearch = () => {
    inputRef.current?.focus()
    searchParams.search = undefined
    setSearchValue('')
  }

  return (
    <TextField
      inputRef={inputRef}
      fullWidth
      placeholder={placeholder}
      value={searchValue}
      onChange={(e) => {
        setSearchValue(e.target.value)
        updateSearch(e.target.value)
      }}
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <Search className="mr-1" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <>
                {isSearching && <CircularProgress size={16} />}
                {searchValue && (
                  <X className="ml-1 h-4 w-4 cursor-pointer hover:opacity-70" onClick={handleClearSearch} />
                )}
              </>
            </InputAdornment>
          ),
        },
      }}
      variant={variant}
      size="small"
      sx={{ ...sx }}
    />
  )
}

const searchTypeOptionalFields = (value: string, searchType: string, optionalFields: Record<any, any>) => {
  // search organization name fields
  if (searchType === 'organization_contacts' && value) {
    return {
      ...optionalFields,
      organization_name: value,
      organization: {
        name: value,
      },
    }
  }

  return optionalFields
}
