import { UserContact } from '@/types.ts'
import theme from '@/theme.ts'
import Typography from '@mui/material/Typography'
import { getPrimaryContactEmail, getUserFullName } from '@/lib/utils.ts'
import { Stack } from '@mui/material'

interface RfpEmailContactInfoPillProp {
  contact: UserContact
  backgroundColor?: string
}
export const RfpEmailContactInfoPill = ({
  contact,
  backgroundColor = theme.palette.lightGrey3.main,
}: RfpEmailContactInfoPillProp) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        backgroundColor: { backgroundColor },
        borderRadius: theme.borderRadius.pill,
        py: 1,
        px: 2,
        fontWeight: 400,
        alignItems: 'center',
      }}
    >
      <Typography variant="body2">{getUserFullName(contact)}</Typography>
      <Typography variant="body2" color={theme.palette.mediumGrey4.main}>
        ({getPrimaryContactEmail(contact.email)})
      </Typography>
    </Stack>
  )
}
