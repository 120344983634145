import { MessageSquareTextIcon, NotepadText, History } from 'lucide-react'
import { SideDrawer } from '@/components/ui/base/side-drawer.tsx'
import { RfpComments } from '@/components/rfps/drawer/rfp-drawer-comments.tsx'
import { RFPHistory } from '@/components/rfps/drawer/rfp-drawer-history.tsx'
import { useCommentStore } from '@/stores/comment-store.ts'
import { CommentThread } from '@/types/comments.ts'

export const RfpSideDrawer = () => {
  const { commentThreads } = useCommentStore()
  const [comments, notes] = commentThreads.reduce<[CommentThread[], CommentThread[]]>(
    (result, thread) => {
      if (thread.directed_organization || thread.send_to_vendor) {
        result[0].push(thread)
      } else {
        result[1].push(thread)
      }
      return result
    },
    [[], []]
  )
  const drawerItems = [
    {
      label: 'Comments',
      icon: <MessageSquareTextIcon />,
      content: <RfpComments comments={comments} label="Comment" />,
      count: 0,
    },
    {
      label: 'Notes',
      icon: <NotepadText />,
      content: <RfpComments comments={notes} label="Note" />,
      count: 0,
    },
    {
      label: 'History',
      icon: <History />,
      content: <RFPHistory />,
      count: 0,
    },
  ]

  return <SideDrawer tabs={drawerItems} variant="permanent" anchor="right" />
}
