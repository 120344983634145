import { z } from 'zod'

// NOTE: DateTime Validation is not working as expected, using z.string() for now
export const FullTimeStampSchema = z.object({
  created_at: z.string().optional().nullable(),
  updated_at: z.string().optional().nullable(),
  deleted_at: z.string().optional().nullable(),
})

// Base schemas for contact information
const BaseAddressSchema = z.object({
  id: z.string().nullable(),
  street: z.string(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
  country: z.string(),
  suite: z.string().nullable().optional(),
  created_at: z.string().nullable().optional(),
  updated_at: z.string().nullable().optional(),
  deleted_at: z.string().nullable().optional(),
})

const ContactAddressSchema = z.object({
  id: z.string().nullable(),
  address_type: z.enum(['headquarters', 'mailing', 'other']).optional(),
  primary: z.boolean(),
  address: BaseAddressSchema,
  created_at: z.string().nullable().optional(),
  updated_at: z.string().nullable().optional(),
  deleted_at: z.string().nullable().optional(),
})

const ContactPhoneSchema = z.object({
  id: z.string().nullable(),
  number: z.string(),
  extension: z.string().optional(),
  contact_type: z.enum(['work', 'mobile', 'other']),
  primary: z.boolean(),
  created_at: z.string().nullable().optional(),
  updated_at: z.string().nullable().optional(),
  deleted_at: z.string().nullable().optional(),
})

const ContactEmailSchema = z.object({
  id: z.string().nullable(),
  address: z.string(),
  contact_type: z.enum(['work', 'other']),
  primary: z.boolean(),
  created_at: z.string().nullable().optional(),
  updated_at: z.string().nullable().optional(),
  deleted_at: z.string().nullable().optional(),
})

// GCSFile schema for profile pictures
const GCSFileSchema = z.object({
  id: z.string(),
  created_at: z.string(),
  updated_at: z.string().nullable(),
  deleted_at: z.string().nullable(),
  user_file_name: z.string(),
  file_type: z.string(),
  encoding: z.string(),
  domain: z.string(),
  get_url: z.string(),
  put_url: z.string(),
})

// User don't show settings
const UserDontShowSettingsSchema = z.object({
  plantlist_detail_review: z.string().nullable().optional(),
})

// Role schema
const RoleSchema = z.object({
  id: z.string(),
  created_at: z.string(),
  updated_at: z.string().nullable(),
  deleted_at: z.string().nullable(),
  name: z.string(),
  permissions: z.array(z.string()),
  custom_role: z.boolean(),
})

// Forward declarations to handle circular references
const UserSchema: z.ZodType<any> = z.lazy(() =>
  z.object({
    id: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    email: z.string(),
    organization_members: z.array(z.lazy(() => OrganizationMemberSchema)),
    pubsub_channel: z.string(),
    created_at: z.string(),
    title: z.string().nullable(),
    biography: z.string().nullable(),
    profile_picture: z.union([GCSFileSchema.partial(), z.null()]).optional(),
    address: z.array(ContactAddressSchema),
    phone: z.array(ContactPhoneSchema),
    timezone: z.string().nullable(),
    other_email: z.array(ContactEmailSchema),
    updated_at: z.string().nullable(),
    deleted_at: z.string().nullable(),
    dont_show: UserDontShowSettingsSchema.optional(),
    // Keep fields from original schema that might be needed
    sub: z.string().nullable().optional(),
    nickname: z.string().nullable().optional(),
    name: z.string().nullable().optional(),
    email_verified: z.boolean().nullable().optional(),
    phone_number: z.string().nullable().optional(),
    phone_number_verified: z.boolean().nullable().optional(),
  })
)

const OrganizationSchema: z.ZodType<any> = z.lazy(() =>
  z.object({
    id: z.string(),
    created_at: z.string(),
    updated_at: z.string().nullable(),
    deleted_at: z.string().nullable(),
    name: z.string(),
    website: z.string().optional(),
    created_by: UserSchema.nullable(),
    logo: GCSFileSchema.nullable(),
    small_logo: GCSFileSchema.nullable(),
    primary_business_type: z.enum(['buyer', 'seller', 'designer', 'hauler']).nullable(),
    verified: z.boolean(),
    legal_name: z.string().nullable(),
    company_structure: z.enum(['individual', 'llc', 's corp', 'c corp']).nullable(),
    tax_id: z.string().nullable(),
  })
)

const OrganizationMemberSchema: z.ZodType<any> = z.lazy(() =>
  z.object({
    id: z.string(),
    created_at: z.string(),
    updated_at: z.string().nullable(),
    deleted_at: z.string().nullable(),
    organization: OrganizationSchema,
    roles: z.array(RoleSchema),
    invite_status: z.string(),
    user: UserSchema,
  })
)

// Export the schemas
export { UserSchema, OrganizationSchema, OrganizationMemberSchema }
