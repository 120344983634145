import { useState, useCallback, memo } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import { OrganizationContact } from '@/types.ts'
import SearchBar from '@/components/ui/base/search-bar.tsx'
import { ContactTypes } from '@/constants.ts'
import ModalHeader from '@/components/ui/base/modal-header.tsx'
import theme from '@/theme.ts'
import { AddCircle } from '@mui/icons-material'
import Alert from '@mui/material/Alert'
import { ClearTextIconButton } from '@/components/ui/base/buttons/text-icon-buttons.tsx'
import { CustomDivider } from '@/components/ui/base/dividers.tsx'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { getOrganizationNameFromContact } from '@/lib/utils.ts'

export interface SearchVendorModalProps {
  open: boolean
  title: string
  cancelButtonText: string
  submitButtonText: string
  onSubmit: (selectedVendors: OrganizationContact[]) => void
  onClose?: () => void
  searchText?: string
  multiSelect?: boolean
  minimumSearchLength?: number
}

// The actual modal component
export const SearchVendorModal = ({
  open,
  onClose,
  title,
  cancelButtonText,
  submitButtonText,
  onSubmit,
  searchText = '',
  multiSelect = false,
  minimumSearchLength = 0,
}: SearchVendorModalProps) => {
  const [searchValue, setSearchValue] = useState(searchText)
  const [vendors, setVendors] = useState<OrganizationContact[]>([])
  const [selectedVendors, setSelectedVendors] = useState<OrganizationContact[]>([])
  const { selectedOrganization } = useOrganization()

  // Handle vendor selection
  const handleVendorSelect = (vendor: OrganizationContact) => {
    // without multi-select - proceed on click
    if (vendor && !multiSelect) onSubmit([vendor])

    // multi-select
    const isSelected = selectedVendors.find((v) => v.id === vendor.id)
    isSelected
      ? setSelectedVendors(selectedVendors.filter((v) => v.id !== vendor.id))
      : setSelectedVendors([...selectedVendors, vendor])
  }

  // Handle search term change
  const handleSearchValueChange = useCallback((value: string) => {
    setSearchValue(value)
  }, [])

  // Handle search results
  const handleSearchResults = useCallback((results: any) => {
    const resultItems = results?.items || []
    // setVendors(Array.from({ length: resultItems.length * 10 }, (_, i) => resultItems[0]))
    setVendors(resultItems)
  }, [])

  // Check if a vendor is selected
  const isVendorSelected = (vendor: OrganizationContact) => {
    return selectedVendors.some((v) => v.id === vendor.id)
  }

  // Handle submit
  const handleSubmit = () => {
    onSubmit(selectedVendors)
    handleClose()
  }

  // Handle close
  const handleClose = () => {
    setSelectedVendors([])
    onClose?.()
  }

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="vendor-search-modal-title">
      <Stack
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 850,
          backgroundColor: 'background.paper',
          boxShadow: 24,
          p: 5,
          borderRadius: theme.borderRadius.md,
        }}
      >
        <ModalHeader title={title || ''} variant="modalHeader3" sx={{ fontWeight: 400, pb: 4 }} />

        <Stack gap={1}>
          <SearchBar
            placeholder="Search contacts"
            searchUrl={`/v1/core/contact/${ContactTypes.ORGANIZATION}/search/`}
            perPage={20}
            page={1}
            requiredFields={{ deleted_at: null, organization_id: selectedOrganization?.id }}
            onSearchResults={handleSearchResults}
            onSearchValueChange={handleSearchValueChange}
            minimumSearchLength={minimumSearchLength}
            value={searchValue}
            searchType="organization_contacts"
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiInputBase-root': {
                boxShadow: 'none',
                px: 0,
              },
              '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
                outline: 'none',
              },
            }}
          />
          <CustomDivider size="1.2px" />
        </Stack>

        <List sx={{ maxHeight: 300, overflowY: 'auto', scrollbarWidth: 'none', mx: 1, p: 0, pt: 1 }}>
          {vendors.length > 0 ? (
            vendors.map((vendor) => (
              <Box
                key={vendor.id}
                sx={{ cursor: 'pointer', ':hover': { backgroundColor: theme.palette.lightGrey.main } }}
              >
                <ListItem onClick={() => handleVendorSelect(vendor)} sx={{ pb: 0.5, pt: 1.5, gap: 2 }}>
                  {multiSelect ? (
                    <Checkbox checked={isVendorSelected(vendor)} onChange={() => handleVendorSelect(vendor)} />
                  ) : (
                    <AddCircle sx={{ color: theme.palette.mediumGrey2.main }} />
                  )}
                  <ListItemText primary={getOrganizationNameFromContact(vendor, 'Unknown')} />
                </ListItem>
                <Divider />
              </Box>
            ))
          ) : searchValue.length >= minimumSearchLength ? (
            <NoResultsFound />
          ) : (
            <PromptUserToSearch />
          )}
        </List>

        <CustomDivider size="1.2px" />
        <Stack direction="row" spacing={2} justifyContent="flex-end" mt={3}>
          <ClearTextIconButton onClick={handleClose} text={cancelButtonText} />
          <Button onClick={handleSubmit} variant="contained" disabled={selectedVendors.length === 0}>
            {submitButtonText}
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}

const PromptUserToSearch = memo(() => (
  <ListItem>
    <Alert sx={{ width: '100%', justifyContent: 'center', my: 2, p: 2 }} variant="standard" severity="info">
      Enter min. 3 characters text to search
    </Alert>
  </ListItem>
))

const NoResultsFound = memo(() => (
  <ListItem>
    <Alert sx={{ width: '100%', justifyContent: 'center', my: 2, p: 2 }} severity="warning">
      No vendor contacts found
    </Alert>
  </ListItem>
))
