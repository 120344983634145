import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Link } from '@tanstack/react-router'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import ArrowBackIosNewRounded from '@mui/icons-material/ArrowBackIosNewRounded'

import PlantListTable from '@/components/plant-list-importer/plant-list-table.tsx'
import FileViewer from '@/components/plant-list-importer/file-viewer.tsx'

import { getPlantListById, updatePlantList } from '@/api/plant-list.ts'
import { getFileProcessingForPlantLists } from '@/api/ai-processed-plant-list-file.ts'
import { type PlantListEntry } from '@/types.ts'
import { createFileRoute } from '@tanstack/react-router'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'

export const Route = createFileRoute('/$orgId/plant-lists/$plantListId')({
  component: PlantListDetail,
})

function PlantListDetail() {
  const { selectedOrganization } = useOrganization()
  const { plantListId: id, orgId } = Route.useParams()
  const queryClient = useQueryClient()
  const {
    isPending,
    error,
    data: plantList,
    isFetching,
    refetch: refetchPlantList,
  } = useQuery({
    queryKey: ['plant-list', id] as const,
    queryFn: ({ queryKey }) => {
      const [_, id] = queryKey
      return getPlantListById(id)
    },
  })

  const { data: fileProcessingData, refetch: refetchFileProcessingData } = useQuery({
    queryKey: ['file-processing-data', id] as const,
    queryFn: ({ queryKey }) => {
      const [_, id] = queryKey
      return getFileProcessingForPlantLists(selectedOrganization?.id, [id])
    },
  })

  const uniqueRfps =
    plantList?.rfp_ids
      .reduce<{ id: number; url: string }[]>((acc, rfp) => {
        const found = acc.some((item) => item.id === rfp.id)
        if (!found) {
          acc.push(rfp)
        }
        return acc
      }, [])
      .sort((a, b) => a.id - b.id) || []

  const updatePlantListMutation = useMutation({
    mutationFn: updatePlantList,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['plant-list', id] })
    },
  })

  const handleUpdatePlantListEntries = async (entries: PlantListEntry[]) => {
    if (!plantList) {
      return
    }
    await updatePlantListMutation.mutateAsync({
      ...plantList,
      entries,
    })
  }

  const handleRFPCreated = async () => {
    await refetchPlantList()
  }

  const handleFileUpload = async () => {
    await refetchPlantList()
    await refetchFileProcessingData()
  }

  const backToPlantListsButton = (
    <Button variant="text" size="small" color="success" sx={{ marginY: 2 }}>
      <Link to="/$orgId/plant-lists" params={{ orgId }}>
        <ArrowBackIosNewRounded fontSize="small" />
        Back to Plant Lists
      </Link>
    </Button>
  )

  if (error) {
    return (
      <Box>
        <Typography variant="h1">Error</Typography>
        <Typography>{error.message}</Typography>
        {backToPlantListsButton}
      </Box>
    )
  }

  if (!isPending && !plantList) {
    return (
      <Box>
        <Typography variant="h1">Not Found</Typography>
        {backToPlantListsButton}
      </Box>
    )
  }

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h1" className="flex items-center gap-4">
        {isPending ? 'Getting plant list...' : plantList?.name || 'Plant List'}
        {isFetching && <CircularProgress size={24} />}
        {backToPlantListsButton}
      </Typography>
      {plantList && fileProcessingData && (
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <PlantListTable
              key={plantList.updated_at}
              plantList={plantList}
              fileProcessingData={fileProcessingData}
              onUpdate={handleUpdatePlantListEntries}
              onRFPCreated={handleRFPCreated}
            />
            <Box className="mt-8">
              <Typography variant="h3">RFPs</Typography>
              <Box data-testid="rfp-ids">
                {uniqueRfps.map((rfp, index) => (
                  <a
                    key={rfp.id}
                    href={rfp.url}
                    target="_blank"
                    rel="noreferrer"
                    className={`block border-b-2 border-l-2 border-r-2 p-2 ${index === 0 ? 'rounded-t-md border-t-2' : ''} ${index === uniqueRfps.length - 1 ? 'rounded-b-md' : ''}`}
                  >
                    RFP {rfp.id}
                  </a>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <FileViewer
              files={plantList.files}
              onFileDelete={() => refetchPlantList()}
              onFileUpload={handleFileUpload}
              plantListId={plantList.id}
              organizationId={plantList.organization.id}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  )
}
