import { Organization, UserInfo } from '@/types'
import { useSessionStorage } from '@uidotdev/usehooks'

/**
 * Plantbid Storage Keys
 */
type StorageKey = 'user' | 'organization' | 'side_nav_open'

// Prefix for all storage keys
const STORAGE_PREFIX = '~PB'
const JOIN_SEPARATOR = '::'

// Type for our storage values
type StorageValues = {
  user: UserInfo | undefined
  organization: Organization['id'] | undefined
  side_nav_open: boolean
}

const createStorageKey = (key: StorageKey): string => {
  return `${STORAGE_PREFIX}${JOIN_SEPARATOR}${key}`
}

export const clearPlantbidStorage = () => {
  sessionStorage.removeItem(createStorageKey('user'))
  sessionStorage.removeItem(createStorageKey('organization'))
}

export function usePlantbidStorage<K extends StorageKey>(key: K, initialValue: StorageValues[K]) {
  return useSessionStorage<StorageValues[K]>(createStorageKey(key), initialValue)
}
