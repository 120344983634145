import { excludeDeletedItems } from '@/lib/utils.ts'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import { useRfpContext } from '@/components/rfps/context/rfp-context.tsx'
import { Job } from '@/types.ts'

export default function JobPlantsMenu({ jobGroups }: { jobGroups: Record<string, Job> }) {
  const {
    activeJobId,
    setAddPlantsFromJobModalProps,
    setAddPlantsFromListModalOpen,
    setAddPlantsModalOpen,
    setCreatePlantListModalOpen,
  } = useRfpContext()
  const { handlePlantMenuClose, jobPlantsMenuAnchorEl } = useRfpContext()
  const jobData = jobGroups[activeJobId]
  const jobPlantTotalCount = excludeDeletedItems(jobData?.plants).length

  const handleClose = () => {
    handlePlantMenuClose()
  }

  const handleAddJobPlants = () => {
    handleClose()
    setAddPlantsFromJobModalProps({
      jobIds: [activeJobId],
      showJobSelection: false,
      open: true,
    })
  }

  const handleAddPlantsFromList = () => {
    handleClose()
    setAddPlantsFromListModalOpen(true)
  }

  const handleAddPlantsManually = () => {
    handleClose()
    setAddPlantsModalOpen(true)
  }

  const handleAddPlantsFromFileUpload = () => {
    handleClose()
    setCreatePlantListModalOpen(true)
  }

  return (
    <Box>
      <Menu
        anchorEl={jobPlantsMenuAnchorEl}
        open={Boolean(jobPlantsMenuAnchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            minWidth: 200,
          },
        }}
      >
        <Typography variant="strong" sx={{ px: 2, py: 1 }}>
          {jobPlantTotalCount} Plants in this Job
        </Typography>
        <MenuItem onClick={handleAddJobPlants} disabled={jobPlantTotalCount === 0}>
          Select Plants to include in RFP ...
        </MenuItem>
        <Divider />
        <Typography variant="strong" sx={{ px: 2, py: 1 }}>
          Add New Plants to Job
        </Typography>
        <MenuItem onClick={handleAddPlantsFromList}>Add from Existing Plant List ...</MenuItem>
        <MenuItem onClick={handleAddPlantsFromFileUpload}>Add from File Upload ...</MenuItem>
        <MenuItem onClick={handleAddPlantsManually}>Add Manually ...</MenuItem>
      </Menu>
    </Box>
  )
}
