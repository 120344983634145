import { Menu as MuiMenu, styled } from '@mui/material'

export const Menu = styled(MuiMenu)({
  transformOrigin: 'right top',
  anchorOrigin: 'right bottom',
  '& .MuiPaper-root': {
    elevation: 3,
    sx: { minWidth: 180, filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.15))', p: 0.5, borderRadius: 2 },
  },
})
