import { ArrowUpToLine, CirclePlus, LucideLeaf, LucideMountainSnow } from 'lucide-react'
import theme from '@/theme.ts'
import { Stack } from '@mui/material'
import RequestPricingHeader from '@/components/rfps/draft/steps/rfp-page-content/components/request-pricing-header.tsx'
import { CustomDivider } from '@/components/ui/base/dividers.tsx'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { TextIconButton } from '@/components/ui/base/buttons/text-icon-buttons.tsx'
import { useRfpModals } from '@/components/rfps/hooks/useRfpModals.tsx'

export default function NewRfpDefaultContent({ isAddingJob = false }: { isAddingJob?: boolean }) {
  const { setAddJobsToRfpModalOpen, setAddPlantsFromListModalOpen, setCreatePlantListModalOpen } = useRfpModals()
  const buttons = [
    {
      onClick: () => setAddJobsToRfpModalOpen('newJob'),
      startIcon: <CirclePlus fontSize="small" color={theme.palette.darkGray.main} size={16} />,
      text: 'From a New Job',
    },
    {
      onClick: () => setAddJobsToRfpModalOpen('existingJob'),
      startIcon: <LucideMountainSnow fontSize="small" color={theme.palette.darkGray.main} size={16} />,
      text: 'From an existing Job',
    },
    {
      onClick: () => setAddPlantsFromListModalOpen(true),
      startIcon: <LucideLeaf fontSize="small" color={theme.palette.darkGray.main} size={16} />,
      text: 'From an existing Plant List ...',
    },
    {
      onClick: () => setCreatePlantListModalOpen(true),
      startIcon: <ArrowUpToLine fontSize="small" color={theme.palette.darkGray.main} size={16} />,
      text: 'From File Upload ...',
    },
  ]

  if (isAddingJob) {
    return (
      <Stack sx={{ gap: 2 }}>
        <RequestPricingHeader />
        <CustomDivider />
        <Stack
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.lightGrey3.main,
            border: '1px solid',
            borderColor: theme.palette.lightGrey2.main,
            borderRadius: theme.borderRadius.lg,
          }}
        >
          <Stack sx={{ py: 6, gap: 1, alignItems: 'center' }}>
            <Typography variant="h3" sx={{ mb: 1 }}>
              Adding job to RFP...
            </Typography>
            <CircularProgress size={24} />
          </Stack>
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack sx={{ gap: 2 }}>
      <RequestPricingHeader />
      <CustomDivider />
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: theme.palette.lightGrey3.main,
          border: '1px solid',
          borderColor: theme.palette.lightGrey2.main,
          borderRadius: theme.borderRadius.lg,
        }}
      >
        <Stack sx={{ py: 6, gap: 1, alignItems: 'center' }}>
          <Typography variant="h3" sx={{ mb: 1 }}>
            Add plants to this RFP:
          </Typography>
          {buttons.map((button, index) => (
            <TextIconButton
              key={index}
              onClick={button.onClick}
              startIcon={button.startIcon}
              text={button.text}
              variant="contained"
              bgColor="white"
              sx={{
                width: 300,
                borderRadius: theme.borderRadius.sm,
                color: theme.palette.darkGray.main,
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                fontWeight: 400,
              }}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  )
}
