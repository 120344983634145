import Stack from '@mui/material/Stack'
import { GetPricingFromPlantbidCard } from '@/components/ui/plantbid-get-pricing-card.tsx'
import { AddCircle } from '@mui/icons-material'
import theme from '@/theme.ts'
import { getDefaultPlantbidOrganizationId, getOrganizationNameFromContact } from '@/lib/utils.ts'
import { memo, ReactNode, useEffect, useState } from 'react'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import { OrganizationContact } from '@/types.ts'

export const VendorPanelSearchResults = ({
  contacts,
  onClick,
  disabledContacts,
  minimumSearchLength = 3,
  searchTerm = '',
}: {
  contacts: OrganizationContact[]
  onClick: (contact: OrganizationContact) => void
  minimumSearchLength?: number
  disabledContacts?: string[]
  searchTerm?: string
}) => {
  const [results, setResults] = useState<ResultItemType[]>([])
  const [plantbidContact, setPlantbidContact] = useState<OrganizationContact | null>(null)
  const [showPlantbidContact, setShowPlantbidContact] = useState<boolean>(true)
  const plantbidOrganizationId = getDefaultPlantbidOrganizationId()

  useEffect(() => {
    // no search results
    if (!contacts || contacts.length === 0) {
      setResults([])
      return
    }

    // format search results
    const formattedResults: ResultItemType[] = contacts
      .filter((contact) => {
        const isDisabled = disabledContacts?.includes(contact.id) || false
        if (contact?.contact_organization?.id === plantbidOrganizationId) {
          setShowPlantbidContact(!isDisabled)
          setPlantbidContact(contact)
          // Filter this contact out of the results array
          return false
        }
        return true
      })
      .map((contact) => {
        const isDisabled = disabledContacts?.includes(contact.id) || false
        return {
          id: contact.id,
          label: getOrganizationNameFromContact(contact, 'Unknown'),
          disabled: isDisabled,
          onClick: () => onClick(contact),
        }
      })
    setResults(formattedResults)
  }, [contacts, disabledContacts, plantbidOrganizationId, onClick])

  return (
    <Stack>
      <ResultItem
        result={{
          id: 'newContact',
          label: 'Add New Contact',
          onClick: () => console.log('add new contact modal'),
        }}
      />

      {showPlantbidContact && (
        <GetPricingFromPlantbidCard
          onClick={() => {
            if (plantbidContact) {
              onClick(plantbidContact)
            }
          }}
          startIcon={<AddCircle sx={{ color: theme.palette.mediumGrey2.main }} />}
          sx={{ borderBottom: '1px solid', borderColor: theme.palette.lightGrey2.main }}
        />
      )}

      {contacts.length ? (
        results.map((result) => {
          return <ResultItem key={result.id} result={result} />
        })
      ) : searchTerm.length >= minimumSearchLength ? (
        <NoSearchResults />
      ) : null}
    </Stack>
  )
}

const NoSearchResults = memo(() => {
  return (
    <Alert sx={{ width: '100%', justifyContent: 'center', my: 2, p: 1 }} severity="warning">
      No matching vendors found
    </Alert>
  )
})

type ResultItemType = {
  id: string
  label: ReactNode
  onClick: () => void
  disabled?: boolean
}
const ResultItem = ({ result }: { result: ResultItemType }) => {
  if (!result || result.disabled) return null

  return (
    <Stack
      direction="row"
      onClick={result.onClick}
      sx={{
        px: 1,
        py: 1.5,
        gap: 1,
        alignItems: 'center',
        cursor: 'pointer',
        borderBottom: '1px solid',
        borderColor: theme.palette.lightGrey2.main,
      }}
    >
      <AddCircle sx={{ color: theme.palette.mediumGrey2.main }} />
      {typeof result.label === 'string' ? (
        <Typography variant="body1" sx={{ fontWeight: 'inherit' }}>
          {result.label}
        </Typography>
      ) : (
        result.label
      )}
    </Stack>
  )
}
