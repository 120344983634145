import { Stack, Typography } from '@mui/material'
import theme from '@/theme.ts'
import { ArrowLeftRight, MoveRight } from 'lucide-react'
import { GlobalEditPlantListEntry } from '@/components/plant-list-details/global-edit-plant-list-entry.tsx'
import { CustomDivider } from '@/components/ui/base/dividers.tsx'
import {
  PlantRelatedObjectsTable,
  PlantRelatedObjectsTableData,
} from '@/components/rfps/plant-related-objects-table.tsx'
import { PrimaryCancelButton } from '@/components/ui/base/buttons/buttons.tsx'
import Button from '@mui/material/Button'
import { PlantListEntry } from '@/types'

interface EditPlantDetailsContentProps {
  entry: PlantListEntry
  activeSpecs: string[]
  setActiveSpecs: (specs: string[]) => void
  updatedEntry: PlantListEntry
  setEditMode: (editMode: boolean) => void
  setAllowReview: (allowReview: boolean) => void
  debouncedEntryUpdates: (entry: Partial<PlantListEntry>) => void
  validateChangesForReview: (entry: PlantListEntry) => boolean
  relatedObjectData: PlantRelatedObjectsTableData
  handleClose: () => void
  allowReview: boolean
}
export const EditPlantDetailsContent = ({
  entry,
  activeSpecs,
  setActiveSpecs,
  updatedEntry,
  setEditMode,
  setAllowReview,
  debouncedEntryUpdates,
  validateChangesForReview,
  relatedObjectData,
  handleClose,
  allowReview,
}: EditPlantDetailsContentProps) => {
  const handleReviewChanges = () => {
    if (validateChangesForReview(updatedEntry)) {
      setEditMode(false)
    } else {
      setAllowReview(false)
    }
  }

  return (
    <Stack>
      {/*plant name and specs*/}
      <Stack sx={{ p: 4, overflowY: 'hidden' }}>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Stack>
            <Typography variant="h2">{entry.common_name || 'N/A'}</Typography>
            <Typography variant="body2">Scientific Name: {entry.scientific_name || 'N/A'}</Typography>
          </Stack>
          <Stack direction="row" sx={{ gap: 1, color: theme.palette.mediumGrey2.main }}>
            <ArrowLeftRight size={20} />
            <Typography variant="body2">Edit Species</Typography>
          </Stack>
        </Stack>
        {/*plant specs*/}
        <GlobalEditPlantListEntry
          entry={updatedEntry}
          onUpdate={debouncedEntryUpdates}
          activeSpecs={activeSpecs}
          handleActiveSpecs={(specs) => setActiveSpecs(specs)}
        />
      </Stack>
      <Stack sx={{ px: 4 }}>
        <CustomDivider size="1" />
      </Stack>
      {/*plant occurence*/}
      <Stack sx={{ p: 4, gap: 2 }}>
        <Typography variant="strong">This item appears in</Typography>
        <PlantRelatedObjectsTable data={relatedObjectData} />
      </Stack>
      <Stack sx={{ px: 4 }}>
        <CustomDivider />
      </Stack>
      <Stack direction="row" sx={{ p: 4, gap: 2, justifyContent: 'flex-end' }}>
        <PrimaryCancelButton onClick={handleClose}>Cancel & Close</PrimaryCancelButton>
        <Button
          variant="contained"
          onClick={handleReviewChanges}
          sx={{ px: 3, gap: 1, backgroundColor: theme.palette.darkGray.main, color: 'white' }}
          disabled={!allowReview}
        >
          <Typography variant="strong">Review Changes</Typography>
          <MoveRight size={20} />
        </Button>
      </Stack>
    </Stack>
  )
}
