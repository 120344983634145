import { useCallback, type MouseEvent, type FC, ReactNode } from 'react'
import { Menu } from '@/components/ui/base/menu'
import { MenuItem, ListItemText, ListItemIcon, SxProps } from '@mui/material'
import { RFPQueryManager, useRFPMutations } from '@/api/rfp-index'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { RFPMenuItem } from '../hooks/useRFPMenu'
import { useConfirmationDialog } from '../hooks/useConfirmDialog'

// Define the menu action types
export type RFPMenuActionType =
  | 'VIEW_DETAILS'
  | 'TOGGLE_IMPORTANT'
  | 'ARCHIVE'
  | 'MARK_CLOSED'
  | 'UNARCHIVE'
  | 'MARK_OPEN'

export interface BaseRFPMenuProps {
  closeMenu: () => void
  rfpQueryManager: RFPQueryManager
}

// Define the props for the RFP menu component
interface RFPMenuProps extends BaseRFPMenuProps {
  menuState: {
    rfp: RFPMenuItem // Non-null RFP
    anchorEl: HTMLElement
  }
}

type MenuAction = {
  type: RFPMenuActionType
  icon?: ReactNode
  label: string
  sx?: SxProps
  disabled?: boolean
}

/**
 * Implement in conjunction with useRFPMenu
 * Usage: `const { RFPMenu, menuState, openMenu, closeMenu } = useRFPMenu()`
 */
export const RFPMenu: FC<RFPMenuProps> = ({ menuState, closeMenu, rfpQueryManager }) => {
  const navigate = useNavigate()
  const toast = useToastNotifications()
  const { showConfirmation, ConfirmationDialog } = useConfirmationDialog()
  const orgId = rfpQueryManager.organizationId
  const { mutation: mutateRFP } = useRFPMutations(rfpQueryManager)
  const { type: rfpType, status } = useSearch({
    strict: false,
    select: (params) => ({ type: params.type || 'sent', status: params.status || 'open' }),
  })

  const importantKey = rfpType === 'sent' ? 'flagged_as_important_by_customer' : 'flagged_as_important_by_vendor'

  const actions: MenuAction[] = [
    {
      type: 'VIEW_DETAILS',
      label: 'Edit RFP',
    },

    {
      type: 'TOGGLE_IMPORTANT',
      // icon: <FlagIcon fontSize="small" />,
      label: menuState.rfp[importantKey] ? 'Unflag as Important' : 'Flag as Important',
    },
    {
      type: 'ARCHIVE',
      label: 'Archive RFP',
      disabled: status === 'archived',
    },
    {
      type: 'UNARCHIVE',
      label: 'Unarchive RFP',
      disabled: status === 'archived',
    },
    {
      type: 'MARK_CLOSED',
      label: 'Mark as Closed',
      disabled: status === 'closed',
    },
    {
      type: 'MARK_OPEN',
      label: 'Mark as Open',
      disabled: status === 'open',
    },
    // { type: 'DIVIDER' },
  ]

  const handleMenuAction = useCallback(
    async (type: RFPMenuActionType, event?: MouseEvent) => {
      event?.preventDefault()
      event?.stopPropagation()

      const { rfp } = menuState

      try {
        switch (type) {
          case 'VIEW_DETAILS':
            navigate({
              to: '/$orgId/rfps/$rfpId',
              params: { orgId, rfpId: rfp.rfp_id },
            })
            break

          case 'TOGGLE_IMPORTANT': {
            const currentValue = rfp[importantKey] || false
            mutateRFP.mutate({
              type,
              rfpId: rfp.rfp_id,
              currentValue,
            })

            toast.addToastNotification({
              message: `RFP ${currentValue ? 'unmarked' : 'marked'} as important`,
              severity: 'success',
            })
            break
          }
          case 'ARCHIVE':
            {
              const confirmed = await showConfirmation({
                title: 'Archive RFP',
                message: 'Are you sure you want to archive this RFP?',
              })

              if (confirmed) {
                await mutateRFP.mutateAsync({
                  type: 'SET_STATE',
                  rfpId: rfp.rfp_id,
                  state: 'archived',
                })

                toast.addToastNotification({
                  message: 'RFP archived',
                  severity: 'success',
                })
              }
            }
            break

          case 'UNARCHIVE':
            {
              await mutateRFP.mutateAsync({
                type: 'SET_STATE',
                rfpId: rfp.rfp_id,
                state: 'open',
              })

              toast.addToastNotification({
                message: 'RFP state updated',
                severity: 'success',
              })
            }
            break

          case 'MARK_CLOSED':
            mutateRFP.mutate({
              type: 'SET_STATE',
              rfpId: rfp.rfp_id,
              state: 'closed',
            })

            toast.addToastNotification({
              message: 'RFP marked as closed',
              severity: 'success',
            })
            break
        }
      } catch (error) {
        console.error(error)
        if (error instanceof Error) {
          toast.addToastNotification({
            message: error.message,
            severity: 'error',
          })
        } else {
          toast.addToastNotification({
            message: 'An unknown error occurred',
            severity: 'error',
          })
        }
      } finally {
        // Don't close the menu until the end, otherwise
        // it will close before the confirmation dialog is opened
        closeMenu()
      }
    },
    [menuState, navigate, orgId, mutateRFP, toast, closeMenu, showConfirmation, importantKey]
  )

  return (
    <>
      <ConfirmationDialog />
      <Menu anchorEl={menuState.anchorEl} open={!!menuState.anchorEl} onClose={closeMenu}>
        {actions.map((item) => {
          if (item.disabled) return
          return (
            <MenuItem
              key={item.type}
              onClick={(e) => {
                e.stopPropagation()
                handleMenuAction(item.type as RFPMenuActionType, e)
              }}
              sx={item.sx}
            >
              {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
              <ListItemText>{item.label}</ListItemText>
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}
