import { RfpState, SentRFP, UserContact } from '@/types.ts'
import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import theme from '@/theme.ts'
import { useMutation } from '@tanstack/react-query'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications.ts'
import { AxiosError } from 'axios'
import { updateSentRfp } from '@/api/rfps.ts'
import { useState } from 'react'
import ModalHeader from '@/components/ui/base/modal-header'
import { CustomDivider } from '@/components/ui/base/dividers'
import { trimUUID } from '@/lib/utils'
import { CustomLexicalEditor } from '@/components/ui/texteditor/lexical/lexical_editor'
import { RfpEmailContactInfoPill } from '../rfp-email-contact-info-pill'
import { useNavigate, useParams } from '@tanstack/react-router'

type DeclineSentRFPPatch = Pick<SentRFP, 'id' | 'declined_notification_subject' | 'declined_notification_body'> & {
  state: RfpState.CLOSED
}

export function DeclineModal({ open, onClose, rfp }: { open: boolean; onClose: () => void; rfp: SentRFP }) {
  const { orgId } = useParams({ from: '/$orgId' })
  const navigate = useNavigate()
  const { addToastNotification } = useToastNotifications()
  const [message, setMessage] = useState('')
  const orgName = rfp.organization.name
  const rfpTitle = `RFP - #${trimUUID(rfp.id)}`
  const date = new Date(rfp.quote_needed_by || '').toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })

  const submit = useMutation({
    mutationFn: ({ id, ...data }: DeclineSentRFPPatch) => updateSentRfp({ sentRfpId: id, data }),
    onError: async (error: AxiosError) => {
      addToastNotification({
        severity: 'error',
        title: 'Error declining RFP',
        message: (error.response?.data as { detail?: string })?.detail || error.message,
      })
      onClose()
    },
  })

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: theme.borderRadius.xl,
        }}
      >
        <ModalHeader variant="modalHeader3" sx={{ mb: 4 }} title="Decline Request for Proposal" />
        <CustomDivider size="1" color={theme.palette.lightGrey2.main} />
        <Stack direction="row" justifyContent="space-between" spacing={2} py={2}>
          <Typography variant="subtitle1" color={theme.palette.mediumGray.dark}>
            {orgName}
          </Typography>
          <Stack direction="row" spacing={6}>
            <Typography variant="h6" color={theme.palette.mediumGray.dark} sx={{ textDecoration: 'underline' }}>
              {rfpTitle}
            </Typography>
            <Typography variant="body2">{date}</Typography>
          </Stack>
        </Stack>
        <CustomDivider size="1" color={theme.palette.lightGrey2.main} />

        <Stack sx={{ backgroundColor: 'white', gap: 1, borderRadius: theme.borderRadius.lg, py: 3 }}>
          <Stack direction="row" sx={{ gap: 1.5, alignItems: 'center' }}>
            <Typography variant="strong">To:</Typography>
            <RfpEmailContactInfoPill contact={rfp.owner_contact as UserContact} />
          </Stack>
          <CustomLexicalEditor
            showFooter={false}
            onCancel={() => {}}
            onChange={(data) => setMessage(data.htmlString)}
            initialHtml={message}
          />
        </Stack>
        <CustomDivider size="1" color={theme.palette.lightGrey2.main} />
        <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end', mt: 4 }}>
          {/* cancel and decline buttons */}
          <Button variant="text" color="darkGray" onClick={() => onClose()}>
            Cancel & Close
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{ fontWeight: 700 }}
            onClick={async () => {
              await submit.mutateAsync({
                id: rfp.id,
                declined_notification_subject: `RFP #${trimUUID(rfp.id)} Declined by ${orgName}`,
                declined_notification_body: message,
                state: RfpState.CLOSED,
              })
              onClose()
              addToastNotification({
                severity: 'success',
                title: 'RFP Declined',
                message: 'RFP has been successfully declined',
              })
              navigate({
                to: '/$orgId/rfps',
                params: { orgId },
                search: { type: 'received' },
              })
            }}
          >
            Reply & Decline RFP
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}
