import { Skeleton, Stack } from '@mui/material'

export const RfpPageContentSkeleton = () => {
  return (
    <Stack spacing={3} sx={{ p: 5, flexGrow: 1, overflow: 'hidden' }}>
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <Skeleton variant="text" width={300} height={40} />
        <Skeleton variant="rectangular" width={150} height={40} sx={{ borderRadius: 1 }} />
      </Stack>
      <Stack sx={{ gap: 3, flexGrow: 1 }}>
        <Skeleton variant="rectangular" sx={{ flexGrow: 1, borderRadius: 2 }} />
        <Skeleton variant="rectangular" height={200} sx={{ borderRadius: 2 }} />
      </Stack>
    </Stack>
  )
}
