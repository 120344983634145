import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useRfpContext } from '@/components/rfps/context/rfp-context.tsx'

export default function RfpJobDefaultContentMenu({
  anchorEl,
  handleClose,
}: {
  anchorEl: HTMLElement | null
  handleClose: () => void
}) {
  const { setAddPlantsFromListModalOpen, setAddPlantsModalOpen, setCreatePlantListModalOpen } = useRfpContext()

  const handleAddPlantsFromList = () => {
    handleClose()
    setAddPlantsFromListModalOpen(true)
  }

  const handleAddPlantsManually = () => {
    handleClose()
    setAddPlantsModalOpen(true)
  }

  const handleAddPlantsFromFileUpload = () => {
    handleClose()
    setCreatePlantListModalOpen(true)
  }

  return (
    <Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            minWidth: 200,
          },
        }}
      >
        <MenuItem onClick={handleAddPlantsFromList}>From Existing Plant List ...</MenuItem>
        <MenuItem onClick={handleAddPlantsFromFileUpload}>From File Upload ...</MenuItem>
        <MenuItem onClick={handleAddPlantsManually}>Add Manually ...</MenuItem>
      </Menu>
    </Box>
  )
}
