import { Card, CardContent, Skeleton, Box, Stack } from '@mui/material'
import { CommentThread } from '@/types/comments.ts'
import Typography from '@mui/material/Typography'
import { pluralize } from '@/lib/pluralize.ts'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import { format } from 'date-fns'

export const PlaceholderCard = ({ text }: { text: string }) => {
  console.log('text', text)
  return (
    <Card sx={{ maxWidth: 500, padding: 2, boxShadow: 'none' }}>
      <CardContent>
        {/* Header Section */}
        <Box display="flex" alignItems="center" gap={2} mb={2}>
          <Skeleton variant="circular" width={40} height={40} />
          <Box>
            <Skeleton variant="text" width={100} height={20} />
            <Skeleton variant="text" width={150} height={20} />
          </Box>
        </Box>

        {/* Message Section */}
        <Box mb={2}>
          <Skeleton variant="text" width="90%" height={20} />
          <Skeleton variant="text" width="85%" height={20} />
          <Skeleton variant="text" width="80%" height={20} />
        </Box>
      </CardContent>
    </Card>
  )
}

const CommentCard = ({ comment }: { comment: CommentThread }) => {
  return (
    <>
      <Divider />
      <Card sx={{ maxWidth: 500, padding: 2, boxShadow: 'none' }}>
        <CardContent>
          <Box display="flex" alignItems="center" gap={2} mb={2}>
            <Avatar />
            <Box>
              <Typography variant="h6">
                {comment.created_by?.first_name} {comment.created_by?.last_name}
              </Typography>
              {comment.created_at && (
                <Typography variant="body2">
                  {format(new Date(comment.created_at), "MMM d, yyyy 'at' h:mm a")}
                </Typography>
              )}
            </Box>
          </Box>
          <Typography>{comment.comments[0].comment}</Typography>
          {comment.comments.length > 1 && (
            <Typography variant="body2" color="primary" sx={{ fontWeight: 700, mt: 2 }}>
              {`${comment.comments.length - 1} ${pluralize(comment.comments.length - 1, 'reply', 'replies')}`}
            </Typography>
          )}
        </CardContent>
      </Card>
    </>
  )
}

export const RfpComments = ({ comments, label }: { comments: CommentThread[]; label: string }) => {
  return (
    <Stack>
      <Typography sx={{ my: 3 }}>{`${comments.length} ${pluralize(comments.length, label, `${label}s`)}`}</Typography>
      {comments.map((comment) => (
        <CommentCard key={comment.id} comment={comment} />
      ))}
      <Divider />
    </Stack>
  )
}
