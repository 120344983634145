import Grid from '@mui/material/Grid2'
import { Stack, Typography } from '@mui/material'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'

export type RadioButtonOption = {
  value: string
  label: string
  description: string
}

export const RfpBiddingBuyingRadioButton = ({
  onChange,
  value,
  label,
}: {
  onChange: (event: any) => void
  value: string
  label?: string
}) => {
  const options: RadioButtonOption[] = [
    {
      value: 'bidding',
      label: 'Bidding',
      description: 'Job NOT Awarded, just collecting quotes',
    },
    {
      value: 'buying',
      label: 'Buying',
      description: 'Job Awarded, ready to buy plants',
    },
  ]

  return (
    <Grid container spacing={1}>
      {label && (
        <Typography variant="strong" sx={{ width: '100%' }}>
          {label}
        </Typography>
      )}
      <Grid size={8}>
        <RadioGroup
          value={value}
          onChange={onChange}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {options.map((option: RadioButtonOption) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={
                <Stack direction="row" sx={{ gap: 1 }}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: '15px',
                      fontWeight: option.value === value ? 700 : 400,
                    }}
                  >
                    {option.label}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    - {option.description}
                  </Typography>
                </Stack>
              }
              style={{ alignItems: 'center' }}
            />
          ))}
        </RadioGroup>
      </Grid>
    </Grid>
  )
}
