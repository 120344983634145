import api from './api'
import { CommentPage, CommentThread } from '@/types/comments'
import {
  CommentPost,
  CommentPostSchema,
  CommentQueryParams,
  CommentResponse,
  CommentThreadPost,
  CommentThreadPostSchema,
  CommentThreadUpdate,
  CommentThreadUpdateSchema,
  CommentUpdate,
  CommentUpdateSchema,
} from './types/comments'

/**
 * Get comment threads with optional filtering
 * @param params - Query parameters for filtering comments
 * @returns Array of comment threads
 */
export async function getCommentThreads(params: CommentQueryParams = {}) {
  const queryParams = new URLSearchParams()

  // Add all params to query string
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) {
      queryParams.append(key, value.toString())
    }
  })

  const { data } = await api.get(`/v1/core/comments/?${queryParams.toString()}`)
  return data as CommentThread[]
}

/**
 * Get comment threads for a specific target page
 * @param page - The type of page (e.g., 'rfp', 'plantlist', 'sent_rfp')
 * @param targetId - The ID of the specific page
 * @param organizationId - The ID of the organization to filter by
 * @returns Array of comment threads
 */
export async function getCommentThreadsByTarget(page: CommentPage, targetId: string, organizationId: string) {
  const queryParams = new URLSearchParams({
    target_id: targetId,
    page: page,
    organization_id: organizationId,
  })

  const { data } = await api.get(`/v1/core/comments/target_page/?${queryParams.toString()}`)
  return data as CommentThread[]
}

/**
 * Get a specific comment thread by ID
 * @param threadId - The ID of the comment thread
 * @returns The comment thread
 */
export async function getCommentThread(threadId: string) {
  const { data } = await api.get(`/v1/core/comments/${threadId}/`)
  return data as CommentThread
}

/**
 * Create a new comment thread
 * @param data - The comment thread data
 * @returns The created comment thread
 */
export async function createCommentThread(data: CommentThreadPost) {
  // Validate the input data with Zod
  CommentThreadPostSchema.parse(data)

  const { data: responseData } = await api.post('/v1/core/comments/', data)
  return responseData as CommentThread
}

/**
 * Add a comment to an existing thread
 * @param threadId - The ID of the comment thread
 * @param comment - The comment data
 * @returns The updated comment thread
 */
export async function addCommentToThread(threadId: string, comment: CommentPost) {
  // Validate the comment with Zod
  CommentPostSchema.parse(comment)

  const thread = await getCommentThread(threadId)
  const updatedThread = {
    ...thread,
    comments: [...thread.comments, comment],
  }

  const { data } = await api.put(`/v1/core/comments/${threadId}/`, updatedThread)
  return data as CommentThread
}

/**
 * Update a comment thread
 * @param threadId - The ID of the comment thread
 * @param data - The data to update
 * @returns The updated comment thread
 */
export async function updateCommentThread(threadId: string, data: CommentThreadUpdate) {
  // Validate the data with Zod
  CommentThreadUpdateSchema.parse(data)

  const { data: responseData } = await api.patch(`/v1/core/comments/${threadId}/`, data)
  return responseData as CommentThread
}

/**
 * Update a specific comment within a thread
 * @param threadId - The ID of the comment thread
 * @param commentId - The ID of the comment to update
 * @param data - The data to update
 * @returns The updated comment thread
 */
export async function updateComment(threadId: string, commentId: string, data: CommentUpdate) {
  // Validate the data with Zod
  CommentUpdateSchema.parse(data)

  const thread = await getCommentThread(threadId)
  const updatedComments = thread.comments.map((comment: CommentResponse) =>
    comment.id === commentId ? { ...comment, ...data } : comment
  )

  const updatedThread = {
    ...thread,
    comments: updatedComments,
  }

  const { data: responseData } = await api.put(`/v1/core/comments/${threadId}/`, updatedThread)
  return responseData as CommentThread
}

/**
 * Mark a comment as read
 * @param threadId - The ID of the comment thread
 * @param commentId - The ID of the comment to mark as read
 * @returns The updated comment thread
 */
export async function markCommentAsRead(threadId: string, commentId: string) {
  return updateComment(threadId, commentId, { unread: false })
}

/**
 * Mark a comment thread as resolved or unresolved
 * @param threadId - The ID of the comment thread
 * @param resolved - Whether the thread is resolved
 * @returns The updated comment thread
 */
export async function resolveCommentThread(threadId: string, resolved: boolean) {
  return updateCommentThread(threadId, { resolved })
}

/**
 * Delete a comment thread
 * @param threadId - The ID of the comment thread
 * @returns Success message
 */
export async function deleteCommentThread(threadId: string): Promise<string> {
  const { data } = await api.delete(`/v1/core/comments/${threadId}/`)
  return data
}
