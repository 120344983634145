import { useEffect, useState, useCallback, useMemo } from 'react'
import Modal from '@mui/material/Modal'
import ModalHeader from '@/components/ui/base/modal-header.tsx'
import { Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useMutation, useQuery } from '@tanstack/react-query'
import { createRfp } from '@/api/rfps.ts'
import { useNavigate, useParams } from '@tanstack/react-router'
import theme from '@/theme.ts'
import { CustomDivider } from '@/components/ui/base/dividers.tsx'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { initialNewRfpFormData } from '@/seed_form_data.ts'
import { NewRfpFormData, RequestForProposal } from '@/types.ts'
import { CreateNewRfpSchema } from '@/lib/validation-schemas.ts'
import { ZodError } from 'zod'
import {
  convertISOStringToZeroTimeDate,
  convertToZeroTimeISODate,
  convertToZeroTimeISOString,
  excludeDeletedItems,
  getUserFullName,
} from '@/lib/utils.ts'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications.ts'
import { getOrganizationMembers } from '@/api/organization_member.ts'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import CollaboratorSelector, {
  MultiSelectChipDropdownOption,
} from '@/components/ui/base/dropdowns/multi-select-chip-dropdown.tsx'
import { RfpBiddingBuyingRadioButton } from '@/components/rfps/rfp-bidding-buying-radio-button.tsx'

/**
 * Component for RFP name and quote date fields
 */
const RfpNameAndQuoteDate = ({
  rfpFormData,
  validationError,
  onFormDataChange,
  onDateChange,
}: {
  rfpFormData: NewRfpFormData
  validationError: ZodError | null
  onFormDataChange: (field: string, value: any) => void
  onDateChange: (field: string, value: any) => void
}) => {
  return (
    <Grid container spacing={1}>
      <Typography variant="strong">RFP Details</Typography>
      
      <Grid container spacing={2} size={12}>
        <Grid 
          size={6} 
          sx={{ display: rfpFormData.quote_needed_by ? '' : 'none' }}
        >
          <TextField
            fullWidth
            label="RFP Name (optional)"
            value={rfpFormData.name || ''}
            error={
              validationError instanceof ZodError && 
              validationError.errors.some((error) => error.path[0] === 'name')
            }
            onChange={(e) => onFormDataChange('name', e.target.value)}
          />
        </Grid>
        
        <Grid size={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Quote Needed by*"
              format="MMMM d, yyyy"
              value={rfpFormData.quote_needed_by instanceof Date ? rfpFormData.quote_needed_by : null}
              onChange={(newValue) => onDateChange('quote_needed_by', newValue)}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error:
                    validationError instanceof ZodError &&
                    validationError.errors.some((error) => error.path[0] === 'quote_needed_by'),
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Grid>
  )
}
RfpNameAndQuoteDate.displayName = 'RfpNameAndQuoteDate'

/**
 * Component for RFP collaborators selection
 */
const RFPCollaborators = ({
  selected,
  options,
  onChange,
}: {
  selected: string[]
  options: MultiSelectChipDropdownOption[]
  onChange: (value: string[]) => void
}) => {
  const collaboratorCount = selected.length || 0
  const label = collaboratorCount ? `Collaborators (${collaboratorCount})` : 'Collaborators'

  return (
    <Grid container spacing={1}>
      <Grid size={12} sx={{ pb: 1 }}>
        <Typography variant="strong">{label}</Typography>
      </Grid>
      
      <Grid size={6}>
        <CollaboratorSelector
          buttonLabel="Add Collaborators"
          options={options}
          selectedIds={selected}
          onChange={onChange}
        />
      </Grid>
      
      <Grid size={6}>
        <Typography variant="body2" color="textSecondary" sx={{ width: '100%' }}>
          Able to view, edit, receive updates on this RFP
        </Typography>
      </Grid>
    </Grid>
  )
}

/**
 * Main modal component for creating or editing RFPs
 */
export default function CreateEditRfpModal({
  open,
  onClose,
  rfpData,
  onSubmit,
}: {
  open: boolean
  onClose: () => void
  rfpData?: RequestForProposal
  onSubmit?: (data: Partial<RequestForProposal>) => void
}) {
  const navigate = useNavigate()
  const orgId = useParams({ select: (params) => params.orgId, from: '/$orgId' })
  const { addToastNotification } = useToastNotifications()
  const [allowRFPCreation, setAllowRFPCreation] = useState(false)
  const [validationError, setValidationError] = useState<ZodError | null>(null)
  const [rfpFormData, setRfpFormData] = useState<NewRfpFormData>(initialNewRfpFormData)

  // Fetch organization members for collaborator selection
  const { data: orgMembers } = useQuery({
    queryKey: ['organization-members', orgId],
    queryFn: async () => getOrganizationMembers(orgId as string),
    enabled: !!orgId,
  })

  // Format organization members for dropdown options
  const collaboratorsOptions = useMemo(() => 
    excludeDeletedItems(orgMembers || [])?.map((member) => ({
      value: member.id,
      name: getUserFullName(member?.user),
    })) || [], 
    [orgMembers]
  )

  // Reset form values when modal opens or rfpData changes
  useEffect(() => {
    if (!open) return

    const formData = rfpData
      ? ({
          name: rfpData.name || '',
          owner: rfpData.owner.id || '',
          phase: rfpData.phase || 'bidding',
          collaborators: excludeDeletedItems(rfpData.collaborators).map((collaborator: any) => collaborator.id),
          quote_needed_by: rfpData.quote_needed_by
            ? rfpData.quote_needed_by.endsWith('Z')
              ? convertToZeroTimeISODate(rfpData.quote_needed_by)
              : convertISOStringToZeroTimeDate(rfpData.quote_needed_by)
            : null,
          organization: rfpData.organization.id || '',
        } as NewRfpFormData)
      : {
          ...initialNewRfpFormData,
          organization: orgId || '',
        }

    setRfpFormData(formData)
  }, [open, orgId, rfpData])

  // Handle modal close
  const handleClose = () => {
    setValidationError(null)
    onClose()
  }

  // Mutation for creating a new RFP
  const createDraftRFPMutation = useMutation({
    mutationFn: createRfp,
    onSuccess: (newRFP) => {
      addToastNotification({
        severity: 'success',
        title: 'RFP Created',
        message: `RFP #${newRFP.id}`,
      })
      navigate({
        to: '/$orgId/rfps/draft/$rfpId',
        params: { orgId, rfpId: newRFP.id },
        search: { step: 'build-rfp' },
      })
    },
    onError: (error) => {
      addToastNotification({
        severity: 'error',
        title: 'Error creating RFP',
        message: error.message,
      })
    },
  })

  // Form validation
  interface ValidateFormDataProp {
    setError?: boolean
    data: NewRfpFormData
  }

  const validateFormData = ({ setError = true, data }: ValidateFormDataProp) => {
    setValidationError(null)
    try {
      if (!data) return false
      CreateNewRfpSchema.parse(data)
      return true
    } catch (error: any) {
      if (setError) setValidationError(error.errors)
      return false
    }
  }

  // Form field handlers
  const handlePhaseChange = (event: any) => {
    setRfpFormData((prev) => ({ ...prev, phase: event.target.value }))
  }

  const handleFormDataChange = useCallback((field: string, value: any) => {
    setRfpFormData((prev) => ({ ...prev, [field]: value }))
  }, [])

  const handleDateChange = useCallback(
    (field: string, value: any) => {
      const formattedDate = value ? new Date(value) : null
      handleFormDataChange(field, formattedDate)
    },
    [handleFormDataChange]
  )

  // Validate form data whenever it changes
  useEffect(() => {
    if (!rfpFormData) return
    const isFormValid = validateFormData({
      setError: false,
      data: rfpFormData,
    })
    setAllowRFPCreation(isFormValid)
  }, [rfpFormData])

  // Submit handlers
  const submitRfpData = () => {
    if (!rfpData || !onSubmit) return

    const formattedData = {
      ...rfpFormData,
      collaborators: (orgMembers || []).filter((member) => rfpFormData.collaborators.includes(member.id)),
      quote_needed_by: rfpFormData.quote_needed_by ? convertToZeroTimeISOString(rfpFormData.quote_needed_by) : null,
      owner: rfpData.owner,
      organization: rfpData.organization,
    }
    onSubmit(formattedData)
    handleClose()
  }

  const submitRFPForm = async () => {
    if (!rfpFormData) return

    if (validateFormData({ data: rfpFormData })) {
      // Handle form submission when editing existing rfp
      if (rfpData) {
        submitRfpData()
        return
      }

      // Create new rfp with form data
      const formattedData = {
        ...rfpFormData,
        quote_needed_by: rfpFormData.quote_needed_by ? convertToZeroTimeISOString(rfpFormData.quote_needed_by) : null,
      }
      await createDraftRFPMutation.mutateAsync(formattedData)
      handleClose()
    }
  }

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition aria-labelledby="new-rfp-modal">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          width: { xs: '90%', lg: 800 },
          maxHeight: 900,
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
          borderRadius: theme.borderRadius.lg,
          p: 6,
        }}
      >
        <ModalHeader
          title={rfpData ? 'Edit RFP Details' : 'New RFP'}
          variant="modalHeader2"
          onCloseButtonClick={handleClose}
          id="edit-rfp-details-modal-title"
        />
        
        <Stack sx={{ gap: 5, pt: 4 }}>
          {/* RFP Details Section */}
          <RfpNameAndQuoteDate
            rfpFormData={rfpFormData}
            validationError={validationError}
            onFormDataChange={handleFormDataChange}
            onDateChange={handleDateChange}
          />
          
          {/* RFP Phase Options */}
          <RfpBiddingBuyingRadioButton
            label="RFP Phase Options"
            value={rfpFormData.phase}
            onChange={handlePhaseChange}
          />
          
          {/* Collaborators Section */}
          <RFPCollaborators
            selected={rfpFormData.collaborators}
            options={collaboratorsOptions}
            onChange={(value) => handleFormDataChange('collaborators', value)}
          />
          
          <CustomDivider size="1" color={theme.palette.lightGrey2.main} />
          
          {/* Action Buttons */}
          <Stack direction="row" sx={{ gap: 2, justifyContent: 'flex-end' }}>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              Cancel & Close
            </Button>
            
            <Button
              variant="contained"
              onClick={submitRFPForm}
              disabled={!allowRFPCreation || createDraftRFPMutation.isPending}
              sx={{ width: 134 }}
            >
              <Stack direction="row" sx={{ gap: 1 }}>
                <CircularProgress
                  size={18}
                  color="inherit"
                  sx={{ display: createDraftRFPMutation.isPending ? 'block' : 'none' }}
                />
                {rfpData ? 'Save Changes' : 'Add RFP'}
              </Stack>
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  )
}
