import { useNavigate } from '@tanstack/react-router'
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import theme from '@/theme.ts'

export type PlantRelatedObjectsTableItem = {
  id: string
  name: string
  link: string
  descriptions: string[]
}
export type PlantRelatedObjectsTableData = {
  job: PlantRelatedObjectsTableItem
  rfp: PlantRelatedObjectsTableItem[]
  quote: PlantRelatedObjectsTableItem[]
  order: PlantRelatedObjectsTableItem[]
}
export const PlantRelatedObjectsTable = ({ data }: { data: PlantRelatedObjectsTableData }) => {
  if (!data) return null

  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'none', border: 'none' }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {['JOB NAME', 'REFS', 'QUOTES', 'ORDERS'].map((header) => (
              <TableCell key={header} sx={{ fontSize: '13px', fontWeight: 700, color: theme.palette.mediumGrey.main }}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ verticalAlign: 'top' }}>
              <RelatedObjectItem item={data.job} />
            </TableCell>
            {[data.rfp, data.quote, data.order].map((items: PlantRelatedObjectsTableItem[], index: number) => {
              return (
                <TableCell sx={{ verticalAlign: 'top' }} key={index}>
                  {!items || !items.length ? (
                    <EmptyRelatedObjectItem />
                  ) : (
                    items.map((item) => <RelatedObjectItem item={item} key={item.id} />)
                  )}
                </TableCell>
              )
            })}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const EmptyRelatedObjectItem = () => {
  return (
    <Typography variant="body2" color={theme.palette.mediumGrey.main}>
      None
    </Typography>
  )
}

export const RelatedObjectItem = ({ item }: { item: PlantRelatedObjectsTableItem }) => {
  const navigate = useNavigate()

  if (!item) return <EmptyRelatedObjectItem />
  return (
    <Stack>
      <Typography
        variant="body1"
        onClick={() => navigate({ to: item.link })}
        sx={{ cursor: 'pointer', textDecoration: 'underline' }}
      >
        {item.name}
      </Typography>
      <Stack sx={{ pb: 1 }}>
        {item.descriptions.map((desc, index) => (
          <Typography variant="body2" key={index} color={theme.palette.mediumGrey.main}>
            {desc}
          </Typography>
        ))}
      </Stack>
    </Stack>
  )
}
