import { Skeleton, Stack } from '@mui/material'
import theme from '@/theme.ts'
import { RfpPageContentSkeleton } from '@/components/rfps/skeletons/rfp-page-content-skeleton.tsx'

interface RfpPageLoadingSkeletonProps {
  showVendorSidebar?: boolean
}
export const RfpPageLoadingSkeleton = ({ showVendorSidebar }: RfpPageLoadingSkeletonProps) => {
  return (
    <Stack sx={{ overflow: 'hidden', height: '100%' }}>
      <Stack direction="row" sx={{ justifyContent: 'space-between', p: 3 }}>
        <Stack direction="row">
          <Skeleton variant="rectangular" width={40} height={40} sx={{ borderRadius: 1, mr: 2 }} />
          <Skeleton variant="text" width={300} height={40} />
        </Stack>
        <Stack direction="row">
          <Skeleton variant="rectangular" width={150} height={40} sx={{ borderRadius: 1, mr: 2 }} />
          <Skeleton variant="rectangular" width={40} height={40} sx={{ borderRadius: 1 }} />
        </Stack>
      </Stack>
      <Stack direction="row" sx={{ backgroundColor: theme.palette.lightGrey3.main, flexGrow: 1, overflow: 'hidden' }}>
        {/* Sidebar Skeleton */}
        {showVendorSidebar && (
          <Stack sx={{ gap: 2, width: '340px', p: 3, backgroundColor: 'white', mt: '1px' }}>
            {[1, 2, 3].map((category) => (
              <Stack key={category} sx={{ gap: 1, pt: 2 }}>
                <Stack direction="row" sx={{ gap: 1, alignItems: 'center' }}>
                  <Skeleton variant="circular" width={12} height={12} />
                  <Skeleton variant="text" width={120} height={24} />
                </Stack>
                {[1, 2].map((item) => (
                  <Stack key={item} sx={{ gap: 1, py: 1 }}>
                    <Skeleton variant="text" width="90%" height={24} />
                    <Skeleton variant="text" width="70%" height={20} />
                  </Stack>
                ))}
              </Stack>
            ))}
          </Stack>
        )}

        {/* Main Content Skeleton */}
        <RfpPageContentSkeleton />

        {/* Side Drawer Skeleton */}
        <Stack
          sx={{
            height: 'auto',
            overflow: 'hidden',
            backgroundColor: theme.palette.lightGrey.main,
            gap: 4,
            pt: 5,
            px: 2,
          }}
        >
          {Array.from({ length: 4 }).map((_, index) => (
            <Skeleton key={index} variant="rectangular" width={40} height={40} sx={{ borderRadius: 1 }} />
          ))}
        </Stack>
      </Stack>
    </Stack>
  )
}
