import { RFPIndexStatus } from '@/api/types/rfps'
import { useCallback, useState, MouseEvent } from 'react'
import { RFPMenu } from '../index/rfp-menu'

type PickFields = 'rfp_id' | 'state' | 'flagged_as_important_by_customer' | 'flagged_as_important_by_vendor'
export type RFPMenuItem = Pick<RFPIndexStatus['data'][number], PickFields>

export interface RFPMenuState {
  rfp: RFPMenuItem | null
  anchorEl: HTMLElement | null
}

export function useRFPMenu() {
  const [menuState, setMenuState] = useState<RFPMenuState>({
    rfp: null,
    anchorEl: null,
  })

  const openMenu = useCallback((event: MouseEvent<HTMLElement>, rfp: RFPMenuItem) => {
    event.stopPropagation()
    setMenuState({ anchorEl: event.currentTarget, rfp })
  }, [])

  const closeMenu = useCallback(() => {
    setMenuState({ anchorEl: null, rfp: null })
  }, [])

  return {
    menuState,
    openMenu,
    closeMenu,
    RFPMenu,
  }
}
