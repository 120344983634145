import { useEffect } from 'react'
import { IconButton, Stack } from '@mui/material'
import LinkIcon from '@mui/icons-material/Link'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getSelection, $isRangeSelection, $createTextNode, COMMAND_PRIORITY_LOW } from 'lexical'
import { $createLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import { $isLinkNode } from '@lexical/link'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'

const LinkComponent = () => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    const unregisterCommand = editor.registerCommand(
      TOGGLE_LINK_COMMAND,
      (payload) => {
        editor.update(() => {
          const selection = $getSelection()
          if ($isRangeSelection(selection)) {
            // Get the text content from the selection
            let textContent = selection.getTextContent()
            if (!textContent) textContent = 'link'

            // Create a link node
            console.log('payload', payload)
            const linkNode = $createLinkNode(payload as string)
            // Create a text node with the selected content
            const textNode = $createTextNode(textContent)
            // Add the text node to the link node
            linkNode.append(textNode)
            // Replace the selection with the link node
            selection.insertNodes([linkNode])
          }
        })
        return true
      },
      COMMAND_PRIORITY_LOW
    )

    return () => {
      unregisterCommand()
    }
  }, [editor])

  const handleInsertLink = () => {
    editor.getEditorState().read(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        const nodes = selection.getNodes()
        const parent = nodes[0].getParent()
        const existingUrl = $isLinkNode(parent) ? parent.getURL() : ''

        const url = prompt('Enter Link URL:', existingUrl)
        if (url === null) {
          // User cancelled
          return
        }

        if (url === '') {
          // Remove link
          editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
        } else {
          // Add or update link
          editor.dispatchCommand(TOGGLE_LINK_COMMAND, url)
        }
      }
    })
  }

  return (
    <>
      <Stack direction="row" spacing={1}>
        <IconButton onClick={handleInsertLink} size="small" aria-label="Insert Link">
          <LinkIcon />
        </IconButton>
      </Stack>
      <LinkPlugin />
    </>
  )
}

export default LinkComponent
