import { Stack } from '@mui/material'
import { ReactNode, useState } from 'react'
import theme from '@/theme.ts'
import IconButton from '@mui/material/IconButton'
import { CustomMenu } from '@/components/ui/base/dropdowns/custom-menu.tsx'
import { PopoverProps } from '@mui/material/Popover'
import { CustomMenuDropdownItem } from '@/types.ts'

interface CustomMenuDropdownProps {
  label?: string
  labelNode?: ReactNode
  border?: string
  backgroundColor?: string
  minMenuWidth?: number | string
  anchorOrigin?: PopoverProps['anchorOrigin']
  transformOrigin?: PopoverProps['transformOrigin']
  menuItems: CustomMenuDropdownItem[]
}

export const CustomMenuDropdown = ({
  border = 'none',
  backgroundColor = 'transparent',
  minMenuWidth = 300,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  menuItems = [],
  label,
  labelNode,
}: CustomMenuDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Stack>
      <IconButton
        sx={{
          minWidth: 0,
          p: 0,
          borderRadius: theme.borderRadius.sm,
          border: border,
          backgroundColor: backgroundColor,
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {labelNode || label || 'Menu'}
      </IconButton>
      <CustomMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        minMenuWidth={minMenuWidth}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        menuItems={menuItems}
      />
    </Stack>
  )
}
