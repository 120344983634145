import { useCallback, useState } from 'react'
import { VendorContactModal, VendorContactModalProps } from '@/components/ui/modals/vendor-contacts-modal.tsx'

export const useVendorContactModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [modalProps, setModalProps] = useState<Omit<VendorContactModalProps, 'open'>>({
    vendorContact: null,
    title: '',
    cancelButtonText: '',
    submitButtonText: '',
    multiSelect: false,
    loading: false,
    onSubmit: () => undefined,
  })

  const openVendorContactModal = useCallback((props: Omit<VendorContactModalProps, 'open'>) => {
    setModalProps(props)
    setIsOpen(true)
  }, [])

  const closeVendorContactModal = useCallback(() => {
    setIsOpen(false)
    modalProps?.onClose?.()
  }, [modalProps])

  const updateVendorContactModal = useCallback((props: Partial<VendorContactModalProps>) => {
    setModalProps((prevProps) => {
      return { ...prevProps, ...props }
    })
  }, [])

  const Component = useCallback(() => {
    const { onClose: _, ...restModalProps } = modalProps
    return <VendorContactModal open={isOpen} onClose={closeVendorContactModal} {...restModalProps} />
  }, [isOpen, closeVendorContactModal, modalProps])

  return {
    openVendorContactModal,
    closeVendorContactModal,
    updateVendorContactModal,
    VendorContactModal: Component,
  }
}
