import Box from '@mui/material/Box'
import theme from '@/theme.ts'
import Typography from '@mui/material/Typography'
import { OrganizationDetails } from '@/components/ui/organization-details.tsx'
import { Menu, MenuItem, Stack } from '@mui/material'
import { RFPDetails } from '@/components/rfps/draft/steps/build-rfp-step-content-rfp-details.tsx'
import { JobDetails } from '@/components/rfps/draft/steps/build-rfp-step-content-job-details.tsx'
import Button from '@mui/material/Button'
import { Add } from '@mui/icons-material'
import { Job, JobPhase, PlantListEntry } from '@/types.ts'
import AddPlantsModal from '@/components/ui/modals/add-plants-modal.tsx'
import { CustomDivider } from '@/components/ui/base/dividers.tsx'
import RFPComments from '@/components/rfps/rfp-comments.tsx'
import AddPlantsFromListModal from '@/components/ui/modals/add-plants-from-list-modal.tsx'
import IconButton from '@mui/material/IconButton'
import { EllipsisVertical } from 'lucide-react'
import { excludeDeletedItems, formatStringToShortMonthDayYear, groupByKey, trimUUID } from '@/lib/utils.ts'
import AddPlantsFromJobsModal from '@/components/ui/modals/add-plants-from-jobs-modal'
import { ConfirmationModal } from '@/components/ui/modals/confirmation-modal.tsx'
import CreatePlantListForRFPModal from '@/components/rfps/modals/create-plant-list-for-rfp-modal.tsx'
import AddJobsToRFPModal from '@/components/ui/modals/add-jobs-to-rfp-modal.tsx'
import { AddPhaseToJobRfpModal } from '@/components/ui/modals/add-phase-to-job-rfp-modal.tsx'
import { CreateRfpSteps } from '@/constants.ts'
import { Route as RfpDraftRoute } from '@/routes/$orgId/rfps.draft.$rfpId'

import RequestPricingHeader from './components/request-pricing-header.tsx'
import JobMenu from './components/job-menu.tsx'
import JobPlantsMenu from './components/job-plants-menu.tsx'
import RfpJobPhasePlantTable from './components/rfp-job-phase-plant-table.tsx'
import RfpJobDefaultContent from './components/rfp-job-default-content.tsx'
import NewRfpDefaultContent from './components/new-rfp-default-content.tsx'

import { useRfpModals } from '@/components/rfps/hooks/useRfpModals.tsx'
import { useRfpContext } from '@/components/rfps/context/rfp-context.tsx'
import CreateEditRfpModal from '@/components/rfps/modals/create-edit-rfp-modal.tsx'
import { useMemo, useState } from 'react'
import VisibilityCallout from '@/components/rfps/visibility-callout.tsx'
import { EditRfpJobPhaseNameModal } from '@/components/rfps/modals/edit-rfp-job-phase-name-modal.tsx'
import { RFP_QUERY_KEYS } from '@/lib/query-keys.ts'
import { useQueryClient } from '@tanstack/react-query'

export default function RfpPageContent({
  editMode = false,
  showHeader = true,
  displayEditButton = false,
  allowPlantTableReorder = false,
  allowPlantTableQuantityChange = false,
  allowPlantTableSelect = false,
  showPlantTableMenu = false,
}: {
  editMode?: boolean
  showHeader?: boolean
  displayEditButton?: boolean
  allowPlantTableReorder?: boolean
  allowPlantTableQuantityChange?: boolean
  allowPlantTableSelect?: boolean
  showPlantTableMenu?: boolean
}) {
  const navigate = RfpDraftRoute.useNavigate()
  const [addJobMenuAnchorEl, setAddJobMenuAnchorEl] = useState<null | HTMLElement>(null)

  const {
    rfpData,
    updateRfpData,
    sentRfp,
    handlePlantMenuClick,
    handleJobMenuClick,
    selectedPlantIds,
    addPlants,
    removeSelectedPlants,
    activeJobId,
    setActiveJobId,
    activePhaseId,
    isAddingJob,
    addJobsToRfp,
  } = useRfpContext()

  const {
    addPlantsFromJobModalProps,
    addPlantsFromListModalOpen,
    createPlantListModalOpen,
    addPlantsModalOpen,
    editRfpDetailsModalOpen,
    addPhaseToJobRfpModalOpen,
    addJobsToRfpModalOpen,
    confirmationModalContent,
    editRfpJobPhaseNameModalOpen,
    setAddPlantsFromJobModalProps,
    setAddPlantsFromListModalOpen,
    setCreatePlantListModalOpen,
    setAddPlantsModalOpen,
    setEditRfpDetailsModalOpen,
    setAddPhaseToJobRfpModalOpen,
    setAddJobsToRfpModalOpen,
    removeModalFromUrl,
    setEditRfpJobPhaseNameModalOpen,
  } = useRfpModals()

  const queryClient = useQueryClient()
  const plants = rfpData.plants || []
  const rfpOrganization = rfpData.organization || {}
  const rfpJobVisibility = rfpData.rfp_settings.show_job_name_to_vendor || false
  const rfpPhaseVisibility = rfpData.rfp_settings.show_phases_on_jobs_to_vendor || false
  const rfpJobPhaseEditable = editMode || false
  const jobGroups = useMemo(() => {
    return (
      rfpData.jobs.reduce(
        (acc: Record<string, Job>, job: string | Job) => {
          if (typeof job === 'string') {
            acc[job] = {} as Job
            return acc
          }
          if (job.deleted_at) {
            return acc
          }
          acc[job.id] = job
          return acc
        },
        {} as Record<string, Job>
      ) || {}
    )
  }, [rfpData.jobs])

  const plantsByJobGroup = groupByKey(excludeDeletedItems(rfpData.plants), 'job_id')
  const activeJobPhaseName = useMemo(() => {
    // If no active job or phase, return empty string
    if (!(activeJobId || activePhaseId) || !jobGroups[activeJobId]) return ''

    const jobData = jobGroups[activeJobId]

    // If active Job and no active phase, return job name
    if (activeJobId && !activePhaseId) return jobData.name || ''

    // If active phase, return phase name
    const phaseData = jobData.phases.find((phase: JobPhase) => phase.id === activePhaseId)
    return phaseData?.name || ''
  }, [activeJobId, activePhaseId, jobGroups])

  const handleAddJobButtonClick = (newJob: boolean = true) => {
    setActiveJobId('')
    setAddJobsToRfpModalOpen(newJob ? 'newJob' : 'existingJob')
    setAddJobMenuAnchorEl(null)
  }

  const handleAddExistingJobsToRfp = (jobIds: Set<string>) => {
    setAddPlantsFromJobModalProps({
      jobIds: Array.from(jobIds),
      showJobSelection: true,
      open: true,
    })
  }
  const handleAddRemovePlantClick = () => {
    navigate({
      to: RfpDraftRoute.fullPath,
      params: {
        orgId: rfpOrganization.id,
        rfpId: rfpData.id,
      },
      search: {
        step: CreateRfpSteps.ADD_VENDORS,
        selectedSentRfpId: sentRfp?.id,
      },
    })
  }

  const handleJobNameEditClick = (job: Job) => {
    setActiveJobId(job.id)
    setEditRfpJobPhaseNameModalOpen(true)
  }

  const handleJobNameUpdate = async (_name: string) => {
    await queryClient.invalidateQueries({
      queryKey: RFP_QUERY_KEYS.draftRfp(rfpData.id),
    })
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: '100%',
        backgroundColor: 'white',
        borderRadius: theme.borderRadius.lg,
      }}
    >
      <Stack
        sx={{
          backgroundColor: 'white',
          borderRadius: theme.borderRadius.lg,
          overflow: 'hidden',
          p: 5.5,
          gap: 3,
        }}
      >
        {showHeader && (
          <>
            {/* Organization details & RFP# */}
            <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
              <OrganizationDetails organization={rfpOrganization} />
              <Typography variant="h3" fontWeight={400} sx={{ color: theme.palette.mediumGrey2.main }}>
                RFP# {trimUUID(rfpData.id)}
              </Typography>
            </Stack>

            {/* RFP Details & Job Details */}
            <Stack direction="row" sx={{ justifyContent: 'space-between', gap: 2 }}>
              <RFPDetails
                openEditRfpModal={() => {
                  setEditRfpDetailsModalOpen(true)
                }}
                rfpData={rfpData}
                editMode={editMode}
              />
              <JobDetails rfpData={rfpData} />
            </Stack>
          </>
        )}

        {/* Jobs/Phases & Group */}
        {Object.keys(jobGroups).length ? (
          <Stack key="rfp-job-groups">
            <Stack
              direction="row"
              sx={{
                justifyContent: 'space-between',
                mb: 1,
                alignItems: 'center',
              }}
            >
              <RequestPricingHeader
                rfpData={rfpData}
                sentRfpId={sentRfp?.id}
                plants={plants}
                selectedPlantIds={selectedPlantIds}
              />

              {/* Bulk Actions & Add Plants - visible only in edit mode */}
              {editMode && (
                <Stack direction="row" sx={{ gap: 1 }}>
                  <Button
                    variant="outlined"
                    aria-label="remove-plants-rfp"
                    onClick={removeSelectedPlants}
                    disabled={selectedPlantIds.size === 0}
                    sx={{
                      color: theme.palette.darkGray.main,
                      borderRadius: theme.borderRadius.sm,
                      borderColor: theme.palette.lightGrey2.main,
                    }}
                  >
                    <Typography variant="button">Remove Selected from RFP</Typography>
                  </Button>

                  {/* Add Job */}
                  <Button
                    variant="outlined"
                    aria-label="add-job-rfp"
                    onClick={(e) => setAddJobMenuAnchorEl(e.currentTarget)}
                    sx={{
                      borderRadius: theme.borderRadius.sm,
                      borderColor: theme.palette.lightGrey2.main,
                      color: theme.palette.darkGray.main,
                    }}
                  >
                    <Add fontSize="small" />
                    <Typography variant="button">Add Job</Typography>
                  </Button>
                  <Menu
                    anchorEl={addJobMenuAnchorEl}
                    open={Boolean(addJobMenuAnchorEl)}
                    onClose={() => setAddJobMenuAnchorEl(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    sx={{
                      '& .MuiPaper-root': {
                        minWidth: 200,
                      },
                    }}
                  >
                    <MenuItem onClick={() => handleAddJobButtonClick(true)}>Create New Job</MenuItem>
                    <MenuItem onClick={() => handleAddJobButtonClick(false)}>Select Existing Job</MenuItem>
                  </Menu>
                </Stack>
              )}

              {displayEditButton && (
                <Button variant="text" onClick={handleAddRemovePlantClick} sx={{ gap: 1 }}>
                  <Typography variant="body2" sx={{ color: theme.palette.darkGray.main }}>
                    Add / Remove Plants
                  </Typography>
                </Button>
              )}
            </Stack>

            <CustomDivider />

            {/* Plant Table */}
            {Object.entries(jobGroups).map(([jobId, data]: [string, unknown]) => {
              const jobData = data as Job
              const hasPlants = excludeDeletedItems(plantsByJobGroup[jobId]).length > 0
              const expectedJobStartDate = formatStringToShortMonthDayYear(jobData.job_date as string) || 'TBD'
              return (
                <Stack key={`rfp-job-${jobId}`} sx={{ pt: 2 }}>
                  <Stack direction="row" sx={{ pt: 4, pb: 1, pl: 2, pr: 0, justifyContent: 'space-between' }}>
                    <Stack direction="row" sx={{ gap: 1, alignItems: 'center' }}>
                      <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Typography fontWeight={700} variant="body1">
                          Job #{trimUUID(jobId)}
                        </Typography>
                        <VisibilityCallout
                          editable={rfpJobPhaseEditable}
                          visible={rfpJobVisibility}
                          itemName={jobData.name || 'Unnamed Job'}
                          onClick={() => handleJobNameEditClick(jobData)}
                        />
                      </Stack>
                    </Stack>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                      {/* Expected Start Date */}
                      <Typography sx={{ mr: 2 }} fontWeight={400} variant="body2" color="textSecondary">
                        Expected Start Date: {expectedJobStartDate}
                      </Typography>

                      {/* Plants Menu */}
                      {editMode && hasPlants && (
                        <>
                          <Button
                            variant="contained"
                            onClick={(e) => handlePlantMenuClick(e, jobId)}
                            sx={{ minWidth: 99 }}
                          >
                            <Add fontSize="small" />
                            Plants
                          </Button>
                          <JobPlantsMenu jobGroups={jobGroups} />
                        </>
                      )}

                      {/* Job Hamburger Menu */}
                      {editMode && (
                        <>
                          <IconButton
                            sx={{
                              px: 0,
                              py: 1,
                              ml: 1,
                              borderRadius: theme.borderRadius.sm,
                              border: '1px solid #ccc',
                              backgroundColor: theme.palette.lightGrey3.main,
                            }}
                            onClick={(e) => handleJobMenuClick(e, jobId)}
                          >
                            <EllipsisVertical />
                          </IconButton>
                          <JobMenu jobGroups={jobGroups} plantsByJobGroup={plantsByJobGroup} />
                        </>
                      )}
                    </Stack>
                  </Stack>
                  <CustomDivider />
                  {hasPlants ? (
                    <RfpJobPhasePlantTable
                      jobId={jobId}
                      phaseVisible={rfpPhaseVisibility}
                      jobGroups={jobGroups}
                      plantsByJobGroup={plantsByJobGroup}
                      editMode={editMode}
                      allowPlantTableQuantityChange={allowPlantTableQuantityChange}
                      allowPlantTableReorder={allowPlantTableReorder}
                      allowPlantTableSelect={allowPlantTableSelect}
                      showPlantTableMenu={showPlantTableMenu}
                    />
                  ) : (
                    <RfpJobDefaultContent jobId={jobId} jobGroups={jobGroups} />
                  )}
                </Stack>
              )
            })}
          </Stack>
        ) : (
          <NewRfpDefaultContent isAddingJob={isAddingJob} />
        )}
        {/* Vendor Comments & Internal Notes */}
        <Stack direction="row" sx={{ justifyContent: 'space-between', gap: 2, mt: 3 }}>
          <RFPComments
            title="General Comments (for Vendors)"
            name="vendorComments"
            value={rfpData.comments_for_vendor || ''}
            placeholder="Type comments here ..."
            onChange={(value) => updateRfpData({ comments_for_vendor: value })}
            editMode={editMode}
          />
          {/* Internal Notes is visible only in edit mode (used in build rfp step) */}
          {editMode && (
            <RFPComments
              title="General Notes"
              name="generalNotes"
              value={rfpData.notes || ''}
              placeholder="Type notes here ..."
              isInternal
              onChange={(value) => updateRfpData({ notes: value })}
              editMode={editMode}
            />
          )}
        </Stack>
      </Stack>

      {/* RFP modals */}
      <CreateEditRfpModal
        open={editRfpDetailsModalOpen}
        onClose={() => {
          setEditRfpDetailsModalOpen(false)
          removeModalFromUrl()
        }}
        rfpData={rfpData}
        onSubmit={updateRfpData}
      />
      <AddPlantsFromJobsModal
        open={addPlantsFromJobModalProps?.open || false}
        onClose={() => {
          setActiveJobId('')
          setAddPlantsFromJobModalProps({})
        }}
        jobIds={addPlantsFromJobModalProps?.jobIds || []}
        jobPlantsInRfp={excludeDeletedItems(rfpData.plants).filter((entry: PlantListEntry) =>
          (addPlantsFromJobModalProps?.jobIds || []).includes(entry.job_id as string)
        )}
        onAddPlants={addPlants}
        showJobSelection={addPlantsFromJobModalProps?.showJobSelection || false}
      />
      <AddPlantsFromListModal
        open={addPlantsFromListModalOpen}
        onClose={() => setAddPlantsFromListModalOpen(false)}
        onAddPlants={addPlants}
      />
      <CreatePlantListForRFPModal
        open={createPlantListModalOpen}
        onClose={() => setCreatePlantListModalOpen(false)}
        onAddPlants={addPlants}
      />
      <AddPlantsModal
        open={addPlantsModalOpen}
        onClose={() => setAddPlantsModalOpen(false)}
        onAddPlants={addPlants}
        job={jobGroups[activeJobId]}
      />
      <AddJobsToRFPModal
        open={!!addJobsToRfpModalOpen}
        onClose={() => setAddJobsToRfpModalOpen('')}
        newJob={addJobsToRfpModalOpen === 'newJob'}
        onAddNewJobs={addJobsToRfp}
        onAddExistingJobs={handleAddExistingJobsToRfp}
      />
      <AddPhaseToJobRfpModal
        open={addPhaseToJobRfpModalOpen}
        onClose={() => {
          setAddPhaseToJobRfpModalOpen(false)
        }}
        jobData={jobGroups[activeJobId]}
        jobNameVisibility={rfpData.rfp_settings.show_job_name_to_vendor || false}
        rfpId={rfpData.id}
      />
      <EditRfpJobPhaseNameModal
        key={`edit-rfp-job-${activeJobId}-phase-${activePhaseId}-name`}
        open={editRfpJobPhaseNameModalOpen}
        name={activeJobPhaseName}
        job_id={activeJobId}
        phase_id={activePhaseId}
        onSubmit={handleJobNameUpdate}
      />
      {confirmationModalContent && <ConfirmationModal {...confirmationModalContent} />}
    </Box>
  )
}
