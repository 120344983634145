import theme from '@/theme'
import { ChatOutlined } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'

export function CommentBubble({ count = 0, unread = false }: { count: number; unread: boolean }) {
  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: unread ? theme.palette.error.light : theme.palette.lightGrey2.main,
          borderRadius: '4px',
          padding: '2px 6px',
          color: '#fff',
        }}
      >
        <ChatOutlined fontSize="small" sx={{ color: '#fff' }} />
        <Typography variant="body2" sx={{ ml: 0.5, fontWeight: 'bold' }}>
          {count}
        </Typography>
      </Box>
    </Stack>
  )
}
