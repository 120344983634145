import { createFileRoute, useNavigate } from '@tanstack/react-router'
import PageLayout from '@/components/ui/layouts/page-layout.tsx'
import { DetailsPageTitleBarActions } from '@/components/rfps/details/details-page-title-bar-actions.tsx'
import Stack from '@mui/material/Stack'
import { useQuery } from '@tanstack/react-query'
import { RFP_QUERY_KEYS } from '@/lib/query-keys.ts'
import { getRfp } from '@/api/rfps.ts'
import theme from '@/theme.ts'
import { Typography } from '@mui/material'
import { TextIconButton } from '@/components/ui/base/buttons/text-icon-buttons.tsx'
import { RfpPageContentSkeleton } from '@/components/rfps/skeletons/rfp-page-content-skeleton.tsx'
import { useFeatureNotImplemented } from '@/hooks/useFeatureNotImplemented.tsx'
import Button from '@mui/material/Button'
import { EditPageRfpContent } from '@/components/rfps/details/edit-page-rfp-content.tsx'

export const Route = createFileRoute('/$orgId/rfps/edit/$rfpId')({
  component: RouteComponent,
})

function RouteComponent() {
  const { orgId, rfpId } = Route.useParams()
  const navigate = useNavigate({ from: Route.fullPath })
  const notifyFeatureNotImplemented = useFeatureNotImplemented()

  const { data: rfpData, isLoading: rfpDataLoading } = useQuery({
    queryKey: RFP_QUERY_KEYS.draftRfp(rfpId),
    queryFn: async () => {
      return await getRfp(rfpId)
    },
    enabled: !!rfpId,
  })

  const handleCancel = () => {
    navigate({ to: `/${orgId}/rfps/${rfpId}` })
  }

  const handleSaveDraft = () => {
    notifyFeatureNotImplemented()
  }

  const handleReview = () => {
    notifyFeatureNotImplemented()
  }

  if (rfpDataLoading) return <RfpPageContentSkeleton />
  if (!rfpData) return null

  return (
    <PageLayout
      title={`RFP #${rfpId}`}
      backRoute={{ to: '/$orgId/rfps' }}
      pageLayoutSx={{ p: 0 }}
      appBarSx={{ pl: 3, pt: 4, pb: 1 }}
      titleBarElement={<DetailsPageTitleBarActions rfpData={rfpData} />}
    >
      <Stack direction="row" sx={{ backgroundColor: theme.palette.lightGrey3.main, height: '100%' }}>
        <Stack
          spacing={3}
          sx={{
            p: 5,
            pb: 0,
            flexGrow: 1,
            width: '100%',
            overflowY: 'hidden',
            scrollbarWidth: 'none',
            position: 'relative',
          }}
        >
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center' }}>
              Make Changes to RFP
            </Typography>
            <Stack direction="row" sx={{ gap: 1 }}>
              <TextIconButton
                onClick={handleCancel}
                text="Cancel"
                sx={{
                  px: 3,
                  borderRadius: theme.borderRadius.sm,
                  color: theme.palette.darkGray.main,
                }}
              />
              <TextIconButton
                onClick={handleSaveDraft}
                text="Save Draft"
                sx={{ px: 3, borderRadius: theme.borderRadius.sm, color: theme.palette.darkGray.main }}
              />
              <Button onClick={handleReview} variant="contained" sx={{ px: 3, borderRadius: theme.borderRadius.sm }}>
                Review & Publish Changes
              </Button>
            </Stack>
          </Stack>
          <Stack
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              scrollbarWidth: 'none',
              pb: 4,
            }}
          >
            <EditPageRfpContent rfpData={rfpData} />
          </Stack>
        </Stack>
      </Stack>
    </PageLayout>
  )
}
