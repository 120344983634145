import { excludeDeletedItems } from '@/lib/utils.ts'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useRfpContext } from '@/components/rfps/context/rfp-context.tsx'
import { Job, PlantListEntry } from '@/types.ts'

export default function JobMenu({
  plantsByJobGroup,
  jobGroups,
}: {
  plantsByJobGroup: Record<string, PlantListEntry[]>
  jobGroups: Record<string, Job>
}) {
  const {
    activeJobId,
    setConfirmationModalContent,
    setAddPhaseToJobRfpModalOpen,
    removeJobFromRfp: handleRemoveJobFromRfp,
  } = useRfpContext()
  const { handleJobMenuClose, jobMenuAnchorEl } = useRfpContext()
  const jobData = jobGroups[activeJobId]
  const plantsInJobCount = excludeDeletedItems(plantsByJobGroup[activeJobId]).length

  const handleClose = () => {
    handleJobMenuClose()
  }

  const removeJobFromRfp = () => {
    handleRemoveJobFromRfp(activeJobId)
    setConfirmationModalContent(null)
  }

  const handleRemoveJob = () => {
    handleClose()
    setConfirmationModalContent({
      open: true,
      title: 'Remove Job from RFP',
      message: `Are you sure you want to remove ${
        activeJobId === 'null'
          ? `the ${plantsInJobCount} unassigned plants from this RFP ?`
          : `${jobData.name} (Job #${activeJobId}) and its plants from this RFP ?`
      }`,
      onConfirm: removeJobFromRfp,
      onCancel: () => {
        setConfirmationModalContent(null)
      },
    })
  }

  const handleJobPhase = () => {
    setAddPhaseToJobRfpModalOpen(true)
    handleClose()
  }

  const handleHidePhases = () => {
    console.log("Hide This Job's Phases from Vendor")
    handleClose()
  }

  const handleRequestImages = () => {
    console.log('Request Images of All Items')
    handleClose()
  }

  return (
    <Box>
      <Menu
        anchorEl={jobMenuAnchorEl}
        open={Boolean(jobMenuAnchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            minWidth: 200,
          },
        }}
      >
        <MenuItem onClick={handleRemoveJob}>Remove Job from RFP</MenuItem>
        <MenuItem onClick={handleJobPhase}>Add a New Phase to Job</MenuItem>
        <MenuItem onClick={handleHidePhases}>Hide This Job's Phases from Vendor</MenuItem>
        <MenuItem onClick={handleRequestImages}>Request Images of All Items</MenuItem>
      </Menu>
    </Box>
  )
}
