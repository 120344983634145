import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import theme from '@/theme.ts'
import { SendHorizontal } from 'lucide-react'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/material'
import { RfpEmailContactInfoPill } from '@/components/rfps/rfp-email-contact-info-pill.tsx'
import { PrimaryCancelButton } from '@/components/ui/base/buttons/buttons.tsx'

export default function RfpSentSuccessModal({
  open,
  onClose,
  rfpId,
  sentRfps,
}: {
  open: boolean
  onClose: () => void
  rfpId: string
  sentRfps: any[]
}) {
  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: theme.borderRadius.xl,
        }}
      >
        <Stack spacing={4} sx={{ alignItems: 'center' }}>
          <Box
            sx={{
              borderRadius: theme.borderRadius.circle,
              backgroundColor: '#26A482',
              width: '90px',
              height: '90px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <SendHorizontal size={48} strokeWidth={2.5} color="#FFFFFF" />
          </Box>
          <Typography variant="h3">Your RFP has been successfully sent!</Typography>
          <Stack
            spacing={3}
            sx={{
              border: '1px solid',
              borderColor: theme.palette.lightGrey2.main,
              borderRadius: theme.borderRadius.md,
              py: 8,
              px: 16,
              gap: 2.5,
            }}
          >
            <Typography variant="h4">RFP#{rfpId} shared with:</Typography>
            {sentRfps.map((sentRfp) => (
              <Stack key={sentRfp.id} sx={{ alignItems: 'center', gap: 1 }}>
                <Typography variant="strong">{sentRfp.directed_organization_contact.organization_name}</Typography>
                {sentRfp.directed_organization_user_contacts.map((contact: any) => (
                  <RfpEmailContactInfoPill key={contact.id} contact={contact} />
                ))}
              </Stack>
            ))}
          </Stack>
          <PrimaryCancelButton variant="outlined" sx={{ fontWeight: 400 }} onClick={onClose}>
            Close Window
          </PrimaryCancelButton>
        </Stack>
      </Box>
    </Modal>
  )
}
