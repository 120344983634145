import { z } from 'zod'
import api from './api'
import {
  RFPContactIndexSchema,
  RFPIndexOptionsSchema as RFPIndexOptionsImport,
  RFPIndexSchema,
  RFPIndexUnion,
  RFPJobIndexSchema,
  RFPStateEnum,
  SentRFPResponseArraySchema,
} from './types/rfps'
import { QueryClient, useMutation } from '@tanstack/react-query'

// Route Options are the source of truth, extend as needed for the query manager
const _RFPIndexOptionsSchema = RFPIndexOptionsImport.extend({
  type: z.enum(['received', 'sent', 'drafts']).optional(),
})

type RFPIndexOptions = z.infer<typeof _RFPIndexOptionsSchema>
type GroupByWithDrafts = NonNullable<RFPIndexOptions['groupBy']> | 'drafts'

// Define a discriminated union for mutation payloads
export type RFPMutationPayload =
  | { type: 'ARCHIVE_ALL'; rfpIds: string[] }
  | { type: 'TOGGLE_IMPORTANT'; rfpId: string; currentValue: boolean }
  | { type: 'SET_STATE'; rfpId: string; state: z.infer<typeof RFPStateEnum> }
  | { type: 'READ_ALL'; rfpIds: string[] }
export class RFPQueryManager {
  organizationId: string
  queryClient: QueryClient

  // Might not be the best to type based on the discriminated union long term, but it works for now
  readonly endpoints: Record<GroupByWithDrafts, string> = {
    drafts: '/v1/core/rfp/',
    status: '/v1/core/sent_rfp/',
    job: '/v1/core/rfp/job_view/',
    contact: '/v1/core/rfp/contact_view/',
  }

  readonly defaults: typeof RFPQueryManager.defaults

  static defaults = {
    type: 'sent',
    groupBy: 'status',
    status: 'open',
    limit: 20,
    page: 0,
  } as const

  constructor(organizationId: string, queryClient: QueryClient) {
    this.organizationId = organizationId
    this.defaults = RFPQueryManager.defaults
    this.queryClient = queryClient
  }

  private async getRfpData(options: RFPIndexOptions): Promise<RFPIndexUnion> {
    const { groupBy = this.defaults.groupBy, limit = this.defaults.limit } = options
    const params = this.buildQueryParams(options)

    if (groupBy === 'job') {
      const { data, hasMore } = await this.fetchWithPagination(
        {
          endpoint: this.endpoints[groupBy],
          params,
          limit: limit ?? this.defaults.limit,
          schema: RFPJobIndexSchema,
        },
        true
      )
      return { data, hasMore, groupBy: 'job' }
    }

    if (groupBy === 'contact') {
      const { data, hasMore } = await this.fetchWithPagination(
        {
          endpoint: this.endpoints[groupBy],
          params,
          limit: options.limit ?? this.defaults.limit,
          schema: RFPContactIndexSchema,
        },
        true
      )
      return { data, hasMore, groupBy: 'contact' }
    }

    const { data, hasMore } = await this.fetchWithPagination(
      {
        endpoint: this.endpoints[groupBy],
        params,
        limit: options.limit ?? this.defaults.limit,
        schema: SentRFPResponseArraySchema,
      },
      true
    )
    return { data, hasMore, groupBy: 'status' }
  }

  private async getDrafts(options: RFPIndexOptions) {
    const { limit = this.defaults.limit } = options
    const params = this.buildQueryParams(options)
    const { data, hasMore } = await this.fetchWithPagination(
      {
        endpoint: this.endpoints['drafts'],
        params,
        limit: limit ?? this.defaults.limit,
        schema: RFPIndexSchema,
      },
      true
    )
    return { data, hasMore }
  }

  getDraftsQueryOptions(options: RFPIndexOptions) {
    const queryKey = this.buildQueryKey(options)
    return {
      queryKey: [...queryKey, 'drafts'],
      queryFn: () => this.getDrafts(options),
    }
  }

  getQueryOptions(options: RFPIndexOptions) {
    const queryKey = this.buildQueryKey(options)

    return {
      queryKey,
      queryFn: () => this.getRfpData(options),
    }
  }

  getMutation() {
    return {
      mutationFn: async <T extends RFPMutationPayload>(payload: T) => {
        switch (payload.type) {
          case 'ARCHIVE_ALL': {
            const { rfpIds } = payload
            const rfpIdsArray = Array.isArray(rfpIds) ? rfpIds : [rfpIds]

            await Promise.allSettled(
              rfpIdsArray.map((id) =>
                api.patch(`/v1/core/rfp/${id}/`, {
                  state: 'archived',
                })
              )
            )
            break
          }
          case 'SET_STATE': {
            const { rfpId, state } = payload
            await api.patch(`/v1/core/rfp/${rfpId}/`, { state })
            break
          }

          case 'TOGGLE_IMPORTANT': {
            const { rfpId, currentValue } = payload
            const newValue = !currentValue

            await api.patch(`/v1/core/rfp/${rfpId}/`, {
              flagged_as_important: newValue,
            })

            break
          }

          case 'READ_ALL': {
            // TODO: THIS ISNT REAL NEED TO READ THE COMMENTS
            const { rfpIds } = payload
            await Promise.allSettled(rfpIds.map((id) => api.get(`/v1/core/rfp/${id}/`)))
            break
          }

          default: {
            const _exhaustiveCheck: never = payload
            throw new Error(`Unhandled payload type: ${_exhaustiveCheck}`)
          }
        }
      },
      onSuccess: () => {
        this.queryClient.invalidateQueries({ queryKey: ['rfps'] })
      },
    }
  }

  getSearchQueryParams(options: RFPIndexOptions) {
    const { type = this.defaults.type, search } = options

    if (!search) {
      return { requiredFields: {}, optionalFields: {} }
    }

    const requiredFields: Record<string, any> = {}
    const optionalFields: Record<string, any> = {}

    if (type === 'received') {
      requiredFields['directed_organization_id'] = this.organizationId
    }

    optionalFields['jobs'] = {
      name: search,
    }

    optionalFields['plants'] = {
      common_name: search,
      scientific_name: search,
    }

    return { requiredFields, optionalFields }
  }

  private buildQueryKey(options: RFPIndexOptions) {
    const {
      type = this.defaults.type,
      groupBy = this.defaults.groupBy,
      status = this.defaults.status,
      page = 0,
    } = options

    return ['rfps', type, { groupBy, status, page }]
  }

  private buildQueryParams(options: RFPIndexOptions) {
    const { type, groupBy = this.defaults.groupBy, page = 0, status = this.defaults.status } = options

    // Set base params common to all requests
    const params = this.setBaseParams(page)

    // Handle drafts separately
    if (type === 'drafts') {
      return this.buildDraftParams(params)
    }

    // Handle different groupBy scenarios
    if (groupBy === 'status') {
      return this.buildStatusGroupParams(params, type, status)
    } else {
      return this.buildViewGroupParams(params, type, status)
    }
  }

  private setBaseParams(page: number): URLSearchParams {
    return new URLSearchParams({
      organization_id: this.organizationId,
      page: page.toString(),
    })
  }

  private buildDraftParams(params: URLSearchParams): URLSearchParams {
    // Filter out sent RFP
    params.set('sent', 'false')
    params.set('state', 'open')
    params.set('limit', '100') // Get all drafts
    return params
  }

  private buildStatusGroupParams(
    params: URLSearchParams,
    type: RFPIndexOptions['type'],
    status: z.infer<typeof RFPStateEnum>
  ): URLSearchParams {
    // Set status flag
    for (const state of RFPStateEnum.options) {
      if (state === status) {
        params.set('state', state)
      }
    }

    if (type === 'received') {
      params.delete('organization_id')
      params.set('directed_organization_id', this.organizationId)
    }

    return params
  }

  private buildViewGroupParams(
    params: URLSearchParams,
    type: RFPIndexOptions['type'],
    status: z.infer<typeof RFPStateEnum>
  ): URLSearchParams {
    // exclude drafts
    params.set('draft', 'false')

    // Set type-specific params
    switch (type) {
      case 'sent':
        params.set('draft', 'false')
        params.set('customer', 'false')
        params.set('vendor', 'true')
        break
      case 'received':
        params.set('customer', 'true')
        params.set('vendor', 'false')
        break
    }
    // Set status flag
    for (const state of RFPStateEnum.options) {
      if (state === status) {
        params.set(state, 'true')

        if (state !== 'open') {
          params.set('open', 'false')
        }
      }
    }

    return params
  }

  private async fetchWithPagination<T extends z.ZodType>(
    {
      endpoint,
      params,
      limit,
      schema,
    }: {
      endpoint: string
      params: URLSearchParams
      limit: number
      schema: T
    },
    debug = false
  ): Promise<{
    data: z.infer<T>
    hasMore: boolean
  }> {
    // Create params for checking if more data exists
    const paginationParams = new URLSearchParams(params)
    paginationParams.set('limit', '1')
    paginationParams.set('page', limit.toString())

    const [{ data }, { data: hasMoreData }] = await Promise.all([
      api.get(`${endpoint}?${params.toString()}`),
      api.get(`${endpoint}?${paginationParams.toString()}`),
    ])

    if (debug) {
      console.log(`${endpoint}?${params.toString()}`, data)
    }

    const parsedData = schema.parse(data) as z.infer<T>
    const hasMore = Boolean(hasMoreData?.length > 0)

    return {
      data: parsedData.slice(0, limit),
      hasMore,
    }
  }
}

export const useRFPMutations = (manager: RFPQueryManager) => {
  const mutation = useMutation({
    ...manager.getMutation(),
  })

  return {
    mutation,
  }
}
