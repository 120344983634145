import { Box, debounce, Modal, Stack, Typography } from '@mui/material'
import { MeasurableRangeDimension, PlantListEntry } from '@/types.ts'
import theme from '@/theme.ts'
import { EllipsisVertical } from 'lucide-react'
import IconButton from '@mui/material/IconButton'
import { MouseEvent, useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { CustomDivider } from '@/components/ui/base/dividers.tsx'
import { specificationOptions } from '@/constants'
import { areArraysEqual } from '@/lib/utils.ts'
import { PlantRelatedObjectsTableData } from '@/components/rfps/plant-related-objects-table.tsx'
import { EditPlantDetailsContent } from '@/components/plant-lists/editPlantDetailsContent.tsx'
import { ReviewPlantDetailsContent } from '@/components/plant-lists/reviewPlantDetailsContent.tsx'

export type GlobalEditPlantListEntryChange = {
  label: string
  changedFrom: string | null
  changedTo: string | null
}
type GlobalEditPlantListEntryModalProps = {
  open: boolean
  onClose: () => void
  entry: PlantListEntry
  onUpdate: (entry: PlantListEntry) => void
}
export const GlobalEditPlantListEntryModal = ({
  open,
  onClose,
  entry,
  onUpdate,
}: GlobalEditPlantListEntryModalProps) => {
  const [activeSpecs, setActiveSpecs] = useState<string[]>([])
  const [updatedEntry, setUpdatedEntry] = useState<PlantListEntry>(entry)
  const [allowReview, setAllowReview] = useState(false)
  const [specChange, setSpecChange] = useState<GlobalEditPlantListEntryChange[]>([])
  const [taxonomyChange, setTaxonomyChange] = useState(false)
  const [editMode, setEditMode] = useState(true)

  const handleClose = () => {
    onClose()
    setEditMode(true)
    setSpecChange([])
    setTaxonomyChange(false)
    setAllowReview(false)
    setUpdatedEntry(entry)
  }

  function formatRangeDimensionChange(entryValue: MeasurableRangeDimension, hasMin: boolean, hasMax: boolean) {
    return entryValue?.min || entryValue?.max
      ? hasMin && hasMax
        ? `${entryValue?.min || 0} - ${entryValue?.max || 0} ${entryValue?.unit || ''}`
        : hasMin && entryValue?.min
          ? `${entryValue?.min || 0} ${entryValue?.unit || ''}`
          : hasMax && entryValue?.max
            ? `${entryValue?.max || 0} ${entryValue?.unit || ''}`
            : null
      : null
  }

  const validateChangesForReview = (updatedEntry: PlantListEntry) => {
    const changeList: GlobalEditPlantListEntryChange[] = []
    specificationOptions.forEach((option) => {
      const entryValue = entry[option.fieldName as keyof PlantListEntry]
      const updatedEntryValue = updatedEntry[option.fieldName as keyof PlantListEntry]
      if (option.fieldType === 'range') {
        const castedEntryValue = entryValue as MeasurableRangeDimension
        const castedUpdatedEntryValue = updatedEntryValue as MeasurableRangeDimension
        const unitValueChanged = Boolean(
          castedEntryValue?.unit !== castedUpdatedEntryValue?.unit &&
            (castedEntryValue?.unit || castedUpdatedEntryValue?.unit)
        )

        const hasMin = Boolean(castedEntryValue?.min || castedUpdatedEntryValue?.min)
        const hasMax = Boolean(castedEntryValue?.max || castedUpdatedEntryValue?.max)
        const minValueChanged = Boolean(castedEntryValue?.min !== castedUpdatedEntryValue?.min && hasMin)
        const maxValueChanged = Boolean(castedEntryValue?.max !== castedUpdatedEntryValue?.max && hasMax)

        if (
          minValueChanged ||
          maxValueChanged ||
          ((castedUpdatedEntryValue?.min || castedUpdatedEntryValue?.max) && unitValueChanged)
        ) {
          const changedFrom = formatRangeDimensionChange(castedEntryValue, hasMin, hasMax)
          const changedTo = formatRangeDimensionChange(castedUpdatedEntryValue, hasMin, hasMax)

          changeList.push({
            label: `${option.name} ${hasMin && hasMax ? '(min-max)' : hasMin ? '(min)' : '(max)'}`,
            changedFrom: changedFrom,
            changedTo: changedTo,
          })
        }
      } else if (option.fieldType === 'multiple') {
        const castedEntryValue = entryValue as string[]
        const castedUpdatedEntryValue = updatedEntryValue as string[]
        if (!areArraysEqual(castedEntryValue, castedUpdatedEntryValue)) {
          changeList.push({
            label: option.name,
            changedFrom: castedEntryValue?.length ? castedEntryValue.join(', ') : null,
            changedTo: castedUpdatedEntryValue?.length ? castedUpdatedEntryValue.join(', ') : null,
          })
        }
      } else if (option.fieldType === 'single') {
        if (entryValue !== updatedEntryValue) {
          changeList.push({
            label: option.name,
            changedFrom: (entryValue as string) || null,
            changedTo: (updatedEntryValue as string) || null,
          })
        }
      }
    })
    setSpecChange(changeList)

    {
      /* Todo: Implement validation for plant taxonomic change */
    }
    const isTaxonomyChanged = false
    setTaxonomyChange(isTaxonomyChanged)

    return changeList.length > 0 || taxonomyChange
  }

  const debouncedEntryUpdates = debounce((entry: Partial<PlantListEntry>) => {
    const updates = { ...updatedEntry, ...entry }
    setUpdatedEntry(updates)
    setAllowReview(validateChangesForReview(updates))
  }, 300)

  if (!entry) return null

  const EditPlantSettingsMenu = () => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)

    const handleMenuClose = () => {
      setMenuAnchorEl(null)
    }

    const handleJobMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
      setMenuAnchorEl(event.currentTarget)
    }

    const handleDelete = () => {
      // Waiting on product team to comment what the correct options are for this menu, before doing this implementation
      console.log('Not implemented')
    }

    return (
      <Stack>
        <IconButton
          component="button"
          sx={{
            px: 0,
            py: 1,
            ml: 1,
            borderRadius: theme.borderRadius.sm,
            border: '1px solid #ccc',
            backgroundColor: theme.palette.lightGrey3.main,
          }}
          onClick={handleJobMenuClick}
        >
          <EllipsisVertical />
        </IconButton>
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiPaper-root': {
              minWidth: 200,
              pt: 1,
            },
          }}
        >
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
      </Stack>
    )
  }

  {
    /*
    we do not have an endpoint to fetch related objects (jobs,rfps,quotes,orders) associated with a plant.
    for now, we are using sample data in the structure of what the formatted response should look like
    for rendering.
  */
  }
  const relatedObjectData: PlantRelatedObjectsTableData = {
    job: {
      id: 'sample_job_id',
      name: 'Sample Job',
      link: '/jobs/sample_job_id',
      descriptions: ['Total QTY Needed: 100'],
    },
    rfp: [
      {
        id: 'sample_rfp_id_1',
        name: 'Sample RFP',
        link: '/rfps/sample_rfp_id',
        descriptions: ['Alternative Suggested: Willow Oak 30 gal'],
      },
      {
        id: 'sample_rfp_id_2',
        name: 'Sample RFP',
        link: '/rfps/sample_rfp_id',
        descriptions: ['QTY Requested: 30'],
      },
    ],
    quote: [
      {
        id: 'sample_quote_id_1',
        name: 'Sample Quote',
        link: '/quotes/sample_quote_id',
        descriptions: ['Priced at $45/item'],
      },
    ],
    order: [],
  }

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition aria-labelledby="edit-rfp-details-modal">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          width: { xs: '90%', lg: 800 },
          maxHeight: 900,
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
          borderRadius: theme.borderRadius.lg,
        }}
      >
        {/*modal header*/}
        <Stack direction="row" sx={{ justifyContent: 'space-between', p: 4 }}>
          <Typography variant="h1" fontWeight={400}>
            Edit Plant Details
          </Typography>
          <Stack direction="row" sx={{ alignItems: 'center', gap: 1 }}>
            <Typography variant="body2" sx={{ color: theme.palette.mediumGrey2.main }}>
              Item #{entry.id}
            </Typography>
            <EditPlantSettingsMenu />
          </Stack>
        </Stack>
        <Stack sx={{ px: 4 }}>
          <CustomDivider />
        </Stack>
        {editMode ? (
          <EditPlantDetailsContent
            entry={entry}
            activeSpecs={activeSpecs}
            setActiveSpecs={setActiveSpecs}
            updatedEntry={updatedEntry}
            setEditMode={setEditMode}
            setAllowReview={setAllowReview}
            debouncedEntryUpdates={debouncedEntryUpdates}
            validateChangesForReview={validateChangesForReview}
            relatedObjectData={relatedObjectData}
            handleClose={handleClose}
            allowReview={allowReview}
          />
        ) : (
          <ReviewPlantDetailsContent
            entry={entry}
            updatedEntry={updatedEntry}
            specChange={specChange}
            taxonomyChange={taxonomyChange}
            relatedObjectData={relatedObjectData}
            setEditMode={setEditMode}
            onUpdate={onUpdate}
            allowReview={allowReview}
            handleClose={handleClose}
          />
        )}
      </Box>
    </Modal>
  )
}
