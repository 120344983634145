import { useToastNotifications } from '@/contexts/hooks/useToastNotifications.ts'

/**
 * Hook that provides a function to show a "Feature not implemented" toast notification
 * @param customMessage Optional custom message to display instead of the default
 * @returns Function that when called displays the notification
 */

export const useFeatureNotImplemented = () => {
  const { addToastNotification } = useToastNotifications()

  const notifyFeatureNotImplemented = (customMessage?: string) => {
    addToastNotification({
      title: 'Feature not implemented',
      message: customMessage || '',
      severity: 'info',
    })
  }

  return notifyFeatureNotImplemented
}
