import { ContactCategories, ContactTypes } from '@/constants.ts'
import type {
  Contact,
  ContactAddress,
  ContactCountCollection,
  ContactDetails,
  ContactDetailsResponse,
  ContactType,
  ElasticsearchResponse,
  Organization,
  OrganizationContact,
  UserContact,
} from '@/types.ts'

import api from './api.ts'

export async function getContacts(contactType: ContactTypes, organization_id?: string) {
  if (!organization_id) {
    throw new Error('Cannot get contacts without an owner organization')
  }
  const url = contactType === ContactTypes.USER ? '/v1/core/contact/user' : '/v1/core/contact/organization'
  const response = await api.get(`${url}/?organization_id=${organization_id}`)
  // fix return type to <(UserContact | OrganizationContact)[]> and contacts page components
  return response.data as Contact[]
}

export async function getOrganizationMemberContacts(organization_id: string, organization_contact_id: string) {
  if (!organization_id) {
    throw new Error('Cannot get contacts without an owner organization')
  } else if (!organization_contact_id) {
    throw new Error('Cannot get contacts without an organization contact id')
  }
  const url = `/v1/core/contact/user/?organization_id=${organization_id}&organization_contact_id=${organization_contact_id}`
  const response = await api.get(url)
  return response.data as UserContact[]
}

export async function searchContacts(
  contactType: ContactTypes,
  keyword: string,
  organization: Organization | null | undefined,
  category: ContactCategories
) {
  if (!organization) {
    throw new Error('Cannot search contacts without an owner organization')
  }

  // if keyword is an email address, search by email
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  const response = await api.post(`/v1/core/contact/${contactType}/search/`, {
    page: 1,
    per_page: 20,
    organization_id: organization.id,
    required_fields: {
      favorite: category === 'favorite' || undefined,
    },
    optional_fields: {
      first_name: keyword,
      last_name: keyword,
      organization_name: keyword,
      phone: {
        number: keyword,
      },
      email: {
        address: emailRegex.test(keyword) ? keyword : undefined,
      },
      address: {
        address: {
          street: keyword,
          city: keyword,
          state: keyword,
          zip: keyword,
        },
      },
    },
  })
  return response.data as ElasticsearchResponse<Contact>
}

export async function searchOrganizationContactsByName(
  organizationName: string,
  organization: Organization | null | undefined
) {
  if (!organization) {
    throw new Error('Cannot search contacts without an owner organization')
  }
  const response = await api.post(`/v1/core/contact/organization/search/`, {
    page: 1,
    per_page: 20,
    organization_id: organization.id,
    required_fields: {
      organization_name: organizationName,
    },
  })
  return response.data as ElasticsearchResponse<OrganizationContact>
}

export async function getContactDetails(id: string) {
  const response = await api.get(`/v1/core/contact/details/${id}`)
  return response.data as ContactDetailsResponse
}

export async function getContactCounts(organization_id?: string) {
  if (!organization_id) {
    throw new Error('Cannot get contact counts without an owner organization')
  }
  const response = await api.get(`/v1/core/contact/counts/?organization_id=${organization_id}`)
  return response.data as ContactCountCollection[]
}

const formatContactDetailsAddress = (contactDetails: ContactDetails) => {
  if (Array.isArray(contactDetails.address) && contactDetails.address.length > 0) {
    return contactDetails.address.reduce((acc: object[], contactDetailAddress: ContactAddress) => {
      const addr = contactDetailAddress.address
      if (addr.street || addr.city || addr.state || addr.zip) {
        const street = [addr.street, addr.suite].filter(Boolean).join(', ')
        acc.push({
          ...contactDetailAddress,
          address: {
            id: addr.id,
            street: street,
            city: addr.city,
            state: addr.state,
            zip: addr.zip,
            country: 'United States',
          },
        })
      }
      return acc
    }, [])
  }
  return []
}

export async function createContact({
  contactDetails,
  organization,
}: {
  contactDetails: ContactDetails
  organization: Organization | null | undefined
}) {
  if (!organization) {
    throw new Error('Cannot create contact without an owner organization')
  }
  const body: any = contactDetails

  body.organization = organization
  body.address = formatContactDetailsAddress(contactDetails)

  // filter out empty organization names from organization_contact
  if (contactDetails.organization_contact) {
    body.organization_contact = contactDetails.organization_contact.filter((orgContact) =>
      orgContact.organization_name.trim()
    )
  }

  const url = contactDetails.type === 'user' ? '/v1/core/contact/user/' : '/v1/core/contact/organization/'

  const response = await api.post(url, body, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data
}

export async function updateContact(contact: Contact) {
  const url =
    contact.type === ContactTypes.USER
      ? `/v1/core/contact/user/${contact.id}`
      : `/v1/core/contact/organization/${contact.id}`
  const response = await api.put(url, contact, {
    headers: { 'Content-Type': 'application/json' },
  })
  return response.data as ContactDetailsResponse
}

export async function updateContactById({
  id,
  contactDetails,
  organization,
}: {
  id: string
  contactDetails: ContactDetails
  organization: Organization | null | undefined
}) {
  if (!organization) {
    throw new Error('Cannot update contact without an owner organization')
  }
  const body: any = contactDetails
  body.organization = organization
  body.address = formatContactDetailsAddress(contactDetails)

  const url = contactDetails.type === 'user' ? `/v1/core/contact/user/${id}` : `/v1/core/contact/organization/${id}`

  const response = await api.put(url, body, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data as ContactDetailsResponse
}

export async function favoriteContact(contact: Contact, organization_contacts: OrganizationContact[] | undefined) {
  const body: Contact = {
    ...contact,
    favorite: true,
    organization_contact: [...(organization_contacts || [])],
  }
  await updateContact(body)
}

export async function unfavoriteContact(contact: Contact, organization_contacts: OrganizationContact[] | undefined) {
  const body: Contact = {
    ...contact,
    favorite: false,
    organization_contact: [...(organization_contacts || [])],
  }
  await updateContact(body)
}

export async function deleteContact(id: string, type: ContactType) {
  const url = type === ContactTypes.USER ? `/v1/core/contact/user/${id}` : `/v1/core/contact/organization/${id}`
  const response = await api.delete(url)
  return response.status
}
