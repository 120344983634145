import { useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

import { X } from 'lucide-react'

import ModalHeader from '@/components/ui/base/modal-header.tsx'
import { V2PlantAutocompleteSearch } from '@/components/ui/base/v2-plant-autocomplete-search.tsx'
import theme from '@/theme.ts'
import { Job, PlantWithQuantity } from '@/types.ts'
import { pluralize } from '@/lib/pluralize.ts'
import VisibilityCallout from '@/components/rfps/visibility-callout'

export default function AddPlantsModal({
  open,
  onClose,
  onAddPlants,
  job,
}: {
  open: boolean
  onClose: () => void
  onAddPlants: (plants: PlantWithQuantity[]) => Promise<void>
  job?: Job
}) {
  const [selectedPlants, setSelectedPlants] = useState<PlantWithQuantity[]>([])

  const handleClose = () => {
    setSelectedPlants([])
    onClose()
  }

  const removeSelectedPlant = (plant: PlantWithQuantity) => {
    setSelectedPlants((prev) => prev.filter((p) => p.plant._id !== plant.plant._id))
  }

  const updatePlantQuantity = (plant: PlantWithQuantity, newQuantity: number) => {
    setSelectedPlants((prev) =>
      prev.map((p) => (p.plant._id === plant.plant._id ? { ...p, quantity: newQuantity } : p))
    )
  }

  const handleAddPlants = async () => {
    await onAddPlants(selectedPlants)
    setSelectedPlants([])
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      aria-labelledby="add-plants-modal-title"
      aria-describedby="add-plants-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 850,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 5,
          borderRadius: theme.borderRadius.md,
        }}
      >
        <ModalHeader title="Add Plants" variant="modalHeader3" sx={{ mb: 5 }} id="add-plants-modal-title" />
        {job && (
          <>
            <Stack direction="row" spacing={1}>
              <Typography variant="strong">Job #{job.id}</Typography>
              <Typography variant="body1"> &mdash; </Typography>
              <VisibilityCallout visible={false} itemName={job.name || 'Unnamed Job'} />
            </Stack>
            <Divider color={theme.palette.mediumGray.light} sx={{ borderWidth: 2, mt: 2 }} />
          </>
        )}

        {selectedPlants.length > 0 && (
          <>
            {!job && <Divider color={theme.palette.mediumGray.light} sx={{ borderWidth: 2, mt: 2 }} />}
            <Box sx={{ display: 'flex', px: 2, py: 2, bgcolor: theme.palette.tableHeaderBackground.main }}>
              <Typography variant="body1" sx={{ flex: 1 }}>
                Plant Name
              </Typography>
              <Typography variant="body1" sx={{ flex: 1 }}>
                Specs
              </Typography>
              <Typography variant="body1" sx={{ width: 200, textAlign: 'center' }}>
                QTY
              </Typography>
              <Box sx={{ width: 40 }} /> {/* Space for delete button */}
            </Box>
            <Divider />
            <Stack spacing={2} sx={{ mb: 4 }}>
              {selectedPlants.map((plant) => (
                <Box key={plant.plant._id}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      px: 2,
                      py: 1,
                      bgcolor: theme.palette.background.default,
                      borderRadius: 1,
                    }}
                  >
                    <Typography variant="body1" sx={{ flex: 1, textTransform: 'capitalize' }}>
                      {plant.plant.common_names[0] || plant.plant.scientific_name}
                    </Typography>
                    <Box sx={{ flex: 1, display: 'flex', gap: 1 }}></Box>
                    <Box sx={{ width: 200, display: 'flex', justifyContent: 'center' }}>
                      <TextField
                        type="number"
                        value={plant.quantity}
                        onChange={(e) => {
                          const value = parseInt(e.target.value) || 1
                          updatePlantQuantity(plant, Math.max(1, value))
                        }}
                        inputProps={{ min: 1 }}
                        sx={{ width: 100 }}
                      />
                    </Box>
                    <IconButton onClick={() => removeSelectedPlant(plant)} sx={{ width: 40 }}>
                      <X size={20} />
                    </IconButton>
                  </Box>
                  <Divider />
                </Box>
              ))}
            </Stack>
          </>
        )}

        <Box sx={{ my: 4 }}>
          <V2PlantAutocompleteSearch
            onChange={(value) => {
              if (value) {
                setSelectedPlants((prev) => [...prev, { plant: value, quantity: 1 }])
              }
            }}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
          }}
        >
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancel &amp; Close
          </Button>
          <Button variant="contained" onClick={handleAddPlants} disabled={!selectedPlants.length}>
            {selectedPlants.length
              ? `Add ${selectedPlants.length} ${pluralize(selectedPlants.length, 'Plant', 'Plants')}`
              : 'Add Plants'}
            {job ? ' to Job & RFP' : ''}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
