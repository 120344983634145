import { excludeDeletedItems } from '@/lib/utils.ts'
import { Stack } from '@mui/material'
import theme from '@/theme.ts'
import { AddCircle } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import { pluralizedLabel } from '@/lib/pluralize.ts'
import { useRfpContext } from '@/components/rfps/context/rfp-context.tsx'
import { Job, PlantListEntry } from '@/types.ts'

export default function AddMissingPlantsFromJobButton({
  plantsByJobGroup,
  jobGroups,
  jobId,
}: {
  plantsByJobGroup: Record<string, PlantListEntry[]>
  jobGroups: Record<string, Job>
  jobId: string
}) {
  const { setActiveJobId, setAddPlantsFromJobModalProps } = useRfpContext()
  const jobData = jobGroups[jobId]
  const jobPlantTotalCount = excludeDeletedItems(jobData?.plants).length
  const jobPlantInRfpCount = excludeDeletedItems(plantsByJobGroup[jobId]).length
  const missingPlantsCount = jobPlantTotalCount - jobPlantInRfpCount

  if (missingPlantsCount < 1) return null

  const handleMissingPlantClick = () => {
    setActiveJobId(jobId)
    setAddPlantsFromJobModalProps({
      jobIds: [jobId],
      showJobSelection: false,
      open: true,
    })
  }

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        py: 2,
        pl: 7.5,
        borderBottom: '1px solid',
        borderColor: theme.palette.lightGrey2.main,
      }}
    >
      <Stack direction="row" sx={{ cursor: 'pointer' }} onClick={handleMissingPlantClick}>
        <AddCircle sx={{ mr: 2, color: theme.palette.primary.main }} />
        <Typography color="textSecondary">
          {pluralizedLabel(missingPlantsCount, 'Plant', 'Plants')} not included in this RFP
        </Typography>
      </Stack>
    </Stack>
  )
}
