import { Route } from '@/routes/$orgId/rfps.index' // Assumes usage on /rfps/index page
import { useState, type MouseEvent, useCallback } from 'react'
import Typography from '@mui/material/Typography'
import { formatStringToShortMonthDayYear as formatDate, timeElapsedSince } from '@/lib/utils'
import { Stack, Box, Collapse } from '@mui/material'
import { RFPIndexDrafts } from '@/api/types/rfps'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { trimUUID } from '@/lib/utils'
import { Menu } from '@/components/ui/base/menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ArchiveIcon from '@mui/icons-material/Archive'
import { ExpandMore, ChevronRight } from '@mui/icons-material'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications'
import { useRFPMutations } from '@/api/rfp-index'
import { useDraftMenu } from '../hooks/useDraftMenu'
import { useConfirmationDialog } from '../hooks/useConfirmDialog'

export function RFPDraftsExpandableList({ drafts }: { drafts: RFPIndexDrafts }) {
  const [open, setOpen] = useState(false)
  const { orgId } = Route.useParams()
  const navigate = Route.useNavigate()
  const { rfpQueryManager } = Route.useLoaderData()
  const { mutation } = useRFPMutations(rfpQueryManager)
  const { showConfirmation, ConfirmationDialog } = useConfirmationDialog()
  // Top-level menu state,
  const [globalMenuAnchorEl, setGlobalMenuAnchorEl] = useState<null | HTMLElement>(null)
  const toast = useToastNotifications()
  // Row-level menu state
  const { DraftMenu, menuState, openMenu, closeMenu } = useDraftMenu()

  // Global menu handlers
  const handleGlobalMenuOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setGlobalMenuAnchorEl(event.currentTarget)
  }, [])

  const handleGlobalMenuClose = useCallback(() => {
    setGlobalMenuAnchorEl(null)
  }, [])

  const handleArchiveAllClick = async () => {
    const confirmed = await showConfirmation({
      title: 'Archive All Drafts',
      message: 'Are you sure you want to archive all drafts?',
    })

    if (confirmed) {
      handleGlobalMenuClose()
      await mutation.mutateAsync({ type: 'ARCHIVE_ALL', rfpIds: drafts.data.map((draft) => draft.id) })

      toast.addToastNotification({
        message: 'All drafts archived',
        severity: 'success',
      })
    }
  }

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ bgcolor: '#FFFFEE', p: 2, borderRadius: 1, cursor: 'pointer' }}
        onClick={() => setOpen(!open)}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          {open ? <ExpandMore sx={{ fontSize: 20 }} /> : <ChevronRight sx={{ fontSize: 20 }} />}
          <Typography variant="indexAccordionHeader">Drafts ({drafts.data.length})</Typography>
        </Stack>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="subtitle2">{drafts.data.length} Draft(s) Saved</Typography>
          <IconButton size="small" onClick={handleGlobalMenuOpen} sx={{ mr: 1 }}>
            <MoreVertIcon sx={{ color: 'black !important' }} />
          </IconButton>
        </Box>
      </Stack>

      <Menu anchorEl={globalMenuAnchorEl} open={Boolean(globalMenuAnchorEl)} onClose={handleGlobalMenuClose}>
        <MenuItem onClick={handleArchiveAllClick}>
          <ListItemIcon>
            <ArchiveIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Archive All</ListItemText>
        </MenuItem>
      </Menu>

      <Collapse in={open} timeout="auto">
        <Table size="small" sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
              <TableCell>ID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Job</TableCell>
              <TableCell>Vendor</TableCell>
              <TableCell>Edited</TableCell>
              <TableCell>Quote Due</TableCell>
              <TableCell sx={{ width: '10%', textAlign: 'right' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {drafts.data.map((draft, index) => (
              <TableRow
                key={index}
                hover
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: 'pointer',
                  borderBottom: '1px solid #e0e0e0',
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  navigate({
                    to: '/$orgId/rfps/draft/$rfpId',
                    params: { orgId, rfpId: draft.id },
                  })
                }}
              >
                <TableCell sx={{ pl: 0, py: '15px' }}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Box
                      sx={{
                        width: 8,
                        height: 8,
                        borderRadius: '50%',
                        backgroundColor: 'transparent',
                      }}
                    />
                    <Typography variant="indexTableItem">RFP - #{trimUUID(draft.id)}</Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Box
                      sx={{
                        width: 8,
                        height: 8,
                        borderRadius: '50%',
                        backgroundColor: 'transparent',
                        border: '1px solid #e0e0e0',
                      }}
                    />
                    <span>Draft</span>
                  </Stack>
                </TableCell>
                <TableCell>{draft.jobs.length ? draft.jobs.map((job) => job.name).join(',') : 'No Job'}</TableCell>
                <TableCell>No Vendor</TableCell>
                <TableCell>
                  {draft.updated_at
                    ? timeElapsedSince(draft.updated_at)
                    : draft.created_at
                      ? timeElapsedSince(draft.created_at)
                      : ''}
                </TableCell>
                <TableCell>{draft.quote_needed_by ? formatDate(draft.quote_needed_by) : 'No due date'}</TableCell>
                <TableCell sx={{ width: '10%' }}>
                  <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                    <IconButton aria-label="more options" size="small" onClick={(e) => openMenu(e, draft)}>
                      <MoreVertIcon sx={{ ml: 'auto' }} />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
            {menuState.draft && menuState.anchorEl && (
              <DraftMenu
                menuState={{
                  draft: menuState.draft,
                  anchorEl: menuState.anchorEl,
                }}
                closeMenu={closeMenu}
                rfpQueryManager={rfpQueryManager}
              />
            )}
          </TableBody>
        </Table>
      </Collapse>
      <ConfirmationDialog />
    </Box>
  )
}
