import { useCallback, type MouseEvent, type FC, ReactNode } from 'react'
import { Menu } from '@/components/ui/base/menu'
import { MenuItem, ListItemText, SxProps, ListItemIcon } from '@mui/material'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications'
import { useRFPMutations } from '@/api/rfp-index'
import { BaseRFPMenuProps } from './rfp-menu'
import { useConfirmationDialog } from '../hooks/useConfirmDialog'
import { GroupMenuData } from '../hooks/useGroupMenu'
import { useSearch } from '@tanstack/react-router'
/**
 * Mark all Closed
Mark all Delayed
Mark all Read
Archive all RFPs
 */

// Define the menu action types for drafts
export type GroupedMenuActionType = 'CLOSE_ALL' | 'READ_ALL' | 'ARCHIVE_ALL' | 'DELAY_ALL' | 'OPEN_ALL'

// Define the props for the Draft menu component
interface GroupedRFPMenuProps extends BaseRFPMenuProps {
  menuState: {
    rfps: GroupMenuData // Non-null RFP
    anchorEl: HTMLElement
  }
}

type MenuAction = {
  type: GroupedMenuActionType
  icon?: ReactNode
  label: string
  sx?: SxProps
  disabled?: boolean
}

/**
 * Implement in conjunction with useDraftMenu
 * This component will be exported from the useDraftMenu hook
 *  Usage: `const { DraftMenu, menuState, openMenu, closeMenu } = useDraftMenu()`
 */
export const GroupedRFPMenu: FC<GroupedRFPMenuProps> = ({ menuState, closeMenu, rfpQueryManager }) => {
  const toast = useToastNotifications()
  const { mutation: mutateGroup } = useRFPMutations(rfpQueryManager)
  const { showConfirmation, ConfirmationDialog } = useConfirmationDialog()
  const { status } = useSearch({
    strict: false,
    select: (params) => ({ type: params.type || 'sent', status: params.status || 'open' }),
  })

  // const sentUnread = rfps.reduce((acc, rfp) => acc + (rfp.unread_comments_by_vendor || 0), 0)
  // const receivedUnread = rfps.reduce((acc, rfp) => acc + (rfp.unread_comments_by_customer || 0), 0)
  // const unread = type === 'sent' ? sentUnread > 0 : receivedUnread > 0

  const actions: MenuAction[] = [
    {
      type: 'CLOSE_ALL',
      label: 'Mark All Closed',
      disabled: status === 'closed',
    },
    {
      type: 'DELAY_ALL',
      label: 'Mark All Delayed',
      disabled: status === 'paused',
    },
    {
      type: 'READ_ALL',
      label: 'Mark All Read',
      disabled: true, // !unread,
    },
    {
      type: 'ARCHIVE_ALL',
      label: 'Archive All RFPs',
      disabled: status === 'archived',
    },
    {
      type: 'OPEN_ALL',
      label: 'Open All RFPs',
      disabled: status === 'open',
    },
  ]

  const handleMenuAction = useCallback(
    async (type: GroupedMenuActionType, event?: MouseEvent) => {
      event?.preventDefault()
      event?.stopPropagation()

      const { rfps } = menuState

      try {
        switch (type) {
          case 'CLOSE_ALL':
            {
              const confirmed = await showConfirmation({
                title: `Close ${rfps.length} RFPs?`,
                message: 'Are you sure you want to mark all RFPs as closed?',
              })

              if (confirmed) {
                await Promise.all(
                  rfps.map((rfp) => mutateGroup.mutateAsync({ type: 'SET_STATE', rfpId: rfp.rfp_id, state: 'closed' }))
                )

                toast.addToastNotification({
                  message: 'All RFPs marked as closed',
                  severity: 'success',
                })
              }
            }
            break

          case 'DELAY_ALL':
            {
              const confirmed = await showConfirmation({
                title: 'Delay All',
                message: 'Are you sure you want mark all RFPs as delayed?',
              })

              if (confirmed) {
                await Promise.all(
                  rfps.map((rfp) => mutateGroup.mutateAsync({ type: 'SET_STATE', rfpId: rfp.rfp_id, state: 'paused' }))
                )

                toast.addToastNotification({
                  message: 'All RFPs delayed',
                  severity: 'success',
                })
              }
            }
            break

          case 'READ_ALL':
            {
              await mutateGroup.mutateAsync({ type: 'READ_ALL', rfpIds: rfps.map((rfp) => rfp.rfp_id) })

              toast.addToastNotification({
                message: 'All RFPs marked as read',
                severity: 'success',
              })
            }
            break

          case 'ARCHIVE_ALL':
            {
              toast.addToastNotification({
                id: 'archive-all-rfps',
                message: 'Archiving all RFPs...',
                severity: 'info',
              })

              await mutateGroup.mutateAsync({ type: 'ARCHIVE_ALL', rfpIds: rfps.map((rfp) => rfp.rfp_id) })

              toast.removeToastNotification('archive-all-rfps')

              toast.addToastNotification({
                message: 'All RFPs archived',
                severity: 'success',
              })
            }
            break

          case 'OPEN_ALL':
            {
              await Promise.all(
                rfps.map((rfp) => mutateGroup.mutateAsync({ type: 'SET_STATE', rfpId: rfp.rfp_id, state: 'open' }))
              )

              toast.addToastNotification({
                message: 'All RFPs opened',
                severity: 'success',
              })
            }
            break
        }
      } catch (error) {
        console.error(error)
        if (error instanceof Error) {
          toast.addToastNotification({
            message: error.message,
            severity: 'error',
          })
        } else {
          toast.addToastNotification({
            message: 'An unknown error occurred',
            severity: 'error',
          })
        }
      } finally {
        // Don't close the menu until the end, otherwise
        // it will close before the confirmation dialog is opened
        closeMenu()
      }
    },
    [menuState, closeMenu, mutateGroup, showConfirmation, toast]
  )

  return (
    <>
      <ConfirmationDialog />
      <Menu anchorEl={menuState.anchorEl} open={!!menuState.anchorEl} onClose={closeMenu}>
        {actions.map((item) => {
          if (item.disabled) return
          return (
            <MenuItem
              key={item.type}
              onClick={(e) => {
                e.stopPropagation()
                handleMenuAction(item.type, e)
              }}
              sx={item.sx}
            >
              {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
              <ListItemText>{item.label}</ListItemText>
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}
