import { MeasurableRangeDimension, PlantListEntry, RangeDimension, SpecificationOptionType } from '@/types.ts'
import { useEffect, useRef, useState } from 'react'
import { specificationOptions } from '@/constants.ts'
import { useQuery } from '@tanstack/react-query'
import { getOptions } from '@/api/plant-list.ts'
import { MenuItem, Stack, Typography } from '@mui/material'
import MeasurableRangeDimensionInput from '@/components/plant-list-importer/measurable-range-dimension-input.tsx'
import RangeDimensionInput from '@/components/plant-list-importer/range-dimension-input.tsx'
import IconButton from '@mui/material/IconButton'
import { AddCircle, Close } from '@mui/icons-material'
import theme from '@/theme.ts'
import { ClearTextIconButton } from '@/components/ui/base/buttons/text-icon-buttons.tsx'
import { CustomMenu } from '@/components/ui/base/dropdowns/custom-menu.tsx'
import Select from '@mui/material/Select'

export const GlobalEditPlantListEntry = ({
  entry,
  onUpdate,
  activeSpecs,
  handleActiveSpecs,
}: {
  entry: PlantListEntry
  onUpdate: (data: Partial<PlantListEntry>) => void
  activeSpecs: string[]
  handleActiveSpecs: (specs: string[]) => void
}) => {
  const [localActiveSpecs, setLocalActiveSpecs] = useState<string[]>(activeSpecs)
  const lastActiveInput = useRef<string | null>(null)
  const [specAnchorEl, setSpecAnchorEl] = useState<null | HTMLElement>(null)
  const [entryValues, setEntryValues] = useState(() => {
    return specificationOptions.reduce(
      (acc, option) => {
        acc[option.fieldName] = entry[option.fieldName as keyof PlantListEntry]
        return acc
      },
      {} as Record<string, any>
    )
  })

  function validatedSpecValue(option: SpecificationOptionType, fieldValue: any) {
    if (option.fieldType === 'range' && !(fieldValue?.min === null && fieldValue?.max === null)) {
      return fieldValue
    } else if (option.fieldType === 'multiple' && fieldValue?.length) {
      return fieldValue
    } else if (option.fieldType === 'single' && fieldValue !== null) {
      return fieldValue
    } else if (localActiveSpecs.includes(option.fieldName)) {
      return fieldValue
    }
    return null
  }

  const { data: optionsEnums } = useQuery({
    queryKey: ['optionEnums'],
    queryFn: getOptions,
  })

  useEffect(() => {
    let validSpecs: string[] = []
    setEntryValues(() => {
      return specificationOptions.reduce(
        (acc, option) => {
          const fieldValue = entryValues[option.fieldName]
          acc[option.fieldName] = fieldValue
          if (validatedSpecValue(option, fieldValue)) validSpecs.push(option.fieldName)
          return acc
        },
        {} as Record<string, any>
      )
    })
    setLocalActiveSpecs(validSpecs)
  }, [entry])

  function handleEntryChange(fieldName: string, value: any, update = true) {
    const updatedEntry = { ...entryValues, [fieldName]: value }
    setEntryValues(updatedEntry)
    if (update) onUpdate(updatedEntry)
  }

  function handleAddSpecification(option: SpecificationOptionType) {
    const defaultValue =
      option.fieldType === 'range'
        ? { min: null, max: null, unit: optionsEnums?.[option.optionsName]?.[0] || null }
        : option.fieldType === 'multiple'
          ? []
          : null
    handleEntryChange(option.fieldName, defaultValue, false)
    handleActiveSpecs([...localActiveSpecs, option.fieldName])
    setSpecAnchorEl(null)
  }

  function handleRemoveSpec(fieldName: string, fieldType: string) {
    const defaultValue =
      fieldType === 'range' ? { min: null, max: null, unit: null } : fieldType === 'multiple' ? [] : null
    handleEntryChange(fieldName, defaultValue)
    handleActiveSpecs(localActiveSpecs.filter((spec: string) => spec !== fieldName))
  }

  return (
    <Stack sx={{ pt: 4, overflowY: 'auto' }}>
      {specificationOptions.map((option) => {
        const entryValue = validatedSpecValue(option, entryValues[option.fieldName])

        // skip if no value
        if (!entryValue && !localActiveSpecs.includes(option.fieldName)) return null

        // get options
        const fieldOptions = optionsEnums?.[option.optionsName] || []

        // conditional rendering by field type
        return (
          <Stack key={option.fieldName} direction="row" sx={{ gap: 2, py: 1, alignItems: 'center' }}>
            <Typography variant="body2" sx={{ fontWeight: 700, minWidth: 150 }}>
              {option.name}
            </Typography>
            {option.fieldType === 'range' ? (
              <Stack direction="row" sx={{ gap: 1, minWidth: 350 }}>
                {fieldOptions.length ? (
                  <MeasurableRangeDimensionInput
                    dimension={entryValue as MeasurableRangeDimension}
                    label=""
                    unitOptions={fieldOptions}
                    onChange={(value) => handleEntryChange(option.fieldName, value)}
                    onInput={(e) => (lastActiveInput.current = e.target.name)}
                  />
                ) : (
                  <RangeDimensionInput
                    dimension={entryValue as RangeDimension}
                    label=""
                    onChange={(value) => handleEntryChange(option.fieldName, value)}
                  />
                )}
              </Stack>
            ) : ['multiple', 'single'].includes(option.fieldType) ? (
              <Stack direction="row" sx={{ minWidth: 350 }}>
                <Select
                  fullWidth
                  multiple={option.fieldType === 'multiple'}
                  value={entryValue ? entryValue : option.fieldType === 'multiple' ? [] : ''}
                  onChange={(e) => handleEntryChange(option.fieldName, e.target.value)}
                >
                  {fieldOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            ) : null}
            <IconButton
              component="button"
              size="small"
              onClick={() => handleRemoveSpec(option.fieldName, option.fieldType)}
            >
              <Close />
            </IconButton>
          </Stack>
        )
      })}
      {localActiveSpecs.length === 0 && (
        <Typography variant="body2" sx={{ pb: 2, color: theme.palette.mediumGrey2.main }}>
          No specifications available
        </Typography>
      )}
      <Stack sx={{ alignItems: 'flex-start' }}>
        <ClearTextIconButton
          onClick={(e) => setSpecAnchorEl(e.currentTarget)}
          text="Add a Spec"
          startIcon={<AddCircle sx={{ color: theme.palette.primary.main }} />}
          sx={{ pl: 0.5, fontWeight: 400, color: theme.palette.mediumGrey2.main }}
        />
        <CustomMenu
          width={300}
          categoryDivider={true}
          anchorEl={specAnchorEl}
          open={Boolean(specAnchorEl)}
          onClose={() => setSpecAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          menuItems={specificationOptions.map((option) => ({
            label: option.name,
            category: option.category,
            onClick: () => handleAddSpecification(option),
            disabled: localActiveSpecs.includes(option.fieldName),
          }))}
        />
      </Stack>
    </Stack>
  )
}
