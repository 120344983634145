import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { IconButton, Tooltip, Stack } from '@mui/material'
import { FormatBold, FormatItalic, FormatUnderlined, StrikethroughS } from '@mui/icons-material'
import { $getSelection, $isRangeSelection, TextFormatType } from 'lexical'
import { useCallback } from 'react'
import { FontFormatProps } from './lexical_editor_types.ts'
import { $patchStyleText } from '@lexical/selection'

export function FontFormatComponent({ activeFormats }: FontFormatProps) {
  const [editor] = useLexicalComposerContext()

  const formatText = useCallback(
    (format: TextFormatType) => {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          const style: any = {}
          switch (format) {
            case 'bold':
              style['font-weight'] = activeFormats.includes('bold') ? 'normal' : 'bold'
              break
            case 'italic':
              style['font-style'] = activeFormats.includes('italic') ? 'normal' : 'italic'
              break
            case 'underline':
              style['text-decoration'] = activeFormats.includes('underline') ? 'none' : 'underline'
              break
            case 'strikethrough':
              style['text-decoration'] = activeFormats.includes('strikethrough') ? 'none' : 'line-through'
              break
            default:
              break
          }
          $patchStyleText(selection, style)
        }
      })
    },
    [editor, activeFormats]
  )

  const fontStyleButtons = [
    {
      icon: <FormatBold />,
      format: 'bold',
      tooltip: 'Bold',
    },
    {
      icon: <FormatItalic />,
      format: 'italic',
      tooltip: 'Italic',
    },
    {
      icon: <FormatUnderlined />,
      format: 'underline',
      tooltip: 'Underline',
    },
    {
      icon: <StrikethroughS />,
      format: 'strikethrough',
      tooltip: 'Strikethrough',
    },
  ]

  return (
    <Stack direction="row" spacing={1}>
      {fontStyleButtons.map(({ icon, format, tooltip }) => (
        <Tooltip key={format} title={tooltip}>
          <IconButton
            size="small"
            onClick={() => formatText(format as TextFormatType)}
            color={activeFormats.includes(format) ? 'primary' : 'default'}
          >
            {icon}
          </IconButton>
        </Tooltip>
      ))}
    </Stack>
  )
}
