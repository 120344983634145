import theme from '@/theme.ts'
import { Stack } from '@mui/material'
import { OrganizationDetails } from '@/components/ui/organization-details.tsx'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useRfpContext } from '@/components/rfps/context/rfp-context.tsx'
import { excludeDeletedItems, formatStringToShortMonthDayYear, groupByKey } from '@/lib/utils.ts'
import { Job } from '@/types.ts'
import RequestPricingHeader from '@/components/rfps/draft/steps/rfp-page-content/components/request-pricing-header.tsx'
import Button from '@mui/material/Button'
import { CustomDivider } from '@/components/ui/base/dividers.tsx'
import IconButton from '@mui/material/IconButton'
import { EllipsisVertical, EyeIcon } from 'lucide-react'
import JobMenu from '@/components/rfps/draft/steps/rfp-page-content/components/job-menu.tsx'
import RfpJobPhasePlantTable from '@/components/rfps/draft/steps/rfp-page-content/components/rfp-job-phase-plant-table.tsx'
import RFPComments from '@/components/rfps/rfp-comments.tsx'
import Checkbox from '@mui/material/Checkbox'
import { useMemo } from 'react'
import { useRfpModals } from '@/components/rfps/hooks/useRfpModals.tsx'
import { ViewVendorRfpModal } from '@/components/rfps/modals/view-vendor-rfp-modal.tsx'
import VisibilityCallout from '@/components/rfps/visibility-callout'

export default function SentRfpPageContent({
  editMode = false,
  showHeader = true,
  pageContentType = 'editSentRfp',
  allowPlantTableReorder = false,
  allowPlantTableQuantityChange = false,
  allowPlantTableSelect = false,
  showPlantTableMenu = false,
}: {
  editMode?: boolean
  showHeader?: boolean
  pageContentType?: 'editSentRfp' | 'reviewSentRfp' | 'detailSentRfp'
  allowPlantTableReorder?: boolean
  allowPlantTableQuantityChange?: boolean
  allowPlantTableSelect?: boolean
  showPlantTableMenu?: boolean
}) {
  const { rfpData, updateRfpData, sentRfp, handleJobMenuClick, selectedPlantIds, handleSelectionChange } =
    useRfpContext()
  const { viewVendorRfpModalOpen, setViewVendorRfpModalOpen } = useRfpModals()

  const plants = useMemo(() => {
    if (!rfpData || !sentRfp) return []

    // If the page content type is not 'editSentRfp', only show plants from sentrfp
    if (pageContentType !== 'editSentRfp') return excludeDeletedItems(sentRfp.plants)

    // otherwise, show missing plants from parent rfp as well
    const sentRfpPlantMap = groupByKey(sentRfp.plants, 'id')
    return excludeDeletedItems(rfpData.plants).map((plant) => {
      return sentRfpPlantMap[plant.id]?.[0] || plant
    })
  }, [rfpData, sentRfp, pageContentType])

  const plantsByJobGroup = useMemo(() => {
    return groupByKey(plants, 'job_id') || {}
  }, [plants])

  if (!rfpData || !sentRfp) return

  const rfpOrganization = rfpData.organization || {}
  const rfpJobVisibility = sentRfp.rfp_settings.show_job_name_to_vendor || false
  const rfpPhaseVisibility = sentRfp.rfp_settings.show_phases_on_jobs_to_vendor || false
  const showDeleted = pageContentType === 'editSentRfp'
  const allowJobSelection = pageContentType === 'editSentRfp'
  const displayVendorRFPViewButton = pageContentType === 'editSentRfp'
  const displayEmptyJobs = pageContentType === 'editSentRfp'
  const displayRemovePlantButton = pageContentType === 'reviewSentRfp'

  const jobGroups =
    excludeDeletedItems(rfpData.jobs).reduce(
      (acc: Record<string, Job>, job: Job) => {
        acc[job.id] = job
        return acc
      },
      {} as Record<string, any>
    ) || {}

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: '100%',
        backgroundColor: 'white',
        borderRadius: theme.borderRadius.lg,
      }}
    >
      <Stack
        sx={{
          backgroundColor: 'white',
          borderRadius: theme.borderRadius.lg,
          overflow: 'hidden',
          p: 5.5,
          gap: 3,
        }}
      >
        {showHeader && (
          <>
            {/* Organization details & RFP# */}
            <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
              <OrganizationDetails organization={rfpOrganization} />
              <Typography variant="h3" fontWeight={400} sx={{ color: theme.palette.mediumGrey2.main }}>
                RFP# {rfpData.id}
              </Typography>
            </Stack>
          </>
        )}

        {/* Jobs/Phases & Group */}
        {Object.keys(jobGroups).length ? (
          <Stack key="rfp-job-groups">
            <Stack
              direction="row"
              sx={{
                justifyContent: 'space-between',
                mb: 1,
                alignItems: 'center',
              }}
            >
              <RequestPricingHeader
                rfpData={rfpData}
                sentRfpId={sentRfp?.id}
                plants={plants}
                selectedPlantIds={selectedPlantIds}
              />

              {displayRemovePlantButton && (
                <Button variant="text" sx={{ gap: 1 }}>
                  <Typography variant="body2" sx={{ color: theme.palette.darkGray.main }}>
                    Add/Remove Plants
                  </Typography>
                </Button>
              )}
              {displayVendorRFPViewButton && (
                <Button variant="text" sx={{ gap: 1 }} onClick={() => setViewVendorRfpModalOpen(true)}>
                  <EyeIcon color={theme.palette.darkGray.main} size={18} />
                  <Typography variant="body2" sx={{ color: theme.palette.darkGray.main, textDecoration: 'underline' }}>
                    Vendor RFP View
                  </Typography>
                </Button>
              )}
            </Stack>

            <CustomDivider />

            {Object.entries(jobGroups).map(([jobId, data]: [string, unknown]) => {
              const jobData = data as Job
              const jobPlants = plantsByJobGroup[jobId] || [] // fallback to empty array if job not in plantsByJobGroup (i.e. no plants for job)

              if (!displayEmptyJobs && jobPlants.length === 0) return null

              const selectedRfpJobPlants = jobPlants.filter((entry) => selectedPlantIds.has(entry.id))
              const expectedJobStartDate = formatStringToShortMonthDayYear(jobData.job_date as string) || 'TBD'
              return (
                <Stack key={`rfp-job-${jobId}-${sentRfp?.id}`} sx={{ pt: 2 }}>
                  <Stack direction="row" sx={{ pt: 4, pb: 1, pl: 0.5, pr: 0, justifyContent: 'space-between' }}>
                    <Stack direction="row" sx={{ gap: 1, alignItems: 'center' }}>
                      <Stack direction="row" sx={{ gap: 1, alignItems: 'center' }}>
                        {allowJobSelection && (
                          <Checkbox
                            checked={jobPlants.length > 0 && jobPlants.length === selectedRfpJobPlants.length}
                            indeterminate={
                              selectedRfpJobPlants.length > 0 && selectedRfpJobPlants.length < jobPlants.length
                            }
                            onChange={() => {
                              if (jobPlants.length === selectedRfpJobPlants.length) {
                                // If all plants are selected, remove all job plants from selection
                                const newSelectedIds = new Set(selectedPlantIds)
                                jobPlants.forEach((plant) => newSelectedIds.delete(plant.id))
                                handleSelectionChange(newSelectedIds)
                              } else {
                                // If not all plants are selected, add all job plants to selection
                                const newSelectedIds = new Set(selectedPlantIds)
                                jobPlants.forEach((plant) => newSelectedIds.add(plant.id))
                                handleSelectionChange(newSelectedIds)
                              }
                            }}
                          />
                        )}
                        <Typography
                          fontWeight={700}
                          variant="body1"
                          sx={{ textDecoration: selectedRfpJobPlants.length ? 'none' : 'line-through' }}
                        >
                          Job #{jobId}
                        </Typography>
                        <VisibilityCallout visible={rfpJobVisibility} itemName={jobData.name || 'Unnamed Job'} />
                      </Stack>
                    </Stack>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                      {/* Expected Start Date */}
                      <Typography sx={{ mr: 2 }} fontWeight={400} variant="body2" color="textSecondary">
                        Expected Start Date: {expectedJobStartDate}
                      </Typography>

                      {/* Job Hamburger Menu */}
                      {editMode && (
                        <>
                          <IconButton
                            sx={{
                              px: 0,
                              py: 1,
                              ml: 1,
                              borderRadius: theme.borderRadius.sm,
                              border: '1px solid #ccc',
                              backgroundColor: theme.palette.lightGrey3.main,
                            }}
                            onClick={(e) => handleJobMenuClick(e, jobId)}
                          >
                            <EllipsisVertical />
                          </IconButton>
                          <JobMenu jobGroups={jobGroups} plantsByJobGroup={plantsByJobGroup} />
                        </>
                      )}
                    </Stack>
                  </Stack>
                  <CustomDivider />
                  {jobPlants.length > 0 ? (
                    <RfpJobPhasePlantTable
                      key={`rfp-job-${jobId}-${sentRfp?.id}`}
                      jobId={jobId}
                      phaseVisible={rfpPhaseVisibility}
                      jobGroups={jobGroups}
                      plantsByJobGroup={plantsByJobGroup}
                      editMode={editMode}
                      showDeleted={showDeleted}
                      allowPlantTableQuantityChange={allowPlantTableQuantityChange}
                      allowPlantTableReorder={allowPlantTableReorder}
                      allowPlantTableSelect={allowPlantTableSelect}
                      showPlantTableMenu={showPlantTableMenu}
                    />
                  ) : (
                    <Box>No RFP Content</Box>
                  )}
                </Stack>
              )
            })}
            {/* Comments to all vendors */}
            <Stack direction="row" sx={{ justifyContent: 'space-between', gap: 2, mt: 3 }}>
              <RFPComments
                title="General Comments (for Vendors)"
                name="vendorComments"
                value={rfpData.comments_for_vendor || ''}
                placeholder="Type comments here ..."
                onChange={(value) => updateRfpData({ comments_for_vendor: value })}
                editMode={editMode}
              />
            </Stack>
          </Stack>
        ) : (
          <Box>No RFP Content</Box>
        )}
      </Stack>
      <ViewVendorRfpModal
        open={viewVendorRfpModalOpen}
        onClose={() => setViewVendorRfpModalOpen(false)}
        sentRfp={sentRfp}
        rfpData={rfpData}
      />
    </Box>
  )
}
