import { createContext, useContext, useState, ReactNode } from 'react'

type CommentModeContextType = {
  isCommentMode: boolean
  commentType: 'note' | 'comment' | null
  setCommentMode: (mode: boolean, type: 'note' | 'comment' | null) => void
  commentText: string
  setCommentText: (text: string) => void
}

const CommentModeContext = createContext<CommentModeContextType | undefined>(undefined)

export function CommentModeProvider({ children }: { children: ReactNode }) {
  const [isCommentMode, setIsCommentMode] = useState(false)
  const [commentType, setCommentType] = useState<'note' | 'comment' | null>(null)
  const [commentText, setCommentText] = useState('')

  const setCommentMode = (mode: boolean, type: 'note' | 'comment' | null) => {
    setIsCommentMode(mode)
    setCommentType(type)
    if (!mode) {
      setCommentText('')
    }
  }

  return (
    <CommentModeContext.Provider
      value={{
        isCommentMode,
        commentType,
        setCommentMode,
        commentText,
        setCommentText,
      }}
    >
      {children}
    </CommentModeContext.Provider>
  )
}

export function useCommentMode() {
  const context = useContext(CommentModeContext)
  if (context === undefined) {
    throw new Error('useCommentMode must be used within a CommentModeProvider')
  }
  return context
}
