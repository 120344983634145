import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react'
import { RouterProvider } from '@tanstack/react-router'
import { Suspense, useEffect, useMemo } from 'react'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import { OrganizationProvider } from '@/contexts/OrganizationContext'
import { UserInfoProvider } from '@/contexts/UserInfoContext'
import { initializeApi, refreshLogin } from './api/api'
import router from './router'
import { PubNubProvider } from './contexts/PubNubContext'
import { ToastNotificationsProvider } from '@/contexts/ToastNotificationContext.tsx'
import { useUserInfo } from './contexts/hooks/useUserInfo'
import { Skeleton, ThemeProvider } from '@mui/material'
import theme from './theme.ts'
import { clearPlantbidStorage, usePlantbidStorage } from './hooks/usePlantbidStorage.ts'

const queryClient = new QueryClient()

function Loader() {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" gap={1} height="100vh">
      <CircularProgress size={100} />
    </Box>
  )
}

function AppContent() {
  const { isLoading, user, ...auth } = useAuth0()

  useEffect(() => {
    initializeApi(auth.getAccessTokenSilently)
    refreshLogin(() => auth.loginWithRedirect({ appState: { returnTo: window.location.href } }))

    if (!auth.isAuthenticated && !isLoading && !user) {
      clearPlantbidStorage()
      router.invalidate()
    }
  }, [auth, isLoading, user])

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ToastNotificationsProvider>
            <UserInfoProvider>
              <OrganizationProvider>
                <PubNubProvider>
                  {isLoading ? <RootLoadingSkeleton /> : <PlantbidRouter auth={{ ...auth, isLoading }} />}
                </PubNubProvider>
              </OrganizationProvider>
            </UserInfoProvider>
          </ToastNotificationsProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

function PlantbidRouter({ auth }: { auth: Auth0ContextInterface }) {
  const { userInfo } = useUserInfo()
  const [_user, setUser] = usePlantbidStorage('user', userInfo)
  const [organization, setOrganization] = usePlantbidStorage(
    'organization',
    userInfo?.user?.organization_members?.[0]?.organization.id
  )

  // Create a memoized context value to prevent unnecessary re-renders
  const routerContext = useMemo(
    () => ({
      auth,
      plantbid: {
        user: userInfo,
        organization,
        setOrganization,
      },
      queryClient,
    }),
    [auth, userInfo, organization, setOrganization]
  )

  useEffect(() => {
    setUser(userInfo)
    router.invalidate()
  }, [userInfo, setUser])

  useEffect(() => {
    if (!organization && userInfo?.user?.organization_members?.[0]?.organization.id) {
      setOrganization(userInfo?.user.organization_members[0].organization.id)
    }
    router.invalidate()
  }, [organization, userInfo, setOrganization])

  return <RouterProvider router={router} context={routerContext} />
}

export default function App() {
  return (
    <Suspense fallback={<Loader />}>
      <AppContent />
    </Suspense>
  )
}

function RootLoadingSkeleton() {
  return (
    <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
      {/* Sidebar skeleton */}
      <Skeleton
        variant="rectangular"
        sx={{
          width: 240, // Typical MUI drawer width
          height: '100vh',
          flexShrink: 0,
          backgroundColor: theme.palette.navigation.main,
        }}
      />

      {/* Main content skeleton */}
      <Box sx={{ p: 3, width: '100%' }}>
        {/* Header area */}
        <Skeleton variant="rectangular" height={60} sx={{ mb: 2 }} />

        {/* Content area */}
        <Box sx={{ display: 'grid', gap: 2 }}>
          <Skeleton variant="rectangular" height={100} />
          <Skeleton variant="rectangular" height={400} />
          <Skeleton variant="rectangular" height={100} />
        </Box>
      </Box>
    </Box>
  )
}
