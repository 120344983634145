import { createFileRoute } from '@tanstack/react-router'
import { useState } from 'react'
import { Download } from 'lucide-react'
import ControlPanel from '@/components/ui/base/control-panel'
import PageLayout from '@/components/ui/layouts/page-layout.tsx'
import type { ElasticsearchResponse } from '@/types'
import Typography from '@mui/material/Typography'
import { zodValidator } from '@tanstack/zod-adapter'
import { Container, Pagination, Stack } from '@mui/material'
import { ZodError } from 'zod'
import { EmptyStateMessage, LoadingSkeleton } from '@/components/rfps/index/component-states'
import { RFPSimpleTable, RFPTable } from '@/components/rfps/index/collapsible-table'
import { ZodErrorMessage } from '@/components/util/zod-error'
import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import CreateEditRfpModal from '@/components/rfps/modals/create-edit-rfp-modal.tsx'
import { RFPIndexOptions, RFPIndexOptionsSchema, RFPSearchResponse } from '@/api/types/rfps'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { RFPQueryManager as RFPQM } from '@/api/rfp-index'
import { RFPFlatList } from '@/components/rfps/index/rfp-flat-list'
import { RFPDraftsExpandableList } from '@/components/rfps/index/rfp-draft-list'

/**
+---------------------------+      +---------------------------+
|        Filtering          |      |         Grouping          |
|---------------------------|      |---------------------------|
| - type (received/sent)    |      | - groupBy                 |
|   (Default: 'sent')       |      |   (status/contact/job)    |
| - status (enum)           |      |   (Default: 'status')     |
|   (Default: 'open')       |      +---------------------------+
| - search (text)           |
|   (Default: none)         |
+---------------------------+
          | Affects Displayed RFPs |
          V                      ^
+------------------------------------+
|          RFP Index View            |
| (Shows subset of RFPs based on     |
|  filtering, grouping, pagination)  |
+------------------------------------+
          ^                      V
+---------------------------+      | Controls View Window |
|        Pagination         |      +----------------------+
|---------------------------|
| - page (number)           |
|   (Default: 0)            |
| - limit (number)          |
|   (Default: 20)           |
+---------------------------+

 */
const queryDefaults = RFPQM.defaults

export const Route = createFileRoute('/$orgId/rfps/')({
  component: RFPSIndex,
  loaderDeps: ({ search }) => search,
  validateSearch: zodValidator(RFPIndexOptionsSchema),

  loader: async ({
    context: { queryClient },
    deps: {
      type = queryDefaults.type,
      groupBy = queryDefaults.groupBy,
      status = queryDefaults.status,
      page = queryDefaults.page,
      search,
    },
    params: { orgId },
  }) => {
    const rfpQueryManager = new RFPQM(orgId, queryClient)
    // Kick off fetches
    queryClient.ensureQueryData(rfpQueryManager.getDraftsQueryOptions({ type: 'drafts', page }))
    queryClient.ensureQueryData(rfpQueryManager.getQueryOptions({ page, type, status, search, groupBy }))

    return { rfpQueryManager }
  },
  errorComponent: ({ error }) => {
    if (error instanceof ZodError) {
      return <ZodErrorMessage error={error} title="RFP Index" />
    }

    if (error instanceof AxiosError) {
      return (
        <Typography variant="h2" color="error" fontWeight="bold">
          Error: {error?.message}
        </Typography>
      )
    }

    return (
      <Typography variant="h2" color="error" fontWeight="bold">
        Error: {error?.message}
      </Typography>
    )
  },
})

function RFPSIndex() {
  const { rfpQueryManager } = Route.useLoaderData()
  const { orgId } = Route.useParams()
  const navigate = Route.useNavigate()
  // Get param values from URL, falls back to defaults
  const {
    type = queryDefaults.type,
    groupBy = queryDefaults.groupBy,
    status = queryDefaults.status,
    page = queryDefaults.page,
    search,
  } = Route.useSearch()
  const [newRfpModalOpen, setNewRfpModalOpen] = useState(false)
  const [searchResults, setSearchResults] = useState<ElasticsearchResponse<RFPSearchResponse> | undefined>(undefined)
  const { optionalFields, requiredFields } = rfpQueryManager.getSearchQueryParams({ type, search })

  const {
    data: drafts,
    isPending: isDraftsPending,
    isError: isDraftsError,
    error: draftsError,
  } = useQuery(rfpQueryManager.getDraftsQueryOptions({ type: 'drafts' }))

  const {
    data: rfps,
    isPending: isRfpsPending,
    isError: isRfpsError,
    error: rfpsError,
  } = useQuery(rfpQueryManager.getQueryOptions({ type, status, search, page, groupBy }))

  const isError = isRfpsError || isDraftsError
  const error = rfpsError || draftsError
  const isPending = isRfpsPending || isDraftsPending
  const hasSearchResults = searchResults

  if (isError) {
    if (error instanceof ZodError) {
      return (
        <Container>
          <ZodErrorMessage error={error} title="RFP Index" />
        </Container>
      )
    }

    return (
      <Container>
        <Typography variant="h2" color="error" fontWeight="bold">
          {/* Error: {error?.message} */}
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Typography>
      </Container>
    )
  }

  const children = hasSearchResults ? (
    searchResults.items.length > 0 ? (
      <RFPSimpleTable rfpData={searchResults.items} type={type} orgId={orgId} />
    ) : (
      <EmptyStateMessage message={`No results found for "${search}"`} />
    )
  ) : isPending ? (
    <LoadingSkeleton />
  ) : type === 'sent' && rfps.data.length === 0 && drafts?.data.length === 0 ? (
    <EmptyStateMessage message="You have not sent any RFPs yet" />
  ) : type === 'received' && rfps.data.length === 0 ? (
    <EmptyStateMessage message="You have not received any RFPs from vendors yet" />
  ) : rfps.groupBy !== 'status' ? (
    <>
      {type === 'sent' && drafts && drafts.data.length > 0 && <RFPDraftsExpandableList drafts={drafts} />}
      <RFPTable rfpData={rfps} type={type} />
      {rfps.hasMore && (
        <Stack py={2}>
          <Pagination
            sx={{
              alignSelf: 'flex-end',
            }}
            variant="outlined"
            shape="rounded"
            page={page}
            size="large"
            onChange={(_, value) => {
              navigate({
                search: (prev) => ({
                  ...prev,
                  page: value,
                }),
              })
            }}
            count={rfps.hasMore ? page + 1 : page}
          />
        </Stack>
      )}
    </>
  ) : (
    <>
      {type === 'sent' && drafts && drafts.data.length > 0 && <RFPDraftsExpandableList drafts={drafts} />}
      <RFPFlatList rfpData={rfps} />
    </>
  )

  return (
    <PageLayout
      title="RFPs"
      icon={<Download size={36} />}
      titleBarElement={
        <Button variant="contained" onClick={() => setNewRfpModalOpen(true)}>
          <AddIcon /> New RFP
        </Button>
      }
    >
      <ControlPanel<RFPSearchResponse>
        tabConfig={{
          tabs: [
            { label: 'Sent RFPs', value: 'sent' },
            { label: 'Received RFPs', value: 'received' },
          ],
          activeTab: type,
          onTabChange: (tab) => {
            navigate({
              search: (prev) => ({
                ...prev,
                type: tab as RFPIndexOptions['type'],
              }),
            })
          },
        }}
        searchConfig={{
          placeholder: 'Search RFPs...',
          searchUrl: '/v1/core/sent_rfp/search/',
          value: search,
          // Get from query manager
          optionalFields,
          requiredFields,
          searchQueryKey: (value) => ['search', '/v1/core/sent_rfp/search/', type, value],
          onSearchResults: (response) => {
            setSearchResults(response)
          },
        }}
        filterConfig={{
          filters: [
            {
              disabled: hasSearchResults ? 'Clear Search to Organize' : undefined,
              label: 'Organize By',
              value: 'groupBy',
              options: [
                { label: 'Status', value: 'status', activeLabel: 'Organize by Status' },
                {
                  label: type === 'sent' ? 'Vendor (Seller)' : 'Customer (Buyer)',
                  value: 'contact',
                  activeLabel: type === 'sent' ? 'Organize by Vendor' : 'Organize by Customer',
                },
                { label: 'Job', value: 'job', activeLabel: 'Organize by Job' },
              ],
            },
            {
              label: 'Status',
              value: 'status',
              options: [
                { label: 'Active (Open)', value: 'open' },
                { label: 'Done (Closed)', value: 'closed' },
                { label: 'Inactive', value: 'paused' },
                { label: 'Archived', value: 'archived' },
              ],
            },
          ],
          filterValues: {
            groupBy,
            status: status,
          },
          onFilterChange: (filter, value) => {
            navigate({
              search: (prev) => ({
                ...prev,
                [filter]: value,
              }),
            })
          },
        }}
      />
      {children}
      <CreateEditRfpModal open={newRfpModalOpen} onClose={() => setNewRfpModalOpen(false)} />
    </PageLayout>
  )
}
