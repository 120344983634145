import { createFileRoute, redirect, useNavigate } from '@tanstack/react-router'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getRfp, getSentRfpsByRfpId, updateRfp, updateSentRfp } from '@/api/rfps.ts'
import StepLayout, { StepConfig } from '@/components/ui/layouts/step-layout'
import BuildRFPStep from '@/components/rfps/draft/steps/build-rfp-step.tsx'
import AddVendorsStep from '@/components/rfps/draft/steps/add-vendors-step.tsx'
import ReviewAndSendStep from '@/components/rfps/draft/steps/review-and-send-step.tsx'
import { ComponentType, createElement, useCallback, useEffect, useState } from 'react'
import { Button, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import theme from '@/theme.ts'
import { useDebouncedCallback } from 'use-debounce'
import * as Sentry from '@sentry/react'
import { RFPStepProps } from '@/components/rfps/types.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization'
import { CommentModeProvider } from '@/contexts/comment-mode-context.tsx'
import { CreateRfpSteps } from '@/constants.ts'
import { RequestForProposal, RfpStatus, SentRFP } from '@/types.ts'
import { excludeDeletedItems } from '@/lib/utils.ts'
import { TextIconButton } from '@/components/ui/base/buttons/text-icon-buttons.tsx'
import { SendHorizonal } from 'lucide-react'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications.ts'
import { AxiosError } from 'axios'
import RfpSentSuccessModal from '@/components/ui/modals/rfp-sent-success-modal.tsx'
import { RFP_QUERY_KEYS } from '@/lib/query-keys'
import { z } from 'zod'
import { zodValidator } from '@tanstack/zod-adapter'
import { RfpPageLoadingSkeleton } from '@/components/rfps/skeletons/rfp-page-loading-skeleton.tsx'
import { RfpSettingsMenu } from '@/components/rfps/rfp-settings-menu.tsx'

export const Route = createFileRoute('/$orgId/rfps/draft/$rfpId')({
  component: DraftRFPPage,
  validateSearch: zodValidator(
    z.object({
      step: z.string().default('build-rfp'),
      modal: z.string().optional(),
      selectedSentRfpId: z.string().optional(),
    })
  ),
  loaderDeps: ({ search }) => search,
  loader: async ({
    params,
    deps,
  }: {
    params: { orgId: string; rfpId: string }
    deps: { step: string; modal?: string; selectedSentRfpId?: string }
  }) => {
    const step = deps.step
    const rfpId = params.rfpId

    // Skip validation for the first step
    if (step === CreateRfpSteps.BUILD_RFP) {
      return { validStep: true }
    }

    const rfp = await getRfp(rfpId)
    const validPlants = excludeDeletedItems(rfp.plants || []).length > 0

    // If trying to access ADD_VENDORS or REVIEW_AND_SEND but no plants, redirect to BUILD_RFP
    if (!validPlants && (step === CreateRfpSteps.ADD_VENDORS || step === CreateRfpSteps.REVIEW_AND_SEND)) {
      throw redirect({
        to: '/$orgId/rfps/draft/$rfpId',
        params: { orgId: params.orgId, rfpId },
        search: { step: CreateRfpSteps.BUILD_RFP },
      })
    }

    // If trying to access REVIEW_AND_SEND, check if there are pending sent RFPs
    if (step === CreateRfpSteps.REVIEW_AND_SEND) {
      const sentRfps = await getSentRfpsByRfpId(rfpId, rfp.organization.id)
      const hasPendingSentRfps = excludeDeletedItems(sentRfps).some(
        (sentRfp: SentRFP) => sentRfp.status === RfpStatus.PENDING
      )

      if (!hasPendingSentRfps) {
        throw redirect({
          to: '/$orgId/rfps/draft/$rfpId',
          params: { orgId: params.orgId, rfpId },
          search: { step: CreateRfpSteps.ADD_VENDORS },
        })
      }
    }

    return { validStep: true }
  },
})

const BASE_RFP_STEPS: StepConfig<RFPStepProps>[] = [
  {
    key: CreateRfpSteps.BUILD_RFP,
    label: 'Build RFP',
    component: BuildRFPStep,
    canProgress: false,
  },
  {
    key: CreateRfpSteps.ADD_VENDORS,
    label: 'Add Vendors',
    component: AddVendorsStep as ComponentType<RFPStepProps>,
    canProgress: false,
  },
  {
    key: CreateRfpSteps.REVIEW_AND_SEND,
    label: 'Review & Send',
    component: ReviewAndSendStep as ComponentType<RFPStepProps>,
    canProgress: false,
  },
]

function DraftRFPPage() {
  const { rfpId } = Route.useParams()
  const { step } = Route.useSearch()
  const navigate = useNavigate({ from: Route.fullPath })
  const queryClient = useQueryClient()
  const { selectedOrganization } = useOrganization()
  const isNotBuildRfpStep = step !== CreateRfpSteps.BUILD_RFP

  const { data: initialRfpData, isLoading: rfpDataLoading } = useQuery({
    queryKey: RFP_QUERY_KEYS.draftRfp(rfpId),
    queryFn: async () => {
      return await getRfp(rfpId)
    },
    enabled: !!selectedOrganization,
    refetchOnWindowFocus: false,
  })

  const { data: sentRfpData, isLoading: sentRfpLoading } = useQuery({
    queryKey: RFP_QUERY_KEYS.sentRfps(rfpId, selectedOrganization?.id),
    queryFn: async () => {
      if (!rfpId || !selectedOrganization?.id) return []
      const rfps = await getSentRfpsByRfpId(rfpId, selectedOrganization?.id as string)
      return excludeDeletedItems(rfps).filter((sentRfp: SentRFP) => sentRfp.status === RfpStatus.PENDING)
    },
    enabled: !!selectedOrganization && !!rfpId,
  })

  const [rfpData, setRfpData] = useState(initialRfpData)
  const [rfpSteps, setRfpSteps] = useState(BASE_RFP_STEPS)
  const [saveStatus, setSaveStatus] = useState<'saved' | 'saving' | 'error' | 'none'>('none')
  const [successModalRfps, setSuccessModalRfps] = useState<SentRFP[]>([])
  const { addToastNotification } = useToastNotifications()

  useEffect(() => {
    if (initialRfpData) {
      setRfpData(initialRfpData)
    }

    const hasValidPlants = excludeDeletedItems(initialRfpData?.plants || []).length > 0
    const hasPendingSentRfps = (sentRfpData || []).length > 0

    setRfpSteps((steps) => {
      return steps.map((step) => {
        if (step.key === CreateRfpSteps.BUILD_RFP) {
          return {
            ...step,
            canProgress: hasValidPlants,
          }
        }
        if (step.key === CreateRfpSteps.ADD_VENDORS) {
          return {
            ...step,
            canProgress: hasPendingSentRfps,
          }
        }
        return step
      })
    })
  }, [initialRfpData, sentRfpData])

  const updateDraftMutation = useMutation({
    mutationFn: ({ rfpId, data }: { rfpId: string; data: any }) => updateRfp(rfpId, data),
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: RFP_QUERY_KEYS.draftRfp(rfpId),
        })
        .then((_r) => null)
    },
  })

  const updateSentRfpMutation = useMutation({
    mutationFn: updateSentRfp,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: RFP_QUERY_KEYS.sentRfps(rfpId, selectedOrganization?.id),
      })
    },
    onError: async (error: AxiosError) => {
      addToastNotification({
        severity: 'error',
        title: 'Error updating RFP for Vendor',
        message: (error.response?.data as { detail?: string })?.detail || error.message,
      })
      Sentry.captureException(error, {
        tags: {
          action: 'updating_sent_rfp',
          rfpId: rfpId,
        },
      })
      throw error
    },
  })

  const handleInvalidateSentRfps = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: RFP_QUERY_KEYS.sentRfps(rfpId, selectedOrganization?.id),
    })
  }, [queryClient, rfpId, selectedOrganization?.id])

  const debouncedSentRfpSave = useDebouncedCallback(async (data: any) => {
    if (!data.sentRfpId || !data.data) return
    setSaveStatus('saving')
    try {
      await updateSentRfpMutation.mutateAsync(data)
      setSaveStatus('saved')
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          action: 'auto_save_sent_rfp',
          rfpId,
        },
      })
      setSaveStatus('error')
    }
  }, 1000)

  const handleUpdateSentRfpData = useCallback(
    ({ sentRfpId, updates }: { sentRfpId: string; updates: Partial<SentRFP> }) => {
      debouncedSentRfpSave({ sentRfpId, data: updates })
    },
    [debouncedSentRfpSave]
  )

  const debouncedSave = useDebouncedCallback(async (data: any) => {
    setSaveStatus('saving')
    try {
      await updateDraftMutation.mutateAsync({
        rfpId,
        data,
      })
      setSaveStatus('saved')
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          action: 'auto_save_rfp',
          rfpId,
        },
      })
      setSaveStatus('error')
    }
  }, 1000)

  const handleUpdateRfpData = useCallback(
    async (updates: any) => {
      if (!rfpData) return

      setSaveStatus('saving')

      try {
        const newData = {
          ...rfpData,
          ...updates,
        }

        setRfpData(newData as RequestForProposal)
        await debouncedSave(newData)
      } catch (error) {
        console.error('Failed to update RFP data:', error)
        setSaveStatus('error')
      }
    },
    [rfpData, debouncedSave, setSaveStatus]
  )

  const handleSaveRfpDraft = async () => {
    if (!rfpData) return

    try {
      setSaveStatus('saving')
      await updateDraftMutation.mutateAsync({
        rfpId,
        data: rfpData,
      })
      setSaveStatus('saved')
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          action: 'manual_save_rfp',
          rfpId,
        },
      })
      setSaveStatus('error')
    }
  }

  const handleSendRfpToVendors = async (targetSentRfps: SentRFP[]) => {
    const updates = { send: true }

    try {
      // handle all updates concurrently and track results
      const results = await Promise.all(
        targetSentRfps.map(async (sentRfp) => {
          try {
            await updateSentRfpMutation.mutateAsync({
              sentRfpId: sentRfp.id as string,
              data: updates,
            })
            return { success: true, rfp: sentRfp }
          } catch {
            return { success: false, rfp: sentRfp }
          }
        })
      )

      const successful = results.filter((r) => r.success).map((r) => r.rfp)
      const failed = results.filter((r) => !r.success).map((r) => r.rfp)

      // Notify user of results
      if (successful.length > 0) {
        setSuccessModalRfps(successful)
        addToastNotification({
          severity: 'success',
          title: 'RFPs Sent Successfully',
          message: `Successfully sent ${successful.length} RFP${successful.length > 1 ? 's' : ''}`,
        })
      }

      if (failed.length > 0) {
        // what if some rfps passed and some failed ?
        addToastNotification({
          severity: 'error',
          title: 'Some RFPs Failed to Send',
          message: `Failed to send ${failed.length} RFP${failed.length > 1 ? 's' : ''}`,
        })
      }

      return { successful, failed }
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          action: 'send_rfps_to_vendors',
          rfpId,
        },
      })
      throw error
    }
  }

  const RfpStepActionButtons = () => {
    return (
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSaveRfpDraft}
          disabled={updateDraftMutation.isPending || saveStatus === 'saving'}
          sx={{
            whiteSpace: 'nowrap',
            minWidth: 109,
          }}
        >
          <Typography variant="body1">{updateDraftMutation.isPending ? 'Saving...' : 'Save Draft'}</Typography>
        </Button>

        {step === CreateRfpSteps.REVIEW_AND_SEND && sentRfpData?.length ? (
          <TextIconButton
            text={`Send to All (${sentRfpData?.length})`}
            onClick={() => handleSendRfpToVendors(sentRfpData || [])}
            startIcon={<SendHorizonal color="white" />}
            variant="contained"
            color="white"
            bgColor={theme.palette.primary.main}
            sx={{ px: 4, borderRadius: theme.borderRadius.sm }}
          />
        ) : null}
      </Stack>
    )
  }

  if (rfpDataLoading || (isNotBuildRfpStep && sentRfpLoading)) {
    return <RfpPageLoadingSkeleton showVendorSidebar={isNotBuildRfpStep} />
  }

  const currentStepComponent = rfpSteps.find((s) => s.key === step)?.component

  return (
    <>
      <StepLayout
        title="New RFP"
        steps={rfpSteps}
        currentStep={step}
        saveStatus={saveStatus}
        stepActionButtons={<RfpStepActionButtons />}
        pageActionButtons={rfpData ? <RfpSettingsMenu rfpData={rfpData} /> : undefined}
        onStepChange={(newStep) =>
          navigate({
            search: { step: newStep },
            replace: true,
          })
        }
      >
        <CommentModeProvider>
          {currentStepComponent &&
            createElement(currentStepComponent, {
              rfpData,
              onUpdateRfpData: handleUpdateRfpData,
              onUpdateSentRfpData: handleUpdateSentRfpData,
              ...(step === CreateRfpSteps.ADD_VENDORS
                ? {
                    sentRfps: sentRfpData || [],
                    onInvalidateSentRfps: handleInvalidateSentRfps,
                  }
                : {}),
            })}
        </CommentModeProvider>
      </StepLayout>
      <RfpSentSuccessModal
        open={successModalRfps.length > 0}
        onClose={() => setSuccessModalRfps([])}
        rfpId={rfpId}
        sentRfps={successModalRfps}
      />
    </>
  )
}
