import { useState, MouseEvent } from 'react'
import { Button, Menu, MenuItem } from '@mui/material'
import { $getSelection, $isRangeSelection } from 'lexical'
import { $patchStyleText } from '@lexical/selection'
import { FormatSize } from '@mui/icons-material'

const FONT_SIZES = ['small', 'medium', 'large']

interface FontSizeDropdownProps {
  fontSize: string
  editor: any // You might want to type this properly with LexicalEditor
}

export function FontSizeDropdown({ fontSize, editor }: FontSizeDropdownProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleFontSizeChange = (newSize: string) => {
    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        $patchStyleText(selection, {
          'font-size': newSize,
        })
      }
    })
    handleClose()
  }

  return (
    <>
      <Button
        aria-controls={open ? 'font-size-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size="small"
        endIcon={<FormatSize />}
        sx={{ minWidth: 70, height: 30 }}
        color="primary"
      >
        {fontSize}
      </Button>
      <Menu id="font-size-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {FONT_SIZES.map((size) => (
          <MenuItem key={size} onClick={() => handleFontSizeChange(size)}>
            {size}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
