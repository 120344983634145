import { Job, PlantListEntry } from '@/types.ts'
import { ChangeEvent, useCallback, useMemo, useState, useEffect } from 'react'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { useQueries } from '@tanstack/react-query'
import { getJobsById } from '@/api/jobs.ts'
import { excludeDeletedItems } from '@/lib/utils.ts'
import { Check, Search, X } from 'lucide-react'
import { InputAdornment, Stack, Typography } from '@mui/material'
import { pluralizedLabel } from '@/lib/pluralize.ts'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip'
import Alert from '@mui/material/Alert'
import { JobPhasePlantsTableRows } from '@/components/rfps/job-phase-plants-table-rows.tsx'
import { FlatPlantTable, FlatPlantTableCellProps, FlatPlantTableRowProps } from '@/components/ui/flat-plant-table.tsx'
import { getFormattedPlantListEntrySpecs } from '@/components/plant-lists/plant-lists-entry-specs.ts'
import { RfpModalPlantTableSkeleton } from '@/components/rfps/skeletons/rfp-modal-plant-table-skeleton.tsx'

interface SelectJobPhasePlantsProps {
  jobIds: string[]
  jobData?: Job[]
  excludedPlants: PlantListEntry[]
  showJobSelection: boolean
  showSelected: boolean
  allowSelection: boolean
  showQuantity: boolean
  allowQuantityEdit: boolean
  allowRemoval: boolean
  handleJobs: (jobIds: string[]) => void
  handleSelectedPlantUpdates: (plants: PlantListEntry[]) => void
}

export const SelectJobPhasePlants = ({
  jobIds,
  jobData = [],
  excludedPlants,
  showJobSelection,
  handleJobs,
  handleSelectedPlantUpdates,
  showSelected,
  allowSelection,
  showQuantity,
  allowQuantityEdit,
  allowRemoval,
}: SelectJobPhasePlantsProps) => {
  const [selectedPlants, setSelectedPlants] = useState<Set<string>>(new Set())
  const [quantities, setQuantities] = useState<Record<string, number>>({})
  const [searchTerm, setSearchTerm] = useState('')
  const { selectedOrganization } = useOrganization()

  const totalColumns = 5
  let colSpan = 2
  if (allowRemoval) colSpan--

  // Query for job data using job ids from props
  const jobQueries = useQueries({
    queries: jobIds.map((jobId) => ({
      queryKey: ['job', jobId],
      queryFn: () => getJobsById(selectedOrganization?.id, jobId),
      enabled: !!selectedOrganization && !!jobId,
    })),
  })

  // add loading state based on queries
  const isLoading = jobQueries.some((query) => query.isLoading)

  // Extract job data from queries or from props as available
  const jobDataList: Job[] = jobData?.length
    ? jobData
    : jobQueries
        .map((query) => query.data as Job | undefined)
        .filter((job): job is Job => Boolean(job) && job?.deleted_at === null)

  // Memoized excluded plant object
  const excludedPlantsObject = useMemo(() => {
    return excludedPlants.reduce(
      (acc, plant) => {
        acc[plant.id] = plant
        return acc
      },
      {} as Record<string, PlantListEntry>
    )
  }, [excludedPlants])

  // Memoize active plants
  const activePlants: PlantListEntry[] = useMemo(() => {
    if (!jobDataList) return []
    return jobDataList.flatMap((job: Job) =>
      excludeDeletedItems(job.plants).map((plant) => ({ ...plant, job_id: job.id }))
    )
  }, [jobDataList])

  useEffect(() => {
    const newQuantities = { ...quantities }
    let hasChanges = false

    activePlants.forEach((plant) => {
      if (!quantities[plant.id]) {
        newQuantities[plant.id] = plant.quantity_count.min || plant.quantity_count.max || 1
        hasChanges = true
      }
    })

    if (hasChanges) {
      setQuantities(newQuantities)
    }
  }, [activePlants])

  useEffect(() => {
    if (selectedPlants.size > 0) {
      const selectedEntriesWithQuantity = Object.fromEntries(
        Object.entries(quantities).filter(([key]) => selectedPlants.has(key))
      )
      updateSelectedPlants(selectedEntriesWithQuantity)
    }
  }, [selectedPlants])

  // Memoize filtered plants
  const filteredPlants = useMemo(() => {
    if (!searchTerm) return activePlants

    const value = searchTerm.toLowerCase()
    return activePlants.filter(
      (entry: PlantListEntry) =>
        entry.common_name?.toLowerCase().includes(value) || entry.scientific_name?.toLowerCase().includes(value)
    )
  }, [activePlants, searchTerm])

  // Memoize plants already in RFP
  const plantsAlreadyInRfpItems: FlatPlantTableRowProps[] = useMemo(() => {
    // iterate through each filtered plant and create rows for table
    const plantRows = filteredPlants
      .filter((entry) => Object.keys(excludedPlantsObject).includes(entry.id))
      .map((entry) => {
        // create row for each plant
        const plantRowCells: FlatPlantTableCellProps[] = []
        const excludedPlant = excludedPlantsObject[entry.id]

        // include check mark if showSelected is true
        if (showSelected) plantRowCells.push({ node: <Check /> })

        // include plant name
        plantRowCells.push({ name: entry.common_name || entry.scientific_name || 'N/A' })

        // include specs
        const specs = getFormattedPlantListEntrySpecs({ entry: excludedPlant })
        specs.length
          ? plantRowCells.push({
              node: (
                <Stack direction="row" sx={{ gap: 1 }}>
                  {specs.map((spec) => (
                    <Chip key={spec} label={spec} />
                  ))}
                </Stack>
              ),
            })
          : plantRowCells.push({
              name: 'N/A',
            })

        // include quantity if showQuantity is true
        if (showQuantity)
          plantRowCells.push({ name: `${excludedPlant.quantity_count.min || excludedPlant.quantity_count.max || 1}` })

        // include removal button if allowRemoval is true
        if (allowRemoval)
          plantRowCells.push({
            node: (
              <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
                <X />
              </Stack>
            ),
          })

        // create row for plant
        const plantRow: FlatPlantTableRowProps = {
          cells: plantRowCells,
        }

        // return plant row
        return plantRow
      })

    if (!plantRows.length) return [] as FlatPlantTableRowProps[]

    // create table rows for plants already in RFP
    const plantAdditionalHeaderRow: FlatPlantTableRowProps = {
      cells: [
        {
          node: (
            <Typography variant="strong">{`${pluralizedLabel(plantRows.length, 'Plant', 'Plants')} included in this RFP`}</Typography>
          ),
          colSpan: totalColumns,
        },
      ],
    }

    // all headers and rows
    return [plantAdditionalHeaderRow, ...plantRows]
  }, [filteredPlants, excludedPlantsObject])

  const updateSelectedPlants = (entries: Record<string, number>) => {
    const plants = activePlants
      .filter((entry) => Object.keys(entries).includes(entry.id))
      .map((entry) => {
        return {
          ...entry,
          quantity_count: {
            ...entry.quantity_count,
            min: entries[entry.id],
          },
        }
      })

    handleSelectedPlantUpdates(plants)
  }

  const handleQuantityChange = (entry_id: string, value: number) => {
    setQuantities((prev) => {
      const newQuantities = {
        ...prev,
        [entry_id]: value,
      }

      // Only update selected plants if the changed plant is selected
      if (selectedPlants.has(entry_id)) {
        const selectedEntriesWithQuantity = Object.fromEntries(
          Object.entries(newQuantities).filter(([key]) => selectedPlants.has(key))
        )
        updateSelectedPlants(selectedEntriesWithQuantity)
      }

      return newQuantities
    })
  }

  const handleCheckboxChange = (entry: PlantListEntry) => {
    setSelectedPlants((prev) => {
      const newSelected = new Set(prev)
      if (newSelected.has(entry.id)) {
        newSelected.delete(entry.id)
      } else {
        newSelected.add(entry.id)
      }
      return newSelected
    })
  }

  const handleGroupCheckboxChange = (group: string, group_id: string, entries: PlantListEntry[]) => {
    setSelectedPlants((prev) => {
      let filteredGroupPlants = []
      if (group === 'phase') {
        filteredGroupPlants = filteredPlants.filter((entry) => entry.phase_id === group_id).map((entry) => entry.id)
      } else {
        filteredGroupPlants = filteredPlants.map((entry) => entry.id)
      }

      const newSelected = new Set(Array.from(prev).filter((id) => !filteredGroupPlants.includes(id)))

      entries.forEach((entry) => {
        newSelected.add(entry.id)
      })

      return newSelected
    })
  }

  // Memoized all table rows
  const plantTableRows = useMemo(() => {
    const jobPlantRows: FlatPlantTableRowProps[] = []

    // create table rows for each job phase data
    jobDataList.forEach((jobData) => {
      // get active plants for the job that are not included in rfp
      const jobPlants = activePlants.filter((entry) =>
        jobData.plants.some((plant) => plant.id === entry.id && !Object.keys(excludedPlantsObject).includes(plant.id))
      )

      // create table rows for each job phase plant data
      const jobRows: FlatPlantTableRowProps[] = JobPhasePlantsTableRows(
        jobData,
        jobPlants,
        filteredPlants,
        selectedPlants,
        quantities,
        handleQuantityChange,
        handleCheckboxChange,
        handleGroupCheckboxChange,
        showSelected,
        allowSelection,
        showQuantity,
        allowQuantityEdit,
        allowRemoval
      )

      // add job rows to jobPlantRows
      jobPlantRows.push(...jobRows)
    })

    // return empty table if no job/phase contains active plants
    if (!(jobPlantRows.length || plantsAlreadyInRfpItems.length))
      return { header: {} as FlatPlantTableRowProps, rows: [] }

    // create table header
    const tableHeaderCells: FlatPlantTableCellProps[] = []

    // add checkbox for selecting all plants if required
    if (showSelected && allowSelection) {
      const filteredSelectedPlants = filteredPlants.filter((entry) => selectedPlants.has(entry.id))
      const isChecked = filteredSelectedPlants.length === filteredPlants.length
      const isIndeterminate =
        filteredSelectedPlants.length !== filteredPlants.length && Boolean(filteredSelectedPlants.length)
      const targetPlants = isChecked ? [] : filteredPlants
      tableHeaderCells.push({
        node: (
          <Checkbox
            checked={isChecked}
            indeterminate={isIndeterminate}
            onChange={() => handleGroupCheckboxChange('all', 'all', targetPlants)}
          />
        ),
      })
    }

    // add plant name and specs
    tableHeaderCells.push({ name: 'Plant Name', sx: { width: '45%' } })
    tableHeaderCells.push({
      name: 'Specs',
      colSpan: showQuantity ? 1 : colSpan,
      sx: { width: showQuantity ? '35%' : '55%' },
    })

    // add quantity if required
    if (showQuantity)
      tableHeaderCells.push({
        name: 'QTY Needed',
        colSpan: colSpan,
        sx: { width: '20%' },
      })

    // create plant table header row
    const plantTableHeaderRow: FlatPlantTableRowProps = {
      cells: tableHeaderCells,
    }

    // return table header and rows
    return {
      header: plantTableHeaderRow,
      rows: [...jobPlantRows, ...plantsAlreadyInRfpItems],
    }
  }, [jobDataList, plantsAlreadyInRfpItems])

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }, [])

  if (isLoading) return <RfpModalPlantTableSkeleton />
  if (!jobDataList) return <Alert severity="error">Failed to fetch job data</Alert>
  if (jobDataList.length === 0) return <Alert severity="error">Invalid Job</Alert>

  return (
    <Stack sx={{ gap: 2, overflow: 'hidden' }}>
      {/* Job Chips */}
      {showJobSelection && (
        <JobNameChips jobs={jobDataList} handleClose={(job_id) => handleJobs(jobIds.filter((id) => id !== job_id))} />
      )}

      {/* Search bar */}
      <SearchPlantsFromJob jobIds={jobIds} searchTerm={searchTerm} handleSearchChange={handleSearchChange} />

      {/* Plant Table */}
      <Stack sx={{ overflow: 'auto', scrollbarWidth: 'none' }}>
        <FlatPlantTable header={plantTableRows.header} rows={plantTableRows.rows} />
      </Stack>
    </Stack>
  )
}

interface JobNameChipProps {
  jobs: Job[]
  handleClose: (job_id: string) => void
  allowCompleteRemoval?: boolean
}

export const JobNameChips = ({ jobs, allowCompleteRemoval = false, handleClose }: JobNameChipProps) => {
  if (jobs.length === 0) return null

  const showCloseButton = jobs.length > 1 || allowCompleteRemoval

  return (
    <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
      {jobs.map((job) => {
        const label = (
          <Stack direction="row" gap={1}>
            <Typography variant="body2" fontWeight={700}>
              {job.name}
            </Typography>
            <Typography>({job.id})</Typography>
          </Stack>
        )
        return <Chip key={job.id} label={label} onDelete={showCloseButton ? () => handleClose(job.id) : undefined} />
      })}
    </Stack>
  )
}

interface SearchPlantsFromJobProps {
  jobIds: string[]
  searchTerm: string
  handleSearchChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export const SearchPlantsFromJob = ({ jobIds, searchTerm, handleSearchChange }: SearchPlantsFromJobProps) => {
  return (
    <TextField
      placeholder={`Search Plants ${jobIds.length > 1 ? `in ${jobIds.length} Jobs` : ''}`}
      onChange={handleSearchChange}
      value={searchTerm}
      variant="outlined"
      size="small"
      sx={{
        width: '40%',
        '& .MuiOutlinedInput-root': {
          '& fieldset': { border: 0 },
          '&:hover fieldset': { border: 0 },
          '&.Mui-focused fieldset': { border: 0 },
        },
      }}
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        },
      }}
    />
  )
}
