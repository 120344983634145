import { createFileRoute, redirect } from '@tanstack/react-router'

import PageLayout from '@/components/ui/layouts/page-layout.tsx'
import NewOrganizationModal from '@/components/ui/modals/new-organization-modal'

export const Route = createFileRoute('/')({
  component: Index,
  beforeLoad: async ({ context }) => {
    if (!context.auth.isAuthenticated) {
      return context
    }

    const currentOrg = context.plantbid.organization

    if (currentOrg && !context.auth.isLoading) {
      throw redirect({ to: `/$orgId`, params: { orgId: currentOrg } })
    }

    if (!currentOrg) {
      const orgs = context.plantbid.user?.user.organization_members
      if (orgs && orgs.length > 0) {
        throw redirect({ to: `/$orgId`, params: { orgId: orgs[0].id } })
      }
    }

    return context
  },
  loader: async ({ context: { plantbid, auth } }) => {
    return {
      promptNewOrg: auth.isAuthenticated && !plantbid.user?.user.organization_members,
    }
  },
})

function Index() {
  const { promptNewOrg } = Route.useLoaderData()
  const envText = import.meta.env.VITE_ENV !== 'production' ? `(${import.meta.env.VITE_ENV})` : ''
  const navigate = Route.useNavigate()

  return (
    <PageLayout title={`Plantbid v1.5 ${envText}`}>
      {promptNewOrg && (
        <NewOrganizationModal
          open={true}
          onClose={() => {}}
          onOrganizationCreated={(id) => {
            navigate({ to: '/$orgId', params: { orgId: id } })
          }}
        />
      )}
    </PageLayout>
  )
}
