import { styled } from '@mui/material/styles'
import { useState, type MouseEvent } from 'react'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'

import { Plus, X } from 'lucide-react'

import { DEFAULT_PLANT_LIST_ENTRY } from '@/constants.ts'
import {
  type MeasurableRangeDimension,
  MultiTrunk,
  type PlantListEntry,
  type RangeDimension,
  type SingleTrunk,
  TrunkPlurality,
} from '@/types.ts'

import InlineEditableMeasurableRangeDimension from './inline-editable-measurable-range-dimension.tsx'
import InlineEditableRangeDimension from './inline-editable-range-dimension.tsx'
import InlineEditableSelect from './inline-editable-select.tsx'
import { formatDimension } from '@/lib/utils.ts'
import theme from '@/theme.ts'
import { CommentableElement } from '@/components/ui/commentable-element.tsx'
import { CommentPage } from '@/types/comments.ts'
import { useRfpContext } from '@/components/rfps/context/rfp-context.tsx'

const EditableChip = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isEditable',
})<{ isEditable?: boolean }>(({ theme, isEditable }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(0.5),
  padding: isEditable ? theme.spacing(1, 5, 1, 1) : theme.spacing(1, 1),
  fontSize: '0.8125rem',
  backgroundColor: theme.palette.tableHeaderBackground.main,
  borderRadius: theme.borderRadius.xs,
  position: 'relative',
  ...(isEditable && {
    '& > *:not(.remove-button)': {
      transition: 'transform 0.2s ease-in-out',
      transform: 'translateX(12px)',
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[400],
      '& > *:not(.remove-button)': {
        transform: 'translateX(0)',
      },
      '& .remove-button': {
        opacity: 1,
      },
    },
  }),
}))

const RemoveButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(0.5),
  padding: theme.spacing(0.5),
  opacity: 0,
  transition: 'opacity 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: theme.palette.grey[500],
  },
}))

// Helper function to check if a value should be included
const shouldIncludeValue = (value: any): boolean => {
  if (value === null || value === undefined) {
    return false
  }
  if (Array.isArray(value)) {
    return value.length > 0
  }
  if (typeof value === 'object' && ('min' in value || 'max' in value)) {
    const { min, max } = value
    return (min !== null && min !== 0 && min !== '') || (max !== null && max !== 0 && max !== '')
  }
  return true
}

// Custom hook to optionally use RFP context
/**
 * Custom hook that attempts to use the RFP context, but gracefully falls back
 * to a default value if the context is not available.
 *
 * This is useful when a component is used both within and outside of an RFP context.
 *
 * @returns An object with the same shape as the RFP context, but with undefined values if the context is not available
 */
function useOptionalRfpContext() {
  try {
    return useRfpContext()
  } catch (error) {
    console.log('RFP context not available:', error)
    // Return a default object with the same shape as the RFP context
    return { sentRfpData: undefined }
  }
}

export default function PlantItemSpecs({
  entry,
  onUpdate,
  optionsEnums,
  editMode = false,
  disabled = false,
  targetPage,
  targetId,
}: {
  entry: PlantListEntry
  onUpdate?: (updatedPlant: PlantListEntry) => void
  optionsEnums?: Record<string, any>
  editMode?: boolean
  disabled?: boolean
  targetPage?: CommentPage
  targetId?: string
}) {
  // Use the optional RFP context
  const { sentRfpData } = useOptionalRfpContext()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleTrunkPluralityChange = (value: string) => {
    if (!onUpdate) {
      return
    }

    if (value === TrunkPlurality.SingleTrunk) {
      onUpdate({
        ...entry,
        trunk_form: {
          plurality: TrunkPlurality.SingleTrunk,
          trunk_count: { min: 1, max: 1 },
          clear_trunk_size: { min: 1, max: 1, unit: null },
        } as SingleTrunk,
      })
    } else {
      onUpdate({
        ...entry,
        trunk_form: {
          plurality: TrunkPlurality.MultiTrunk,
          trunk_count: { min: 1, max: 1 },
          cane_caliper: { min: 1, max: 1, unit: null },
          clear_trunk_size: { min: 1, max: 1, unit: null },
        } as MultiTrunk,
      })
    }
  }

  const handleTrunkCountChange = (value: RangeDimension) => {
    if (!entry.trunk_form || entry.trunk_form.plurality === TrunkPlurality.SingleTrunk || !onUpdate) {
      return
    }
    onUpdate({
      ...entry,
      trunk_form: {
        ...entry.trunk_form,
        trunk_count: value,
      },
    })
  }

  const handleCaneCaliperChange = (value: MeasurableRangeDimension) => {
    if (!entry.trunk_form || entry.trunk_form.plurality === TrunkPlurality.SingleTrunk || !onUpdate) {
      return
    }
    onUpdate({
      ...entry,
      trunk_form: {
        ...entry.trunk_form,
        cane_caliper: value,
      },
    })
  }

  const handleClearTrunkSizeChange = (value: MeasurableRangeDimension) => {
    if (!entry.trunk_form || !onUpdate) {
      return
    }
    onUpdate({
      ...entry,
      trunk_form: {
        ...entry.trunk_form,
        clear_trunk_size: value,
      },
    })
  }
  const handleSpecUpdate = (field: string, value: any) => {
    if (!onUpdate) {
      return
    }

    if (field === 'trunk_form.plurality') {
      handleTrunkPluralityChange(value as string)
    } else if (field === 'trunk_form.clear_trunk_size') {
      handleClearTrunkSizeChange(value as MeasurableRangeDimension)
    } else if (field === 'trunk_form.trunk_count') {
      handleTrunkCountChange(value as RangeDimension)
    } else if (field === 'trunk_form.cane_caliper') {
      handleCaneCaliperChange(value as MeasurableRangeDimension)
    } else {
      onUpdate({ ...entry, [field]: value })
    }
  }

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation()
  }

  type BaseSpec = {
    label: string
    field: string
    menuGroup?: string
    menuLabel?: string
    show?: boolean
  }

  type RangeSpec = BaseSpec & {
    type: 'range'
    value: RangeDimension | undefined
  }

  type MeasurableRangeSpec = BaseSpec & {
    type: 'measurableRange'
    value: MeasurableRangeDimension | undefined
    unitOptions: string[]
  }

  type SelectSpec = BaseSpec & {
    type: 'select'
    value: string | null | undefined
    options: string[]
  }

  type MultiSelectSpec = BaseSpec & {
    type: 'multiSelect'
    value: string[] | undefined
    options: string[]
  }

  type Spec = RangeSpec | MeasurableRangeSpec | SelectSpec | MultiSelectSpec

  // Define all possible specs (move existing specs array definition here)
  const allSpecs: Spec[] = [
    {
      label: 'Area',
      value: entry.surface_area,
      field: 'surface_area',
      type: 'measurableRange',
      unitOptions: optionsEnums?.imperialareameasurement,
      menuGroup: 'Size',
      menuLabel: 'Surface Area',
    },
    {
      label: 'Cont. Size',
      value: entry.container,
      field: 'container',
      type: 'measurableRange',
      unitOptions: optionsEnums?.imperialvolumemeasurement,
      menuGroup: 'Size',
      menuLabel: 'Container Size',
    },
    {
      label: 'Cal',
      value: entry.caliper,
      field: 'caliper',
      type: 'measurableRange',
      unitOptions: optionsEnums?.imperiallengthmeasurement,
      menuGroup: 'Size',
      menuLabel: 'Caliper',
    },
    {
      label: 'H',
      value: entry.height,
      field: 'height',
      type: 'measurableRange',
      unitOptions: optionsEnums?.imperiallengthmeasurement,
      menuGroup: 'Size',
      menuLabel: 'Height',
    },
    {
      label: 'W',
      value: entry.width,
      field: 'width',
      type: 'measurableRange',
      unitOptions: optionsEnums?.imperiallengthmeasurement,
      menuGroup: 'Size',
      menuLabel: 'Width',
    },
    {
      label: 'DBH',
      value: entry.dbh,
      field: 'dbh',
      type: 'measurableRange',
      unitOptions: optionsEnums?.imperiallengthmeasurement,
      menuGroup: 'Size',
      menuLabel: 'DBH',
    },
    {
      label: '',
      value: entry.trunk_form?.plurality,
      field: 'trunk_form.plurality',
      type: 'select',
      options: optionsEnums?.trunkplurality,
      menuGroup: 'Trunk',
      menuLabel: 'Trunk Form',
    },
    {
      label: 'Clr. Trunk',
      value: entry.trunk_form?.clear_trunk_size,
      field: 'trunk_form.clear_trunk_size',
      type: 'measurableRange',
      unitOptions: optionsEnums?.imperiallengthmeasurement,
    },
    {
      label: 'Trunk Cnt.',
      value: entry.trunk_form?.trunk_count,
      field: 'trunk_form.trunk_count',
      type: 'range',
      show: entry.trunk_form?.plurality !== TrunkPlurality.SingleTrunk,
    },
    {
      label: 'Cane Cal',
      value: (entry.trunk_form as MultiTrunk)?.cane_caliper,
      field: 'trunk_form.cane_caliper',
      type: 'measurableRange',
      unitOptions: optionsEnums?.imperiallengthmeasurement,
    },
    {
      label: 'Stage',
      value: entry.plant_stage,
      field: 'plant_stage',
      type: 'multiSelect',
      options: optionsEnums?.plantstage,
      menuGroup: 'Tags',
      menuLabel: 'Plant Stage',
    },
    {
      label: 'Palm Trunk',
      value: entry.palm_trunk,
      field: 'palm_trunk',
      type: 'multiSelect',
      options: optionsEnums?.palmtrunk,
      menuGroup: 'Tags',
      menuLabel: 'Palm Trunk',
    },
    {
      label: 'Chars.',
      value: entry.characteristics,
      field: 'characteristics',
      type: 'multiSelect',
      options: optionsEnums?.characteristics,
      menuGroup: 'Tags',
      menuLabel: 'Characteristics',
    },
    {
      label: 'Shape',
      value: entry.shape,
      field: 'shape',
      type: 'multiSelect',
      options: optionsEnums?.shape,
      menuGroup: 'Tags',
      menuLabel: 'Shape',
    },
    {
      label: 'Pkg. Cnt.',
      value: entry.package_count,
      field: 'package_count',
      type: 'range',
      menuGroup: 'Packaging',
      menuLabel: 'Package Count',
    },
    {
      label: 'Shipping',
      value: entry.shipping_container,
      field: 'shipping_container',
      type: 'select',
      options: optionsEnums?.shippingcontainer,
      menuGroup: 'Packaging',
      menuLabel: 'Shipping Container',
    },
    {
      label: 'Plant Cont.',
      value: entry.plant_container,
      field: 'plant_container',
      type: 'select',
      options: optionsEnums?.plantcontainer,
      menuGroup: 'Packaging',
      menuLabel: 'Plant Container',
    },
    {
      label: 'Root Pkg.',
      value: entry.root_packaging,
      field: 'root_packaging',
      type: 'select',
      options: optionsEnums?.rootpackaging,
      menuGroup: 'Packaging',
      menuLabel: 'Root Packaging',
    },
  ]

  // Filter shown specs as before
  const specs = allSpecs.filter((spec) => shouldIncludeValue(spec.value) && (spec.show === undefined || spec.show))

  // Get available specs to add (those not currently shown)
  const availableSpecs = allSpecs.filter(
    (spec) => !shouldIncludeValue(spec.value) && (spec.show === undefined || spec.show) && spec.menuGroup
  )

  // Add handler for adding a spec
  const handleAddSpec = (spec: any) => {
    // Initialize empty value based on spec type
    let initialValue
    switch (spec.type) {
      case 'range':
        initialValue = { min: 1, max: 1 }
        break
      case 'measurableRange':
        initialValue = { min: 1, max: 1, unit: spec.unitOptions[0] }
        break
      case 'select':
        initialValue = spec.options[0]
        break
      case 'multiSelect':
        initialValue = [spec.options[0]]
        break
    }
    handleSpecUpdate(spec.field, initialValue)
    handleMenuClose()
  }

  const handleRemoveSpec = (spec: Spec, event: MouseEvent) => {
    if (!onUpdate) {
      return
    }

    event.stopPropagation()
    if (spec.field.startsWith('trunk_form.')) {
      onUpdate({
        ...entry,
        trunk_form: null,
      })
    } else {
      const emptyValue = DEFAULT_PLANT_LIST_ENTRY[spec.field as keyof PlantListEntry]
      onUpdate({ ...entry, [spec.field]: emptyValue })
    }
  }

  const renderEditableField = (spec: Spec) => {
    const disabledStyles = disabled
      ? {
          color: theme.palette.text.disabled,
          opacity: 0.7,
          pointerEvents: 'none',
        }
      : {}

    return !editMode ? (
      <Typography variant="body2" sx={disabledStyles}>
        {spec.label && `${spec.label}: `}
        {spec.type === 'range' && spec.value && formatDimension(spec.value)}
        {spec.type === 'measurableRange' && spec.value && formatDimension(spec.value)}
        {spec.type === 'select' && spec.value}
        {spec.type === 'multiSelect' && spec.value && spec.value.join(', ')}
      </Typography>
    ) : (
      <Box sx={disabledStyles}>
        {spec.type === 'range' && spec.value && (
          <InlineEditableRangeDimension
            label={spec.label}
            value={spec.value}
            onSave={(newValue) => handleSpecUpdate(spec.field, newValue)}
            onClick={handleClick}
          />
        )}
        {spec.type === 'measurableRange' && spec.value && (
          <InlineEditableMeasurableRangeDimension
            label={spec.label}
            value={spec.value}
            onSave={(newValue) => handleSpecUpdate(spec.field, newValue)}
            unitOptions={spec.unitOptions}
            onClick={handleClick}
          />
        )}
        {spec.type === 'select' && spec.value && (
          <InlineEditableSelect
            label={spec.label}
            value={spec.value}
            options={spec.options}
            onSave={(newValue) => handleSpecUpdate(spec.field, newValue)}
            onClick={handleClick}
          />
        )}
        {spec.type === 'multiSelect' && spec.value && (
          <InlineEditableSelect
            label={spec.label}
            value={spec.value}
            options={spec.options}
            onSave={(newValue) => handleSpecUpdate(spec.field, newValue)}
            multiple
            onClick={handleClick}
          />
        )}
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {editMode && !disabled && (
        <IconButton size="small" onClick={handleMenuOpen} sx={{ mr: 1 }}>
          <Plus />
        </IconButton>
      )}
      {specs.map((spec, index) => {
        const specChip = (
          <EditableChip key={index} isEditable={editMode && !disabled}>
            {renderEditableField(spec)}
            {editMode && !disabled && (
              <RemoveButton className="remove-button" size="small" onClick={(e) => handleRemoveSpec(spec, e)}>
                <X size={16} />
              </RemoveButton>
            )}
          </EditableChip>
        )
        if (!targetId || !targetPage || !entry.id) {
          return specChip
        }
        return (
          <CommentableElement
            pathObject="plants"
            pathId={entry.id}
            targetPath={`plants.${spec.field}`}
            itemName={spec.menuLabel || spec.label || spec.field}
            targetPage={targetPage}
            targetId={targetId}
            directedOrganizationId={sentRfpData?.directed_organization?.id}
            key={index}
            sx={{ display: 'inline-flex', marginRight: 0, flexWrap: 'wrap' }}
          >
            {specChip}
          </CommentableElement>
        )
      })}
      <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose} onClick={(e) => e.stopPropagation()}>
        <Typography variant="body1" sx={{ pl: 2, fontWeight: 'bold' }}>
          Add Plant Spec:
        </Typography>
        {Object.entries(
          availableSpecs.reduce((groups: Record<string, typeof availableSpecs>, spec) => {
            const group = spec.menuGroup!
            if (!groups[group]) {
              groups[group] = []
            }
            groups[group].push(spec)
            return groups
          }, {})
        ).map(([group, groupSpecs]) => (
          <div key={group}>
            <Typography variant="body1" sx={{ pl: 2, fontWeight: 'bold' }}>
              {group}
            </Typography>
            {groupSpecs.map((spec: any, index: number) => (
              <MenuItem key={index} onClick={() => handleAddSpec(spec)} sx={{ pl: 4 }}>
                {spec.menuLabel || spec.label || spec.field}
              </MenuItem>
            ))}
          </div>
        ))}
      </Menu>
    </Box>
  )
}
