import { createFileRoute, Link, useNavigate } from '@tanstack/react-router'
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createSentRfp, getRfp, getSentRfpsByRfpId, updateSentRfp } from '@/api/rfps.ts'
import { excludeDeletedItems, getOrganizationNameFromContact, timeElapsedSince } from '@/lib/utils.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import PageLayout from '@/components/ui/layouts/page-layout.tsx'
import { useEffect, useState, useCallback } from 'react'
import { Circle, DescriptionOutlined } from '@mui/icons-material'
import { pluralizedLabel } from '@/lib/pluralize.ts'
import theme from '@/theme.ts'
import { CustomDivider } from '@/components/ui/base/dividers.tsx'
import {
  CustomLexicalEditorChanges,
  OrganizationContact,
  PlantListEntry,
  Quote,
  RfpCustomerStatus,
  RfpStatus,
  SentRFP,
  UserContact,
} from '@/types.ts'
import { ClearTextIconButtonGroup, TextIconButton } from '@/components/ui/base/buttons/text-icon-buttons.tsx'
import {
  BellRing,
  CalendarClock,
  ChevronDown,
  ChevronRight,
  Files,
  MessageSquareText,
  NotepadText,
  SendHorizonal,
} from 'lucide-react'
import { RfpEmailContactInfoPill } from '@/components/rfps/rfp-email-contact-info-pill.tsx'
import { CustomLexicalEditor } from '@/components/ui/texteditor/lexical/lexical_editor.tsx'
import { RFP_QUERY_KEYS } from '@/lib/query-keys.ts'
import { AxiosError } from 'axios'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications.ts'
import RfpSentSuccessModal from '@/components/ui/modals/rfp-sent-success-modal.tsx'
import { RfpSideDrawer } from '@/components/rfps/drawer/rfp-side-drawer.tsx'
import { RfpPageContentSkeleton } from '@/components/rfps/skeletons/rfp-page-content-skeleton.tsx'
import { RfpPageLoadingSkeleton } from '@/components/rfps/skeletons/rfp-page-loading-skeleton.tsx'
import { zodValidator } from '@tanstack/zod-adapter'
import { z } from 'zod'
import { CommentModeProvider } from '@/contexts/comment-mode-context.tsx'
import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import { useFeatureNotImplemented } from '@/hooks/useFeatureNotImplemented.tsx'
import { useSearchVendorModal } from '@/hooks/useSearchVendorModal.tsx'
import { DetailsPageRfpContent } from '@/components/rfps/details/details-page-rfp-content.tsx'
import { DetailsPageTitleBarActions } from '@/components/rfps/details/details-page-title-bar-actions.tsx'
import { useConfirmationModal } from '@/hooks/useConfirmationModal.tsx'
import SearchBar from '@/components/ui/base/search-bar.tsx'
import { ContactTypes } from '@/constants.ts'
import { useVendorContactModal } from '@/hooks/useVendorContactModal.tsx'
import { RfpVendorInfoCard } from '@/components/rfps/rfp-vendor-info-card.tsx'
import { RfpProvider } from '@/components/rfps/context/rfp-context.tsx'
import RfpPageContent from '@/components/rfps/draft/steps/rfp-page-content'

export const Route = createFileRoute('/$orgId/rfps/$rfpId')({
  component: RouteComponent,
  validateSearch: zodValidator(
    z.object({
      sent_rfp: z.string().optional(),
      modal: z.string().optional(),
    })
  ),
})

type RfpItemCountInfo = {
  totalItems: number
  totalQty: number
}
type ItemCountSummary = {
  rfp: RfpItemCountInfo
  sentRfp: Record<string, RfpItemCountInfo>
}
function RouteComponent() {
  const { rfpId, orgId } = Route.useParams()
  const sent_rfp = Route.useSearch({
    select: (state) => state.sent_rfp,
  })

  const [notSentRfps, setNotSentRfps] = useState<SentRFP[]>([])
  const [quoteReceivedRfps, setQuoteReceivedRfps] = useState<SentRFP[]>([])
  const [awaitingResponseRfps, setAwaitingResponseRfps] = useState<SentRFP[]>([])
  const [rfpItemCountSummary, setRfpItemCountSummary] = useState<ItemCountSummary>()
  const [searchBarResultContacts, setSearchBarResultContacts] = useState<OrganizationContact[]>([])

  const queryClient = useQueryClient()
  const { selectedOrganization } = useOrganization()
  const navigate = useNavigate()
  const { ConfirmationModal, openConfirmationModal } = useConfirmationModal()
  const [selectedSentRfpId, setSelectedSentRfpId] = useState(sent_rfp || null)
  const notifyFeatureNotImplemented = useFeatureNotImplemented()
  const { openSearchVendorModal, closeSearchVendorModal, SearchVendorModal } = useSearchVendorModal()
  const { openVendorContactModal, closeVendorContactModal, updateVendorContactModal, VendorContactModal } =
    useVendorContactModal()
  const { addToastNotification } = useToastNotifications()

  const { data: sentRfpData, isLoading: sentRfpDataLoading } = useQuery({
    queryKey: RFP_QUERY_KEYS.sentRfps(rfpId, selectedOrganization?.id),
    queryFn: async () => {
      if (!rfpId || !selectedOrganization?.id) return []

      const rfps: SentRFP[] = excludeDeletedItems(
        (await getSentRfpsByRfpId(rfpId, selectedOrganization?.id as string)) || []
      )

      // reset selected sent rfp id if not found in the list
      if (selectedSentRfpId && !rfps.find((rfp) => rfp.id === selectedSentRfpId)) {
        setSelectedSentRfpId(null)
      }

      // set rfp data by status categories
      setNotSentRfps(rfps.filter((rfp) => rfp.status === RfpStatus.PENDING))
      setAwaitingResponseRfps(rfps.filter((rfp) => rfp.status === RfpStatus.SENT))
      setQuoteReceivedRfps(rfps.filter((rfp) => rfp.customer_status === RfpCustomerStatus.QUOTED))

      return rfps
    },
    enabled: !!selectedOrganization && !!rfpId,
  })

  const { data: rfpData, isLoading: rfpDataLoading } = useQuery({
    queryKey: RFP_QUERY_KEYS.draftRfp(rfpId),
    queryFn: async () => {
      return await getRfp(rfpId)
    },
    enabled: !!rfpId,
  })

  useEffect(() => {
    setSelectedSentRfpId(sent_rfp || '')

    const rfpItems = excludeDeletedItems(rfpData?.plants || [])
    const rfpItemIds = rfpItems.flatMap((item) => item.id)

    // rfp count info
    const rfpItemsCount = rfpItemIds.length
    const rfpQtyCount = rfpItems.reduce(
      (acc, plant) => acc + (plant.quantity_count.min || plant.quantity_count.max || 0),
      0
    )

    // sent rfp count info
    const sentRfpCountInfo = (sentRfpData || [])?.reduce((acc: Record<string, RfpItemCountInfo>, rfp) => {
      const matching_plants = rfp.plants.filter((plant: PlantListEntry) => rfpItemIds.includes(plant.id))
      acc[rfp.id] = {
        totalItems: matching_plants.length,
        totalQty: matching_plants.reduce(
          (acc, plant) => acc + (plant.quantity_count.min || plant.quantity_count.max || 0),
          0
        ),
      }
      return acc
    }, {})

    // set rfp count info summary
    const summary: ItemCountSummary = {
      rfp: {
        totalItems: rfpItemsCount,
        totalQty: rfpQtyCount,
      },
      sentRfp: sentRfpCountInfo,
    }
    setRfpItemCountSummary(summary)
  }, [sent_rfp, sentRfpData, rfpData, searchBarResultContacts])

  const handleSearchResults = useCallback((results: any) => {
    const newContacts = (results?.items || []).map((item: any) => item.id)
    setSearchBarResultContacts(newContacts)
  }, [])

  const updateSentRfpMutation = useMutation({
    mutationFn: updateSentRfp,
    onError: async (error: AxiosError) => {
      addToastNotification({
        severity: 'error',
        title: 'Error updating RFP',
        message: (error.response?.data as { detail?: string })?.detail || error.message,
      })
    },
  })

  const createSentRfpMutation = useMutation({
    mutationFn: createSentRfp,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: RFP_QUERY_KEYS.sentRfps(rfpId, selectedOrganization?.id) })
      await queryClient.invalidateQueries({ queryKey: RFP_QUERY_KEYS.draftRfp(rfpId) })
    },
    onError(error: Error) {
      console.error('Error creating sent RFP:', error)
    },
  })

  // handle invalid rfpId and loading state
  if (!rfpId) return null
  if (rfpDataLoading || sentRfpDataLoading) return <RfpPageLoadingSkeleton showVendorSidebar={true} />

  const MainRFPDetailsCard = () => {
    const isSelected = !selectedSentRfpId
    const totalPlants = rfpItemCountSummary?.rfp?.totalItems || 0
    const totalQty = rfpItemCountSummary?.rfp?.totalQty || 0

    return (
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          py: 1,
          pl: 2,
          pr: 0.5,
          backgroundColor: isSelected ? theme.palette.lightGrey3.main : 'transparent',
          borderRadius: isSelected ? theme.borderRadius.md : 0,
        }}
      >
        <Link to={`/$orgId/rfps/$rfpId`} params={{ orgId, rfpId }}>
          <Stack direction="row" sx={{ gap: 1, cursor: 'pointer', flexGrow: 1 }}>
            <DescriptionOutlined />
            <Stack>
              <Typography variant="body1" fontWeight={700}>
                View RFP Details
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {pluralizedLabel(totalPlants, 'Plant', 'Plants')}, {`${totalQty} Total QTY`}
              </Typography>
            </Stack>
          </Stack>
        </Link>
      </Stack>
    )
  }

  const handleAddVendorContacts = async (orgContact: OrganizationContact | null, userContacts: UserContact[]) => {
    if (!rfpData || !orgContact || userContacts.length === 0) return false

    const orgName = getOrganizationNameFromContact(orgContact, 'Unknown')

    try {
      await createSentRfpMutation.mutateAsync({
        rfpId: rfpData.id,
        organizationId: rfpData.organization.id,
        directed_organizations: [
          {
            directed_organization_contact: orgContact.id,
            directed_organization_user_contacts: userContacts.map((contact) => contact.id),
            send: false,
          },
        ],
        plants: rfpData.plants,
      })

      // Success notification
      addToastNotification({
        severity: 'success',
        title: 'RFP Updated Successfully',
        message: `${orgName} has been successfully added to RFP# ${rfpData.id}`,
      })

      return true
    } catch {
      // Error notification
      addToastNotification({
        severity: 'error',
        title: 'RFP Error',
        message: `Failed to add ${orgName} to RFP# ${rfpData.id}`,
      })

      return false
    }
  }

  const sentRfpExistsForOrgContact = (orgContact: OrganizationContact) => {
    return (sentRfpData || []).some((rfp) => rfp.directed_organization_contact.id === orgContact.id)
  }

  const handleVendorSelection = async (orgContacts: OrganizationContact[]) => {
    if (!orgContacts || orgContacts.length === 0) return

    const orgContact = orgContacts[0]
    const orgName = getOrganizationNameFromContact(orgContact)

    // validate vendor has not already been added
    if (sentRfpExistsForOrgContact(orgContact)) {
      addToastNotification({
        severity: 'error',
        title: 'Vendor Already Exists',
        message: `${orgName} has already been added to RFP #${rfpId}`,
      })
      return
    }

    const handleSubmission = async (contacts: UserContact[]) => {
      updateVendorContactModal({ loading: true })
      const submitted = await handleAddVendorContacts(orgContact, contacts)
      submitted ? closeVendorContactModal() : updateVendorContactModal({ loading: false })
    }

    const handleBackNavigation = () => {
      closeVendorContactModal()
      invokeSearchVendorModal(orgName)
    }

    openVendorContactModal({
      vendorContact: orgContact,
      title: 'Add Vendor to RFP',
      cancelButtonText: 'Cancel & Close',
      submitButtonText: 'Add Vendor to RFP',
      multiSelect: true,
      loading: false,
      onSubmit: async (contacts: UserContact[]) => await handleSubmission(contacts),
      onBackNavigate: () => handleBackNavigation(),
    })
  }

  function invokeSearchVendorModal(searchText?: string) {
    openSearchVendorModal({
      searchText: searchText || '',
      title: 'Add Vendor to RFP',
      cancelButtonText: 'Cancel & Close',
      submitButtonText: 'Add Vendor to RFP',
      multiSelect: false,
      onSubmit: async (vendors) => {
        closeSearchVendorModal()
        await handleVendorSelection(vendors)
      },
    })
  }

  const CategorizedSentRFPDetailCards = () => {
    const contents = [
      {
        status: 'notsent',
        label: 'Not Sent',
        rfps: notSentRfps,
        color: theme.palette.mediumRed1.main,
        defaultText: 'No RFPs has be sent',
      },
      {
        status: 'pending',
        label: 'Awaiting Response',
        rfps: awaitingResponseRfps,
        color: theme.palette.mediumYellow1.main,
        defaultText: 'No RFPs are awaiting response',
      },
      {
        status: 'quoted',
        label: 'Quote Received',
        rfps: quoteReceivedRfps,
        color: theme.palette.mediumGreen1.main,
        defaultText: 'No RFPs have received quotes',
      },
    ]

    return (
      <Stack sx={{ overflowY: 'auto', flexGrow: 1, scrollbarWidth: 'none' }}>
        {contents.map((item) => (
          <Accordion key={item.label} sx={{ boxShadow: 'none' }} defaultExpanded={true}>
            <AccordionSummary
              sx={{ p: 0, gap: 1, margin: 0, flexDirection: 'row-reverse' }}
              expandIcon={<ChevronDown />}
              aria-controls="panel-content"
              id="panel-header"
            >
              <Stack direction="row" sx={{ gap: 1, alignItems: 'center' }}>
                <Circle sx={{ color: item.color, fontSize: 12 }} />
                <Typography variant="body2" sx={{ color: theme.palette.mediumGrey2.main, fontWeight: 400 }}>
                  {item.label}
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0, border: 'none', boxShadow: 'none' }}>
              {item.rfps.length ? (
                item.rfps.map((rfp) => {
                  const rfpItemCountInfo = rfpItemCountSummary?.sentRfp[rfp.id]
                  const plantCountInfo = rfpItemCountInfo
                    ? `${rfpItemCountInfo.totalItems} Plants, ${rfpItemCountInfo.totalQty} Total QTY`
                    : 'n/a'
                  return (
                    <RfpVendorInfoCard
                      key={rfp.id}
                      rfpId={rfpId}
                      selectedRfpId={selectedSentRfpId}
                      info={plantCountInfo}
                      rfp={rfp}
                      onClick={() =>
                        navigate({
                          to: `/$orgId/rfps/$rfpId`,
                          params: { orgId, rfpId },
                          search: { sent_rfp: rfp.id },
                        })
                      }
                      openConfirmationModal={openConfirmationModal}
                    />
                  )
                })
              ) : (
                <Stack
                  sx={{
                    p: 1,
                    borderTop: `1px solid ${theme.palette.lightGrey2.main}`,
                  }}
                >
                  <Typography key="default" variant="body2">
                    {item.defaultText}
                  </Typography>
                </Stack>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    )
  }

  const SentRfpContent = ({ sentRfpId }: { sentRfpId: string }) => {
    const [successModalRfp, setSuccessModalRfp] = useState<SentRFP[]>([])
    const sentRfp = sentRfpData?.find((rfp) => rfp.id === sentRfpId)
    const isRfpNotSent = sentRfp?.status === RfpStatus.PENDING
    const isRfpQuoted = sentRfp?.customer_status === RfpCustomerStatus.QUOTED
    const isRfpAwaitingResponse = !(isRfpNotSent && isRfpQuoted)

    // Todo - replace with actual quotes data when available
    const quotesData: Quote[] = [{ id: '1111' }, { id: '2222' }]

    if (rfpDataLoading || sentRfpDataLoading) return <RfpPageContentSkeleton />
    if (!sentRfp || !rfpData) return null

    const handleSuccessModalClose = async () => {
      setSuccessModalRfp([])
      await queryClient.invalidateQueries({
        queryKey: RFP_QUERY_KEYS.sentRfps(rfpData?.id, selectedOrganization?.id),
      })
    }

    const handleSendToVendor = async () => {
      if (!sentRfp) return

      const updates = {
        send: true,
      }
      await updateSentRfpMutation.mutateAsync({
        sentRfpId: sentRfpId,
        data: updates,
      })
      setSuccessModalRfp([sentRfp])
    }

    const onUpdateSentRfpData = async (updates: Partial<SentRFP>) => {
      if (!sentRfpId || !updates) return

      await updateSentRfpMutation.mutateAsync({
        sentRfpId: sentRfpId,
        data: updates,
      })
    }

    const handleEditorChanges = async (data: CustomLexicalEditorChanges) => {
      if (data.htmlString === sentRfp.notification_body) return
      const defaultSubject = `RFP# ${rfpData?.id}`

      const updatesData = {
        notification_body: data.htmlString,
        notification_subject: defaultSubject,
      }
      await onUpdateSentRfpData(updatesData)
    }

    const handleNote = () => {
      notifyFeatureNotImplemented()
    }

    const handleComment = () => {
      notifyFeatureNotImplemented()
    }

    const handleSendReminder = () => {
      notifyFeatureNotImplemented()
    }

    return (
      <Stack sx={{ gap: 4, p: 5, flexGrow: 1 }}>
        <Stack direction="row" sx={{ justifyContent: 'space-between', position: 'sticky', top: 0 }}>
          <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center' }}>
            {sentRfp.directed_organization.name || 'N/A'}
          </Typography>
          {isRfpNotSent ? (
            <TextIconButton
              text="Send to Vendor"
              onClick={handleSendToVendor}
              startIcon={<SendHorizonal size={18} color="white" />}
              variant="contained"
              color="white"
              bgColor={theme.palette.primary.main}
              sx={{ px: 3, borderRadius: theme.borderRadius.md }}
            />
          ) : (
            <ClearTextIconButtonGroup
              items={[
                {
                  text: 'Add Note',
                  startIcon: <NotepadText size={20} />,
                  onClick: () => handleNote(),
                },
                {
                  text: 'Comment',
                  startIcon: <MessageSquareText size={20} />,
                  onClick: () => handleComment(),
                },
              ]}
              sx={{ backgroundColor: 'white' }}
            />
          )}
        </Stack>
        {isRfpQuoted && (
          <Stack sx={{ gap: 1.5 }}>
            {quotesData.map((quote: Quote) => (
              <Stack
                key={quote.id}
                direction="row"
                sx={{
                  justifyContent: 'space-between',
                  backgroundColor: 'white',
                  borderRadius: theme.borderRadius.lg,
                  p: 3,
                }}
              >
                <Stack direction="row" sx={{ gap: 2, alignItems: 'center' }}>
                  <Files size={20} color={theme.palette.mediumGrey2.main} />
                  <Typography variant="body1">Quote - #</Typography>
                  <Typography variant="body2" color={theme.palette.mediumGrey2.main}>
                    Placed xxx
                  </Typography>
                </Stack>
                <Stack direction="row" sx={{ gap: 2, alignItems: 'center' }}>
                  <Typography variant="body2" fontWeight={700}>
                    Total Price:
                  </Typography>
                  <Typography variant="body2">$$$$</Typography>
                  <ChevronRight size={24} color={theme.palette.mediumGrey2.main} />
                </Stack>
              </Stack>
            ))}
          </Stack>
        )}
        <Stack sx={{ gap: 4, flexGrow: 1, overflow: 'auto', scrollbarWidth: 'none', height: 0, position: 'relative' }}>
          {isRfpNotSent ? (
            <Stack sx={{ backgroundColor: 'white', gap: 1, p: 4, borderRadius: theme.borderRadius.lg }}>
              <Stack direction="row" sx={{ gap: 1, alignItems: 'center' }}>
                <Typography variant="strong">To:</Typography>
                {sentRfp.directed_organization_user_contacts.map((contact: any) => (
                  <RfpEmailContactInfoPill key={contact.id} contact={contact} />
                ))}
              </Stack>
              <CustomLexicalEditor
                showToolbar={true}
                showFooter={false}
                onCancel={() => {}}
                onChange={handleEditorChanges}
                initialHtml={sentRfp.notification_body || ''}
              />
            </Stack>
          ) : null}
          <Stack sx={{ backgroundColor: 'white', gap: 1, p: 2, borderRadius: theme.borderRadius.lg }}>
            {isRfpAwaitingResponse && (
              <Stack sx={{ gap: 4, pt: 4, px: 5.5 }}>
                <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <Stack direction="row" sx={{ gap: 1, color: theme.palette.mediumGrey.main }}>
                    <CalendarClock size={20} />
                    <Typography variant="body1">Sent {timeElapsedSince(sentRfp.sent_at || '')}</Typography>
                  </Stack>
                  <Stack direction="row" sx={{ gap: 1 }}>
                    <TextIconButton
                      text="Send Reminder"
                      onClick={handleSendReminder}
                      startIcon={<BellRing size={16} color={theme.palette.mediumGrey.main} />}
                      bgColor={theme.palette.lightGrey3.main}
                      sx={{
                        px: 3,
                        border: `1px solid ${theme.palette.lightGrey2.main}`,
                        borderRadius: theme.borderRadius.md,
                      }}
                    />
                    <Button variant="contained" onClick={() => notifyFeatureNotImplemented()}>
                      Quote on Behalf of Vendor
                    </Button>
                  </Stack>
                </Stack>
                <CustomDivider size="1" color={theme.palette.lightGrey2.main} />
              </Stack>
            )}
            <RfpProvider initialRfpData={rfpData} onUpdateRfpData={() => {}} sentRfp={sentRfp}>
              <RfpPageContent showHeader={false} displayEditButton={isRfpNotSent} />
            </RfpProvider>
          </Stack>
        </Stack>
        <RfpSentSuccessModal
          open={successModalRfp.length > 0}
          onClose={handleSuccessModalClose}
          rfpId={rfpData.id}
          sentRfps={successModalRfp}
        />
      </Stack>
    )
  }

  return (
    <PageLayout
      title={`RFP #${rfpId}`}
      backRoute={{ to: '/$orgId/rfps' }}
      pageLayoutSx={{ p: 0 }}
      appBarSx={{ pl: 3, pt: 4, pb: 1 }}
      titleBarElement={
        <DetailsPageTitleBarActions
          rfpData={rfpData}
          sentRfpData={sentRfpData?.find((rfp) => rfp.id === selectedSentRfpId)}
        />
      }
    >
      <Stack direction="row" sx={{ backgroundColor: theme.palette.lightGrey3.main, height: '100%' }}>
        <Stack sx={{ gap: 2, width: '340px', p: 3, backgroundColor: 'white' }}>
          <MainRFPDetailsCard />
          <CustomDivider />
          <Stack direction="row" gap={1} pt={1}>
            <SearchBar
              placeholder="Search vendors ..."
              searchUrl={`/v1/core/contact/${ContactTypes.ORGANIZATION}/search/`}
              perPage={20}
              page={1}
              requiredFields={{ deleted_at: null }}
              onSearchResults={handleSearchResults}
              searchType="organization_contacts"
            />
            <Button
              variant="contained"
              sx={{ p: 1, minWidth: '40px', borderRadius: theme.borderRadius.sm }}
              onClick={() => invokeSearchVendorModal()}
            >
              <AddIcon />
            </Button>
          </Stack>
          <CategorizedSentRFPDetailCards />
        </Stack>
        <Stack direction="row" sx={{ flexGrow: 1 }}>
          <CommentModeProvider>
            {selectedSentRfpId ? (
              <SentRfpContent sentRfpId={selectedSentRfpId} />
            ) : (
              <DetailsPageRfpContent rfpData={rfpData} rfpDataLoading={rfpDataLoading} />
            )}
          </CommentModeProvider>
        </Stack>
        <RfpSideDrawer />
      </Stack>
      {ConfirmationModal}
      <SearchVendorModal />
      <VendorContactModal />
    </PageLayout>
  )
}
