import { useCallback, useState, MouseEvent } from 'react'
import { GroupedRFPMenu } from '../index/grouped-menu'
import { RFP } from '@/api/types/rfps'

type PickFields =
  | 'rfp_id'
  | 'flagged_as_important_by_customer'
  | 'flagged_as_important_by_vendor'
  | 'state'
  | 'unread_comments_by_vendor'
  | 'unread_comments_by_customer'
export type GroupMenuData = Pick<RFP, PickFields>[]

export interface GroupedMenuState {
  rfps: GroupMenuData | null
  anchorEl: HTMLElement | null
}
/**
 * Implement in conjunction with useGroupedMenu
 * This component will be exported from the useGroupedMenu hook
 *  Usage: `const { GroupedMenu, menuState, openMenu, closeMenu } = useGroupedMenu()`
 */
export function useGroupedMenu() {
  const [menuState, setMenuState] = useState<GroupedMenuState>({
    rfps: null,
    anchorEl: null,
  })

  const openMenu = useCallback((event: MouseEvent<HTMLElement>, rfps: GroupMenuData) => {
    event.stopPropagation()
    setMenuState({ anchorEl: event.currentTarget, rfps })
  }, [])

  const closeMenu = useCallback(() => {
    setMenuState({ anchorEl: null, rfps: null })
  }, [])

  return {
    menuState,
    openMenu,
    closeMenu,
    GroupedRFPMenu,
  }
}
