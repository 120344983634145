import theme from '@/theme.ts'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import { Job, RequestForProposal, SentRFP } from '@/types.ts'
import { useMemo } from 'react'
import { excludeDeletedItems, formatStringToShortMonthDayYear, groupByKey } from '@/lib/utils.ts'
import { OrganizationDetails } from '@/components/ui/organization-details.tsx'
import Typography from '@mui/material/Typography'
import RequestPricingHeader from '@/components/rfps/draft/steps/rfp-page-content/components/request-pricing-header.tsx'
import { RFPDetails } from '@/components/rfps/draft/steps/build-rfp-step-content-rfp-details.tsx'
import { JobDetails } from '@/components/rfps/draft/steps/build-rfp-step-content-job-details.tsx'
import { CustomDivider } from '@/components/ui/base/dividers.tsx'
import RfpJobPhasePlantTable from '@/components/rfps/draft/steps/rfp-page-content/components/rfp-job-phase-plant-table.tsx'
import Box from '@mui/material/Box'
import RFPComments from '@/components/rfps/rfp-comments.tsx'
import VisibilityCallout from '@/components/rfps/visibility-callout.tsx'

export const ViewVendorRfpModal = ({
  open,
  onClose,
  sentRfp,
  rfpData,
}: {
  open: boolean
  onClose: () => void
  sentRfp: SentRFP | undefined
  rfpData: RequestForProposal
}) => {
  const plants = useMemo(() => excludeDeletedItems(sentRfp?.plants || []), [sentRfp])
  const plantsByJobGroup = useMemo(() => {
    return groupByKey(plants, 'job_id') || {}
  }, [plants])

  // if sentRfp is undefined, return null instead of nothing
  if (!sentRfp) {
    return null
  }

  const jobGroups =
    excludeDeletedItems(rfpData.jobs).reduce(
      (acc: Record<string, Job>, job: Job) => {
        acc[job.id] = job
        return acc
      },
      {} as Record<string, any>
    ) || {}

  const selectedPlantIds = new Set(plants.map((plant) => plant.id))
  const rfpOrganization = rfpData.organization || {}
  const rfpJobVisibility = sentRfp.rfp_settings.show_job_name_to_vendor || false
  const rfpPhaseVisibility = sentRfp.rfp_settings.show_phases_on_jobs_to_vendor || false

  // return modal
  return (
    <Modal open={open} onClose={onClose} closeAfterTransition aria-labelledby="view-vendor-rfp-modal-title">
      <Stack
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 1024,
          backgroundColor: 'background.paper',
          boxShadow: 24,
          p: 5,
          gap: 4,
          borderRadius: theme.borderRadius.md,
        }}
        onClick={(e) => e.stopPropagation()} // Prevent clicks from bubbling to the Modal
      >
        <Stack sx={{ gap: 2, maxHeight: 700, overflowY: 'auto', scrollbarWidth: 'none' }}>
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <OrganizationDetails organization={rfpOrganization} />
            <Typography variant="h3" fontWeight={400} sx={{ color: theme.palette.mediumGrey.main }}>
              RFP# {sentRfp.id}
            </Typography>
          </Stack>

          {/* RFP Details & Job Details */}
          <Stack direction="row" sx={{ justifyContent: 'space-between', gap: 2 }}>
            <RFPDetails openEditRfpModal={() => {}} rfpData={rfpData} editMode={false} />
            <JobDetails rfpData={sentRfp} />
          </Stack>

          {/* Jobs/Phases & Group */}
          {Object.keys(jobGroups).length ? (
            <Stack key="rfp-job-groups">
              {/* Header with summary on plant & item count */}
              <RequestPricingHeader
                rfpData={rfpData}
                sentRfpId={sentRfp?.id}
                plants={plants}
                selectedPlantIds={selectedPlantIds}
                sx={{ pl: 2, mb: 2 }}
              />
              <CustomDivider />
              {Object.entries(jobGroups).map(([jobId, data]: [string, unknown]) => {
                const jobData = data as Job
                const jobPlants = plantsByJobGroup[jobId] || []

                if (jobPlants.length === 0) return null

                const expectedJobStartDate = formatStringToShortMonthDayYear(jobData.job_date as string) || 'TBD'
                return (
                  <Stack key={`rfp-job-${jobId}-${sentRfp?.id}`}>
                    <Stack direction="row" sx={{ pt: 4, pb: 1, pl: 2, pr: 0, justifyContent: 'space-between' }}>
                      <Stack direction="row" sx={{ gap: 1, alignItems: 'center' }}>
                        <Stack direction="row" sx={{ gap: 1, alignItems: 'center' }}>
                          <Typography fontWeight={700} variant="body1">
                            Job #{jobId}
                          </Typography>
                          {rfpJobVisibility && (
                            <VisibilityCallout
                              editable={false}
                              visible={true}
                              itemName={jobData.name || 'Unnamed Job'}
                            />
                          )}
                        </Stack>
                      </Stack>
                      <Stack direction="row" sx={{ alignItems: 'center' }}>
                        {/* Expected Start Date */}
                        <Typography
                          sx={{ mr: 2 }}
                          fontWeight={400}
                          variant="body2"
                          color={theme.palette.mediumGrey.main}
                        >
                          Expected Start Date: {expectedJobStartDate}
                        </Typography>
                      </Stack>
                    </Stack>

                    <CustomDivider color={theme.palette.mediumGrey3.main} />

                    {jobPlants.length > 0 ? (
                      <RfpJobPhasePlantTable
                        key={`rfp-job-${jobId}-${sentRfp?.id}`}
                        jobId={jobId}
                        phaseVisible={rfpPhaseVisibility}
                        jobGroups={jobGroups}
                        plantsByJobGroup={plantsByJobGroup}
                        editMode={false}
                        vendorView={true}
                      />
                    ) : (
                      <Box>No RFP Content</Box>
                    )}
                  </Stack>
                )
              })}
            </Stack>
          ) : null}
          {/* Comments to all vendors */}
          <Stack direction="row" sx={{ justifyContent: 'space-between', gap: 2, mt: 2 }}>
            <RFPComments
              title="General Comments (for All Vendors)"
              name="vendorComments"
              value={rfpData.comments_for_vendor || ''}
              placeholder="None"
              onChange={() => {}}
              editMode={false}
            />
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  )
}
