import { Settings } from 'lucide-react'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications.ts'
import { RequestForProposal, RfpState, RfpStatus } from '@/types.ts'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteRfp, updateRfp } from '@/api/rfps.ts'
import * as Sentry from '@sentry/react'
import { useNavigate, useParams } from '@tanstack/react-router'
import { CustomMenuDropdown } from '@/components/ui/base/dropdowns/custom-menu-dropdown.tsx'
import { Box } from '@mui/material'
import theme from '@/theme.ts'
import { useState } from 'react'
import { useFeatureNotImplemented } from '@/hooks/useFeatureNotImplemented.tsx'
import { useUserInfo } from '@/contexts/hooks/useUserInfo.ts'
import { checkResourceDeletePermission } from '@/components/util/resource-permissions.ts'
import { RFP_QUERY_KEYS } from '@/lib/query-keys.ts'

type HandleErrorProps = {
  error: Error
  label: string
  title: string
}

interface RfpSettingsMenuProps {
  rfpData: RequestForProposal
}

export const RfpSettingsMenu = ({ rfpData }: RfpSettingsMenuProps) => {
  const { userInfo } = useUserInfo()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const orgId = useParams({ select: (params) => params.orgId, from: '/$orgId' })
  const { addToastNotification } = useToastNotifications()
  const notifyFeatureNotImplemented = useFeatureNotImplemented()
  const [successMessage, setSuccessMessage] = useState('')

  // determine if rfp can be deleted
  const isArchived = rfpData.state === RfpState.ARCHIVED
  const isNotPublished = rfpData.status === RfpStatus.PENDING
  const hasDeletePermission = checkResourceDeletePermission(rfpData, userInfo?.user)
  const allowDeletion = hasDeletePermission && isNotPublished && isArchived

  const handleError = ({ error, label, title }: HandleErrorProps) => {
    addToastNotification({
      severity: 'error',
      title: title,
      message: error.message,
    })
    Sentry.captureException(error, {
      tags: {
        action: label,
        rfpId: rfpData.id,
      },
    })
  }

  const deleteRfpMutation = useMutation({
    mutationFn: (rfpId: string) => deleteRfp(rfpId),
    onSuccess: async () => {
      addToastNotification({
        severity: 'success',
        title: 'RFP Deleted',
        message: `The RFP #${rfpData.id} has been successfully deleted`,
      })
      await navigate({ to: '/$orgId/rfps', params: { orgId } })
    },
    onError: (error) => {
      handleError({
        error,
        label: 'delete',
        title: `Error deleting RFP #${rfpData.id}`,
      })
    },
  })

  const updateRfpMutation = useMutation({
    mutationFn: ({ rfpId, data }: { rfpId: string; data: RequestForProposal }) => updateRfp(rfpId, data),
    onSuccess: async () => {
      addToastNotification({
        severity: 'success',
        title: 'RFP Updated',
        message: successMessage || `The RFP #${rfpData.id} has been successfully updated`,
      })
      await queryClient.invalidateQueries({ queryKey: RFP_QUERY_KEYS.draftRfp(rfpData.id) })
    },
    onError: (error) => {
      handleError({
        error,
        label: 'update',
        title: `Error updating RFP #${rfpData.id}`,
      })
    },
  })

  const handleRfpSettings = async (key: string) => {
    const rfpSettings = rfpData.rfp_settings

    // update phase visibility settings
    if (key === 'show_phases_on_jobs_to_vendor') {
      rfpSettings.show_phases_on_jobs_to_vendor = !rfpSettings.show_phases_on_jobs_to_vendor
      setSuccessMessage(
        rfpSettings.show_phases_on_jobs_to_vendor
          ? 'All Job Phases are now visible to Vendors in this RFP'
          : 'All Job Phases are now hidden from Vendors in this RFP'
      )
    }

    // update job name visibility settings
    if (key === 'show_job_names_to_vendor') {
      rfpSettings.show_job_name_to_vendor = !rfpSettings.show_job_name_to_vendor
      setSuccessMessage(
        rfpSettings.show_job_name_to_vendor
          ? 'All Job names are now visible to Vendors in this RFP'
          : 'All Job names are now hidden from Vendors in this RFP'
      )
    }

    const updatedRfp: RequestForProposal = { ...rfpData, rfp_settings: rfpSettings }

    await updateRfpMutation.mutateAsync({ rfpId: rfpData.id, data: updatedRfp })
  }

  const handleArchiveRfp = async () => {
    setSuccessMessage(`RFP #${rfpData.id} has been successfully archived`)
    await updateRfpMutation.mutateAsync({ rfpId: rfpData.id, data: { ...rfpData, state: RfpState.ARCHIVED } })
  }

  const handleDeleteRfp = async () => {
    // alert user if rfp is published or unarchived
    if (!allowDeletion) {
      addToastNotification({
        severity: 'warning',
        title: 'RFP Deletion Error',
        message: 'Published RFPs can not be deleted',
      })
      return
    }

    // proceed with deletion
    await deleteRfpMutation.mutateAsync(rfpData.id)
  }

  const handleEditRfp = () => {
    navigate({
      to: '.',
      search: (prev) => {
        return {
          ...prev,
          modal: 'edit-rfp-details-modal',
        }
      },
      replace: true,
    })
  }

  const category = 'Customize RFP'

  const manageCollaboratorsText = rfpData.collaborators.length
    ? `Manage collaborators (${rfpData.collaborators.length}) ...`
    : `Manage collaborators`

  const menuItems = [
    {
      label: 'Request Images for all items',
      onClick: () => notifyFeatureNotImplemented(),
      category,
    },
    {
      label: rfpData.rfp_settings.show_job_name_to_vendor ? 'Hide Job Names from Vendor' : 'Show Job Names to Vendor',
      onClick: () => handleRfpSettings('show_job_names_to_vendor'),
      category,
    },
    {
      label: 'Show Phase Names(s) to Vendor',
      onClick: () => notifyFeatureNotImplemented(),
      category,
    },
    {
      label: rfpData.rfp_settings.show_phases_on_jobs_to_vendor ? 'Hide Phase from Vendor' : 'Show Phase to Vendor',
      onClick: () => handleRfpSettings('show_phases_on_jobs_to_vendor'),
      category,
      divider: true,
    },
    {
      label: 'Edit RFP',
      onClick: handleEditRfp,
      category,
    },
    {
      label: manageCollaboratorsText,
      onClick: handleEditRfp,
      category,
    },
    {
      label: 'Archive RFP',
      onClick: handleArchiveRfp,
      category,
      disabled: isArchived,
      divider: true,
    },
    {
      label: 'Delete this RFP',
      category,
      disabled: !isArchived,
      onClick: handleDeleteRfp,
      sx: { color: theme.palette.mediumRed2.main },
    },
  ]

  if (!rfpData) return null

  const labelNode = (
    <Box
      sx={{
        padding: '10px 12px',
        border: '1px solid',
        borderRadius: theme.borderRadius.sm,
        borderColor: theme.palette.lightGrey2.main,
        color: theme.palette.black.main,
        backgroundColor: theme.palette.lightGrey3.main,
      }}
    >
      <Settings size={20} />
    </Box>
  )

  return <CustomMenuDropdown labelNode={labelNode} menuItems={menuItems} />
}
