import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ChangeEvent, useState } from 'react'
import { z } from 'zod'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Modal from '@mui/material/Modal'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'

import ContactOrganizationForm from '@/components/ui/modals/contact-organization-form.tsx'
import ContactPersonForm from '@/components/ui/modals/contact-person-form.tsx'

import { createContact } from '@/api/contacts.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { trimValues } from '@/lib/utils.ts'
import { OrganizationContactSchema, PersonContactSchema } from '@/lib/validation-schemas.ts'
import type { ContactDetails, ContactEmail, ContactPhone, UserContact } from '@/types.ts'
import { initialOrgContactDetails, initialPersonContactDetails } from '@/seed_form_data.ts'

type ContactType = 'organization' | 'person'

type NewContactModalProps = {
  open: boolean
  onClose: () => void
  onSuccess?: (contact: UserContact) => void
}
export default function NewContactModal({ open, onClose, onSuccess }: NewContactModalProps) {
  const { selectedOrganization } = useOrganization()
  const queryClient = useQueryClient()
  const [contactType, setContactType] = useState<ContactType>('organization')
  const [formData, setFormData] = useState<ContactDetails>(initialOrgContactDetails)
  const [validationError, setValidationError] = useState<string | null>(null)

  const createContactMutation = useMutation({
    mutationFn: createContact,
    onSuccess: async (data) => {
      if (onSuccess) {
        onSuccess(data)
      }

      await queryClient.invalidateQueries({
        queryKey: ['contacts'],
      })

      setFormData(initialOrgContactDetails)
      setContactType('organization')
      onClose()
    },
  })

  const handleContactTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newContactType = event.target.value as ContactType
    setContactType(newContactType)
    if (newContactType === 'organization') {
      setFormData(initialOrgContactDetails)
    } else {
      setFormData(initialPersonContactDetails)
    }
    setValidationError(null)
  }

  const handleFormChange = (newDetails: ContactPhone[] | ContactEmail[] | ContactDetails) => {
    setFormData({ ...formData, ...newDetails })
    setValidationError(null)
  }

  const validateForm = (data: ContactDetails): boolean => {
    try {
      const schema = contactType === 'organization' ? OrganizationContactSchema : PersonContactSchema
      schema.parse(data)
      setValidationError(null)
      return true
    } catch (error) {
      if (error instanceof z.ZodError) {
        setValidationError(error.errors[0].message)
      } else {
        setValidationError('An unknown error occurred')
      }
      return false
    }
  }

  const handleSubmit = async () => {
    const trimmedFormData = trimValues(formData)
    if (validateForm(trimmedFormData)) {
      try {
        await createContactMutation.mutateAsync({
          contactDetails: trimmedFormData,
          organization: selectedOrganization,
        })
      } catch (error) {
        setValidationError(`Failed to add contact. Please try again. ${error}`)
      }
    }
  }

  const handleClose = () => {
    setFormData(initialOrgContactDetails)
    setContactType('organization')
    setValidationError(null)
    onClose()
  }

  return (
    <Modal open={open} onClose={handleClose} closeAfterTransition>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          height: 850,
          maxHeight: 900,
          bgcolor: 'white',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflowY: 'auto',
        }}
      >
        <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
          <Box>
            <Typography variant="h2" gutterBottom>
              Add Contact
            </Typography>
            <RadioGroup row value={contactType} onChange={handleContactTypeChange} sx={{ mb: 2 }}>
              <FormControlLabel control={<Radio />} label="Organization" value="organization" />
              <FormControlLabel control={<Radio />} label="Person" value="person" />
            </RadioGroup>
            {contactType === 'organization' ? (
              <ContactOrganizationForm contactDetails={formData} onContactDetailsChange={handleFormChange} />
            ) : (
              <ContactPersonForm contactDetails={formData} onContactDetailsChange={handleFormChange} />
            )}

            {validationError && (
              <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                {validationError}
              </Alert>
            )}
          </Box>

          <Box display="flex" justifyContent="flex-end" mt={2} gap={2}>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              Cancel & Close
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Add Contact
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
