import { ReactNode } from 'react'
import { useDroppable } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import TableBody from '@mui/material/TableBody'

interface DroppableTableGroupProps {
  groupId: string
  items: string[]
  children: ReactNode
  accepts?: string[]
  disabled?: boolean
}

export const DroppableTableGroup = ({
  groupId,
  items,
  children,
  accepts,
  disabled = false,
}: DroppableTableGroupProps) => {
  const { setNodeRef } = useDroppable({
    id: groupId,
    data: {
      type: 'GROUP',
      groupId,
      accepts,
    },
    disabled,
  })

  return (
    <SortableContext items={items} strategy={verticalListSortingStrategy}>
      <TableBody ref={setNodeRef}>{children}</TableBody>
    </SortableContext>
  )
}
