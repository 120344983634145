import { useState, useCallback } from 'react'
import { ConfirmationModalProps } from '@/types.ts'
import { ConfirmationModal } from '@/components/ui/modals/confirmation-modal.tsx'

type ConfirmationOptions = Omit<ConfirmationModalProps, 'open' | 'onConfirm' | 'onCancel'>

export function useConfirmationModal() {
  const [isOpen, setIsOpen] = useState(false)
  const [options, setOptions] = useState<ConfirmationOptions | null>(null)
  const [resolveRef, setResolveRef] = useState<((value: boolean) => void) | null>(null)

  const openConfirmationModal = useCallback((options: ConfirmationOptions): Promise<boolean> => {
    setOptions(options)
    setIsOpen(true)

    return new Promise<boolean>((resolve) => {
      setResolveRef(() => resolve)
    })
  }, [])

  const handleConfirm = useCallback(() => {
    setIsOpen(false)
    if (resolveRef) {
      resolveRef(true)
    }
  }, [resolveRef])

  const handleCancel = useCallback(() => {
    setIsOpen(false)
    if (resolveRef) {
      resolveRef(false)
    }
  }, [resolveRef])

  return {
    ConfirmationModal: options ? (
      <ConfirmationModal
        open={isOpen}
        title={options.title}
        message={options.message}
        danger={options.danger}
        loading={options.loading}
        cancelButtonText={options.cancelButtonText}
        confirmButtonText={options.confirmButtonText}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    ) : null,
    openConfirmationModal,
  }
}
