import { useMutation } from '@tanstack/react-query'
import { useState, useEffect } from 'react'
import { Route as RfpDraftRoute } from '@/routes/$orgId/rfps.draft.$rfpId'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { MessageSquareText } from 'lucide-react'

import { createSentRfp } from '@/api/rfps'
import VendorPanel from '@/components/rfps/draft/steps/vendor-panel.tsx'
import { OrganizationContact, PlantListEntry, SentRFP, UserContact } from '@/types.ts'
import { RFPStepProps } from '@/components/rfps/types.ts'
import * as Sentry from '@sentry/react'
import { useNavigate } from '@tanstack/react-router'
import CommentModeContainer from '@/components/rfps/comment-mode-container.tsx'
import { RfpPageTitle } from '@/components/rfps/rfp-page-title.tsx'
import { RfpProvider } from '@/components/rfps/context/rfp-context.tsx'
import RfpPageContent from '@/components/rfps/draft/steps/rfp-page-content'
import { useVendorContactModal } from '@/hooks/useVendorContactModal.tsx'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications.ts'
import { RFPFeatureAddVendorIllustration } from '@/components/rfps/draft/steps/rfp_feature_add_vendor_illustration.tsx'
import { useSearchVendorModal } from '@/hooks/useSearchVendorModal.tsx'
import { useCommentStore } from '@/stores/comment-store.ts'
import { excludeDeletedItems, getOrganizationNameFromContact } from '@/lib/utils.ts'
import Typography from '@mui/material/Typography'
import SentRfpPageContent from '@/components/rfps/draft/steps/rfp-page-content/sent-rfp-content.tsx'

interface AddVendorsStepProps extends RFPStepProps {
  sentRfps: SentRFP[]
  onInvalidateSentRfps: () => Promise<void>
}

function AddVendorsStepContent({ rfpData, onUpdateSentRfpData, sentRfps, onInvalidateSentRfps }: AddVendorsStepProps) {
  const addVendorRFPContentParentId = 'add-vendor-rfp-content-parent'
  const { openSearchVendorModal, closeSearchVendorModal, SearchVendorModal } = useSearchVendorModal()
  const { openVendorContactModal, closeVendorContactModal, updateVendorContactModal, VendorContactModal } =
    useVendorContactModal()
  const { addToastNotification } = useToastNotifications()
  const [selectedSentRfp, setSelectedSentRfp] = useState<SentRFP | null>()
  const { selectedSentRfpId } = RfpDraftRoute.useSearch()
  const navigate = useNavigate({ from: RfpDraftRoute.fullPath })
  const { setCommentMode, isCommentMode, commentType } = useCommentStore()

  const handleCommentModeClick = (type: 'note' | 'comment') => {
    if (isCommentMode && commentType === type) {
      setCommentMode(false, null)
    } else {
      setCommentMode(true, type)
    }
  }

  // Update selectedSentRfp when sentRfps changes or when selectedSentRfpId changes
  useEffect(() => {
    if (sentRfps && selectedSentRfpId) {
      const rfp = sentRfps.find((rfp) => rfp.id === selectedSentRfpId)
      if (rfp) {
        setSelectedSentRfp(rfp)
      } else {
        navigate({ search: (prev) => ({ ...prev, selectedSentRfpId: undefined }) })
      }
    } else {
      setSelectedSentRfp(null)
    }
  }, [sentRfps, selectedSentRfpId, navigate])

  const createSentRfpMutation = useMutation({
    mutationFn: createSentRfp,
    onSuccess: async (newSentRfps) => {
      await onInvalidateSentRfps()
      // After creating a new sent RFP, select it and update the URL
      navigate({
        search: (prev) => ({ ...prev, selectedSentRfpId: newSentRfps[0].id }),
      })
    },
    onError: (error) => {
      console.error('Error creating sent RFP:', error)
    },
  })

  if (!onUpdateSentRfpData) {
    Sentry.captureMessage('onUpdateSentRfpData is required for AddVendorsStep')
    return null
  }

  const handleVendorSelection = (contact: OrganizationContact, plants?: PlantListEntry[]) => {
    if (!contact) return

    const orgName = getOrganizationNameFromContact(contact, 'Unknown')

    const handleSubmission = async (contacts: UserContact[]) => {
      updateVendorContactModal({ loading: true })
      try {
        await createSentRfpMutation.mutateAsync({
          rfpId: rfpData.id,
          organizationId: rfpData.organization.id,
          directed_organizations: [
            {
              directed_organization_contact: contact.id as string,
              directed_organization_user_contacts: contacts.map((contact) => contact.id),
              send: false,
            },
          ],
          plants: plants || excludeDeletedItems(rfpData.plants),
        })

        // Sent Success notification & close modal
        addToastNotification({
          severity: 'success',
          title: 'RFP Updated Successfully',
          message: `${orgName} has been successfully added to RFP# ${rfpData.id}`,
        })
        closeVendorContactModal()
      } catch {
        // Error notification & stop animation
        addToastNotification({
          severity: 'error',
          title: 'RFP Error',
          message: `Failed to add ${orgName} to RFP# ${rfpData.id}`,
        })
        updateVendorContactModal({ loading: false })
      }
    }

    openVendorContactModal({
      vendorContact: contact,
      title: 'Add Vendor to RFP',
      cancelButtonText: 'Cancel & Close',
      submitButtonText: 'Add Vendor to RFP',
      multiSelect: true,
      loading: false,
      onSubmit: async (contacts: UserContact[]) => await handleSubmission(contacts),
    })
  }

  const handleSelectVendorClick = (sentRfp: SentRFP) => {
    setSelectedSentRfp(sentRfp)
    navigate({
      search: (prev) => ({ ...prev, selectedSentRfpId: sentRfp.id }),
    })
  }

  const handleVendorGroupAddClick = (plants: PlantListEntry[]) => {
    openSearchVendorModal({
      searchText: '',
      title: 'Add Vendor to RFP',
      cancelButtonText: 'Cancel & Close',
      submitButtonText: 'Add Vendor to RFP',
      multiSelect: false,
      onSubmit: (vendors) => {
        closeSearchVendorModal()
        handleVendorSelection(vendors[0], plants)
      },
    })
  }

  return (
    <CommentModeContainer>
      <Box sx={{ display: 'flex', height: '100%', width: '100%' }}>
        <Stack sx={{ width: 350, backgroundColor: 'white', p: 4 }}>
          <VendorPanel
            sentRfps={sentRfps}
            onContactSelect={handleVendorSelection}
            setSentRfp={handleSelectVendorClick}
            sentRfpId={selectedSentRfp?.id}
            onVendorGroupAddClick={handleVendorGroupAddClick}
          />
        </Stack>
        <Stack
          direction="column"
          sx={{
            flexGrow: 1,
          }}
        >
          {selectedSentRfp && (
            <RfpPageTitle
              title={
                <Stack direction="row" gap={1}>
                  <Typography variant="h3" fontWeight={400}>
                    Customize plant list for
                  </Typography>
                  <Typography variant="h3">{selectedSentRfp.directed_organization.name}</Typography>
                </Stack>
              }
              actionItems={[
                {
                  text: 'Add Comment to Vendor',
                  onClick: () => handleCommentModeClick('comment'),
                  startIcon: <MessageSquareText />,
                  active: isCommentMode && commentType === 'comment',
                  'data-comment-button': 'true',
                },
              ]}
            />
          )}
          <Box
            id={addVendorRFPContentParentId}
            sx={{
              p: 3,
              flexGrow: 1,
              overflow: 'auto',
              scrollbarWidth: 'none',
              height: 0,
              position: 'relative',
            }}
          >
            {!selectedSentRfp ? (
              <Stack>
                <RFPFeatureAddVendorIllustration />
                <RfpProvider initialRfpData={rfpData} onUpdateRfpData={() => {}}>
                  <RfpPageContent allowPlantTableQuantityChange={true} allowPlantTableSelect={true} />
                </RfpProvider>
              </Stack>
            ) : (
              <RfpProvider
                initialRfpData={rfpData}
                onUpdateRfpData={() => {}}
                sentRfp={selectedSentRfp}
                initialSelectedPlantIds={excludeDeletedItems(selectedSentRfp?.plants).map((plant) => plant.id)}
                initialSentRfpData={selectedSentRfp}
                onUpdateSentRfpData={onUpdateSentRfpData}
              >
                {selectedSentRfpId ? (
                  <SentRfpPageContent
                    showHeader={false}
                    allowPlantTableSelect={true}
                    pageContentType="editSentRfp"
                    allowPlantTableQuantityChange={true}
                  />
                ) : (
                  <RfpPageContent
                    showHeader={false}
                    allowPlantTableSelect={true}
                    allowPlantTableQuantityChange={true}
                  />
                )}
              </RfpProvider>
            )}
          </Box>
        </Stack>
        <SearchVendorModal />
        <VendorContactModal />
      </Box>
    </CommentModeContainer>
  )
}

export default function AddVendorsStep(props: AddVendorsStepProps) {
  if (!props.rfpData) {
    return <div>LOADING RFP...</div>
  }
  return <AddVendorsStepContent {...props} />
}
