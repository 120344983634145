import { useQuery } from '@tanstack/react-query'
import { useState, type MouseEvent, type SyntheticEvent } from 'react'

import Alert from '@mui/material/Alert'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Fade from '@mui/material/Fade'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import { Building2, Building2Icon, Check, ChevronDown, ChevronUp, Plus, Settings } from 'lucide-react'

import NewOrganizationModal from '@/components/ui/modals/new-organization-modal.tsx'
import SettingsAndMembersModal from '@/components/ui/modals/settings-and-members-modal.tsx'

import { fetchGCSFile } from '@/lib/gcs-file-fetcher.ts'
import { ImageFile } from '@/types.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { useUserInfo } from '@/contexts/hooks/useUserInfo.ts'
import theme from '@/theme.ts'
import { Link, useNavigate } from '@tanstack/react-router'

type OrganizationMenuProps = {
  expanded: boolean
}
export default function OrganizationMenu({ expanded }: OrganizationMenuProps) {
  const { userInfo, refetchUserInfo, userOrganizations: organizations } = useUserInfo()
  const { organizationModalOpen, toggleOrganizationModal, selectedOrganization } = useOrganization()
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [newOrgOpen, setNewOrgOpen] = useState(false)
  const [orgCreated, setOrgCreated] = useState(false)
  const navigate = useNavigate()

  const { data: logo } = useQuery({
    queryKey: ['side-nav-logo', selectedOrganization?.logo?.get_url],
    queryFn: async () => {
      if (!selectedOrganization?.logo?.get_url) {
        return null
      }

      return (await fetchGCSFile(selectedOrganization.logo)) as ImageFile
    },
  })
  const { data: smallLogo } = useQuery({
    queryKey: ['side-nav-logo', selectedOrganization?.small_logo?.get_url],
    queryFn: async () => {
      if (!selectedOrganization?.small_logo?.get_url) {
        return null
      }

      return (await fetchGCSFile(selectedOrganization.small_logo)) as ImageFile
    },
  })

  const disableOrgSettings = !userInfo?.user.organization_members
    .find((member) => member.organization.id === selectedOrganization?.id)
    ?.roles.some((role) => role.permissions.includes('manage_organization'))

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOrgSettingsOpen = () => {
    toggleOrganizationModal(true)
    handleClose()
  }

  const handleNewOrgOpen = () => {
    setNewOrgOpen(true)
    handleClose()
  }

  const handleTooltipOpen = () => {
    if (disableOrgSettings) {
      setTooltipOpen(true)
    }
  }

  const handleOrganizationCreated = async (newOrganizationId: string) => {
    await refetchUserInfo()
    setNewOrgOpen(false)
    setOrgCreated(true)
    navigate({ to: '/$orgId', params: { orgId: newOrganizationId } })
  }

  const handleSnackbarClose = (_event?: SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return
    }

    setOrgCreated(false)
  }

  return (
    <>
      <ListItem
        onClick={handleClick}
        sx={{
          justifyContent: 'center',
          color: 'white',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          ':hover': {
            bgcolor: theme.palette.primary.main,
          },
        }}
        aria-controls={open ? 'organization-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        {!expanded && (
          <ListItemIcon sx={{ minWidth: 0 }}>
            {selectedOrganization?.small_logo ? (
              <Avatar src={smallLogo?.url} alt={selectedOrganization?.name} sx={{ width: 40, height: 40 }} />
            ) : (
              <Avatar sx={{ width: 40, height: 40 }}>{selectedOrganization?.name[0] || <Building2 />}</Avatar>
            )}
          </ListItemIcon>
        )}
        {expanded && (
          <>
            <Box>
              {logo ? (
                <Box
                  component="img"
                  sx={{
                    width: 230,
                    height: 130,
                  }}
                  alt="Organization brand logo"
                  src={logo?.url}
                />
              ) : (
                <Box>
                  <Box
                    sx={{
                      width: 230,
                      height: 130,
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Building2Icon size={100} />
                  </Box>
                  <ListItemText
                    primary={
                      <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>
                        {selectedOrganization?.name || 'Select Organization'}
                      </Typography>
                    }
                    sx={{ textAlign: 'center', fontWeight: 'bold' }}
                  />
                </Box>
              )}
            </Box>
            <ListItemIcon
              sx={{
                minWidth: 0,
                color: 'white',
              }}
            >
              {open ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
            </ListItemIcon>
          </>
        )}
      </ListItem>
      <Menu
        id="organization-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        slotProps={{ paper: { sx: { width: 280 } } }}
        TransitionComponent={Fade}
      >
        <Typography variant="body1" px={2} fontWeight="bold" mb={1}>
          My Organizations
        </Typography>
        {organizations && organizations.length > 0 ? (
          organizations.map((organization) => (
            <Link key={organization.id} to="/$orgId" params={{ orgId: organization.id }}>
              <MenuItem onClick={() => handleClose()}>
                <ListItemText primary={organization.name} sx={{ whiteSpace: 'wrap' }} />
                {selectedOrganization?.id === organization.id && (
                  <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                    <Check size={16} />
                  </ListItemIcon>
                )}
              </MenuItem>
            </Link>
          ))
        ) : (
          <Typography p={2} pt={0} textAlign="center">
            You don&apos;t belong to any organizations.
          </Typography>
        )}
        <Divider />
        <Tooltip
          title="You don't have permission to view this organization's settings."
          open={tooltipOpen}
          onOpen={handleTooltipOpen}
          onClose={() => setTooltipOpen(false)}
          placement="right"
          arrow
        >
          <span>
            <MenuItem onClick={handleOrgSettingsOpen} disabled={disableOrgSettings}>
              <ListItemIcon>
                <Settings size={16} />
              </ListItemIcon>
              <ListItemText primary="Settings & Members" />
            </MenuItem>
          </span>
        </Tooltip>
        <MenuItem onClick={handleNewOrgOpen}>
          <ListItemIcon>
            <Plus size={16} />
          </ListItemIcon>
          <ListItemText
            primary={`Add ${organizations && organizations.length > 0 ? 'another' : ''} organization ...`}
          />
        </MenuItem>
      </Menu>

      <SettingsAndMembersModal open={organizationModalOpen} onClose={() => toggleOrganizationModal(false)} />

      <NewOrganizationModal
        open={newOrgOpen}
        onClose={() => setNewOrgOpen(false)}
        onOrganizationCreated={handleOrganizationCreated}
      />

      <Snackbar open={orgCreated} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success" variant="filled" sx={{ width: '100%' }}>
          Organization created successfully!
        </Alert>
      </Snackbar>
    </>
  )
}
