import { useCallback, type MouseEvent, type FC, ReactNode } from 'react'
import { Menu } from '@/components/ui/base/menu'
import { MenuItem, ListItemText, SxProps, ListItemIcon } from '@mui/material'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications'
import { useNavigate } from '@tanstack/react-router'
import { DraftMenuItem } from '../hooks/useDraftMenu'
import { useRFPMutations } from '@/api/rfp-index'
import { BaseRFPMenuProps } from './rfp-menu'
import { useConfirmationDialog } from '../hooks/useConfirmDialog'

// Define the menu action types for drafts
export type DraftMenuActionType = 'VIEW_DETAILS' | 'TOGGLE_IMPORTANT' | 'DELETE' | 'ARCHIVE' | 'UNARCHIVE'

// Define the props for the Draft menu component
interface DraftRFPMenuProps extends BaseRFPMenuProps {
  menuState: {
    draft: DraftMenuItem // Non-null RFP
    anchorEl: HTMLElement
  }
}

type MenuAction = {
  type: DraftMenuActionType
  icon?: ReactNode
  label: string
  sx?: SxProps
  disabled?: boolean
}

/**
 * Implement in conjunction with useDraftMenu
 * This component will be exported from the useDraftMenu hook
 *  Usage: `const { DraftMenu, menuState, openMenu, closeMenu } = useDraftMenu()`
 */
export const DraftMenu: FC<DraftRFPMenuProps> = ({ menuState, closeMenu, rfpQueryManager }) => {
  const navigate = useNavigate()
  const toast = useToastNotifications()
  const orgId = rfpQueryManager.organizationId
  const { mutation: mutateDraft } = useRFPMutations(rfpQueryManager)
  const { showConfirmation, ConfirmationDialog } = useConfirmationDialog()

  const isArchived = menuState.draft.state === 'archived'
  // Define draft-specific actions
  const actions: MenuAction[] = [
    {
      type: 'VIEW_DETAILS',
      // icon: <VisibilityIcon fontSize="small" />,
      label: 'Edit Draft',
    },
    {
      type: 'TOGGLE_IMPORTANT',
      // icon: <FlagIcon fontSize="small" />,
      label: menuState.draft.flagged_as_important ? 'Unflag as Important' : 'Flag as Important',
    },
    {
      type: 'UNARCHIVE',
      // icon: <ArchiveIcon fontSize="small" />,
      label: 'Unarchive RFP',
      disabled: !isArchived,
    },
    {
      type: 'ARCHIVE',
      // icon: <ArchiveIcon fontSize="small" sx={{ color: 'error.main' }} />,
      label: 'Archive RFP',
      disabled: isArchived,
      sx: { color: 'inherit' },
    },
  ]

  const handleMenuAction = useCallback(
    async (type: DraftMenuActionType, event?: MouseEvent) => {
      event?.preventDefault()
      event?.stopPropagation()

      const { draft } = menuState

      try {
        switch (type) {
          case 'VIEW_DETAILS':
            navigate({
              to: '/$orgId/rfps/draft/$rfpId',
              params: { orgId, rfpId: draft.id },
            })
            break

          case 'TOGGLE_IMPORTANT': {
            const currentValue = draft.flagged_as_important || false
            // Implement draft-specific mutation
            mutateDraft.mutate({ type: 'TOGGLE_IMPORTANT', rfpId: draft.id, currentValue })

            toast.addToastNotification({
              message: `Draft ${currentValue ? 'unmarked' : 'marked'} as important`,
              severity: 'success',
            })
            break
          }
          case 'ARCHIVE':
            {
              const confirmed = await showConfirmation({
                title: 'Archive Draft',
                message: 'Are you sure you want to archive this draft?',
              })

              if (confirmed) {
                await mutateDraft.mutateAsync({ type: 'SET_STATE', rfpId: draft.id, state: 'archived' })

                toast.addToastNotification({
                  message: 'Draft archived',
                  severity: 'success',
                })
              }
            }
            break

          case 'UNARCHIVE':
            {
              await mutateDraft.mutateAsync({ type: 'SET_STATE', rfpId: draft.id, state: 'open' })

              toast.addToastNotification({
                message: 'Draft unarchived',
                severity: 'success',
              })
            }
            break
        }
      } catch (error) {
        console.error(error)
        if (error instanceof Error) {
          toast.addToastNotification({
            message: error.message,
            severity: 'error',
          })
        } else {
          toast.addToastNotification({
            message: 'An unknown error occurred',
            severity: 'error',
          })
        }
      } finally {
        // Don't close the menu until the end, otherwise
        // it will close before the confirmation dialog is opened
        closeMenu()
      }
    },
    [menuState, navigate, orgId, toast, closeMenu, mutateDraft, showConfirmation]
  )

  return (
    <>
      <ConfirmationDialog />
      <Menu anchorEl={menuState.anchorEl} open={!!menuState.anchorEl} onClose={closeMenu}>
        {actions.map((item) => (
          <MenuItem
            key={item.type}
            onClick={(e) => {
              e.stopPropagation()
              handleMenuAction(item.type, e)
            }}
            sx={item.sx}
          >
            {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
