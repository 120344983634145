/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as SentryTestImport } from './routes/sentry-test'
import { Route as Auth0CallbackImport } from './routes/auth0-callback'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as OrgIdImport } from './routes/$orgId'
import { Route as IndexImport } from './routes/index'
import { Route as OrgIdIndexImport } from './routes/$orgId/index'
import { Route as AuthenticatedAuthTestImport } from './routes/_authenticated/auth-test'
import { Route as OrgIdRfpsIndexImport } from './routes/$orgId/rfps.index'
import { Route as OrgIdQuotesIndexImport } from './routes/$orgId/quotes.index'
import { Route as OrgIdPlantsIndexImport } from './routes/$orgId/plants.index'
import { Route as OrgIdPlantListsIndexImport } from './routes/$orgId/plant-lists.index'
import { Route as OrgIdOrdersIndexImport } from './routes/$orgId/orders.index'
import { Route as OrgIdJobsIndexImport } from './routes/$orgId/jobs.index'
import { Route as OrgIdContactsIndexImport } from './routes/$orgId/contacts.index'
import { Route as OrgIdRfpsRfpIdImport } from './routes/$orgId/rfps.$rfpId'
import { Route as OrgIdPlantsPlantListIdImport } from './routes/$orgId/plants.$plantListId'
import { Route as OrgIdPlantListsPlantListIdImport } from './routes/$orgId/plant-lists.$plantListId'
import { Route as OrgIdContactsContactIdImport } from './routes/$orgId/contacts.$contactId'
import { Route as OrgIdRfpsEditRfpIdImport } from './routes/$orgId/rfps.edit.$rfpId'
import { Route as OrgIdRfpsDraftRfpIdImport } from './routes/$orgId/rfps.draft.$rfpId'

// Create/Update Routes

const SentryTestRoute = SentryTestImport.update({
  id: '/sentry-test',
  path: '/sentry-test',
  getParentRoute: () => rootRoute,
} as any)

const Auth0CallbackRoute = Auth0CallbackImport.update({
  id: '/auth0-callback',
  path: '/auth0-callback',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const OrgIdRoute = OrgIdImport.update({
  id: '/$orgId',
  path: '/$orgId',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const OrgIdIndexRoute = OrgIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => OrgIdRoute,
} as any)

const AuthenticatedAuthTestRoute = AuthenticatedAuthTestImport.update({
  id: '/auth-test',
  path: '/auth-test',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const OrgIdRfpsIndexRoute = OrgIdRfpsIndexImport.update({
  id: '/rfps/',
  path: '/rfps/',
  getParentRoute: () => OrgIdRoute,
} as any)

const OrgIdQuotesIndexRoute = OrgIdQuotesIndexImport.update({
  id: '/quotes/',
  path: '/quotes/',
  getParentRoute: () => OrgIdRoute,
} as any)

const OrgIdPlantsIndexRoute = OrgIdPlantsIndexImport.update({
  id: '/plants/',
  path: '/plants/',
  getParentRoute: () => OrgIdRoute,
} as any)

const OrgIdPlantListsIndexRoute = OrgIdPlantListsIndexImport.update({
  id: '/plant-lists/',
  path: '/plant-lists/',
  getParentRoute: () => OrgIdRoute,
} as any)

const OrgIdOrdersIndexRoute = OrgIdOrdersIndexImport.update({
  id: '/orders/',
  path: '/orders/',
  getParentRoute: () => OrgIdRoute,
} as any)

const OrgIdJobsIndexRoute = OrgIdJobsIndexImport.update({
  id: '/jobs/',
  path: '/jobs/',
  getParentRoute: () => OrgIdRoute,
} as any)

const OrgIdContactsIndexRoute = OrgIdContactsIndexImport.update({
  id: '/contacts/',
  path: '/contacts/',
  getParentRoute: () => OrgIdRoute,
} as any)

const OrgIdRfpsRfpIdRoute = OrgIdRfpsRfpIdImport.update({
  id: '/rfps/$rfpId',
  path: '/rfps/$rfpId',
  getParentRoute: () => OrgIdRoute,
} as any)

const OrgIdPlantsPlantListIdRoute = OrgIdPlantsPlantListIdImport.update({
  id: '/plants/$plantListId',
  path: '/plants/$plantListId',
  getParentRoute: () => OrgIdRoute,
} as any)

const OrgIdPlantListsPlantListIdRoute = OrgIdPlantListsPlantListIdImport.update(
  {
    id: '/plant-lists/$plantListId',
    path: '/plant-lists/$plantListId',
    getParentRoute: () => OrgIdRoute,
  } as any,
)

const OrgIdContactsContactIdRoute = OrgIdContactsContactIdImport.update({
  id: '/contacts/$contactId',
  path: '/contacts/$contactId',
  getParentRoute: () => OrgIdRoute,
} as any)

const OrgIdRfpsEditRfpIdRoute = OrgIdRfpsEditRfpIdImport.update({
  id: '/rfps/edit/$rfpId',
  path: '/rfps/edit/$rfpId',
  getParentRoute: () => OrgIdRoute,
} as any)

const OrgIdRfpsDraftRfpIdRoute = OrgIdRfpsDraftRfpIdImport.update({
  id: '/rfps/draft/$rfpId',
  path: '/rfps/draft/$rfpId',
  getParentRoute: () => OrgIdRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/$orgId': {
      id: '/$orgId'
      path: '/$orgId'
      fullPath: '/$orgId'
      preLoaderRoute: typeof OrgIdImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/auth0-callback': {
      id: '/auth0-callback'
      path: '/auth0-callback'
      fullPath: '/auth0-callback'
      preLoaderRoute: typeof Auth0CallbackImport
      parentRoute: typeof rootRoute
    }
    '/sentry-test': {
      id: '/sentry-test'
      path: '/sentry-test'
      fullPath: '/sentry-test'
      preLoaderRoute: typeof SentryTestImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/auth-test': {
      id: '/_authenticated/auth-test'
      path: '/auth-test'
      fullPath: '/auth-test'
      preLoaderRoute: typeof AuthenticatedAuthTestImport
      parentRoute: typeof AuthenticatedImport
    }
    '/$orgId/': {
      id: '/$orgId/'
      path: '/'
      fullPath: '/$orgId/'
      preLoaderRoute: typeof OrgIdIndexImport
      parentRoute: typeof OrgIdImport
    }
    '/$orgId/contacts/$contactId': {
      id: '/$orgId/contacts/$contactId'
      path: '/contacts/$contactId'
      fullPath: '/$orgId/contacts/$contactId'
      preLoaderRoute: typeof OrgIdContactsContactIdImport
      parentRoute: typeof OrgIdImport
    }
    '/$orgId/plant-lists/$plantListId': {
      id: '/$orgId/plant-lists/$plantListId'
      path: '/plant-lists/$plantListId'
      fullPath: '/$orgId/plant-lists/$plantListId'
      preLoaderRoute: typeof OrgIdPlantListsPlantListIdImport
      parentRoute: typeof OrgIdImport
    }
    '/$orgId/plants/$plantListId': {
      id: '/$orgId/plants/$plantListId'
      path: '/plants/$plantListId'
      fullPath: '/$orgId/plants/$plantListId'
      preLoaderRoute: typeof OrgIdPlantsPlantListIdImport
      parentRoute: typeof OrgIdImport
    }
    '/$orgId/rfps/$rfpId': {
      id: '/$orgId/rfps/$rfpId'
      path: '/rfps/$rfpId'
      fullPath: '/$orgId/rfps/$rfpId'
      preLoaderRoute: typeof OrgIdRfpsRfpIdImport
      parentRoute: typeof OrgIdImport
    }
    '/$orgId/contacts/': {
      id: '/$orgId/contacts/'
      path: '/contacts'
      fullPath: '/$orgId/contacts'
      preLoaderRoute: typeof OrgIdContactsIndexImport
      parentRoute: typeof OrgIdImport
    }
    '/$orgId/jobs/': {
      id: '/$orgId/jobs/'
      path: '/jobs'
      fullPath: '/$orgId/jobs'
      preLoaderRoute: typeof OrgIdJobsIndexImport
      parentRoute: typeof OrgIdImport
    }
    '/$orgId/orders/': {
      id: '/$orgId/orders/'
      path: '/orders'
      fullPath: '/$orgId/orders'
      preLoaderRoute: typeof OrgIdOrdersIndexImport
      parentRoute: typeof OrgIdImport
    }
    '/$orgId/plant-lists/': {
      id: '/$orgId/plant-lists/'
      path: '/plant-lists'
      fullPath: '/$orgId/plant-lists'
      preLoaderRoute: typeof OrgIdPlantListsIndexImport
      parentRoute: typeof OrgIdImport
    }
    '/$orgId/plants/': {
      id: '/$orgId/plants/'
      path: '/plants'
      fullPath: '/$orgId/plants'
      preLoaderRoute: typeof OrgIdPlantsIndexImport
      parentRoute: typeof OrgIdImport
    }
    '/$orgId/quotes/': {
      id: '/$orgId/quotes/'
      path: '/quotes'
      fullPath: '/$orgId/quotes'
      preLoaderRoute: typeof OrgIdQuotesIndexImport
      parentRoute: typeof OrgIdImport
    }
    '/$orgId/rfps/': {
      id: '/$orgId/rfps/'
      path: '/rfps'
      fullPath: '/$orgId/rfps'
      preLoaderRoute: typeof OrgIdRfpsIndexImport
      parentRoute: typeof OrgIdImport
    }
    '/$orgId/rfps/draft/$rfpId': {
      id: '/$orgId/rfps/draft/$rfpId'
      path: '/rfps/draft/$rfpId'
      fullPath: '/$orgId/rfps/draft/$rfpId'
      preLoaderRoute: typeof OrgIdRfpsDraftRfpIdImport
      parentRoute: typeof OrgIdImport
    }
    '/$orgId/rfps/edit/$rfpId': {
      id: '/$orgId/rfps/edit/$rfpId'
      path: '/rfps/edit/$rfpId'
      fullPath: '/$orgId/rfps/edit/$rfpId'
      preLoaderRoute: typeof OrgIdRfpsEditRfpIdImport
      parentRoute: typeof OrgIdImport
    }
  }
}

// Create and export the route tree

interface OrgIdRouteChildren {
  OrgIdIndexRoute: typeof OrgIdIndexRoute
  OrgIdContactsContactIdRoute: typeof OrgIdContactsContactIdRoute
  OrgIdPlantListsPlantListIdRoute: typeof OrgIdPlantListsPlantListIdRoute
  OrgIdPlantsPlantListIdRoute: typeof OrgIdPlantsPlantListIdRoute
  OrgIdRfpsRfpIdRoute: typeof OrgIdRfpsRfpIdRoute
  OrgIdContactsIndexRoute: typeof OrgIdContactsIndexRoute
  OrgIdJobsIndexRoute: typeof OrgIdJobsIndexRoute
  OrgIdOrdersIndexRoute: typeof OrgIdOrdersIndexRoute
  OrgIdPlantListsIndexRoute: typeof OrgIdPlantListsIndexRoute
  OrgIdPlantsIndexRoute: typeof OrgIdPlantsIndexRoute
  OrgIdQuotesIndexRoute: typeof OrgIdQuotesIndexRoute
  OrgIdRfpsIndexRoute: typeof OrgIdRfpsIndexRoute
  OrgIdRfpsDraftRfpIdRoute: typeof OrgIdRfpsDraftRfpIdRoute
  OrgIdRfpsEditRfpIdRoute: typeof OrgIdRfpsEditRfpIdRoute
}

const OrgIdRouteChildren: OrgIdRouteChildren = {
  OrgIdIndexRoute: OrgIdIndexRoute,
  OrgIdContactsContactIdRoute: OrgIdContactsContactIdRoute,
  OrgIdPlantListsPlantListIdRoute: OrgIdPlantListsPlantListIdRoute,
  OrgIdPlantsPlantListIdRoute: OrgIdPlantsPlantListIdRoute,
  OrgIdRfpsRfpIdRoute: OrgIdRfpsRfpIdRoute,
  OrgIdContactsIndexRoute: OrgIdContactsIndexRoute,
  OrgIdJobsIndexRoute: OrgIdJobsIndexRoute,
  OrgIdOrdersIndexRoute: OrgIdOrdersIndexRoute,
  OrgIdPlantListsIndexRoute: OrgIdPlantListsIndexRoute,
  OrgIdPlantsIndexRoute: OrgIdPlantsIndexRoute,
  OrgIdQuotesIndexRoute: OrgIdQuotesIndexRoute,
  OrgIdRfpsIndexRoute: OrgIdRfpsIndexRoute,
  OrgIdRfpsDraftRfpIdRoute: OrgIdRfpsDraftRfpIdRoute,
  OrgIdRfpsEditRfpIdRoute: OrgIdRfpsEditRfpIdRoute,
}

const OrgIdRouteWithChildren = OrgIdRoute._addFileChildren(OrgIdRouteChildren)

interface AuthenticatedRouteChildren {
  AuthenticatedAuthTestRoute: typeof AuthenticatedAuthTestRoute
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedAuthTestRoute: AuthenticatedAuthTestRoute,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/$orgId': typeof OrgIdRouteWithChildren
  '': typeof AuthenticatedRouteWithChildren
  '/auth0-callback': typeof Auth0CallbackRoute
  '/sentry-test': typeof SentryTestRoute
  '/auth-test': typeof AuthenticatedAuthTestRoute
  '/$orgId/': typeof OrgIdIndexRoute
  '/$orgId/contacts/$contactId': typeof OrgIdContactsContactIdRoute
  '/$orgId/plant-lists/$plantListId': typeof OrgIdPlantListsPlantListIdRoute
  '/$orgId/plants/$plantListId': typeof OrgIdPlantsPlantListIdRoute
  '/$orgId/rfps/$rfpId': typeof OrgIdRfpsRfpIdRoute
  '/$orgId/contacts': typeof OrgIdContactsIndexRoute
  '/$orgId/jobs': typeof OrgIdJobsIndexRoute
  '/$orgId/orders': typeof OrgIdOrdersIndexRoute
  '/$orgId/plant-lists': typeof OrgIdPlantListsIndexRoute
  '/$orgId/plants': typeof OrgIdPlantsIndexRoute
  '/$orgId/quotes': typeof OrgIdQuotesIndexRoute
  '/$orgId/rfps': typeof OrgIdRfpsIndexRoute
  '/$orgId/rfps/draft/$rfpId': typeof OrgIdRfpsDraftRfpIdRoute
  '/$orgId/rfps/edit/$rfpId': typeof OrgIdRfpsEditRfpIdRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AuthenticatedRouteWithChildren
  '/auth0-callback': typeof Auth0CallbackRoute
  '/sentry-test': typeof SentryTestRoute
  '/auth-test': typeof AuthenticatedAuthTestRoute
  '/$orgId': typeof OrgIdIndexRoute
  '/$orgId/contacts/$contactId': typeof OrgIdContactsContactIdRoute
  '/$orgId/plant-lists/$plantListId': typeof OrgIdPlantListsPlantListIdRoute
  '/$orgId/plants/$plantListId': typeof OrgIdPlantsPlantListIdRoute
  '/$orgId/rfps/$rfpId': typeof OrgIdRfpsRfpIdRoute
  '/$orgId/contacts': typeof OrgIdContactsIndexRoute
  '/$orgId/jobs': typeof OrgIdJobsIndexRoute
  '/$orgId/orders': typeof OrgIdOrdersIndexRoute
  '/$orgId/plant-lists': typeof OrgIdPlantListsIndexRoute
  '/$orgId/plants': typeof OrgIdPlantsIndexRoute
  '/$orgId/quotes': typeof OrgIdQuotesIndexRoute
  '/$orgId/rfps': typeof OrgIdRfpsIndexRoute
  '/$orgId/rfps/draft/$rfpId': typeof OrgIdRfpsDraftRfpIdRoute
  '/$orgId/rfps/edit/$rfpId': typeof OrgIdRfpsEditRfpIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/$orgId': typeof OrgIdRouteWithChildren
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/auth0-callback': typeof Auth0CallbackRoute
  '/sentry-test': typeof SentryTestRoute
  '/_authenticated/auth-test': typeof AuthenticatedAuthTestRoute
  '/$orgId/': typeof OrgIdIndexRoute
  '/$orgId/contacts/$contactId': typeof OrgIdContactsContactIdRoute
  '/$orgId/plant-lists/$plantListId': typeof OrgIdPlantListsPlantListIdRoute
  '/$orgId/plants/$plantListId': typeof OrgIdPlantsPlantListIdRoute
  '/$orgId/rfps/$rfpId': typeof OrgIdRfpsRfpIdRoute
  '/$orgId/contacts/': typeof OrgIdContactsIndexRoute
  '/$orgId/jobs/': typeof OrgIdJobsIndexRoute
  '/$orgId/orders/': typeof OrgIdOrdersIndexRoute
  '/$orgId/plant-lists/': typeof OrgIdPlantListsIndexRoute
  '/$orgId/plants/': typeof OrgIdPlantsIndexRoute
  '/$orgId/quotes/': typeof OrgIdQuotesIndexRoute
  '/$orgId/rfps/': typeof OrgIdRfpsIndexRoute
  '/$orgId/rfps/draft/$rfpId': typeof OrgIdRfpsDraftRfpIdRoute
  '/$orgId/rfps/edit/$rfpId': typeof OrgIdRfpsEditRfpIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/$orgId'
    | ''
    | '/auth0-callback'
    | '/sentry-test'
    | '/auth-test'
    | '/$orgId/'
    | '/$orgId/contacts/$contactId'
    | '/$orgId/plant-lists/$plantListId'
    | '/$orgId/plants/$plantListId'
    | '/$orgId/rfps/$rfpId'
    | '/$orgId/contacts'
    | '/$orgId/jobs'
    | '/$orgId/orders'
    | '/$orgId/plant-lists'
    | '/$orgId/plants'
    | '/$orgId/quotes'
    | '/$orgId/rfps'
    | '/$orgId/rfps/draft/$rfpId'
    | '/$orgId/rfps/edit/$rfpId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/auth0-callback'
    | '/sentry-test'
    | '/auth-test'
    | '/$orgId'
    | '/$orgId/contacts/$contactId'
    | '/$orgId/plant-lists/$plantListId'
    | '/$orgId/plants/$plantListId'
    | '/$orgId/rfps/$rfpId'
    | '/$orgId/contacts'
    | '/$orgId/jobs'
    | '/$orgId/orders'
    | '/$orgId/plant-lists'
    | '/$orgId/plants'
    | '/$orgId/quotes'
    | '/$orgId/rfps'
    | '/$orgId/rfps/draft/$rfpId'
    | '/$orgId/rfps/edit/$rfpId'
  id:
    | '__root__'
    | '/'
    | '/$orgId'
    | '/_authenticated'
    | '/auth0-callback'
    | '/sentry-test'
    | '/_authenticated/auth-test'
    | '/$orgId/'
    | '/$orgId/contacts/$contactId'
    | '/$orgId/plant-lists/$plantListId'
    | '/$orgId/plants/$plantListId'
    | '/$orgId/rfps/$rfpId'
    | '/$orgId/contacts/'
    | '/$orgId/jobs/'
    | '/$orgId/orders/'
    | '/$orgId/plant-lists/'
    | '/$orgId/plants/'
    | '/$orgId/quotes/'
    | '/$orgId/rfps/'
    | '/$orgId/rfps/draft/$rfpId'
    | '/$orgId/rfps/edit/$rfpId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  OrgIdRoute: typeof OrgIdRouteWithChildren
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  Auth0CallbackRoute: typeof Auth0CallbackRoute
  SentryTestRoute: typeof SentryTestRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  OrgIdRoute: OrgIdRouteWithChildren,
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  Auth0CallbackRoute: Auth0CallbackRoute,
  SentryTestRoute: SentryTestRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/$orgId",
        "/_authenticated",
        "/auth0-callback",
        "/sentry-test"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/$orgId": {
      "filePath": "$orgId.tsx",
      "children": [
        "/$orgId/",
        "/$orgId/contacts/$contactId",
        "/$orgId/plant-lists/$plantListId",
        "/$orgId/plants/$plantListId",
        "/$orgId/rfps/$rfpId",
        "/$orgId/contacts/",
        "/$orgId/jobs/",
        "/$orgId/orders/",
        "/$orgId/plant-lists/",
        "/$orgId/plants/",
        "/$orgId/quotes/",
        "/$orgId/rfps/",
        "/$orgId/rfps/draft/$rfpId",
        "/$orgId/rfps/edit/$rfpId"
      ]
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/auth-test"
      ]
    },
    "/auth0-callback": {
      "filePath": "auth0-callback.tsx"
    },
    "/sentry-test": {
      "filePath": "sentry-test.tsx"
    },
    "/_authenticated/auth-test": {
      "filePath": "_authenticated/auth-test.tsx",
      "parent": "/_authenticated"
    },
    "/$orgId/": {
      "filePath": "$orgId/index.tsx",
      "parent": "/$orgId"
    },
    "/$orgId/contacts/$contactId": {
      "filePath": "$orgId/contacts.$contactId.tsx",
      "parent": "/$orgId"
    },
    "/$orgId/plant-lists/$plantListId": {
      "filePath": "$orgId/plant-lists.$plantListId.tsx",
      "parent": "/$orgId"
    },
    "/$orgId/plants/$plantListId": {
      "filePath": "$orgId/plants.$plantListId.tsx",
      "parent": "/$orgId"
    },
    "/$orgId/rfps/$rfpId": {
      "filePath": "$orgId/rfps.$rfpId.tsx",
      "parent": "/$orgId"
    },
    "/$orgId/contacts/": {
      "filePath": "$orgId/contacts.index.tsx",
      "parent": "/$orgId"
    },
    "/$orgId/jobs/": {
      "filePath": "$orgId/jobs.index.tsx",
      "parent": "/$orgId"
    },
    "/$orgId/orders/": {
      "filePath": "$orgId/orders.index.tsx",
      "parent": "/$orgId"
    },
    "/$orgId/plant-lists/": {
      "filePath": "$orgId/plant-lists.index.tsx",
      "parent": "/$orgId"
    },
    "/$orgId/plants/": {
      "filePath": "$orgId/plants.index.tsx",
      "parent": "/$orgId"
    },
    "/$orgId/quotes/": {
      "filePath": "$orgId/quotes.index.tsx",
      "parent": "/$orgId"
    },
    "/$orgId/rfps/": {
      "filePath": "$orgId/rfps.index.tsx",
      "parent": "/$orgId"
    },
    "/$orgId/rfps/draft/$rfpId": {
      "filePath": "$orgId/rfps.draft.$rfpId.tsx",
      "parent": "/$orgId"
    },
    "/$orgId/rfps/edit/$rfpId": {
      "filePath": "$orgId/rfps.edit.$rfpId.tsx",
      "parent": "/$orgId"
    }
  }
}
ROUTE_MANIFEST_END */
