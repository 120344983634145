import { RequestForProposal, SentRFP } from '@/types.ts'
import { Button, Stack } from '@mui/material'
import { TextIconButton } from '@/components/ui/base/buttons/text-icon-buttons.tsx'
import theme from '@/theme.ts'
import { RfpSettingsMenu } from '@/components/rfps/rfp-settings-menu.tsx'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications.ts'
import { AxiosError } from 'axios'
import { patchRfp } from '@/api/rfps.ts'
import { RFP_QUERY_KEYS } from '@/lib/query-keys.ts'
import { useState } from 'react'
import { DeclineModal } from '../modals/decline-sent-rfp'

export const DetailsPageTitleBarActions = ({
  rfpData,
  sentRfpData,
}: {
  rfpData?: RequestForProposal
  sentRfpData?: SentRFP
}) => {
  const isArchived = rfpData?.state === 'archived'
  const { addToastNotification } = useToastNotifications()
  const queryClient = useQueryClient()
  const [isDeclining, setIsDeclining] = useState(false)

  const patchRfpMutation = useMutation({
    mutationFn: (params: { rfpId: string; data: Partial<RequestForProposal> }) => patchRfp(params.rfpId, params.data),
    onError: async (error: AxiosError) => {
      addToastNotification({
        severity: 'error',
        title: 'RFP Update Error',
        message: (error.response?.data as { detail?: string })?.detail || error.message,
      })
    },
  })

  if (!rfpData) return null

  const handleArchive = async () => {
    try {
      // archive rfp api call
      await patchRfpMutation.mutateAsync({
        rfpId: rfpData.id,
        data: {
          state: 'archived',
        } as Partial<RequestForProposal>,
      })
      // notify user
      addToastNotification({
        severity: 'success',
        title: 'RFP Archived',
        message: 'RFP has been successfully archived',
      })
      // invalidate query
      await queryClient.invalidateQueries({
        queryKey: RFP_QUERY_KEYS.draftRfp(rfpData.id),
      })
    } catch {
      addToastNotification({
        severity: 'error',
        title: 'RFP Archive Error',
        message: 'An error occurred while archiving RFP',
      })
    }
  }

  return (
    <Stack direction="row" sx={{ gap: 2, pr: 4 }}>
      {/* TODO: ONLY SHOW IF VENDOR */}
      <Button variant="contained" color="secondary" sx={{ fontWeight: 400 }} onClick={() => setIsDeclining(true)}>
        No Bid (Decline)
      </Button>
      {isDeclining && sentRfpData && (
        <DeclineModal open={true} onClose={() => setIsDeclining(false)} rfp={sentRfpData} />
      )}

      <TextIconButton
        text={isArchived ? 'Archived' : 'Archive'}
        onClick={handleArchive}
        bgColor={theme.palette.lightGrey3.main}
        disabled={isArchived}
        sx={{
          px: 3,
          borderRadius: theme.borderRadius.md,
          border: `1px solid ${theme.palette.lightGrey2.main}`,
        }}
      />
      <RfpSettingsMenu rfpData={rfpData} />
    </Stack>
  )
}
