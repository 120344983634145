import { Outlet, createFileRoute } from '@tanstack/react-router'
import { AuthenticatedContext } from './__root'
import Box from '@mui/material/Box'
import SideNav from '@/components/ui/side-nav'
export const Route = createFileRoute('/_authenticated')({
  beforeLoad: async ({ context, location }) => {
    const { auth } = context

    if (!auth.isAuthenticated && !auth.isLoading) {
      // For auth0 redirect
      await auth.loginWithRedirect({ appState: { returnTo: location.href } })
    }

    // Hard cast to AuthenticatedContext as we've already checked for the organization
    // this just works in React Router, not sure why we have to do this in TanStack Router
    return context as AuthenticatedContext
  },
  component: AuthenticatedLayout,
})

function AuthenticatedLayout() {
  return (
    <>
      <SideNav />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          margin: 'auto',
          height: '100vh',
        }}
      >
        <Outlet />
      </Box>
    </>
  )
}
