import { z } from 'zod'
import { CommentPage } from '@/types/comments'
import { UserSchema, OrganizationSchema, FullTimeStampSchema } from './shared'

// Organization contact schema (simplified)
const OrganizationContactSchema = z.object({
  id: z.string(),
  // Add other contact fields as needed
})

// Target page schema
export const TargetPageSchema = z.object({
  page: z.enum([CommentPage.SENT_RFP, CommentPage.PLANTLIST, CommentPage.RFP]),
  target_id: z.string(),
})

// Comment specifier schema
export const CommentSpecifierSchema = z.object({
  path_object: z.string(),
  path_id: z.string(),
})

// Individual comment schema for API responses
// Using a different approach for recursive types
export const CommentResponseSchema: z.ZodType<any> = z.lazy(() =>
  z.object({
    id: z.string(),
    ...FullTimeStampSchema.shape,
    created_by: UserSchema.nullable(),
    comment: z.string(),
    organization_members: z.array(UserSchema).default([]),
    organization_user_contacts: z.array(OrganizationContactSchema).default([]),
    directed_organization_user_contacts: z.array(OrganizationContactSchema).default([]),
    directed_organization_members: z.array(UserSchema).default([]),
    replying_to_comment: CommentResponseSchema.nullable(),
    unread: z.boolean().default(true),
  })
)

// Comment thread schema for API responses
export const CommentThreadResponseSchema = z.object({
  id: z.string(),
  organization: OrganizationSchema,
  ...FullTimeStampSchema.shape,
  created_by: UserSchema.nullable(),
  target_pages: z.array(TargetPageSchema),
  comment_specifier: z.array(CommentSpecifierSchema).default([]),
  organization_contact: OrganizationContactSchema.nullable(),
  directed_organization: OrganizationSchema.nullable(),
  directed_organization_contact: OrganizationContactSchema.nullable(),
  comments: z.array(CommentResponseSchema).default([]),
  resolved: z.boolean().default(false),
  target_path: z.string(),
})

// Schema for creating a new comment
export const CommentPostSchema = z.object({
  created_by: z.string(),
  comment: z.string(),
  created_at: z.string().optional(),
  deleted_at: z.string().nullable().optional(),
  organization_members: z.array(z.string()).default([]),
  organization_user_contacts: z.array(z.string()).default([]),
  directed_organization_user_contacts: z.array(z.string()).default([]),
  directed_organization_members: z.array(z.string()).default([]),
  replying_to_comment: z.string().nullable().optional(),
  unread: z.boolean().default(true),
})

// Schema for creating a new comment thread
export const CommentThreadPostSchema = z.object({
  organization: z.string(),
  target_pages: z.array(TargetPageSchema),
  target_path: z.string(),
  comment_specifier: z.array(CommentSpecifierSchema).default([]),
  organization_contact: z.string().nullable().optional(),
  directed_organization: z.string().nullable().optional(),
  directed_organization_contact: z.string().nullable().optional(),
  resolved: z.boolean().default(false),
  comments: z.array(CommentPostSchema).default([]),
  send_to_vendor: z.boolean().default(false),
})

// Schema for updating a comment
export const CommentUpdateSchema = z.object({
  comment: z.string().optional(),
  organization_members: z.array(z.string()).optional(),
  organization_user_contacts: z.array(z.string()).optional(),
  directed_organization_user_contacts: z.array(z.string()).optional(),
  directed_organization_members: z.array(z.string()).optional(),
  unread: z.boolean().optional(),
})

// Schema for updating a comment thread
export const CommentThreadUpdateSchema = z.object({
  target_pages: z.array(TargetPageSchema).optional(),
  comment_specifier: z.array(CommentSpecifierSchema).optional(),
  resolved: z.boolean().optional(),
})

// Types inferred from Zod schemas
export type CommentResponse = z.infer<typeof CommentResponseSchema>
export type CommentPost = z.infer<typeof CommentPostSchema>
export type CommentThreadPost = z.infer<typeof CommentThreadPostSchema>
export type CommentUpdate = z.infer<typeof CommentUpdateSchema>
export type CommentThreadUpdate = z.infer<typeof CommentThreadUpdateSchema>

// API query parameters interface
export interface CommentQueryParams {
  limit?: number
  page?: number
  order_by?: string
  include_deleted?: boolean
  order_by_direction?: 'asc' | 'desc'
  organization_id?: string
  target_id?: string
  page_type?: CommentPage
}
