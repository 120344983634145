import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import VendorGroups from '@/components/rfps/draft/steps/vendor-groups.tsx'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { useEffect, useState } from 'react'
import RfpSentSuccessModal from '@/components/ui/modals/rfp-sent-success-modal.tsx'
import Box from '@mui/material/Box'
import { TextIconButton } from '@/components/ui/base/buttons/text-icon-buttons.tsx'
import { EllipsisVertical, SendHorizonal } from 'lucide-react'
import theme from '@/theme.ts'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getSentRfpsByRfpId, updateSentRfp } from '@/api/rfps.ts'
import { excludeDeletedItems } from '@/lib/utils.ts'
import { CustomLexicalEditorChanges, RequestForProposal, SentRFP, RfpStatus } from '@/types.ts'
import { AxiosError } from 'axios'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications'
import { CustomLexicalEditor } from '@/components/ui/texteditor/lexical/lexical_editor.tsx'
import { RfpEmailContactInfoPill } from '@/components/rfps/rfp-email-contact-info-pill.tsx'
import IconButton from '@mui/material/IconButton'
import { RFP_QUERY_KEYS } from '@/lib/query-keys'
import { RfpProvider } from '@/components/rfps/context/rfp-context.tsx'
import SentRfpPageContent from '@/components/rfps/draft/steps/rfp-page-content/sent-rfp-content.tsx'
import { useNavigate } from '@tanstack/react-router'
import { Route } from '@/routes/$orgId/rfps.draft.$rfpId'

interface ReviewAndSendStepProps {
  rfpData: RequestForProposal
  onUpdateSentRfpData: (updates: any) => void
}
function ReviewAndSendStepContent({ rfpData, onUpdateSentRfpData }: ReviewAndSendStepProps) {
  const { orgId } = Route.useParams()
  const queryClient = useQueryClient()
  const { selectedOrganization } = useOrganization()
  const navigate = useNavigate()
  const [selectedSentRfp, setSelectedSentRfp] = useState<SentRFP | null>(null)
  const [successModalRfp, setSuccessModalRfp] = useState<SentRFP[]>([])
  const { addToastNotification } = useToastNotifications()

  const { data: sentRfpData, isLoading } = useQuery({
    queryKey: RFP_QUERY_KEYS.sentRfps(rfpData?.id, selectedOrganization?.id),
    queryFn: async () => {
      if (!rfpData.id || !selectedOrganization?.id) return []
      const rfps = await getSentRfpsByRfpId(rfpData?.id, selectedOrganization?.id as string)
      return excludeDeletedItems(rfps)
      // .filter((sentRfp: SentRFP) => sentRfp.status === RfpStatus.PENDING)
    },
    enabled: !!selectedOrganization && !!rfpData?.id,
  })

  useEffect(() => {
    if (sentRfpData && sentRfpData.length === 0) {
      setSelectedSentRfp(null)
      return
    }

    const isCurrentSelectionValid = (sentRfpData || []).some((sentRfp: SentRFP) => sentRfp.id === selectedSentRfp?.id)

    if (sentRfpData && (!selectedSentRfp || !isCurrentSelectionValid)) {
      setSelectedSentRfp(sentRfpData[0])
    }
  }, [sentRfpData, selectedSentRfp])

  const updateSentRfpMutation = useMutation({
    mutationFn: updateSentRfp,
    onError: async (error: AxiosError) => {
      addToastNotification({
        severity: 'error',
        title: 'Error updating RFP',
        message: (error.response?.data as { detail?: string })?.detail || error.message,
      })
    },
  })

  // TODO: render some skeletons while loading
  if (isLoading) console.log('render some skeletons while loading')

  if (!rfpData || (sentRfpData && sentRfpData.length === 0)) {
    return null
  }

  const handleSelectVendorClick = (sentRfp: SentRFP) => {
    setSelectedSentRfp(sentRfp)
  }

  const handleSuccessModalClose = async () => {
    const successModalRfps = successModalRfp.map((rfp) => rfp.id)
    const remainingSentRfp = (sentRfpData || []).some(
      (rfp) => rfp.status === RfpStatus.PENDING && !successModalRfps.includes(rfp.id)
    )

    setSuccessModalRfp([])

    await queryClient.invalidateQueries({
      queryKey: RFP_QUERY_KEYS.sentRfps(rfpData?.id, selectedOrganization?.id),
    })

    // redirect when no rfp remains to be sent
    if (!remainingSentRfp) {
      await queryClient.invalidateQueries({ queryKey: [RFP_QUERY_KEYS.all] })
      navigate({
        to: '/$orgId/rfps',
        params: { orgId },
      })
    }
  }

  const handleSendToVendor = async () => {
    if (!selectedSentRfp) return

    const updates = {
      send: true,
    }
    await updateSentRfpMutation.mutateAsync({
      sentRfpId: selectedSentRfp?.id as string,
      data: updates,
    })
    setSuccessModalRfp([selectedSentRfp as SentRFP])
    await queryClient.invalidateQueries({ queryKey: [RFP_QUERY_KEYS.all] })
  }

  const handleEditorChanges = async (data: CustomLexicalEditorChanges) => {
    if (!selectedSentRfp || data.htmlString === selectedSentRfp.notification_body) return

    const defaultSubject = `RFP# ${rfpData?.id}`

    const updatesData = {
      notification_body: data.htmlString,
      notification_subject: defaultSubject,
    }
    onUpdateSentRfpData({
      sentRfpId: selectedSentRfp?.id as string,
      updates: updatesData,
    })
    await queryClient.invalidateQueries({ queryKey: ['sent-rfps'] })
  }

  return (
    <Box sx={{ display: 'flex', height: '100%', width: '100%' }}>
      <Stack sx={{ width: 350, flexShrink: 0, backgroundColor: 'white', p: 4 }}>
        <Typography variant="h3" sx={{ fontWeight: 400 }}>
          Review RFP for
        </Typography>
        <VendorGroups
          sentRfps={sentRfpData as SentRFP[]}
          selectedSentRfpId={selectedSentRfp?.id || null}
          onVendorClick={handleSelectVendorClick}
        />
      </Stack>
      <Stack spacing={1} sx={{ flexGrow: 1 }}>
        <Stack
          spacing={3}
          sx={{ p: 5, flexGrow: 1, overflow: 'auto', scrollbarWidth: 'none', height: 0, position: 'relative' }}
        >
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Typography variant="h3" sx={{ fontWeight: 400, display: 'flex', alignItems: 'center' }}>
              Message to {selectedSentRfp?.directed_organization.name || 'N/A'}
            </Typography>
            <TextIconButton
              text="Send to This Vendor"
              onClick={handleSendToVendor}
              startIcon={<SendHorizonal color={theme.palette.mediumGrey2.main} />}
              variant="contained"
              color={theme.palette.darkGray.main}
              bgColor="white"
              sx={{ px: 2, borderRadius: theme.borderRadius.md }}
            />
          </Stack>
          <Stack sx={{ backgroundColor: 'white', gap: 1, p: 4, borderRadius: theme.borderRadius.lg }}>
            <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
              <Stack direction="row" sx={{ gap: 1, alignItems: 'center' }}>
                <Typography variant="strong">To:</Typography>
                {selectedSentRfp?.directed_organization_user_contacts.map((contact: any) => (
                  <RfpEmailContactInfoPill key={contact.id} contact={contact} />
                ))}
              </Stack>
              <IconButton
                sx={{
                  p: 0,
                  borderRadius: theme.borderRadius.sm,
                  border: '1px solid',
                  borderColor: theme.palette.lightGrey2.main,
                  backgroundColor: theme.palette.lightGrey3.main,
                }}
                onClick={() => console.log('waiting on design to comment on dropdown')}
              >
                <EllipsisVertical size={20} />
              </IconButton>
            </Stack>
            <CustomLexicalEditor
              showToolbar={true}
              showFooter={false}
              onCancel={() => {}}
              onChange={handleEditorChanges}
              initialHtml={selectedSentRfp?.notification_body || ''}
            />
          </Stack>
          <RfpProvider
            key={selectedSentRfp?.id}
            initialRfpData={rfpData}
            onUpdateRfpData={() => {}}
            initialSelectedPlantIds={excludeDeletedItems(selectedSentRfp?.plants || []).map((p) => p.id)}
            sentRfp={selectedSentRfp || undefined}
            initialSentRfpData={selectedSentRfp}
          >
            <SentRfpPageContent
              editMode={false}
              showHeader={false}
              allowPlantTableSelect={false}
              pageContentType="reviewSentRfp"
              allowPlantTableQuantityChange={false}
            />
          </RfpProvider>
        </Stack>
      </Stack>
      <RfpSentSuccessModal
        open={successModalRfp.length > 0}
        onClose={handleSuccessModalClose}
        rfpId={rfpData?.id}
        sentRfps={successModalRfp}
      />
    </Box>
  )
}

export default function ReviewAndSendStep(props: ReviewAndSendStepProps) {
  if (!props.rfpData) {
    return <div>LOADING RFP DATA...</div>
  }

  return <ReviewAndSendStepContent {...props} />
}
