import Box from '@mui/material/Box'
import { MessageSquareText, NotepadText } from 'lucide-react'
import { RfpSideDrawer } from '@/components/rfps/drawer/rfp-side-drawer.tsx'
import { RfpPageTitle } from '@/components/rfps/rfp-page-title.tsx'
import { RFPStepProps } from '@/components/rfps/types.ts'
import CommentModeContainer from '@/components/rfps/comment-mode-container.tsx'
import RfpPageContent from './rfp-page-content'
import { RfpProvider } from '@/components/rfps/context/rfp-context.tsx'
import { useCommentStore } from '@/stores/comment-store.ts'

function BuildRFPStepContent({ rfpData, onUpdateRfpData }: RFPStepProps) {
  const { setCommentMode, isCommentMode, commentType } = useCommentStore()

  const handleCommentModeClick = (type: 'note' | 'comment') => {
    if (isCommentMode && commentType === type) {
      setCommentMode(false, null)
    } else {
      setCommentMode(true, type)
    }
  }

  return (
    <CommentModeContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <RfpPageTitle
          title={'Add Plants & RFP Details'}
          actionItems={[
            {
              text: 'Add Internal Note',
              onClick: () => handleCommentModeClick('note'),
              startIcon: <NotepadText />,
              active: isCommentMode && commentType === 'note',
              'data-comment-button': 'true',
            },
            {
              text: 'Add Comment to All Vendors',
              onClick: () => handleCommentModeClick('comment'),
              startIcon: <MessageSquareText />,
              active: isCommentMode && commentType === 'comment',
              'data-comment-button': 'true',
            },
          ]}
        />
        <Box
          sx={{
            px: 3,
            pb: 3,
            flexGrow: 1,
            overflow: 'auto',
            scrollbarWidth: 'none',
            height: 0,
          }}
        >
          <RfpProvider initialRfpData={rfpData} onUpdateRfpData={onUpdateRfpData}>
            <RfpPageContent
              editMode={true}
              allowPlantTableSelect={true}
              allowPlantTableQuantityChange={true}
              allowPlantTableReorder={true}
              showPlantTableMenu={true}
            />
          </RfpProvider>
        </Box>
      </Box>
      <RfpSideDrawer />
    </CommentModeContainer>
  )
}

export default function BuildRFPStep({ rfpData, onUpdateRfpData }: RFPStepProps) {
  if (!rfpData) {
    return <div>LOADING RFP...</div>
  }
  return <BuildRFPStepContent rfpData={rfpData} onUpdateRfpData={onUpdateRfpData} />
}
