import { Job, JobPhase } from '@/types.ts'
import { formatStringToShortMonthDayYear } from '@/lib/utils.ts'
import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import JobPhaseMenu from './job-phase-menu.tsx'
import VisibilityCallout from '@/components/rfps/visibility-callout.tsx'
import { useRfpContext } from '@/components/rfps/context/rfp-context.tsx'

export default function PhaseHeader({
  phase,
  job,
  index,
  phaseVisible,
  editMode,
  vendorView = false,
}: {
  phase: JobPhase
  job: Job
  index: number
  phaseVisible: boolean
  editMode: boolean
  vendorView?: boolean
}) {
  const expectedPhaseStartDate = formatStringToShortMonthDayYear(phase.expected_start_date as string) || 'TBD'
  const { setActiveJobId, setActivePhaseId, setEditRfpJobPhaseNameModalOpen } = useRfpContext()
  const showPhaseName = !vendorView || phaseVisible

  const handlePhaseNameEditClick = () => {
    if (!phase?.id) return
    setActiveJobId(job.id)
    setActivePhaseId(phase.id)
    setEditRfpJobPhaseNameModalOpen(true)
  }

  return (
    <Stack sx={{ pt: 1 }}>
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <Typography fontWeight={700} variant="body2">
            Phase #{index + 1}
          </Typography>
          {showPhaseName && (
            <VisibilityCallout
              editable={editMode}
              visible={phaseVisible}
              itemName={phase.name || 'Unnamed Phase'}
              typographyVariant="body2"
              onClick={handlePhaseNameEditClick}
            />
          )}
        </Stack>
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <Typography fontWeight={400} variant="body2">
            Expected Start Date: {expectedPhaseStartDate}
          </Typography>
          {editMode && <JobPhaseMenu />}
        </Stack>
      </Stack>
    </Stack>
  )
}
