export const USER_QUERY_KEYS = {
  userInfo: ['user-info'],
  // invalidate user-info when profile-picture changes
  profilePicture: ['user-info', 'profile-picture'],
}

export const RFP_QUERY_KEYS = {
  all: 'rfps',
  draftRfp: (rfpId: string) => ['rfps', 'drafts', rfpId],
  sentRfps: (rfpId: string, organizationId?: string) => ['rfps', 'sent-rfps', rfpId, organizationId],
  // Add more RFP-related query keys as needed
}

export const VENDOR_QUERY_KEYS = {
  vendorContacts: (vendorContactId: string) => ['vendor-contacts', vendorContactId],
}
