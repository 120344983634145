import { RfpPageContentSkeleton } from '@/components/rfps/skeletons/rfp-page-content-skeleton.tsx'
import { RequestForProposal } from '@/types.ts'
import { Stack, Typography } from '@mui/material'
import { ClearTextIconButtonGroup, TextIconButton } from '@/components/ui/base/buttons/text-icon-buttons.tsx'
import { MessageSquareText, NotepadText } from 'lucide-react'
import theme from '@/theme.ts'
import { useFeatureNotImplemented } from '@/hooks/useFeatureNotImplemented.tsx'
import { Route as RfpDetailsRoute } from '@/routes/$orgId/rfps.$rfpId.tsx'
import { RfpProvider } from '@/components/rfps/context/rfp-context.tsx'
import RfpPageContent from '@/components/rfps/draft/steps/rfp-page-content'

interface DetailsPageRfpContentProps {
  rfpDataLoading: boolean
  rfpData: RequestForProposal | undefined
}
export const DetailsPageRfpContent = ({ rfpDataLoading, rfpData }: DetailsPageRfpContentProps) => {
  const notifyFeatureNotImplemented = useFeatureNotImplemented()
  const { rfpId, orgId } = RfpDetailsRoute.useParams()
  const navigate = RfpDetailsRoute.useNavigate()

  if (rfpDataLoading) return <RfpPageContentSkeleton />
  if (!rfpData) return null

  const handleEditRfp = () => {
    navigate({ to: `/${orgId}/rfps/edit/${rfpId}` })
  }

  const handleNote = () => {
    notifyFeatureNotImplemented()
  }

  const handleComment = () => {
    notifyFeatureNotImplemented()
  }

  const handleUpdateRfpData = (data: Partial<RequestForProposal>) => {
    console.log('Functionality not implemented', data)
  }

  return (
    <Stack
      spacing={3}
      sx={{
        p: 5,
        pb: 0,
        flexGrow: 1,
        width: '100%',
        overflowY: 'hidden',
        scrollbarWidth: 'none',
        position: 'relative',
      }}
    >
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center' }}>
          RFP Details
        </Typography>
        <Stack direction="row" sx={{ gap: 2 }}>
          <ClearTextIconButtonGroup
            items={[
              {
                text: 'Add Note',
                startIcon: <NotepadText size={20} />,
                onClick: () => handleNote(),
              },
              {
                text: 'Comment',
                startIcon: <MessageSquareText size={20} />,
                onClick: () => handleComment(),
              },
            ]}
            sx={{ backgroundColor: 'white' }}
          />
          <TextIconButton onClick={handleEditRfp} text="Edit RFP" sx={{ px: 3, borderRadius: theme.borderRadius.md }} />
        </Stack>
      </Stack>
      <Stack
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          scrollbarWidth: 'none',
          pb: 4,
        }}
      >
        <Stack sx={{ p: 2, flexGrow: 1, backgroundColor: 'white', borderRadius: theme.borderRadius.lg }}>
          <RfpProvider initialRfpData={rfpData} onUpdateRfpData={handleUpdateRfpData}>
            <RfpPageContent />
          </RfpProvider>
        </Stack>
      </Stack>
    </Stack>
  )
}
