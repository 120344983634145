import { PlantListEntry, RequestForProposal } from '@/types.ts'
import { excludeDeletedItems } from '@/lib/utils.ts'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/material'

export default function RequestPricingHeader({
  rfpData,
  sentRfpId,
  plants = [],
  selectedPlantIds,
  sx,
}: {
  rfpData?: RequestForProposal
  sentRfpId?: string
  plants?: PlantListEntry[]
  selectedPlantIds?: Set<string>
  sx?: SxProps
}) {
  let title = ''
  const defaultTitle = 'Request Pricing for Plants'

  // Get the correct plants array based on whether this is a sent RFP or not
  const plantsToCount = sentRfpId
    ? rfpData?.plants.filter((plant: PlantListEntry) => selectedPlantIds?.has(plant.id))
    : excludeDeletedItems(plants)

  if (plantsToCount && plantsToCount.length > 0) {
    const totalItemCount = plantsToCount.reduce(
      (acc: number, plant: any) => acc + (plant.quantity_count?.min || plant.quantity_count?.max || 0),
      0
    )
    title = `Request Pricing for ${plantsToCount.length} Plants (${totalItemCount} Items)`
  }

  return (
    <Typography variant="body1" sx={{ fontWeight: 700, pl: 2, ...sx }}>
      {title || defaultTitle}
    </Typography>
  )
}
