import { useCallback, useMemo } from 'react'
import { useCommentStore } from '@/stores/comment-store.ts'
import { CommentSpecifier, CommentPage } from '@/types/comments.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { useUserInfo } from '@/contexts/hooks/useUserInfo.ts'
import { useQuery } from '@tanstack/react-query'
import { getCommentThreadsByTarget } from '@/api/comments.ts'

export function useComments(
  pathObject: string,
  pathId: string,
  targetPath: string,
  targetPage: CommentPage,
  targetId: string
) {
  const store = useCommentStore()
  const { selectedOrganization } = useOrganization()
  const { userInfo } = useUserInfo()

  const specifier: CommentSpecifier = useMemo(
    () => ({
      path_id: pathId,
      path_object: pathObject,
    }),
    [pathId, pathObject]
  )

  const target = useMemo(
    () => ({
      page: targetPage,
      target_id: targetId,
    }),
    [targetPage, targetId]
  )

  // Use React Query to fetch and cache comment threads
  const { isLoading } = useQuery({
    queryKey: ['commentThreads', target.page, target.target_id, selectedOrganization?.id],
    queryFn: async () => {
      if (!selectedOrganization?.id) return []
      const threads = await getCommentThreadsByTarget(target.page, target.target_id, selectedOrganization.id)
      store.fetchThreadsForTarget(target.page, target.target_id, selectedOrganization.id)
      return threads
    },
    enabled: !!selectedOrganization?.id,
  })

  const thread = store.findThreadForSpecifier(specifier, targetPath)
  const hasComments = thread !== null && thread.comments.length > 0
  const isNote = thread?.directed_organization === null && !thread?.send_to_vendor

  const createComment = useCallback(
    (text: string, isNote: boolean, directedOrganizationId?: string) => {
      if (!selectedOrganization?.id || !userInfo?.user?.id) {
        return Promise.reject(new Error('User or organization not available'))
      }

      let sendToVendor = false
      if (!isNote && !directedOrganizationId) {
        sendToVendor = true // send to all vendors if no directed organization and is not a note
      }

      return store.createCommentThread(
        specifier,
        target,
        targetPath,
        text,
        selectedOrganization.id,
        userInfo.user.id,
        isNote,
        directedOrganizationId,
        sendToVendor
      )
    },
    [store, specifier, target, targetPath, selectedOrganization?.id, userInfo?.user?.id]
  )

  const addComment = useCallback(
    (text: string, mentionedUserIds = [], mentionedDirectedUserIds = []) => {
      if (!thread || !userInfo?.user?.id) {
        return Promise.reject(new Error('Thread not found or user not available'))
      }

      return store.addCommentToThread(thread.id, text, userInfo.user.id, mentionedUserIds, mentionedDirectedUserIds)
    },
    [store, thread, userInfo?.user?.id]
  )

  return {
    thread,
    hasComments,
    isNote,
    commentCount: thread?.comments.length || 0,
    createComment,
    addComment,
    specifier,
    target,
    targetPath,
    isLoading,
    error: store.error,
  }
}
