import { InfoButton } from '@/components/ui/base/buttons/buttons'
import theme from '@/theme'
import { Box, IconButton } from '@mui/material'
import { Skeleton } from '@mui/material'
import { Fade, TableCell, TableRow, Typography } from '@mui/material'
import { TableBody } from '@mui/material'
import { Table } from '@mui/material'
import { TableContainer } from '@mui/material'
import { ChevronRight } from 'lucide-react'

export function LoadingSkeleton() {
  return (
    <TableContainer>
      <Table>
        <TableBody
          sx={{
            borderTop: '1px solid #8B97A0',
          }}
        >
          {Array.from({ length: 10 }).map((_, i) => (
            <Fade key={i} in timeout={300} style={{ transitionDelay: `${i * 50}ms` }}>
              <TableRow>
                <TableCell width={48} padding="none">
                  <IconButton size="small">
                    <ChevronRight size={20} />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <Typography variant="indexAccordionHeader">
                    <Skeleton variant="text"></Skeleton>
                  </Typography>
                </TableCell>
              </TableRow>
            </Fade>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export function EmptyStateMessage({ message, link }: { message?: string; link?: string }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        gap={'23px'}
        borderRadius={theme.borderRadius.lg}
        border={`1px solid ${theme.palette.grey[400]}`}
        p={6}
      >
        <Typography textAlign={'center'} variant="h2">
          {message}
        </Typography>
        {link && (
          <a href={link} target="_blank" rel="noopener noreferrer">
            <InfoButton>Learn More</InfoButton>
          </a>
        )}
      </Box>
    </Box>
  )
}
