import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { $isImageNode, ImageNode } from './lexical_image_node'
import { FontSizeDropdown } from './lexical_toolbar_component_font_size.tsx' // We'll create this
import { $getSelectionStyleValueForProperty } from '@lexical/selection'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import { $getSelection, $isRangeSelection, SELECTION_CHANGE_COMMAND } from 'lexical'
import { useCallback, useEffect, useRef, useState } from 'react'
import { UndoRedoComponent } from './lexical_toolbar_component_undo_redo.tsx'
import { AlignmentComponent } from './lexical_toolbar_component_alignment.tsx'
import { FontFormatComponent } from './lexical_toolbar_component_font_format.tsx'
import { ListFormatComponent } from './lexical_toolbar_component_list_format.tsx'
import { CustomListNode } from './lexical_editor_types.ts'
import LinkComponent from './lexical_toolbar_component_links.tsx'
import ImageComponent from './lexical_toolbar_component_images.tsx'

const LowPriority = 1

export default function ToolbarPlugin() {
  const [editor] = useLexicalComposerContext()
  const toolbarRef = useRef(null)
  const [activeFormats, setActiveFormats] = useState<string[]>([])

  const [isImageSelected, setIsImageSelected] = useState(false)
  const [, setSelectedImageAlignment] = useState<string>('left')
  const [fontSize, setFontSize] = useState<string>('medium')

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const formats = []
      if ($getSelectionStyleValueForProperty(selection, 'font-weight', '') === 'bold') {
        formats.push('bold')
      }
      if ($getSelectionStyleValueForProperty(selection, 'font-style', '') === 'italic') {
        formats.push('italic')
      }
      if ($getSelectionStyleValueForProperty(selection, 'text-decoration', '').includes('underline')) {
        formats.push('underline')
      }
      if ($getSelectionStyleValueForProperty(selection, 'text-decoration', '').includes('line-through')) {
        formats.push('strikethrough')
      }
      setActiveFormats(formats)

      const nodes = selection.getNodes()
      const imageNode = nodes.find((node): node is ImageNode => $isImageNode(node))
      setIsImageSelected(!!imageNode)
      if (imageNode) {
        setSelectedImageAlignment(imageNode.getAlignment())
      }
      const fontSize = $getSelectionStyleValueForProperty(selection, 'font-size', 'medium')
      setFontSize(fontSize)

      const parent = nodes[0]?.getParent()
      const grandparent = parent?.getParent()
      if (grandparent && grandparent.getType() === 'list') {
        const listType = (grandparent as CustomListNode).getListType()
        if (['number', 'bullet'].includes(listType)) {
          setActiveFormats((prev) => {
            if (!prev.includes(listType)) {
              return [...prev, listType]
            }
            return prev
          })
        } else {
          setActiveFormats((prev) => prev.filter((format) => format !== 'number' && format !== 'bullet'))
        }
      }
    }
  }, [])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar()
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, _newEditor) => {
          $updateToolbar()
          return false
        },
        LowPriority
      )
    )
  }, [editor, isImageSelected, $updateToolbar])

  return (
    <Box
      ref={toolbarRef}
      sx={{
        display: 'flex',
        alignItems: 'left',
        gap: 1,
        p: 1,
        borderBottom: '1px solid',
        borderColor: 'divider',
      }}
    >
      <UndoRedoComponent />

      <Divider orientation="vertical" flexItem />

      <FontSizeDropdown fontSize={fontSize} editor={editor} />

      <Divider orientation="vertical" flexItem />

      <FontFormatComponent activeFormats={activeFormats} />

      <Divider orientation="vertical" flexItem />

      <AlignmentComponent isImageSelected={isImageSelected} />

      <Divider orientation="vertical" flexItem />

      <ListFormatComponent activeFormats={activeFormats} />

      <Divider orientation="vertical" flexItem />

      <LinkComponent />
      <ImageComponent />
    </Box>
  )
}
