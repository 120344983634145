import { useCallback, useState } from 'react'
import { SearchVendorModal, SearchVendorModalProps } from '@/components/ui/modals/search-vendor-modal.tsx'

export const useSearchVendorModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [config, setConfig] = useState<Omit<SearchVendorModalProps, 'open'>>({
    title: 'Add Vendor',
    cancelButtonText: 'Cancel & Close',
    submitButtonText: 'Submit',
    onSubmit: () => undefined,
  })

  const openModal = useCallback((modalConfig: Partial<Omit<SearchVendorModalProps, 'open'>>) => {
    setConfig((prev) => ({ ...prev, ...modalConfig }))
    setIsOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setIsOpen(false)
    config?.onClose?.()
  }, [config])

  const Component = useCallback(() => {
    const { onClose: _, ...restConfig } = config
    return <SearchVendorModal open={isOpen} onClose={closeModal} {...restConfig} />
  }, [isOpen, closeModal, config])

  return {
    openSearchVendorModal: openModal,
    closeSearchVendorModal: closeModal,
    SearchVendorModal: Component,
  }
}
