import { OrganizationContact, UserContact } from '@/types.ts'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import theme from '@/theme.ts'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/material'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import { ArrowLeft, Search } from 'lucide-react'
import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { useDebounce } from '@uidotdev/usehooks'
import { useQuery } from '@tanstack/react-query'
import { getOrganizationMemberContacts } from '@/api/contacts.ts'
import { getDefaultPlantbidOrganizationId, getUserFullName } from '@/lib/utils.ts'
import Alert from '@mui/material/Alert'
import { ClearTextIconButton } from '@/components/ui/base/buttons/text-icon-buttons.tsx'
import { VENDOR_QUERY_KEYS } from '@/lib/query-keys.ts'
import { OrganizationDetails } from '../organization-details'
import { CustomDivider } from '@/components/ui/base/dividers.tsx'
import { ContactsCheckboxList } from '@/components/contacts/contacts-checkbox-list.tsx'
import { ContactListSkeleton } from '@/components/contacts/contact-list-skeleton.tsx'
import ModalHeader from '@/components/ui/base/modal-header.tsx'

export interface VendorContactModalProps {
  title: string
  open: boolean
  multiSelect: boolean
  cancelButtonText: string
  submitButtonText: string
  vendorContact: OrganizationContact | null
  onSubmit: (contacts: UserContact[]) => void
  onClose?: () => void
  onBackNavigate?: () => void
  minimumSearchLength?: number
  loading?: boolean
}

export const VendorContactModal = ({
  title,
  open,
  onClose,
  vendorContact,
  multiSelect,
  cancelButtonText,
  submitButtonText,
  onSubmit,
  loading = false,
  onBackNavigate,
}: VendorContactModalProps) => {
  const { selectedOrganization } = useOrganization()
  const [searchString, setSearchString] = useState('')
  const [allowSubmission, setAllowSubmission] = useState(false)
  const [plantbidOrganization, setPlantbidOrganization] = useState(false)
  const [selectedContacts, setSelectedContacts] = useState<UserContact[]>([])
  const [filteredContacts, setFilteredContacts] = useState<UserContact[]>([])
  const debouncedSearchString = useDebounce(searchString, 500)
  const plantbidOrganizationId = getDefaultPlantbidOrganizationId()

  const { data: memberContacts, isLoading: memberContactsLoading } = useQuery({
    queryKey: [
      VENDOR_QUERY_KEYS.vendorContacts(vendorContact?.id as string),
      {
        vendorContactId: vendorContact?.id,
        organizationId: selectedOrganization?.id,
      },
    ],
    queryFn: () => getOrganizationMemberContacts(selectedOrganization?.id as string, vendorContact?.id as string),
    enabled: !!selectedOrganization?.id && !!vendorContact?.id,
  })

  useEffect(() => {
    const isPlantbidContact = vendorContact?.contact_organization?.id === plantbidOrganizationId

    const filteredContacts =
      debouncedSearchString.length && memberContacts?.length
        ? memberContacts.filter((contact: UserContact) =>
            getUserFullName(contact).toLowerCase().includes(debouncedSearchString.toLowerCase())
          )
        : memberContacts || []

    setFilteredContacts(filteredContacts)
    setAllowSubmission(filteredContacts.length > 0 || isPlantbidContact)
    setPlantbidOrganization(isPlantbidContact)
  }, [debouncedSearchString, memberContacts, plantbidOrganizationId, plantbidOrganizationId])

  if (!vendorContact) {
    return null
  }

  const onContactSelect = (contact: UserContact) => {
    if (multiSelect) {
      // Multiple selection mode
      selectedContacts.includes(contact)
        ? setSelectedContacts(selectedContacts.filter((c) => c.id !== contact.id))
        : setSelectedContacts([...selectedContacts, contact])
    } else {
      // Single selection mode - replace the entire selection
      setSelectedContacts([contact])
    }
  }

  const handleClose = () => {
    onClose?.()
  }

  return (
    <Modal open={open} onClose={handleClose} closeAfterTransition>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 6,
          borderRadius: theme.borderRadius.md,
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <ModalHeader title={title || ''} variant="modalHeader3" sx={{ fontWeight: 400, pb: 2 }} />
        <Stack direction="row" sx={{ gap: 4 }}>
          <Stack sx={{ minWidth: 400 }}>
            <OrganizationDetails organizationContact={vendorContact} displayVerified={true} />
          </Stack>
          <Stack sx={{ flexGrow: 1, minWidth: 500, maxHeight: 500, overflowY: 'hidden' }}>
            {!(memberContacts?.length === 0 && plantbidOrganization) && (
              <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center', pb: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  Send to the following people:
                </Typography>
                <TextField
                  placeholder="Search"
                  variant="standard"
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="start">
                          <Search style={{ color: theme.palette.text.disabled, paddingRight: '5px' }} />
                        </InputAdornment>
                      ),
                    },
                  }}
                  sx={{
                    '& .MuiInput-underline:before': { borderBottom: 'none' },
                    '& .MuiInput-underline:after': { borderBottom: 'none' },
                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottom: 'none' },
                    '& .MuiInputBase-input': { outline: 'none', textAlign: 'right', pr: 1 },
                  }}
                  onChange={(e) => setSearchString(e.target.value)}
                />
              </Stack>
            )}

            <CustomDivider size="1.5px" />

            <Stack sx={{ p: 0 }}>
              {memberContactsLoading ? (
                <ContactListSkeleton count={3} />
              ) : filteredContacts.length === 0 ? (
                memberContacts?.length ? (
                  <Alert variant="standard" severity="info" sx={{ p: 3 }}>
                    No contacts were found matching the search term
                  </Alert>
                ) : plantbidOrganization ? (
                  <Alert variant="standard" severity="info" sx={{ p: 3 }}>
                    Please click the button below to add Plantbid as vendor to this RFP
                  </Alert>
                ) : (
                  <Alert variant="standard" severity="warning" sx={{ p: 3 }}>
                    No contacts are available for this organization
                  </Alert>
                )
              ) : (
                <ContactsCheckboxList
                  contacts={filteredContacts}
                  selectedContacts={selectedContacts}
                  onSelect={onContactSelect}
                />
              )}
            </Stack>

            <CustomDivider size="1.5px" />
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row">
            {onBackNavigate && (
              <ClearTextIconButton
                text="Back"
                startIcon={<ArrowLeft />}
                onClick={onBackNavigate}
                sx={{ color: theme.palette.mediumGrey2.main }}
              />
            )}
          </Stack>
          <Stack direction="row" sx={{ justifyContent: 'flex-end', gap: 2 }}>
            <ClearTextIconButton
              onClick={handleClose}
              text={cancelButtonText}
              sx={{ color: theme.palette.mediumGrey2.main }}
            />
            <Button
              loading={loading}
              variant="contained"
              loadingPosition="start"
              onClick={() => onSubmit(selectedContacts)}
              disabled={!allowSubmission || memberContactsLoading || loading}
            >
              {loading ? 'Loading...' : submitButtonText}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  )
}
