import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { FORMAT_ELEMENT_COMMAND } from 'lexical'
import { IconButton, Tooltip } from '@mui/material'
import { FormatAlignLeft, FormatAlignCenter, FormatAlignRight, FormatAlignJustify } from '@mui/icons-material'
import { useCallback } from 'react'
import { AlignmentType, AlignmentComponentProps } from './lexical_editor_types.ts'
import { FORMAT_IMAGE_COMMAND } from './lexical_editor_commands.ts'

export const AlignmentComponent = ({ isImageSelected }: AlignmentComponentProps) => {
  const [editor] = useLexicalComposerContext()

  const formatAlignment = useCallback(
    (alignment: AlignmentType) => {
      const target = isImageSelected ? FORMAT_IMAGE_COMMAND : FORMAT_ELEMENT_COMMAND
      editor.dispatchCommand(target, alignment)
    },
    [editor, isImageSelected]
  )

  const alignmentButtons = [
    { icon: <FormatAlignLeft />, value: 'left', tooltip: 'Align Left' },
    { icon: <FormatAlignCenter />, value: 'center', tooltip: 'Align Center' },
    { icon: <FormatAlignRight />, value: 'right', tooltip: 'Align Right' },
    { icon: <FormatAlignJustify />, value: 'justify', tooltip: 'Justify' },
  ]

  return (
    <>
      {alignmentButtons.map(({ icon, value, tooltip }) => (
        <Tooltip key={value} title={tooltip}>
          <IconButton size="small" onClick={() => formatAlignment(value as AlignmentType)}>
            {icon}
          </IconButton>
        </Tooltip>
      ))}
    </>
  )
}
