import { Button, Typography } from '@mui/material'
import { Box } from '@mui/material'
import { createFileRoute, Link, Outlet } from '@tanstack/react-router'

export const Route = createFileRoute('/$orgId')({
  component: OrganizationGate,

  beforeLoad: async ({ context: { plantbid, auth }, params }) => {
    // Authenticate the user
    if (!auth.isAuthenticated && !auth.isLoading) {
      throw await auth.loginWithRedirect({ appState: { returnTo: location.href } })
    }

    // Check if the user is a member of the organization
    const userOrgs = plantbid.user?.user.organization_members
    if (
      !auth.isLoading &&
      userOrgs &&
      !userOrgs?.some((org) => {
        return org.organization.id === params.orgId
      })
    ) {
      console.log('Not a member of org')
      throw new Error('Not a member of org')
    }

    if (params.orgId !== plantbid.organization) {
      plantbid.setOrganization(params.orgId)
    }
  },
  errorComponent: () => {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh" p={4}>
        <Typography variant="h2" gutterBottom>
          You do not have access to this organization.
        </Typography>

        <Typography variant="body1" color="text.secondary" align="center">
          Please contact an organization administrator to request access.
        </Typography>
        {/* TODO: add a button to redirect to the home page */}
        <Button sx={{ mt: 2 }} variant="contained" color="primary">
          <Link to="/">Go Home</Link>
        </Button>
      </Box>
    )
  },
})

function OrganizationGate() {
  return (
    <>
      <Outlet />
    </>
  )
}
