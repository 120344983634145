import { IconButton, Stack } from '@mui/material'
import {
  FormatListBulleted as FormatListBulletedIcon,
  FormatListNumbered as FormatListNumberedIcon,
} from '@mui/icons-material'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { INSERT_UNORDERED_LIST_COMMAND, INSERT_ORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND } from '@lexical/list'
import { ListFormatProps } from './lexical_editor_types.ts'

export const ListFormatComponent = ({ activeFormats }: ListFormatProps) => {
  const [editor] = useLexicalComposerContext()

  const handleClick = (key: string) => {
    if (activeFormats.includes(key)) {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(key === 'number' ? INSERT_ORDERED_LIST_COMMAND : INSERT_UNORDERED_LIST_COMMAND, undefined)
    }
  }

  return (
    <Stack direction="row" spacing={1}>
      <IconButton onClick={() => handleClick('bullet')} size="small" aria-label="Unordered List">
        <FormatListBulletedIcon />
      </IconButton>

      <IconButton onClick={() => handleClick('number')} size="small" aria-label="Ordered List">
        <FormatListNumberedIcon />
      </IconButton>
    </Stack>
  )
}
