import { useEffect, useRef, useState, type ChangeEvent, type KeyboardEvent, type MouseEvent } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import { Stack } from '@mui/material'
import theme from '@/theme.ts'
import { X, Flag, CircleCheck } from 'lucide-react'
import { useUserInfo } from '@/contexts/hooks/useUserInfo.ts'
import { useProfilePicture } from '@/hooks/user-profile/useProfilePicture'
import Avatar from '@mui/material/Avatar'
import { CircleUserRound } from 'lucide-react'
import Button from '@mui/material/Button'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { format } from 'date-fns'
import Popover from '@mui/material/Popover'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { getOrganizationMembers } from '@/api/organization_member.ts'
import { useQuery } from '@tanstack/react-query'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import type { OrganizationMember } from '@/types.ts'
import { CommentThread } from '@/types/comments.ts'
import { useCommentStore } from '@/stores/comment-store.ts'
import { useAuth0 } from '@auth0/auth0-react'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'

export default function CommentUI({
  anchorEl,
  onClose,
  onSubmit,
  itemName = '[item]',
  commentThread,
}: {
  anchorEl: HTMLElement | null
  onClose: () => void
  onSubmit: (text: string) => void
  itemName?: string // Name of the item being commented on
  commentThread: CommentThread
}) {
  const { commentType, commentText, setCommentText } = useCommentStore()
  const { user } = useAuth0()
  const { userInfo } = useUserInfo()
  const { data: profilePicture } = useProfilePicture(userInfo?.user)
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const [dueDate, setDueDate] = useState<Date | null>(null)
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const [placement, setPlacement] = useState<{
    anchorOrigin: { vertical: 'top' | 'bottom'; horizontal: 'left' | 'right' }
    transformOrigin: { vertical: 'top' | 'bottom'; horizontal: 'left' | 'right' }
  }>({
    anchorOrigin: { vertical: 'top', horizontal: 'right' },
    transformOrigin: { vertical: 'bottom', horizontal: 'left' },
  })

  // States for @ mention functionality
  const { selectedOrganization } = useOrganization()
  const [mentionAnchorEl, setMentionAnchorEl] = useState<HTMLElement | null>(null)
  const [mentionQuery, setMentionQuery] = useState('')
  const [mentionStartIndex, setMentionStartIndex] = useState<number | null>(null)
  const [cursorPosition, setCursorPosition] = useState<number>(0)
  const [selectedMemberIndex, setSelectedMemberIndex] = useState(0)

  // Fetch organization members
  const { data: organizationMembers } = useQuery({
    queryKey: ['organization-members', 'comments', selectedOrganization?.id],
    queryFn: () => (selectedOrganization?.id ? getOrganizationMembers(selectedOrganization.id) : Promise.resolve([])),
    enabled: !!selectedOrganization?.id && !!mentionAnchorEl && commentType === 'note',
  })

  // Filter members based on the mention query
  const filteredMembers =
    organizationMembers?.filter((member) => {
      const fullName = `${member.user.first_name} ${member.user.last_name}`.toLowerCase()
      const email = member.user.email.toLowerCase()
      return fullName.includes(mentionQuery.toLowerCase()) || email.includes(mentionQuery.toLowerCase())
    }) || []

  // Reset selected member index when filtered members change
  useEffect(() => {
    setSelectedMemberIndex(0)
  }, [filteredMembers.length, mentionQuery])

  const COMMENT_UI_WIDTH = 400

  useEffect(() => {
    if (inputRef.current && anchorEl) {
      inputRef.current.focus()
    }
  }, [anchorEl])

  useEffect(() => {
    if (!anchorEl) return

    // Check if we have enough space on the right
    const rect = anchorEl.getBoundingClientRect()
    const spaceOnRight = window.innerWidth - rect.right

    if (spaceOnRight < COMMENT_UI_WIDTH) {
      // Not enough space on the right, flip to left side
      setPlacement((prev) => ({
        ...prev,
        transformOrigin: { vertical: 'bottom', horizontal: 'right' },
      }))
    } else {
      // Enough space on the right, use default placement
      setPlacement((prev) => ({
        ...prev,
        transformOrigin: { vertical: 'bottom', horizontal: 'left' },
      }))
    }
  }, [anchorEl])

  const handleSubmit = () => {
    if (commentText.trim()) {
      onSubmit(commentText)
      setCommentText('')
      onClose()
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    // Update cursor position on every key press
    if (inputRef.current) {
      setCursorPosition(inputRef.current.selectionStart)
    }

    if (event.key === 'Escape') {
      if (mentionAnchorEl) {
        // Close mention popover if open
        setMentionAnchorEl(null)
        setMentionQuery('')
        setMentionStartIndex(null)
      } else {
        onClose()
      }
    } else if (event.key === 'Enter' && !event.shiftKey) {
      if (mentionAnchorEl && filteredMembers.length > 0) {
        // Select the highlighted member when Enter is pressed
        handleMentionSelect(filteredMembers[selectedMemberIndex])
        event.preventDefault()
      } else {
        event.preventDefault()
        handleSubmit()
      }
    } else if (event.key === '@' && !mentionAnchorEl && commentType === 'note') {
      // When @ is typed in a note, open the mention popover
      setMentionAnchorEl(inputRef.current)
      setMentionStartIndex(cursorPosition)
      setMentionQuery('')
      setSelectedMemberIndex(0)

      // Calculate position for the mention popover
      if (inputRef.current) {
        const textArea = inputRef.current
        const cursorCoords = getCursorCoordinates(textArea, cursorPosition)
        console.log('Cursor coordinates:', cursorCoords)
      }
    } else if (mentionAnchorEl) {
      // Handle navigation in the mention list
      if (event.key === 'ArrowDown') {
        event.preventDefault() // Prevent cursor movement in the text field
        setSelectedMemberIndex((prevIndex) => (prevIndex < filteredMembers.length - 1 ? prevIndex + 1 : prevIndex))
      } else if (event.key === 'ArrowUp') {
        event.preventDefault() // Prevent cursor movement in the text field
        setSelectedMemberIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0))
      } else if (event.key === ' ' || event.key === 'Tab') {
        // Close mention popover on space or tab
        setMentionAnchorEl(null)
        setMentionQuery('')
        setMentionStartIndex(null)
      }
    }
  }

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation()
  }

  const handleFlagAsImportant = () => {
    console.log('Comment flagged as important:', { itemName })
  }

  const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newText = e.target.value
    setCommentText(newText)

    // Update cursor position
    if (inputRef.current) {
      const newCursorPosition = inputRef.current.selectionStart
      setCursorPosition(newCursorPosition)
    }

    // Handle @ mention (only for notes)
    if (mentionStartIndex !== null && commentType === 'note') {
      // Check if the @ symbol has been deleted
      if (
        mentionStartIndex >= newText.length || // Text is now shorter than where @ was
        newText.charAt(mentionStartIndex) !== '@' // Character at @ position is no longer @
      ) {
        // Close the mention popup if @ is deleted
        setMentionAnchorEl(null)
        setMentionQuery('')
        setMentionStartIndex(null)
        return
      }

      // Only process if we have a valid mention start index and cursor is after the @
      if (inputRef.current && inputRef.current.selectionStart > mentionStartIndex) {
        const newCursorPos = inputRef.current.selectionStart
        const currentQuery = newText.substring(mentionStartIndex + 1, newCursorPos)
        setMentionQuery(currentQuery)
      }
    }
  }

  const handleMentionSelect = (member: OrganizationMember) => {
    if (mentionStartIndex !== null) {
      // Insert the selected member's name into the text
      const beforeMention = commentText.substring(0, mentionStartIndex)
      const afterMention = commentText.substring(cursorPosition)
      const mentionText = `@${member.user.first_name} ${member.user.last_name} `

      const newText = beforeMention + mentionText + afterMention
      setCommentText(newText)

      // Close the mention popover
      setMentionAnchorEl(null)
      setMentionQuery('')
      setMentionStartIndex(null)

      // Calculate the new cursor position
      const newCursorPosition = mentionStartIndex + mentionText.length

      // Update the cursor position state
      setCursorPosition(newCursorPosition)

      // Focus back on the input and set cursor position after the inserted mention
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus()
          inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition)
        }
      }, 0)
    }
  }

  // Helper function to get cursor coordinates in a textarea
  const getCursorCoordinates = (textArea: HTMLTextAreaElement, position: number) => {
    // Create a clone of the textarea
    const clone = document.createElement('div')
    clone.style.position = 'absolute'
    clone.style.visibility = 'hidden'
    clone.style.whiteSpace = 'pre-wrap'
    clone.style.overflow = 'auto'
    clone.style.width = `${textArea.offsetWidth}px`
    clone.style.height = 'auto'
    clone.style.fontSize = window.getComputedStyle(textArea).fontSize
    clone.style.fontFamily = window.getComputedStyle(textArea).fontFamily
    clone.style.lineHeight = window.getComputedStyle(textArea).lineHeight
    clone.style.padding = window.getComputedStyle(textArea).padding

    // Get text up to cursor position
    const text = textArea.value.substring(0, position)
    clone.textContent = text

    // Add a span at the cursor position
    const span = document.createElement('span')
    span.id = 'cursor-position'
    clone.appendChild(span)

    // Add to document, measure, then remove
    document.body.appendChild(clone)
    const coords = {
      left: span.offsetLeft,
      top: span.offsetTop + span.offsetHeight,
    }
    document.body.removeChild(clone)

    return coords
  }

  const isNote = commentType === 'note'
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={placement.anchorOrigin}
      transformOrigin={placement.transformOrigin}
      data-comment-ui="true"
      sx={{
        '& .MuiPopover-paper': {
          width: COMMENT_UI_WIDTH,
          p: 2,
          ...(isNote
            ? {
                backgroundColor: '#FFFFEE',
                border: '1px solid #84863E',
                borderRadius: 0,
              }
            : {
                backgroundColor: 'white',
                borderRadius: theme.borderRadius.xl,
                ...(placement.transformOrigin.horizontal === 'left'
                  ? { borderBottomLeftRadius: 0 }
                  : { borderBottomRightRadius: 0 }),
              }),
        },
      }}
      onClick={handleClick}
    >
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              {isNote ? 'Note' : 'Comment'}
            </Box>
            <Box component="span" sx={{ fontWeight: 'normal' }}>
              {' on ' + itemName}
            </Box>
          </Typography>
          <Stack direction="row" spacing={1}>
            {commentThread.comments.length > 0 && (
              <IconButton
                onClick={() => console.log('Mark as resolved clicked')}
                size="small"
                sx={{ color: theme.palette.text.secondary }}
              >
                <CircleCheck size={20} />
              </IconButton>
            )}
            <IconButton onClick={onClose} size="small">
              <X size={20} />
            </IconButton>
          </Stack>
        </Stack>

        {commentThread.comments.map((commentData) => (
          <Stack direction="row" spacing={2} key={commentData.id}>
            <Avatar sx={{ width: 32, height: 32 }} src={commentData.created_by?.user.profile_picture?.get_url}>
              {commentData.created_by?.user.first_name?.[0]}
            </Avatar>
            <Stack spacing={1}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {commentData.created_by?.user.first_name} {commentData.created_by?.user.last_name}
              </Typography>
              {commentData.created_at && (
                <Typography variant="caption" sx={{}}>
                  {format(new Date(commentData.created_at), "MMM d, yyyy 'at' h:mm a")}
                </Typography>
              )}
              <Typography variant="body2">{commentData.comment}</Typography>
            </Stack>
          </Stack>
        ))}

        <Stack spacing={2}>
          <Stack direction="row" spacing={2} alignItems="flex-start">
            {userInfo?.user && (
              <Avatar
                sx={{ width: 32, height: 32 }}
                src={profilePicture?.url || user?.picture}
                alt={userInfo.name || 'User'}
              />
            )}
            <Stack spacing={1} sx={{ width: '100%', position: 'relative' }}>
              <TextField
                multiline
                rows={2}
                value={commentText}
                onChange={handleTextChange}
                onKeyDown={handleKeyDown}
                placeholder={commentThread.comments.length > 0 ? 'Reply ...' : 'Enter comment ...'}
                fullWidth
                autoFocus
                inputRef={inputRef}
                onClick={() => {
                  if (inputRef.current) {
                    setCursorPosition(inputRef.current.selectionStart)
                  }
                }}
                onFocus={() => {
                  if (inputRef.current) {
                    setCursorPosition(inputRef.current.selectionStart)
                  }
                }}
                onSelect={() => {
                  if (inputRef.current) {
                    setCursorPosition(inputRef.current.selectionStart)
                  }
                }}
              />

              {/* @ Mention Popover - only for notes */}
              {commentType === 'note' && (
                <Popover
                  open={Boolean(mentionAnchorEl)}
                  anchorEl={mentionAnchorEl}
                  disableAutoFocus
                  disableEnforceFocus
                  disableRestoreFocus
                  onClose={() => {
                    setMentionAnchorEl(null)
                    setMentionQuery('')
                    setMentionStartIndex(null)
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  sx={{
                    '& .MuiPopover-paper': {
                      width: 'auto',
                      minWidth: 250,
                      maxWidth: '80%',
                      maxHeight: 200,
                      mt: 0.5,
                      boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
                      borderRadius: theme.borderRadius.md,
                      border: `1px solid ${theme.palette.divider}`,
                      zIndex: theme.zIndex.modal + 2,
                    },
                  }}
                  container={inputRef.current?.parentElement}
                  slotProps={{
                    paper: {
                      elevation: 4,
                      sx: {
                        overflow: 'auto',
                      },
                    },
                  }}
                  marginThreshold={0}
                >
                  <List sx={{ p: 0 }}>
                    {filteredMembers.length > 0 ? (
                      filteredMembers.map((member, index) => (
                        <ListItem
                          key={member.id}
                          onClick={() => handleMentionSelect(member)}
                          sx={{
                            cursor: 'pointer',
                            padding: '6px 12px',
                            backgroundColor:
                              index === selectedMemberIndex ? theme.palette.action.selected : 'transparent',
                            '&:hover': {
                              backgroundColor:
                                index === selectedMemberIndex
                                  ? theme.palette.action.selected
                                  : theme.palette.action.hover,
                            },
                          }}
                          divider={index < filteredMembers.length - 1}
                          dense
                        >
                          <ListItemAvatar sx={{ minWidth: 40 }}>
                            <Avatar sx={{ width: 28, height: 28 }}>
                              <CircleUserRound size={16} />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              member.user.first_name || member.user.last_name
                                ? `${member.user.first_name} ${member.user.last_name}`.trim()
                                : member.user.email
                            }
                            secondary={member.user.email}
                            primaryTypographyProps={{
                              fontWeight: index === selectedMemberIndex ? 'bold' : 'regular',
                              fontSize: '0.9rem',
                              noWrap: true,
                            }}
                            secondaryTypographyProps={{
                              fontSize: '0.8rem',
                              noWrap: true,
                            }}
                            sx={{ margin: 0 }}
                          />
                        </ListItem>
                      ))
                    ) : (
                      <ListItem dense>
                        <ListItemText
                          primary="No members found"
                          primaryTypographyProps={{
                            fontSize: '0.9rem',
                            fontStyle: 'italic',
                            textAlign: 'center',
                          }}
                        />
                      </ListItem>
                    )}
                  </List>
                </Popover>
              )}

              <Stack direction="row" justifyContent="space-between">
                <Button
                  variant="text"
                  size="small"
                  startIcon={<Flag size={16} />}
                  onClick={handleFlagAsImportant}
                  sx={{ color: theme.palette.text.secondary }}
                >
                  Flag as Important
                </Button>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    open={datePickerOpen}
                    onClose={() => setDatePickerOpen(false)}
                    value={dueDate}
                    onChange={(newValue) => {
                      setDueDate(newValue)
                      setDatePickerOpen(false)
                    }}
                    minDate={new Date()}
                    slots={{
                      textField: () => (
                        <Button
                          variant="text"
                          size="small"
                          onClick={() => setDatePickerOpen(true)}
                          sx={{ color: theme.palette.text.secondary }}
                        >
                          {dueDate ? `Due: ${format(dueDate, 'MMM d, yyyy')}` : '+ Due Date'}
                        </Button>
                      ),
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Popover>
  )
}
