import { getOrganizationById } from '@/api/organization_management'
import PageLayout from '@/components/ui/layouts/page-layout'
import { CardActionArea, CardContent } from '@mui/material'
import { Card, Grid2 as Grid } from '@mui/material'
import { Typography } from '@mui/material'
import { Box } from '@mui/material'
import { createFileRoute, Link } from '@tanstack/react-router'
import {} from 'lucide-react'

export const Route = createFileRoute('/$orgId/')({
  loader: async ({ params }) => {
    // TODO: use react query to handle this
    const org = await getOrganizationById(params.orgId)

    // This will never hit because of the error component in the parent route
    // But it's here to satisfy the type checker
    if (!org) {
      throw new Error('Organization not found')
    }

    return {
      org,
    }
  },
  component: Index,
})

function Index() {
  const { org } = Route.useLoaderData()
  return (
    <PageLayout title={org.name + ' Organization Dashboard'}>
      <Box sx={{ flexGrow: 1, py: 3 }}>
        <Grid container spacing={3}>
          <Grid>
            <Card sx={{ height: '100%' }}>
              <CardActionArea component={Link} to="plants">
                <CardContent>
                  <Typography variant="h5" component="h2">
                    Plants
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Manage your manufacturing plants
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid>
            <Card sx={{ height: '100%' }}>
              <CardActionArea component={Link} to="rfps">
                <CardContent>
                  <Typography variant="h5" component="h2">
                    RFPs
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    View and manage requests for proposals
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid>
            <Card sx={{ height: '100%' }}>
              <CardActionArea component={Link} to="jobs">
                <CardContent>
                  <Typography variant="h5" component="h2">
                    Jobs
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Track and manage ongoing jobs
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid>
            <Card sx={{ height: '100%' }}>
              <CardActionArea component={Link} to="quotes">
                <CardContent>
                  <Typography variant="h5" component="h2">
                    Quotes
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Review and manage quotes
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </PageLayout>
  )
}
