import { RFPIndexDrafts } from '@/api/types/rfps'
import { useCallback, useState, MouseEvent } from 'react'
import { DraftMenu } from '../index/draft-menu'

type PickFields = 'id' | 'flagged_as_important' | 'state'
export type DraftMenuItem = Pick<RFPIndexDrafts['data'][number], PickFields>

export interface DraftMenuState {
  draft: DraftMenuItem | null
  anchorEl: HTMLElement | null
}

export function useDraftMenu() {
  const [menuState, setMenuState] = useState<DraftMenuState>({
    draft: null,
    anchorEl: null,
  })

  const openMenu = useCallback((event: MouseEvent<HTMLElement>, draft: DraftMenuItem) => {
    event.stopPropagation()
    setMenuState({ anchorEl: event.currentTarget, draft })
  }, [])

  const closeMenu = useCallback(() => {
    setMenuState({ anchorEl: null, draft: null })
  }, [])

  return {
    menuState,
    openMenu,
    closeMenu,
    DraftMenu,
  }
}
