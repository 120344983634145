import { MeasurableRangeDimension, PlantListEntry } from '@/types.ts'
import { specificationOptions } from '@/constants.ts'

export const getFormattedPlantListEntrySpecs = ({ entry }: { entry: PlantListEntry }): string[] => {
  const specs: string[] = []

  specificationOptions.forEach((option) => {
    const entryValue = entry[option.fieldName as keyof PlantListEntry]

    // get valid measurable range dimension specs
    if (option.fieldType === 'range') {
      const castedEntryValue = entryValue as MeasurableRangeDimension
      if (castedEntryValue.min || castedEntryValue.max) {
        const spec = [`${option.name}: `]
        if (castedEntryValue.min) spec.push(`${castedEntryValue.min}`)
        if (castedEntryValue.max && castedEntryValue.min) spec.push('-')
        if (castedEntryValue.max) spec.push(`${castedEntryValue.max}`)
        if (castedEntryValue.unit) spec.push(` ${castedEntryValue.unit}`)
        specs.push(spec.join(''))
      }

      // get valid string tag like specs
    } else if (option.fieldType === 'multiple') {
      const castedEntryValue = entryValue as string[]
      if (castedEntryValue.length) {
        specs.push(`${option.name}: ${castedEntryValue.join(', ')}`)
      }

      // get valid single string specs
    } else if (option.fieldName === 'single') {
      if (entryValue) {
        specs.push(`${option.name}: ${entryValue}`)
      }
    }
  })

  return specs
}
