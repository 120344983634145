import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import theme from '@/theme.ts'

export default function RfpJobPhaseDefaultContent({ jobId, phaseId }: { jobId: string; phaseId: string }) {
  return (
    <Stack key={`rfp-job-${jobId}-phase-${phaseId}`} sx={{ py: 2, justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant="body1" color={theme.palette.mediumGrey2.main}>
        No plants found in this Phase
      </Typography>
    </Stack>
  )
}
