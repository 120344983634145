import { useCallback, useEffect } from 'react'
import { Route as RfpDraftRoute } from '@/routes/$orgId/rfps.draft.$rfpId'
import { useSearch } from '@tanstack/react-router'
import { useRfpContext } from '../context/rfp-context'

export function useRfpModals() {
  const {
    // Modal state
    addPlantsFromJobModalProps,
    addPlantsFromListModalOpen,
    createPlantListModalOpen,
    addPlantsModalOpen,
    editRfpDetailsModalOpen,
    addPhaseToJobRfpModalOpen,
    addJobsToRfpModalOpen,
    viewVendorRfpModalOpen,
    editRfpJobPhaseNameModalOpen,
    confirmationModalContent,

    // Modal setters
    setAddPlantsFromJobModalProps,
    setAddPlantsFromListModalOpen,
    setCreatePlantListModalOpen,
    setAddPlantsModalOpen,
    setEditRfpDetailsModalOpen,
    setAddPhaseToJobRfpModalOpen,
    setAddJobsToRfpModalOpen,
    setViewVendorRfpModalOpen,
    setConfirmationModalContent,
    setEditRfpJobPhaseNameModalOpen,

    closeAllModals,
  } = useRfpContext()

  // Router integration
  const navigate = RfpDraftRoute.useNavigate()
  const { modal } = useSearch({ strict: false })

  // Initialize modals based on URL
  useEffect(() => {
    if (!modal) {
      return
    }
    if (modal === 'edit-rfp-details-modal') {
      setEditRfpDetailsModalOpen(true)
    }
    // Add more modal URL params here
  }, [modal, setEditRfpDetailsModalOpen])

  // Modal management functions
  const removeModalFromUrl = useCallback(() => {
    navigate({
      search: (prev) => {
        const newSearch = { ...prev }
        delete newSearch.modal
        return newSearch
      },
      replace: true,
    }).then()
  }, [navigate])

  // Override closeAllModals to also remove from URL
  const handleCloseAllModals = useCallback(() => {
    closeAllModals()
    removeModalFromUrl()
  }, [closeAllModals, removeModalFromUrl])

  return {
    // Modal state
    addPlantsFromJobModalProps,
    addPlantsFromListModalOpen,
    createPlantListModalOpen,
    addPlantsModalOpen,
    editRfpDetailsModalOpen,
    addPhaseToJobRfpModalOpen,
    addJobsToRfpModalOpen,
    viewVendorRfpModalOpen,
    confirmationModalContent,
    editRfpJobPhaseNameModalOpen,

    // Modal setters
    setAddPlantsFromJobModalProps,
    setAddPlantsFromListModalOpen,
    setCreatePlantListModalOpen,
    setAddPlantsModalOpen,
    setEditRfpDetailsModalOpen,
    setAddPhaseToJobRfpModalOpen,
    setAddJobsToRfpModalOpen,
    setViewVendorRfpModalOpen,
    setConfirmationModalContent,
    setEditRfpJobPhaseNameModalOpen,

    // Helper functions
    removeModalFromUrl,
    closeAllModals: handleCloseAllModals,
  }
}
