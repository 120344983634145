import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html'
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table'
import ExampleTheme from './lexical_theme'
import ToolbarPlugin from './lexical_toolbar'
import { Box, Button } from '@mui/material'
import { ListItemNode, ListNode } from '@lexical/list'
import { useEffect, useRef, useState } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { LinkNode } from '@lexical/link'
import { ImageNode } from './lexical_image_node'
import { $getRoot, $isElementNode } from 'lexical'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { validateUrl } from '@/lib/utils.ts'
import { AlignmentType } from '@/components/ui/texteditor/lexical/lexical_editor_types.ts'
import { useDebounce } from '@uidotdev/usehooks'
import { CustomLexicalEditorProps } from '@/types.ts'

function Placeholder() {
  return (
    <Box
      className="editor-placeholder"
      sx={{
        position: 'absolute',
        top: '16px',
        userSelect: 'none',
        color: 'grey.400',
      }}
    >
      ...
    </Box>
  )
}

function ContentEditableComponent() {
  return (
    <ContentEditable
      style={{
        minHeight: '150px',
        resize: 'none',
        fontSize: '15px',
        caretColor: '#444',
        position: 'relative',
        tabSize: '1',
        outline: 0,
        padding: '15px 10px',
        textAlign: 'left',
      }}
      className="editor-input"
    />
  )
}

function SaveButton() {
  const [editor] = useLexicalComposerContext()
  const handleSave = () => {
    editor.update(() => {
      const htmlString = $generateHtmlFromNodes(editor)
      console.log('Save content', htmlString)
    })
  }

  return (
    <Button variant="contained" onClick={handleSave}>
      Save
    </Button>
  )
}

function InitializeEditorPlugin({ initialHtml }: { initialHtml: string }) {
  const [editor] = useLexicalComposerContext()
  const hasInitialized = useRef(false)

  useEffect(() => {
    if (initialHtml.length && !hasInitialized.current) {
      editor.update(() => {
        const parser = new DOMParser()
        const dom = parser.parseFromString(initialHtml || '', 'text/html')

        // Find all img elements in the original DOM
        const imgElements = dom.querySelectorAll('img')
        const imageNodes: ImageNode[] = []

        // Create ImageNodes from img elements
        imgElements.forEach((imgElement) => {
          const src = imgElement.getAttribute('src') || ''
          const alt = imgElement.getAttribute('alt') || ''
          const style = imgElement.getAttribute('style') || ''

          let alignment = 'left'
          if (style.includes('float: right')) alignment = 'right'
          if (style.includes('margin: 0 auto')) alignment = 'center'

          const imageNode = new ImageNode(src, alt, alignment as AlignmentType)
          imageNodes.push(imageNode)

          // Remove the img element from DOM
          imgElement.remove()
        })

        // Generate nodes from the modified DOM
        const nodes = $generateNodesFromDOM(editor, dom)

        // Clear the editor
        $getRoot().clear()

        // Insert nodes and images
        nodes.forEach((node) => {
          if ($isElementNode(node) && node.isEmpty()) {
            // If we find an empty element node, insert an image here
            if (imageNodes.length > 0) {
              const imageNode = imageNodes.shift()
              if (imageNode) {
                $getRoot().append(imageNode)
              }
            }
          }
          $getRoot().append(node)
        })

        // Append any remaining images
        imageNodes.forEach((imageNode) => {
          $getRoot().append(imageNode)
        })
      })
      hasInitialized.current = true
    }
  }, [editor, initialHtml])

  return null
}

export function CustomLexicalEditor({
  showToolbar,
  showFooter,
  onCancel,
  onChange,
  initialHtml,
}: CustomLexicalEditorProps) {
  const editorConfig = {
    namespace: 'lexicaleditor',
    nodes: [TableNode, TableCellNode, TableRowNode, ListNode, ListItemNode, LinkNode, ImageNode],
    onError(error: Error) {
      throw error
    },
    theme: ExampleTheme,
  }

  function EditorChangePlugin({ onChange }: { onChange: (data: any) => void }) {
    const [editorContent, setEditorContent] = useState<{ htmlString: string; jsonContent: any } | null>(null)
    const debouncedContent = useDebounce(editorContent, 300)
    const [editor] = useLexicalComposerContext()

    useEffect(() => {
      if (debouncedContent && onChange) {
        onChange(debouncedContent)
      }
    }, [debouncedContent, onChange])

    useEffect(() => {
      return editor.registerUpdateListener(({ editorState }) => {
        editor.update(() => {
          const htmlString = $generateHtmlFromNodes(editor)
          const jsonContent = editorState.toJSON()
          setEditorContent({ htmlString, jsonContent })
        })
      })
    }, [editor])

    return null
  }

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <Box
        sx={{
          borderRadius: '5px',
          position: 'relative',
          lineHeight: '15px',
        }}
      >
        {showToolbar && <ToolbarPlugin />}
        <Box
          sx={{
            background: 'transparent',
            position: 'relative',
          }}
        >
          <RichTextPlugin
            contentEditable={<ContentEditableComponent />}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <LinkPlugin validateUrl={validateUrl} />
          <InitializeEditorPlugin initialHtml={initialHtml || ''} />
          <ListPlugin />
          <EditorChangePlugin onChange={onChange || (() => {})} />
        </Box>
      </Box>
      {showFooter && (
        <Box justifyContent="end" display="flex" gap={1}>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <SaveButton />
        </Box>
      )}
      {/*<LexicalEditorHandleChangesPlugin onChange={handleChange} />*/}
    </LexicalComposer>
  )
}
