import { useState, useCallback } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  DialogProps,
} from '@mui/material'

interface ConfirmationDialogOptions {
  title?: string
  message?: string
  confirmText?: string
  cancelText?: string
  confirmButtonProps?: object
  cancelButtonProps?: object
}

interface UseConfirmationDialogProps {
  dialogProps?: Partial<DialogProps>
}

export const useConfirmationDialog = (props?: UseConfirmationDialogProps) => {
  const [dialogState, setDialogState] = useState<{
    isOpen: boolean
    options: ConfirmationDialogOptions
    resolveRef: (value: boolean) => void
  }>({
    isOpen: false,
    options: {},
    resolveRef: () => {},
  })

  const showConfirmation = useCallback((options: ConfirmationDialogOptions): Promise<boolean> => {
    return new Promise((resolve) => {
      setDialogState({
        isOpen: true,
        options,
        resolveRef: resolve,
      })
    })
  }, [])

  const handleClose = useCallback(() => {
    setDialogState((prev) => {
      prev.resolveRef(false)
      return { ...prev, isOpen: false }
    })
  }, [])

  const handleConfirm = useCallback(() => {
    setDialogState((prev) => {
      prev.resolveRef(true)
      return { ...prev, isOpen: false }
    })
  }, [])

  const ConfirmationDialog = useCallback(() => {
    const { isOpen, options } = dialogState

    return (
      <Dialog
        open={isOpen}
        onClose={handleClose}
        {...props?.dialogProps}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
        <DialogTitle id="confirmation-dialog-title">{options.title || 'Confirm Action'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-dialog-description">
            {options.message || 'Are you sure you want to proceed?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" {...options.cancelButtonProps}>
            {options.cancelText || 'Cancel'}
          </Button>
          <Button onClick={handleConfirm} color="error" variant="contained" autoFocus {...options.confirmButtonProps}>
            {options.confirmText || 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }, [dialogState, handleClose, handleConfirm, props?.dialogProps])

  return {
    showConfirmation,
    ConfirmationDialog,
  }
}
