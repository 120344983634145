// Assumes usage on /rfps/index page
import { Route } from '@/routes/$orgId/rfps.index'
import Typography from '@mui/material/Typography'
import { formatStringToShortMonthDayYear as formatDate, timeElapsedSince } from '@/lib/utils'
import { Pagination, Stack, Box } from '@mui/material'
import { RFPIndexStatus } from '@/api/types/rfps'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import FlagIcon from '@mui/icons-material/Flag'
import { trimUUID } from '@/lib/utils'
import theme from '@/theme'
import { ChatOutlined } from '@mui/icons-material'
import { useRFPMenu } from '../hooks/useRFPMenu'
import { Link } from '@tanstack/react-router'

/**
 ************************************************
 RIGHT NOW THE MENU INTERACTIONS ASSUME SENT RFPs
 STILL NEED TO BETTER UNDERSTAND RECEIVED RFPs
 ************************************************
 */

export function RFPFlatList({ rfpData }: { rfpData: RFPIndexStatus }) {
  const { orgId } = Route.useParams()
  const searchParams = Route.useSearch()
  const { type } = searchParams
  const navigate = Route.useNavigate()

  const { rfpQueryManager } = Route.useLoaderData()
  const { RFPMenu, menuState, openMenu, closeMenu } = useRFPMenu()

  return (
    <>
      <Table size="small" aria-label="RFP table" sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
            <TableCell>ID</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Jobs</TableCell>
            <TableCell>Vendor</TableCell>
            <TableCell>Sent</TableCell>
            <TableCell>Quote Due</TableCell>
            <TableCell sx={{ width: '10%', textAlign: 'right' }}>Comments</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rfpData.data.map((rfp) => {
            return (
              <TableRow
                key={rfp.id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: 'pointer',
                  borderBottom: '1px solid #e0e0e0',
                }}
                hover
                onClick={(e) => {
                  e.stopPropagation()
                  navigate({
                    to: '/$orgId/rfps/$rfpId',
                    params: { orgId, rfpId: rfp.rfp_id },
                    search: { sent_rfp: type === 'received' ? rfp.id : undefined },
                  })
                }}
              >
                <TableCell sx={{ pl: 0, py: '15px' }}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Box
                      sx={{
                        width: 8,
                        height: 8,
                        borderRadius: '100%',
                        backgroundColor: rfp.unread_change_by_customer ? theme.palette.error.light : 'transparent',
                      }}
                    />
                    <Typography
                      variant="indexTableItem"
                      sx={{
                        fontWeight: rfp.unread_change_by_customer ? 'bold' : undefined,
                      }}
                    >
                      <Link
                        to={`/$orgId/rfps/$rfpId`}
                        params={{ orgId, rfpId: rfp.rfp_id }}
                        search={{ sent_rfp: type === 'received' ? rfp.id : undefined }}
                      >
                        RFP - #{trimUUID(rfp.rfp_id)}
                      </Link>
                    </Typography>
                    {rfp.flagged_as_important_by_customer && (
                      <Box component="span" sx={{ ml: 1 }}>
                        <FlagIcon color="error" fontSize="small" />
                      </Box>
                    )}
                  </Stack>
                </TableCell>
                <TableCell>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Box
                      sx={{
                        width: 8,
                        height: 8,
                        borderRadius: '50%',
                        backgroundColor: rfp.status === 'pending' ? '#ff9800' : '#4caf50',
                      }}
                    />
                    <Typography variant="body2">
                      {rfp.status === 'pending' ? `Pending` : rfp.status === 'sent' ? `Sent` : 'In Progress'}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell>{rfp.jobs.length ? rfp.jobs.map((job) => job.name).join(',') : 'No Job'}</TableCell>
                <TableCell>{rfp.directed_organization?.name || 'No Contact'}</TableCell>
                <TableCell>{rfp.created_at ? timeElapsedSince(rfp.created_at) : ''}</TableCell>
                <TableCell>{rfp.quote_needed_by ? formatDate(rfp.quote_needed_by) : 'No due date'}</TableCell>
                <TableCell sx={{ width: '10%', textAlign: 'right' }}>
                  <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: theme.palette.lightGrey2.main, // Red background
                          borderRadius: '4px', // Rounded corners
                          padding: '2px 6px', // Padding for spacing
                          color: '#fff', // White text
                        }}
                      >
                        <ChatOutlined fontSize="small" sx={{ color: '#fff' }} />
                        <Typography variant="body2" sx={{ ml: 0.5, fontWeight: 'bold' }}>
                          {rfp.total_comments}
                        </Typography>
                      </Box>
                    </Stack>
                    <IconButton aria-label="more options" onClick={(e) => openMenu(e, rfp)} size="small">
                      <MoreVertIcon sx={{ ml: 'auto' }} />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {menuState.rfp && menuState.anchorEl && (
        <RFPMenu
          menuState={{
            rfp: menuState.rfp,
            anchorEl: menuState.anchorEl,
          }}
          closeMenu={closeMenu}
          rfpQueryManager={rfpQueryManager}
        />
      )}

      {rfpData.hasMore && (
        <Stack py={2}>
          <Pagination
            sx={{ alignSelf: 'flex-end' }}
            variant="outlined"
            shape="rounded"
            page={searchParams.page || 0}
            size="large"
            onChange={(_, value) => {
              navigate({
                search: (prev) => ({
                  ...prev,
                  page: value,
                }),
              })
            }}
            count={rfpData.hasMore ? (searchParams.page || 0) + 1 : searchParams.page || 0}
          />
        </Stack>
      )}
    </>
  )
}
