// Assumes usage on /rfps/index page
import { Route } from '@/routes/$orgId/rfps.index'
import { Box, Collapse, IconButton, Stack, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { ChevronRight, DotIcon } from 'lucide-react'
import { formatStringToShortMonthDayYear as formatDate, formatDateTime as formatDateTime, trimUUID } from '@/lib/utils'
import { ReactNode, useState } from 'react'
import { Link } from '@tanstack/react-router'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import FlagIcon from '@mui/icons-material/Flag'

import {
  type RFPIndexGroupedUnion,
  type RFPIndexOptions,
  RFPContactIndex,
  RFPJobIndex,
  RFPSearchResponse,
} from '@/api/types/rfps'
import { Table } from '@mui/material'
import { TableContainer } from '@mui/material'
import theme from '@/theme'
import { useRFPMenu } from '../hooks/useRFPMenu'
import { CommentBubble } from './comment-bubble'
import { useGroupedMenu } from '../hooks/useGroupMenu'

export function RFPTable({ rfpData, type }: { rfpData: RFPIndexGroupedUnion; type: RFPIndexOptions['type'] }) {
  return (
    <TableContainer>
      <Table>
        <TableHead
          sx={{
            backgroundColor: '#F3F6F9',
            borderBottom: '1px solid rgba(186, 210, 228, 0.698)',
          }}
        >
          <TableRow>
            <TableCell sx={{ py: 1 }} width={'50%'}>
              {rfpData.groupBy === 'job' ? 'Job' : type === 'sent' ? 'Vendor' : 'Customer'}
            </TableCell>
            <TableCell sx={{ py: 1, pl: 0 }} width={'40%'}>
              Status
            </TableCell>
            <TableCell sx={{ py: 1 }} width={'10%'}>
              Comments
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            borderTop: '1px solid #8B97A0',
          }}
        >
          {/* TODO: Potential type refactor here do so the narrowing in ToggleRow */}
          {rfpData.groupBy === 'contact'
            ? rfpData.data.map((item) => {
                return <ToggleRow key={item.id} item={{ ...item, groupBy: 'contact' }} type={type} />
              })
            : rfpData.data.map((item) => {
                return <ToggleRow key={item.id} item={{ ...item, groupBy: 'job' }} type={type} />
              })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export function ToggleRow({
  item,
  type,
}: {
  item: (RFPContactIndex[number] & { groupBy: 'contact' }) | (RFPJobIndex[number] & { groupBy: 'job' })
  type: RFPIndexOptions['type']
}) {
  // local boolean flip, no need for global map
  const [open, setOpen] = useState(false)
  const { rfpQueryManager } = Route.useLoaderData()
  const { menuState, openMenu, closeMenu, GroupedRFPMenu } = useGroupedMenu()
  const count = item.rfps.length

  const sentUnread = item.rfps.reduce((acc, rfp) => acc + (rfp.unread_comments_by_vendor || 0), 0)
  const receivedUnread = item.rfps.reduce((acc, rfp) => acc + (rfp.unread_comments_by_customer || 0), 0)
  const unread = type === 'sent' ? sentUnread > 0 : receivedUnread > 0
  const totalComments = item.rfps.reduce((acc, rfp) => acc + rfp.total_comments, 0)

  const title =
    item.groupBy === 'job'
      ? !item.job
        ? 'NO JOB'
        : item.job.name || `Job #${item.job.id}`
      : type === 'sent'
        ? item.vendor?.organization_name || `Vendor #${trimUUID(item.vendor?.id || '')}`
        : item.customer?.organization_name || `Customer #${trimUUID(item.customer?.id || '')}`

  const viewedCount = item.rfps.reduce((acc, rfp) => {
    const key = type === 'sent' ? 'last_viewed_by_vendor' : 'last_viewed_by_customer'
    return acc + (rfp[key] ? 1 : 0)
  }, 0)

  const inProgressCount = item.rfps.reduce((acc, rfp) => {
    const key = type === 'sent' ? 'vendor_status' : 'customer_status'
    const inProgress = rfp[key] === 'in progress'
    return acc + (inProgress ? 1 : 0)
  }, 0)

  const quotedCount = item.rfps.reduce((acc, rfp) => {
    const key = type === 'sent' ? 'vendor_status' : 'customer_status'
    return acc + (rfp[key] === 'quoted' ? 1 : 0)
  }, 0)

  return (
    <>
      <TableRow
        sx={{
          cursor: 'pointer',
          '&:hover': { backgroundColor: '#FFFFEE' },
        }}
        onClick={() => setOpen(!open)}
      >
        <TableCell sx={{ whiteSpace: 'nowrap', width: '50%', pl: 0.25 }}>
          <Box display={'flex'} gap={0.25} alignItems={'center'}>
            <Box
              sx={{
                width: 8,
                height: 8,
                borderRadius: '100%',
                backgroundColor: unread ? theme.palette.error.light : 'transparent',
              }}
            />
            <IconButton
              size="small"
              sx={{
                transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
                transition: `transform ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
              }}
            >
              <ChevronRight size={20} />
            </IconButton>
            <Typography variant="indexAccordionHeader" sx={{ fontWeight: unread ? 'bold' : undefined }}>
              {title} ({count})
            </Typography>
          </Box>
        </TableCell>
        <TableCell sx={{ width: '40%', pl: 0, transition: 'text-align 0.3s ease' }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  backgroundColor: theme.palette.lightGrey2.main, // green for viewed
                }}
              />
              <Typography variant="subtitle2" color="text.secondary">
                {viewedCount} Viewed
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  backgroundColor: theme.palette.warning.light, // orange for in progress
                }}
              />
              <Typography variant="subtitle2" color="text.secondary">
                {inProgressCount} In Progress
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  backgroundColor: theme.palette.success.light, // blue for quoted
                }}
              />
              <Typography variant="subtitle2" color="text.secondary">
                {quotedCount} Quoted
              </Typography>
            </Box>
          </Stack>
        </TableCell>
        <TableCell sx={{ width: '10%', textAlign: 'right' }}>
          <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
            <CommentBubble count={totalComments} unread={unread} />
            <IconButton aria-label="more options" onClick={(e) => openMenu(e, item.rfps)} size="small">
              <MoreVertIcon sx={{ ml: 'auto' }} />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      {menuState.anchorEl && menuState.rfps && (
        <GroupedRFPMenu
          menuState={{
            anchorEl: menuState.anchorEl,
            rfps: menuState.rfps,
          }}
          closeMenu={closeMenu}
          rfpQueryManager={rfpQueryManager}
        />
      )}
      <TableRow>
        <TableCell padding="none" colSpan={4}>
          <Collapse in={open}>
            <Box>
              <RFPTableStructure groupBy={item.groupBy}>
                {item.groupBy === 'contact'
                  ? item.rfps.map((rfp) => {
                      return (
                        <RFPRow
                          key={rfp.rfp_id}
                          rfp={{
                            ...rfp,
                            groupBy: item.groupBy,
                          }}
                          type={type}
                        />
                      )
                    })
                  : item.rfps.map((rfp) => {
                      return (
                        <RFPRow
                          key={rfp.rfp_id}
                          rfp={{
                            ...rfp,
                            groupBy: item.groupBy,
                          }}
                          type={type}
                        />
                      )
                    })}
              </RFPTableStructure>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

function RFPTableStructure({
  children,
  groupBy,
}: {
  children: ReactNode
  groupBy: RFPIndexOptions['groupBy'] | 'search'
}) {
  const headers = [
    { label: 'ID' },
    { label: 'Status' },
    { label: groupBy === 'job' ? 'Vendors' : 'Jobs' },
    { label: 'Sent' },
    { label: 'Quote Due' },
    { label: 'Comments', width: '10%' },
  ]

  return (
    <Table
      size="small"
      sx={{
        borderTop: 'none',
        tableLayout: 'fixed',
      }}
    >
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableCell key={header.label} width={header.width}>
              {header.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </Table>
  )
}

export function RFPSimpleTable({
  rfpData,
  type,
  orgId,
}: {
  rfpData: RFPSearchResponse[]
  type: RFPIndexOptions['type']
  orgId: string
}) {
  return (
    <RFPTableStructure groupBy={'search'}>
      {rfpData.map((rfp) => (
        <RFPSearchResultRow key={rfp.id} orgId={orgId} rfp={rfp} type={type} />
      ))}
    </RFPTableStructure>
  )
}

export function RFPSearchResultRow({
  orgId,
  rfp,
}: {
  orgId: string
  rfp: RFPSearchResponse
  type?: RFPIndexOptions['type']
}) {
  const sent = rfp.sent_at ? formatDate(rfp.sent_at) : ''
  const quoteDue = rfp.quote_needed_by ? formatDate(rfp.quote_needed_by) : ''
  const jobDate = rfp.delivery_date ? formatDate(rfp.delivery_date) : ''

  // const isVendor = type === 'received'
  const dot = (color: 'success' | 'error' | 'info' = 'info') => (
    <DotIcon
      size={16}
      strokeWidth={8}
      color={color === 'success' ? '#26A482' : color === 'error' ? '#FF0000' : '#DB9D00'}
    />
  )
  const fallback = (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      {dot('info')}
      Updated - {formatDateTime(rfp.updated_at || (rfp.created_at as string))}
    </div>
  )

  const status = () => {
    return fallback
  }

  const name = rfp.name || 'RFP'

  return (
    <TableRow key={rfp.id}>
      <TableCell
        sx={{
          padding: '15px 10px 15px 42px',
        }}
      >
        <Typography variant="indexTableItem">
          {rfp.status === 'pending' ? (
            <Link
              to={`/$orgId/rfps/draft/$rfpId`}
              params={{ orgId, rfpId: rfp.id }}
              search={{
                step: 'build-rfp',
              }}
            >
              {name} - #{trimUUID(rfp.id)}
            </Link>
          ) : (
            <Link to={`/$orgId/rfps/$rfpId`} params={{ orgId, rfpId: rfp.id }}>
              {name} - #{trimUUID(rfp.id)}
            </Link>
          )}
        </Typography>
      </TableCell>
      <TableCell>{status()}</TableCell>
      <TableCell>{sent}</TableCell>
      <TableCell>{quoteDue}</TableCell>
      <TableCell>{jobDate}</TableCell>
    </TableRow>
  )
}

export function RFPRow({
  rfp,
  type,
}: {
  rfp:
    | (RFPContactIndex[number]['rfps'][number] & { groupBy: 'contact' })
    | (RFPJobIndex[number]['rfps'][number] & { groupBy: 'job' })
  type: RFPIndexOptions['type']
}) {
  const { rfpQueryManager } = Route.useLoaderData()
  const navigate = Route.useNavigate()
  const { orgId } = Route.useParams()
  const sent = rfp.sent_at ? formatDate(rfp.sent_at) : ''
  const quoteDue = rfp.quote_needed_by ? formatDate(rfp.quote_needed_by) : ''
  const names =
    rfp.groupBy === 'job'
      ? rfp.contacts.map((contact) => contact.organization_name)
      : rfp.jobs.map((job) => job.job_name)
  const unread = type === 'sent' ? rfp.unread_comments_by_vendor > 0 : rfp.unread_comments_by_customer > 0
  const { RFPMenu, menuState, openMenu, closeMenu } = useRFPMenu()

  return (
    <>
      <TableRow
        key={rfp.rfp_id}
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          navigate({
            to: '/$orgId/rfps/$rfpId',
            params: { orgId, rfpId: rfp.rfp_id },
            ...(type === 'received' && rfp.sent_rfp_id && { search: { sent_rfp: rfp.sent_rfp_id } }),
          })
        }}
      >
        <TableCell sx={{ pl: 0, py: '15px' }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              sx={{
                width: 8,
                height: 8,
                borderRadius: '100%',
                backgroundColor: unread ? theme.palette.error.light : 'transparent',
              }}
            />
            <Typography
              variant="indexTableItem"
              sx={{
                fontWeight: unread ? 'bold' : undefined,
              }}
            >
              <Link
                to={`/$orgId/rfps/$rfpId`}
                params={{ orgId, rfpId: rfp.rfp_id }}
                search={{ sent_rfp: type === 'received' && rfp.sent_rfp_id ? rfp.sent_rfp_id : undefined }}
              >
                RFP - #{trimUUID(rfp.rfp_id)}
              </Link>
            </Typography>
            {rfp.flagged_as_important_by_customer && (
              <Box component="span" sx={{ ml: 1 }}>
                <FlagIcon color="error" fontSize="small" />
              </Box>
            )}
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              sx={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor: rfp.status === 'pending' ? '#ff9800' : '#4caf50',
              }}
            />
            <Typography variant="body2">
              {rfp.status === 'pending' ? `Pending` : rfp.status === 'sent' ? `Sent` : 'In Progress'}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>{names.join(', ')}</TableCell>
        <TableCell>{sent}</TableCell>
        <TableCell>{quoteDue}</TableCell>
        <TableCell sx={{ width: '10%', textAlign: 'right' }}>
          <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
            <CommentBubble count={rfp.total_comments} unread={unread} />
            <IconButton aria-label="more options" onClick={(e) => openMenu(e, rfp)} size="small">
              <MoreVertIcon sx={{ ml: 'auto' }} />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      {menuState.anchorEl && menuState.rfp && (
        <RFPMenu
          menuState={{
            rfp: menuState.rfp,
            anchorEl: menuState.anchorEl,
          }}
          closeMenu={closeMenu}
          rfpQueryManager={rfpQueryManager}
        />
      )}
    </>
  )
}
