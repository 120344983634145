import { Menu, MenuItem, Divider, ListItemIcon, Typography, SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import { PopoverProps } from '@mui/material/Popover'
import { CustomMenuDropdownItem } from '@/types.ts'

interface CustomMenuProps {
  anchorEl: HTMLElement | null
  open: boolean
  onClose: () => void
  minMenuWidth?: number | string
  menuItems: CustomMenuDropdownItem[]
  width?: number | string
  anchorOrigin?: PopoverProps['anchorOrigin']
  transformOrigin?: PopoverProps['transformOrigin']
  categoryDivider?: boolean
  sx?: SxProps
}

export const CustomMenu = ({
  anchorEl,
  open,
  onClose,
  minMenuWidth = 200,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
  transformOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  menuItems,
  categoryDivider = false,
  sx = {},
}: CustomMenuProps) => {
  // Group items by category
  const categorizedItems = menuItems.reduce(
    (acc, item) => {
      const category = item.category || 'uncategorized'
      if (!acc[category]) {
        acc[category] = []
      }
      acc[category].push(item)
      return acc
    },
    {} as Record<string, CustomMenuDropdownItem[]>
  )
  const categoryCount = Object.keys(categorizedItems).length

  const handleItemClick = (item: CustomMenuDropdownItem) => {
    if (item.onClick) {
      item.onClick()
    }
    onClose()
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      slotProps={{
        paper: {
          sx: {
            minWidth: minMenuWidth,
            ...sx,
          },
        },
      }}
    >
      {Object.entries(categorizedItems).map(([category, items], categoryIndex) => (
        <Box key={`category-${category}-${categoryIndex}`}>
          {/* Only show category header if it's not uncategorized */}
          {category !== 'uncategorized' && (
            <Typography
              sx={{
                px: 2,
                py: 1,
                display: 'block',
                color: 'text.primary',
                fontWeight: 500,
              }}
            >
              {category}
            </Typography>
          )}

          {items.map((item, index) => (
            <Box key={index}>
              <MenuItem onClick={() => handleItemClick(item)} disabled={item?.disabled} sx={item?.sx}>
                {item.icon && (
                  <ListItemIcon>
                    <item.icon fontSize="small" />
                  </ListItemIcon>
                )}
                {item.label}
              </MenuItem>
              {item.divider && <Divider />}
            </Box>
          ))}
          {categoryDivider && categoryCount !== categoryIndex + 1 && <Divider />}
        </Box>
      ))}
    </Menu>
  )
}
