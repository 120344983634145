import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import RequestPricingHeader from '@/components/rfps/rfp-request-pricing-header'
import { PlantListEntry, SentRFP, UserContact } from '@/types'
import { excludeDeletedItems, getPrimaryContactEmail, getUserFullName } from '@/lib/utils.ts'
import { RfpVendorInfoCard } from '@/components/rfps/rfp-vendor-info-card.tsx'
import { Typography } from '@mui/material'
import { Route } from '@/routes/$orgId/rfps.draft.$rfpId'
import { useConfirmationModal } from '@/hooks/useConfirmationModal.tsx'
import { AddBoxOutlined } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import theme from '@/theme.ts'

interface GroupedSentRfps {
  [key: string]: {
    plants: PlantListEntry[]
    vendors: any[]
  }
}
function groupSentRfpsForIdenticalItems(sentRfps: SentRFP[]): GroupedSentRfps {
  const grouped: GroupedSentRfps = {}

  sentRfps.forEach((sentRfp: SentRFP) => {
    const plantIds = excludeDeletedItems(sentRfp.plants)
      .map((p: any) => `${p.id}-${p.quantity_count.min || p.quantity_count.max || 0}`)
      .sort()
      .join('-')

    if (!grouped[plantIds]) {
      grouped[plantIds] = {
        plants: sentRfp.plants,
        vendors: [],
      }
    }

    grouped[plantIds].vendors.push(sentRfp)
  })
  return grouped
}

export default function VendorGroups({
  sentRfps,
  selectedSentRfpId,
  onVendorClick,
  onVendorGroupAddClick,
}: {
  sentRfps: SentRFP[]
  selectedSentRfpId: string | null
  onVendorClick: (sentRfp: SentRFP) => void
  onVendorGroupAddClick?: (plants: PlantListEntry[]) => void
}) {
  const { rfpId } = Route.useParams()
  const { openConfirmationModal, ConfirmationModal } = useConfirmationModal()
  return (
    <Stack sx={{ pt: 2 }}>
      {Object.entries(groupSentRfpsForIdenticalItems(sentRfps || [])).map(([plantGroupKey, group]) => (
        <Box key={plantGroupKey}>
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <RequestPricingHeader
              plants={group.plants}
              variant="vendor-panel"
              sx={{ fontSize: '0.875rem', color: '#708391', alignItems: 'center', display: 'flex' }}
            />
            <IconButton
              aria-label="add-vendor-to-group-button"
              sx={{ p: 0.2, borderRadius: theme.borderRadius.sm }}
              onClick={() => onVendorGroupAddClick?.(group.plants)}
            >
              <AddBoxOutlined sx={{ color: theme.palette.mediumGrey.main }} />
            </IconButton>
          </Stack>

          {group.vendors.map((sentRfp: SentRFP) => (
            <Stack key={sentRfp.id}>
              <RfpVendorInfoCard
                rfpId={rfpId}
                selectedRfpId={selectedSentRfpId}
                rfp={sentRfp}
                info={<DirectedOrganizationUserContacts sentRfp={sentRfp} selectedSentRfpId={selectedSentRfpId} />}
                onClick={() => onVendorClick(sentRfp)}
                openConfirmationModal={openConfirmationModal}
              />
            </Stack>
          ))}
        </Box>
      ))}
      {ConfirmationModal}
    </Stack>
  )
}

const DirectedOrganizationUserContacts = ({
  sentRfp,
  selectedSentRfpId,
}: {
  sentRfp: SentRFP
  selectedSentRfpId: string | null
}) => {
  return (
    <Stack direction="row" spacing={0.5}>
      {sentRfp.directed_organization_user_contacts.map((user: UserContact, index: number) => (
        <Typography
          variant="body2"
          key={user.id}
          sx={{
            color: selectedSentRfpId === sentRfp.id ? 'primary.main' : 'text.secondary',
          }}
        >
          {getUserFullName(user) || getPrimaryContactEmail(user.email) || 'N/A'}
          {index < sentRfp.directed_organization_user_contacts.length - 1 && ','}
        </Typography>
      ))}
    </Stack>
  )
}
