import { RFP_QUERY_KEYS } from '@/lib/query-keys.ts'
import { CustomMenuDropdown } from '@/components/ui/base/dropdowns/custom-menu-dropdown.tsx'
import { EllipsisVertical, Files } from 'lucide-react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteSentRfp } from '@/api/rfps.ts'
import { AxiosError } from 'axios'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { memo } from 'react'
import Typography from '@mui/material/Typography'
import { SentRFP } from '@/types.ts'
import Stack from '@mui/material/Stack'

export const SentRfpCardDropdownMenu = ({
  rfpId,
  sentRfp,
  openConfirmationModal,
}: {
  rfpId: string
  sentRfp: SentRFP
  openConfirmationModal: (options: any) => Promise<boolean>
}) => {
  const queryClient = useQueryClient()
  const { addToastNotification } = useToastNotifications()
  const { selectedOrganization } = useOrganization()

  const deleteSentRfpMutation = useMutation({
    mutationFn: deleteSentRfp,
    onError: async (error: AxiosError) => {
      addToastNotification({
        severity: 'error',
        title: 'Error deleting RFP',
        message: (error.response?.data as { detail?: string })?.detail || error.message,
      })
    },
  })

  const handleRemoveVendor = async (sentRfp: SentRFP) => {
    if (!sentRfp) return

    const confirmation = await openConfirmationModal({
      title: 'Remove Vendor from RFP',
      message: <DeleteVendorConfirmationMessage rfpId={rfpId} sentRfp={sentRfp} />,
      danger: true,
      cancelButtonText: 'Cancel & Close',
      confirmButtonText: 'Remove & Notify Vendor',
    })

    if (!confirmation) return

    await deleteSentRfpMutation.mutateAsync(sentRfp.id)
    await queryClient.invalidateQueries({
      queryKey: RFP_QUERY_KEYS.sentRfps(rfpId, selectedOrganization?.id),
    })
  }

  const handleFeatureNotImplemented = () => {
    // replace this with the feature not implemented hook
    console.log('Feature not implemented')
  }

  const menuItems = [
    {
      label: <BuildQuoteForVendorMenuItem />,
      onClick: () => handleFeatureNotImplemented(),
      divider: true,
      disabled: true,
    },
    {
      label: 'Duplicate RFP for another Vendor',
      onClick: () => handleFeatureNotImplemented(),
      disabled: true,
    },
    {
      label: 'Reset Plant & Quantity Requests',
      onClick: () => handleFeatureNotImplemented(),
      disabled: true,
      divider: true,
    },
    {
      label: 'Edit Contact Details ...',
      onClick: () => handleFeatureNotImplemented(),
      disabled: false,
      divider: true,
    },
    {
      label: 'Remove Vendor from RFP',
      onClick: () => handleRemoveVendor(sentRfp),
      disabled: false,
    },
  ]

  return (
    <CustomMenuDropdown
      labelNode={<EllipsisVertical />}
      menuItems={menuItems}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    />
  )
}

const DeleteVendorConfirmationMessage = memo(({ rfpId, sentRfp }: { rfpId: string; sentRfp: SentRFP }) => {
  const vendorName = sentRfp.directed_organization.name || 'N/A'
  return (
    <Stack gap={1}>
      <Stack>
        <Typography variant="body1" fontWeight={700}>
          Are you sure you want to remove {vendorName} from RFP #{rfpId} ?
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="body1">
          This vendor will no longer be able to provide a quote and will be notified of this removal
        </Typography>
      </Stack>
    </Stack>
  )
})

const BuildQuoteForVendorMenuItem = memo(() => {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Files size={20} />
      <Typography variant="body1">Build Quote on Behalf of Vendor</Typography>
    </Stack>
  )
})
