import { Organization, OrganizationContact, OrganizationMember, User } from '../types.ts'

export enum CommentPage {
  SENT_RFP = 'sent_rfp',
  PLANTLIST = 'plantlist',
  RFP = 'rfp',
}

export interface TargetPage {
  page: CommentPage
  target_id: string
}

export interface CommentSpecifier {
  path_object: string // Type of object being referenced
  path_id: string // ID of the specific object
}

export interface Comment {
  id: string
  created_at: string | null
  updated_at: string | null
  deleted_at: string | null
  created_by: OrganizationMember | null
  comment: string
  organization_members: User[]
  organization_user_contacts: OrganizationContact[]
  directed_organization_user_contacts: OrganizationContact[]
  directed_organization_members: User[]
  replying_to_comment: Comment | null
  unread: boolean
}

export interface CommentThread {
  id: string
  organization: Organization
  created_at: string | null
  updated_at: string | null
  deleted_at: string | null
  created_by: User | null
  target_pages: TargetPage[]
  comment_specifier: CommentSpecifier[]
  organization_contact: OrganizationContact | null
  directed_organization: Organization | null
  directed_organization_contact: OrganizationContact | null
  comments: Comment[]
  resolved: boolean
  target_path: string
  send_to_vendor: boolean
}
