import { Stack } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import theme from '@/theme.ts'
import { EllipsisVertical } from 'lucide-react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import { useRfpContext } from '@/components/rfps/context/rfp-context.tsx'

export default function JobPhaseMenu() {
  const { phaseMenuAnchorEl, handlePhaseMenuClick, handlePhaseMenuClose } = useRfpContext()
  const handleRemovePhaseFromJob = () => {
    handlePhaseMenuClose()
  }

  const handleRemovePhaseFromRfp = () => {
    handlePhaseMenuClose()
  }

  const handleClose = () => {
    handlePhaseMenuClose()
  }

  return (
    <Stack>
      <IconButton
        sx={{
          px: 0,
          py: 1,
          ml: 1,
          borderRadius: theme.borderRadius.sm,
          border: '1px solid #ccc',
          backgroundColor: theme.palette.lightGrey3.main,
        }}
        onClick={handlePhaseMenuClick}
      >
        <EllipsisVertical />
      </IconButton>
      <Menu
        anchorEl={phaseMenuAnchorEl}
        open={Boolean(phaseMenuAnchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            minWidth: 200,
          },
        }}
      >
        <MenuItem onClick={handleRemovePhaseFromRfp}>Remove Phase & Plants from RFP</MenuItem>
        <Divider />
        <MenuItem onClick={handleRemovePhaseFromJob}>Delete Phase & Plants from Job</MenuItem>
      </Menu>
    </Stack>
  )
}
