import { excludeDeletedItems } from '@/lib/utils.ts'
import { MouseEvent, useState } from 'react'
import { Stack } from '@mui/material'
import { LucideBan, LucideLeaf } from 'lucide-react'
import theme from '@/theme.ts'
import Typography from '@mui/material/Typography'
import { pluralizedLabel } from '@/lib/pluralize.ts'
import { TextIconButton } from '@/components/ui/base/buttons/text-icon-buttons.tsx'
import { AddCircle, KeyboardArrowDownOutlined } from '@mui/icons-material'
import { useRfpContext } from '@/components/rfps/context/rfp-context.tsx'
import RfpJobDefaultContentMenu from './rfp-job-default-content-menu.tsx'
import { Job } from '@/types.ts'

export default function RfpJobDefaultContent({ jobGroups, jobId }: { jobGroups: Record<string, Job>; jobId: string }) {
  const { setActiveJobId, setAddPlantsFromJobModalProps } = useRfpContext()
  const jobData = jobGroups[jobId]
  const jobPlantCount = excludeDeletedItems(jobData?.plants).length
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)

  const handleDropdownMenuClick = (event: MouseEvent<HTMLElement>) => {
    setActiveJobId(jobId)
    setMenuAnchorEl(event.currentTarget)
  }

  const handleAddPlantsFromJobClick = () => {
    setActiveJobId(jobId)
    setAddPlantsFromJobModalProps({
      jobIds: [jobId],
      showJobSelection: true,
      open: true,
    })
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null)
  }

  return (
    <Stack
      key={`rfp-job-default-content-${jobId}`}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack sx={{ py: 6, gap: 1, alignItems: 'center' }}>
        {jobPlantCount ? (
          <Stack sx={{ gap: 1, alignItems: 'center' }}>
            <LucideLeaf color={theme.palette.darkGray.main} size={18} />
            <Typography variant="body1" sx={{ fontWeight: 400, mb: 1 }}>
              {pluralizedLabel(jobPlantCount, 'Plant', 'Plants')} found in this Job
            </Typography>
            <TextIconButton
              text="Select plants to include in RFP ..."
              onClick={handleAddPlantsFromJobClick}
              startIcon={<AddCircle sx={{ color: theme.palette.primary.main }} />}
              variant="outlined"
              borderColor={theme.palette.lightGrey2.main}
              bgColor="white"
              sx={{
                px: 2,
                borderRadius: theme.borderRadius.sm,
                color: theme.palette.darkGray.main,
                boxShadow: '0px 3px 8px 0px #1B1B1B1A',
                fontWeight: 400,
              }}
            />
          </Stack>
        ) : (
          <Stack sx={{ gap: 1, alignItems: 'center' }}>
            <LucideBan color={theme.palette.darkGray.main} size={18} />
            <Typography variant="body1">No plants found in this Job</Typography>
          </Stack>
        )}
        <TextIconButton
          text="Add new plants to this Job"
          onClick={handleDropdownMenuClick}
          endIcon={<KeyboardArrowDownOutlined />}
          variant="outlined"
          bgColor="white"
          borderColor={theme.palette.lightGrey2.main}
          sx={{
            px: 2,
            borderRadius: theme.borderRadius.sm,
            color: theme.palette.black.main,
            boxShadow: 'none',
            fontWeight: 400,
          }}
        />
        <RfpJobDefaultContentMenu anchorEl={menuAnchorEl} handleClose={handleMenuClose} />
      </Stack>
    </Stack>
  )
}
