import { RequestForProposal } from '@/types.ts'
import { Stack } from '@mui/material'
import theme from '@/theme.ts'
import { CommentModeProvider } from '@/contexts/comment-mode-context.tsx'
import { RfpProvider } from '@/components/rfps/context/rfp-context.tsx'
import RfpPageContent from '@/components/rfps/draft/steps/rfp-page-content'

interface DetailsPageRfpContentProps {
  rfpData: RequestForProposal | undefined
}

export const EditPageRfpContent = ({ rfpData }: DetailsPageRfpContentProps) => {
  const handleUpdateRfpData = (data: Partial<RequestForProposal>) => {
    console.log('Functionality not implemented', data)
  }

  if (!rfpData) return null

  return (
    <Stack sx={{ p: 2, flexGrow: 1, backgroundColor: 'white', borderRadius: theme.borderRadius.lg }}>
      <CommentModeProvider>
        {/*
          Until lucas's implementation of RFP container with change log is released
          and supported by backend - use existing component
        */}
        <RfpProvider initialRfpData={rfpData} onUpdateRfpData={handleUpdateRfpData}>
          <RfpPageContent editMode={true} />
        </RfpProvider>
      </CommentModeProvider>
    </Stack>
  )
}
