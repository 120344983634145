import type {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
} from 'lexical'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getNodeByKey, $getSelection, $isRangeSelection, DecoratorNode } from 'lexical'
import { ReactElement, useEffect, useState } from 'react'
import { mergeRegister } from '@lexical/utils'
import { SELECTION_CHANGE_COMMAND } from 'lexical'

type ImagePayload = {
  src: string
  altText: string
  alignment?: 'left' | 'center' | 'right' | 'justify'
  width?: number
  height?: number
  key?: NodeKey
}

type SerializedImageNode = ImagePayload & SerializedLexicalNode

function ImageComponent({
  src,
  altText,
  width,
  height,
  alignment,
  nodeKey,
}: {
  src: string
  altText: string
  width?: number
  height?: number
  alignment: string
  nodeKey: string
}) {
  const [editor] = useLexicalComposerContext()
  const [isSelected, setIsSelected] = useState(false)

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          const selection = $getSelection()
          if (!$isRangeSelection(selection)) {
            setIsSelected(false)
            return
          }
          const nodes = selection.getNodes()
          const isNodeSelected = nodes.some((node) => node.getKey() === nodeKey)
          setIsSelected(isNodeSelected)
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          const selection = $getSelection()
          if (!$isRangeSelection(selection)) {
            setIsSelected(false)
            return false
          }
          const nodes = selection.getNodes()
          const isNodeSelected = nodes.some((node) => node.getKey() === nodeKey)
          setIsSelected(isNodeSelected)
          return false
        },
        1
      )
    )
  }, [editor, nodeKey])

  const handleClick = () => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey)
      if (node) {
        node.selectNext()
      }
    })
  }

  return (
    <div
      style={{
        textAlign: alignment as 'left' | 'center' | 'right' | 'justify',
        userSelect: 'none',
        padding: '2px',
      }}
      draggable={false}
      contentEditable={false}
    >
      <img
        src={src}
        alt={altText}
        width={width}
        height={height}
        onClick={handleClick}
        style={{
          display: 'inline-block',
          maxWidth: '300px',
          height: 'auto',
          cursor: 'pointer',
          outline: isSelected ? '2px solid #1976d2' : 'none',
          borderRadius: '2px',
        }}
      />
    </div>
  )
}

export class ImageNode extends DecoratorNode<ReactElement> {
  __src: string
  __altText: string
  __alignment: 'left' | 'center' | 'right' | 'justify'
  __width?: number
  __height?: number

  static getType(): string {
    return 'image'
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode(node.__src, node.__altText, node.__alignment, node.__width, node.__height, node.__key)
  }

  constructor(
    src: string,
    altText: string,
    alignment: 'left' | 'center' | 'right' | 'justify' = 'left',
    width?: number,
    height?: number,
    key?: NodeKey
  ) {
    super(key)
    this.__src = src
    this.__altText = altText
    this.__alignment = alignment
    this.__width = width
    this.__height = height
  }

  setAlignment(alignment: 'left' | 'center' | 'right' | 'justify'): void {
    const self = this.getWritable()
    self.__alignment = alignment
  }

  getAlignment(): 'left' | 'center' | 'right' | 'justify' {
    return this.__alignment
  }

  createDOM(): HTMLElement {
    const span = document.createElement('span')
    span.style.display = 'inline-block'
    span.style.width = '100%'
    span.style.textAlign = this.__alignment
    return span
  }

  updateDOM(): boolean {
    return false
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('img')
    element.setAttribute('src', this.__src)
    element.setAttribute('alt', this.__altText)
    if (this.__alignment) {
      element.style.display = 'block'
      element.style.margin = this.__alignment === 'center' ? '0 auto' : '0'
      element.style.float = this.__alignment === 'left' || this.__alignment === 'right' ? this.__alignment : 'none'
    }
    if (this.__width) {
      element.style.width = `${this.__width}px`
    }
    if (this.__height) {
      element.style.height = `${this.__height}px`
    }
    return { element }
  }

  static importDOM(): DOMConversionMap | null {
    return {
      img: () => ({
        conversion: convertImageElement,
        priority: 0,
      }),
    }
  }

  static importJSON(serializedNode: SerializedImageNode): ImageNode {
    const node = new ImageNode(
      serializedNode.src,
      serializedNode.altText,
      serializedNode.alignment,
      serializedNode.width,
      serializedNode.height
    )
    return node
  }

  exportJSON(): SerializedImageNode {
    return {
      type: 'image',
      src: this.__src,
      altText: this.__altText,
      alignment: this.__alignment,
      width: this.__width,
      height: this.__height,
      version: 1,
    }
  }

  decorate(): ReactElement {
    return (
      <ImageComponent
        src={this.__src}
        altText={this.__altText}
        width={this.__width}
        height={this.__height}
        alignment={this.__alignment}
        nodeKey={this.__key}
      />
    )
  }
}

function convertImageElement(domNode: Node): DOMConversionOutput | null {
  if (!(domNode instanceof HTMLImageElement)) {
    return null
  }
  const { alt: altText, src, width, height } = domNode
  const node = new ImageNode(src, altText || '', undefined, width, height)
  return { node }
}

export function $createImageNode({ src, altText, alignment = 'left', width, height, key }: ImagePayload): ImageNode {
  return new ImageNode(src, altText, alignment, width, height, key)
}

export function $isImageNode(node: LexicalNode | null | undefined): node is ImageNode {
  return node instanceof ImageNode
}
