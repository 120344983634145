import { getPrimaryContactEmail, getPrimaryContactPhone, getUserFullName } from '@/lib/utils'
import { UserContact } from '@/types'
import { Checkbox, Stack, SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import theme from '@/theme.ts'

export const ContactsCheckboxList = ({
  contacts,
  selectedContacts,
  onSelect,
  sx = {},
}: {
  contacts: UserContact[]
  selectedContacts: UserContact[]
  onSelect: (contact: UserContact) => void
  sx?: SxProps
}) => {
  return (
    <Stack sx={{ flexGrow: 1, maxHeight: 400, overflowY: 'auto', scrollbarWidth: 'none', ...sx }}>
      {contacts.map((contact: UserContact) => {
        const contactPhone = getPrimaryContactPhone(contact?.phone)
        const contactEmail = getPrimaryContactEmail(contact?.email)

        return (
          <Stack
            direction="row"
            sx={{ pb: 2, borderBottom: '1px solid', borderColor: theme.palette.lightGrey2.main }}
            key={contact.id}
          >
            <Box>
              <Checkbox checked={selectedContacts.includes(contact)} onChange={() => onSelect(contact)} />
            </Box>
            <Stack sx={{ pt: 1 }}>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                {getUserFullName(contact)}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 400, color: 'text.secondary' }}>
                {contactEmail || contactPhone || 'n/a'}
              </Typography>
            </Stack>
          </Stack>
        )
      })}
    </Stack>
  )
}
