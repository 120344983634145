import { useEffect } from 'react'
import { IconButton, Stack } from '@mui/material'
import ImageIcon from '@mui/icons-material/Image'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $createImageNode, $isImageNode, ImageNode } from './lexical_image_node'
import { INSERT_IMAGE_COMMAND, FORMAT_IMAGE_COMMAND } from './lexical_editor_commands'
import { $getSelection, $isRangeSelection, $insertNodes, COMMAND_PRIORITY_LOW } from 'lexical'

const ImageComponent = () => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    const unregisterInsertImageCommand = editor.registerCommand(
      INSERT_IMAGE_COMMAND,
      (payload) => {
        editor.update(() => {
          const imageNode = $createImageNode(payload)
          $insertNodes([imageNode])
        })
        return true
      },
      COMMAND_PRIORITY_LOW
    )

    const unregisterFormatImageCommand = editor.registerCommand(
      FORMAT_IMAGE_COMMAND,
      (alignment) => {
        editor.update(() => {
          const selection = $getSelection()
          if ($isRangeSelection(selection)) {
            const nodes = selection.getNodes()
            const imageNode = nodes.find((node): node is ImageNode => $isImageNode(node))
            if (imageNode) {
              imageNode.setAlignment(alignment)
            }
          }
        })
        return true
      },
      COMMAND_PRIORITY_LOW
    )

    return () => {
      unregisterInsertImageCommand()
      unregisterFormatImageCommand()
    }
  }, [editor])

  const handleInsertImage = () => {
    const src = prompt('Enter image URL:', 'https://')
    if (src) {
      const altText = prompt('Enter alt text:', '')
      editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
        src: src,
        altText: altText || '',
        alignment: 'left',
      })
    }
  }

  return (
    <Stack direction="row" spacing={1}>
      <IconButton onClick={handleInsertImage} size="small" aria-label="Insert Image">
        <ImageIcon />
      </IconButton>
    </Stack>
  )
}

export default ImageComponent
