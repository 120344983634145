import { ReactNode } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { SxProps } from '@mui/material'

interface DraggableTableRowProps {
  id: string
  groupId: string
  children: ReactNode
  dragHandle?: boolean
  onClick?: () => void
  disabled?: boolean
  sx?: SxProps
}

export const DraggableTableRow = ({
  id,
  groupId,
  children,
  dragHandle = true,
  onClick,
  disabled = false,
  sx = {},
}: DraggableTableRowProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
    data: {
      type: 'ITEM',
      groupId,
    },
    disabled,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  }

  return (
    <TableRow
      ref={setNodeRef}
      style={style}
      onClick={onClick}
      sx={{
        ...sx,
      }}
    >
      {dragHandle && !disabled && (
        <TableCell
          padding="none"
          sx={{
            width: 48,
            cursor: 'grab',
          }}
          {...attributes}
          {...listeners}
        >
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <DragIndicatorIcon />
          </Box>
        </TableCell>
      )}
      {children}
    </TableRow>
  )
}
