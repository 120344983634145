import Box from '@mui/material/Box'
import { ReactNode, useEffect } from 'react'
import { useCommentStore } from '@/stores/comment-store.ts'

const NOTE_CURSOR = `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='white' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><path d='m 8,18 h 5 M 8,14 h 8 M 8,10 h 6 M 6,4 h 12 c 1.108,0 2,0.892 2,2 v 14 c 0,1.108 -0.892,2 -2,2 H 6 C 4.892,22 4,21.108 4,20 V 6 C 4,4.892 4.892,4 6,4 Z M 16,2 V 6 M 12,2 V 6 M 8,2 v 4'/></svg>") 12 12, pointer`
const COMMENT_CURSOR = `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='white' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><path d='M 17,12 H 7 M 13,8 H 7 m 14,7 a 2,2 0 0 1 -2,2 H 7 L 3,21 V 5 A 2,2 0 0 1 5,3 h 14 a 2,2 0 0 1 2,2 z'/></svg>") 12 12, pointer`

interface CommentModeContainerProps {
  children: ReactNode
}

export default function CommentModeContainer({ children }: CommentModeContainerProps) {
  const { isCommentMode, commentType, setCommentMode } = useCommentStore()

  // Add event listener for Escape key to exit comment mode
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isCommentMode) {
        setCommentMode(false, null)
      }
    }

    // Add event listener
    document.addEventListener('keydown', handleKeyDown)

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isCommentMode, setCommentMode])

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        cursor: isCommentMode ? (commentType === 'note' ? NOTE_CURSOR : COMMENT_CURSOR) : 'default',
        '& *': isCommentMode
          ? {
              pointerEvents: 'none',
              cursor: 'inherit',
            }
          : undefined,
        '& [data-commentable="true"], & [data-comment-ui="true"], & [data-comment-button="true"]': isCommentMode
          ? {
              pointerEvents: 'auto',
            }
          : undefined,
        '& [data-comment-ui="true"] *': isCommentMode
          ? {
              pointerEvents: 'auto',
              cursor: 'initial',
            }
          : undefined,
        '& [data-comment-ui="true"] button': isCommentMode
          ? {
              cursor: 'pointer',
            }
          : undefined,
        '& [data-comment-ui="true"] .MuiInputBase-root, & [data-comment-ui="true"] textarea': isCommentMode
          ? {
              cursor: 'text',
            }
          : undefined,
      }}
    >
      {children}
    </Box>
  )
}
