import { Divider, Stack } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

export const ContactListSkeleton = ({ count = 3 }: { count: number }) => {
  return (
    <Stack sx={{ pt: 3, gap: 2 }}>
      {Array.from({ length: count }).map((_, index) => (
        <Stack key={index} sx={{ gap: 1 }}>
          <Skeleton variant="rounded" width="70%" />
          <Skeleton variant="rounded" width="35%" />
          <Divider />
        </Stack>
      ))}
    </Stack>
  )
}
