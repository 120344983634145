import { PlantListEntry } from '@/types.ts'
import { useCallback, useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import theme from '@/theme.ts'
import ModalHeader from '@/components/ui/base/modal-header.tsx'
import { Button, Stack } from '@mui/material'
import { pluralizedLabel } from '@/lib/pluralize.ts'
import { SelectJobPhasePlants } from '@/components/rfps/select-job-phase-plants.tsx'

interface AddPlantsFromJobsModalProps {
  open: boolean
  onClose: () => void
  jobIds: string[]
  jobPlantsInRfp: PlantListEntry[]
  onAddPlants: (plants: PlantListEntry[]) => Promise<void>
  showJobSelection?: boolean
}

export default function AddPlantsFromJobsModal({
  open,
  onClose,
  jobIds,
  jobPlantsInRfp,
  onAddPlants,
  showJobSelection = false,
}: AddPlantsFromJobsModalProps) {
  const [selectedJobIds, setSelectedJobIds] = useState<string[]>([])
  const [selectedPlants, setSelectedPlants] = useState<PlantListEntry[]>([])
  const [excludedPlants, setExcludedPlants] = useState<PlantListEntry[]>([])

  useEffect(() => {
    setSelectedJobIds(jobIds)
    setExcludedPlants(jobPlantsInRfp)
  }, [jobIds, jobPlantsInRfp])

  const handleClose = () => {
    setSelectedPlants([])
    onClose()
  }

  const handleSelectedPlantUpdates = useCallback((plants: PlantListEntry[]) => {
    setSelectedPlants(plants)
  }, [])

  const handleAddPlants = async () => {
    await onAddPlants(selectedPlants)
    handleClose()
  }

  const handleJobs = (jobIds: string[]) => {
    setSelectedJobIds(jobIds)
  }

  return (
    <Modal open={open} onClose={handleClose} closeAfterTransition aria-labelledby="add-plants-from-jobs-modal-title">
      <Stack
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 1000,
          backgroundColor: 'background.paper',
          boxShadow: 24,
          p: 5,
          maxHeight: '80vh',
          overflowY: 'hidden',
          borderRadius: theme.borderRadius.lg,
        }}
      >
        <ModalHeader
          title="Select Plants for RFP"
          sx={{ mb: 5, fontWeight: 400 }}
          id="add-plants-from-jobs-modal-title"
        />

        <SelectJobPhasePlants
          jobIds={selectedJobIds}
          excludedPlants={excludedPlants}
          showSelected={true}
          allowSelection={true}
          showQuantity={true}
          allowQuantityEdit={true}
          showJobSelection={showJobSelection}
          allowRemoval={false}
          handleJobs={handleJobs}
          handleSelectedPlantUpdates={handleSelectedPlantUpdates}
        />

        <Stack
          direction="row"
          sx={{
            justifyContent: 'flex-end',
            mt: 5,
          }}
        >
          <Stack direction="row" gap={2}>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              Cancel &amp; Close
            </Button>
            <Button variant="contained" onClick={handleAddPlants} disabled={!selectedPlants.length}>
              {selectedPlants.length
                ? `Add ${pluralizedLabel(selectedPlants.length, 'Plant', 'Plants')}`
                : 'Add Plants'}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  )
}
