import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { CirclePlus, Leaf } from 'lucide-react'
import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade'
import rfpPlantListImage from '@/assets/rfp-plant-list.png'
import { DarkPrimaryButton } from '@/components/ui/base/buttons/buttons.tsx'
import { MouseEvent, ReactNode, useRef, useState } from 'react'
import { SxProps } from '@mui/material'

export const GetPricingFromPlantbidCard = ({
  onClick,
  startIcon,
  showPopper = true,
  sx,
}: {
  onClick?: () => void
  startIcon?: ReactNode
  showPopper?: boolean
  sx?: SxProps
}) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [showModal, setShowModal] = useState(false)

  const handleHover = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    clearTimeout(timeoutRef.current)
    setShowModal(showPopper)
  }

  const handleLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setShowModal(false)
    }, 300) // 300ms grace period to move to modal
  }

  const handleClick = () => {
    setShowModal(false)
    onClick?.()
  }

  return (
    <Box
      onClick={handleClick}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      sx={{
        display: 'flex',
        gap: 1,
        p: 1,
        alignItems: 'start',
        cursor: 'pointer',
        ...sx,
      }}
    >
      {startIcon}
      <Box>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Typography variant="body1" fontWeight={600}>
            Plantbid
          </Typography>
          <Leaf size={20} strokeWidth={2.5} />
        </Stack>
        <Typography variant="caption" color="text.primary">
          <Typography variant="strong" sx={{ fontSize: 'inherit' }}>
            FREE!
          </Typography>{' '}
          Get competitive pricing from the team at Plantbid at no cost to you.
        </Typography>
      </Box>

      <Popper
        open={showModal}
        anchorEl={anchorEl}
        placement="right"
        transition
        onMouseEnter={() => clearTimeout(timeoutRef.current)}
        onMouseLeave={() => setShowModal(false)}
        sx={{ zIndex: 9999 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <Box
              sx={{
                bgcolor: 'background.paper',
                p: 3,
                borderRadius: 1,
                boxShadow: 3,
                maxWidth: 600,
                ml: 2,
              }}
            >
              <Box
                component="img"
                src={rfpPlantListImage}
                alt="add_vendor_to_rfp_modal_illustration"
                sx={{
                  width: '100%',
                }}
              />
              <Typography variant="h3" gutterBottom>
                Quotes from Plantbid
              </Typography>
              <Typography variant="body2" gutterBottom>
                <Typography variant="strong">How it works:</Typography> Add us to your RFP and we'll provide you with a
                free quote.
              </Typography>
              <Stack direction="row" spacing={2} sx={{ mt: 2, alignItems: 'center' }}>
                <DarkPrimaryButton onClick={handleClick}>
                  <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                    <CirclePlus strokeWidth={2} />
                    <Typography variant="strong">Get a Quote from Plantbid</Typography>
                  </Stack>
                </DarkPrimaryButton>
                <Typography variant="strong" component="p">
                  Free!
                </Typography>
              </Stack>
            </Box>
          </Fade>
        )}
      </Popper>
    </Box>
  )
}
