import { styled } from '@mui/material/styles'
import TableCell from '@mui/material/TableCell'

export const PlantTableHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: 'transparent',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}))

export const PlantTableCell = styled(TableCell)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}))
