// Flat table
import Alert from '@mui/material/Alert'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import React from 'react'
import { SxProps, Typography } from '@mui/material'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import theme from '@/theme.ts'

interface FlatPlantTableProps {
  header: FlatPlantTableRowProps
  rows: FlatPlantTableRowProps[]
}
export const FlatPlantTable = ({ header, rows }: FlatPlantTableProps) => {
  if (rows.length === 0) return <Alert severity="error">No Plants Found</Alert>

  return (
    <Table stickyHeader>
      {header ? (
        <TableHead>
          <FlatPlantTableRow
            cells={header.cells}
            rowItemSx={{
              borderBottom: '1px solid',
              borderBottomColor: theme.palette.lightGrey2.main,
              backgroundColor: '#F3F6F9',
              p: 0,
              ...header.rowItemSx,
            }}
          />
        </TableHead>
      ) : null}
      <TableBody>
        {rows.map((row, index) => {
          return <FlatPlantTableRow {...row} key={index} />
        })}
      </TableBody>
    </Table>
  )
}

export type FlatPlantTableCellProps = {
  name?: string
  node?: React.ReactNode
  colSpan?: number
  sx?: SxProps
}
export type FlatPlantTableRowProps = {
  cells: FlatPlantTableCellProps[]
  rowSx?: SxProps
  rowItemSx?: SxProps
}

const FlatPlantTableRow = ({ cells, rowSx = {}, rowItemSx = {} }: FlatPlantTableRowProps) => {
  return (
    <TableRow sx={{ ...rowSx }}>
      {cells.map((cell, index) => {
        // create cell styling based on props
        let cellStyle = {}
        if (rowItemSx) cellStyle = { ...rowItemSx }
        if (cell.sx) cellStyle = { ...cellStyle, ...cell.sx }

        // render cell node or text based on available data
        return cell.node ? (
          <TableCell key={index} sx={cellStyle} colSpan={cell?.colSpan || 1}>
            {cell.node}
          </TableCell>
        ) : (
          <TableCell key={index} sx={cellStyle} colSpan={cell?.colSpan || 1}>
            <Typography variant="body2" fontWeight={400}>
              {cell.name}
            </Typography>
          </TableCell>
        )
      })}
    </TableRow>
  )
}
