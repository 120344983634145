import React, { useState, useEffect } from 'react'
import { Chip, Typography, Popover, List, ListItem, ListItemIcon, ListItemText, Checkbox, Stack } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { AddCircleOutlined } from '@mui/icons-material'
import { ClearTextIconButton } from '@/components/ui/base/buttons/text-icon-buttons.tsx'
import theme from '@/theme.ts'

export type MultiSelectChipDropdownOption = {
  value: string
  name: string
}

interface MultiSelectChipDropdownProps {
  options: MultiSelectChipDropdownOption[]
  selectedIds: string[]
  onChange: (selectedIds: string[]) => void
  label?: string
  popperLabel?: string
  buttonLabel?: string
  disabled?: boolean
}

export default function MultiSelectChipDropdown({
  options,
  selectedIds,
  onChange,
  label = '',
  buttonLabel = '',
  popperLabel = '',
  disabled = false,
}: MultiSelectChipDropdownProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [selectedOptions, setSelectedOptions] = useState<MultiSelectChipDropdownOption[]>([])

  // Update selected collaborators when selectedIds changes
  useEffect(() => {
    const selected = options.filter((option) => selectedIds.includes(option.value))
    setSelectedOptions(selected)
  }, [selectedIds, options])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!disabled && !anchorEl) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleToggle = (value: string) => {
    const newSelectedIds = selectedIds.includes(value)
      ? selectedIds.filter((selectedId) => selectedId !== value)
      : [...selectedIds, value]
    onChange(newSelectedIds)
  }

  const open = Boolean(anchorEl)

  return (
    <Stack gap={2}>
      {label && <Typography variant="body2">{label}</Typography>}

      {selectedOptions.length ? (
        <Stack direction="row" flexWrap="wrap" sx={{ gap: 1 }}>
          {selectedOptions.map((option) => (
            <Chip
              key={option.value}
              label={option.name}
              sx={{ bgColor: theme.palette.lightGrey2.main }}
              onDelete={() => handleToggle(option.value)}
              deleteIcon={<CloseIcon />}
            />
          ))}
        </Stack>
      ) : null}

      <Stack sx={{ gap: 0, alignItems: 'start' }}>
        <ClearTextIconButton
          text={buttonLabel}
          onClick={handleClick}
          startIcon={<AddCircleOutlined color="primary" />}
          sx={{
            p: 0,
            fontWeight: 400,
            color: theme.palette.mediumGrey2.main,
          }}
        />
      </Stack>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              width: 250,
              maxHeight: 300,
              p: 1,
              mt: 0.5,
            },
          },
        }}
      >
        {popperLabel && (
          <Typography variant="strong" sx={{ p: 1 }}>
            {popperLabel}
          </Typography>
        )}
        <List sx={{ width: '100%' }}>
          {options.map((option) => {
            const isSelected = selectedIds.includes(option.value)

            return (
              <ListItem
                key={option.value}
                dense
                component="div"
                onClick={() => handleToggle(option.value)}
                sx={{ borderRadius: 1, cursor: 'pointer', p: 0, pl: 1 }}
              >
                <ListItemIcon sx={{ p: 0, minWidth: 0 }}>
                  <Checkbox edge="start" checked={isSelected} tabIndex={-1} disableRipple />
                </ListItemIcon>
                <ListItemText primary={option.name} />
              </ListItem>
            )
          })}
        </List>
      </Popover>
    </Stack>
  )
}
