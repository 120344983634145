import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { useState, type MouseEvent } from 'react'

import Divider from '@mui/material/Divider'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { EllipsisVertical, Pencil, Star, StarOff, Trash2 } from 'lucide-react'

import EditContactModal from '@/components/ui/modals/edit-contact-modal.tsx'
import { ConfirmationModal } from '@/components/ui/modals/confirmation-modal.tsx'

import { deleteContact, favoriteContact, unfavoriteContact } from '@/api/contacts.ts'
import type { ContactDetailsResponse } from '@/types.ts'

type ContactMenuProps = {
  contactDetails: ContactDetailsResponse
}
export default function ContactMenu({ contactDetails }: ContactMenuProps) {
  const { contact, organization_contact } = contactDetails
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [editContact, setEditContact] = useState(false)
  const open = Boolean(anchorEl)
  const deleteContactMutation = useMutation({
    mutationFn: () => deleteContact(contact.id, contact.type),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['contacts'] })

      // wait 1 second before navigating to /contacts to allow query invalidation to settle
      await new Promise((resolve) => setTimeout(resolve, 1000))
      // TODO: Is this the correct id?
      await navigate({ to: '/$orgId/contacts', params: { orgId: contact.organization.id } })
    },
  })

  const favoriteContactMutation = useMutation({
    mutationFn: () => favoriteContact(contact, organization_contact),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['contact'] })
    },
  })

  const unfavoriteContactMutation = useMutation({
    mutationFn: () => unfavoriteContact(contact, organization_contact),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['contact'] })
    },
  })

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleToggleFavorite = async () => {
    handleClose()
    if (contact.favorite) {
      await unfavoriteContactMutation.mutateAsync()
    } else {
      await favoriteContactMutation.mutateAsync()
    }
  }

  const handleDelete = async () => {
    await deleteContactMutation.mutateAsync()
  }

  const openEditContactModal = () => {
    handleClose()
    setEditContact(true)
  }

  const openConfirmDeleteModal = () => {
    handleClose()
    setConfirmDelete(true)
  }

  return (
    <>
      <IconButton aria-label="contact menu" onClick={handleClick}>
        <EllipsisVertical />
      </IconButton>

      <Menu
        id="contact-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: -240, vertical: 'bottom' }}
        slotProps={{ paper: { sx: { width: 280 } } }}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={openEditContactModal} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Pencil size={18} /> Edit Contact Details
        </MenuItem>
        <MenuItem onClick={handleToggleFavorite} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {contact.favorite ? (
            <>
              <StarOff size={18} /> Unfavorite
            </>
          ) : (
            <>
              <Star size={18} /> Favorite
            </>
          )}
        </MenuItem>
        <Divider />
        <MenuItem onClick={openConfirmDeleteModal} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Trash2 size={18} /> Delete
        </MenuItem>
      </Menu>

      <EditContactModal
        contact={contact}
        organizationContact={organization_contact}
        open={editContact}
        onClose={() => setEditContact(false)}
      />

      <ConfirmationModal
        open={confirmDelete}
        title="Delete Contact"
        message="Are you sure you want to delete this contact? This action cannot be undone."
        onConfirm={handleDelete}
        onCancel={() => setConfirmDelete(false)}
        loading={deleteContactMutation.isPending}
        danger={true}
      />
    </>
  )
}
