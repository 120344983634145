import { type PlantListEntry, SpecificationOptionType } from './types.ts'

export const DEFAULT_ROUTE = '/'

export const IMAGE_FILE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'image/gif']
export const SPREADSHEET_FILE_TYPES = ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

export const ACCEPTED_FILE_TYPES = [...IMAGE_FILE_TYPES, ...SPREADSHEET_FILE_TYPES, 'application/pdf']

export const ACCEPTED_FILE_EXTENSIONS = ['.jpeg', '.jpg', '.png', '.webp', '.gif', '.csv', '.xlsx', '.pdf']

export const MAX_IMAGE_DIMENSION = 8000

export const DEFAULT_PLANT_LIST_ENTRY: PlantListEntry = {
  id: '',
  caliper: { min: null, max: null, unit: null },
  dbh: { min: null, max: null, unit: null },
  container: { min: null, max: null, unit: null },
  height: { min: null, max: null, unit: null },
  width: { min: null, max: null, unit: null },
  surface_area: { min: null, max: null, unit: null },
  package_count: { min: null, max: null },
  quantity_count: { min: null, max: null },
  shipping_container: null,
  plant_container: null,
  root_packaging: null,
  trunk_form: null,
  file_id: null,
  scientific_name: null,
  common_name: null,
  notes: null,
  plant_id: null,
  shape: [],
  palm_trunk: [],
  plant_stage: [],
  characteristics: [],
  rfp_id: [],
  parent_of_order: null,
  deleted_at: null,
}

export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL
export const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN
export const AUTH0_CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID
export const AUTH0_AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE

export const US_STATES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
] as const

export enum ContactTypes {
  USER = 'user',
  ORGANIZATION = 'organization',
}
export enum ContactAddressTypes {
  HEADQUARTERS = 'headquarters',
  MAILING = 'mailing',
  OTHER = 'other',
}
export enum ContactEmailTypes {
  WORK = 'work',
  OTHER = 'other',
}
export enum ContactPhoneTypes {
  WORK = 'work',
  MOBILE = 'mobile',
  OTHER = 'other',
}
export type ContactCategories = 'all' | 'favorite' | 'customers' | 'vendors' | 'my_team'

export enum BusinessTypes {
  BUYER = 'buyer',
  SELLER = 'seller',
  DESIGNER = 'designer',
  HAULER = 'hauler',
}

// calendar
export const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
export const allDays = [...weekDays, 'saturday', 'sunday']

// permissions
export enum OrganizationPermissionTypes {
  OWNER = 'owner',
  ADMIN = 'admin',
  MANAGE_CONTACTS = 'manage_contacts',
  CREATE_PLANTLIST = 'create_plantlist',
  MANAGE_ROLES = 'manage_roles',
  MANAGE_USERS = 'manage_users',
  MANAGE_ORGANIZATION = 'manage_organization',
}

export const notificationCategories = [
  ['all', 'All activity'],
  ['rfp', "Activity on RFPs I'm involved with"],
  ['quote', "Activity on quotes I'm involved with"],
  ['order', "Activity on orders I'm involved with"],
  ['job', "Activity on jobs I'm involved with"],
  ['delivery', "Activity on deliveries I'm involved with"],
  ['invoice', "Activity on invoices I'm involved with"],
]

export enum CreateRfpSteps {
  BUILD_RFP = 'build-rfp',
  ADD_VENDORS = 'add-vendors',
  REVIEW_AND_SEND = 'review-and-send',
}

export const specificationOptions: SpecificationOptionType[] = [
  {
    name: 'Caliper',
    optionsName: 'imperiallengthmeasurement',
    fieldName: 'caliper',
    category: 'Size',
    fieldType: 'range',
  },
  { name: 'DBH', optionsName: 'imperiallengthmeasurement', fieldName: 'dbh', category: 'Size', fieldType: 'range' },
  {
    name: 'Container',
    optionsName: 'imperialvolumemeasurement',
    fieldName: 'container',
    category: 'Size',
    fieldType: 'range',
  },
  { name: 'Width', optionsName: 'imperiallengthmeasurement', fieldName: 'width', category: 'Size', fieldType: 'range' },
  {
    name: 'Height',
    optionsName: 'imperiallengthmeasurement',
    fieldName: 'height',
    category: 'Size',
    fieldType: 'range',
  },
  {
    name: 'Surface Area',
    optionsName: 'imperialvolumemeasurement',
    fieldName: 'surface_area',
    category: 'Size',
    fieldType: 'range',
  },
  {
    name: 'Package Count',
    optionsName: 'packagecount',
    fieldName: 'package_count',
    category: 'Packaging',
    fieldType: 'range',
  },
  {
    name: 'Shipping Container',
    optionsName: 'shippingcontainer',
    fieldName: 'shipping_container',
    category: 'Packaging',
    fieldType: 'single',
  },
  {
    name: 'Plant Container',
    optionsName: 'plantcontainer',
    fieldName: 'plant_container',
    category: 'Packaging',
    fieldType: 'single',
  },
  {
    name: 'Root Packaging',
    optionsName: 'rootpackaging',
    fieldName: 'root_packaging',
    category: 'Packaging',
    fieldType: 'single',
  },
  {
    name: 'Trunk Form',
    optionsName: 'trunkplurality',
    fieldName: 'trunk_form',
    category: 'Trunk',
    fieldType: 'single',
  },
  { name: 'Shape', optionsName: 'shape', fieldName: 'shape', category: 'Tags', fieldType: 'multiple' },
  { name: 'Palm Trunk', optionsName: 'palmtrunk', fieldName: 'palm_trunk', category: 'Tags', fieldType: 'multiple' },
  { name: 'Plant Stage', optionsName: 'plantstage', fieldName: 'plant_stage', category: 'Tags', fieldType: 'multiple' },
  {
    name: 'Characteristics',
    optionsName: 'characteristics',
    fieldName: 'characteristics',
    category: 'Tags',
    fieldType: 'multiple',
  },
]

export enum RfpTableRowItemType {
  PLANT_ITEM = 'plant_item',
  PHASE_DROP_AREA = 'phase_drop_area',
}

export enum PlantbidOrganizationId {
  DEVELOPMENT = '67d35a401aca5c611a3cf6dc',
  STAGING = '6645233ed90c8ef5a804e0fa',
  PRODUCTION = '666a037ce19492bbb6ce20d9',
}
