import { Box, Skeleton, Stack } from '@mui/material'
import theme from '@/theme.ts'

export const RfpModalPlantTableSkeleton = () => {
  return (
    <Stack sx={{ gap: 2 }}>
      {/* Search bar skeleton */}
      <Skeleton variant="rectangular" width="40%" height={40} sx={{ borderRadius: 1 }} />

      {/* Table header skeleton */}
      <Stack
        direction="row"
        sx={{
          p: 2,
          borderBottom: `1px solid ${theme.palette.grey[200]}`,
          backgroundColor: theme.palette.grey[50],
        }}
      >
        <Box sx={{ width: 40 }} /> {/* Checkbox space */}
        <Skeleton variant="text" width="50%" sx={{ fontSize: '1rem' }} />
        <Skeleton variant="text" width="25%" sx={{ fontSize: '1rem', ml: 2 }} />
        <Skeleton variant="text" width="25%" sx={{ fontSize: '1rem', ml: 2 }} />
      </Stack>

      {/* Table rows skeleton */}
      {[...Array(5)].map((_, index) => (
        <Stack
          key={index}
          direction="row"
          sx={{
            p: 2,
            borderBottom: `1px solid ${theme.palette.grey[100]}`,
          }}
        >
          <Box sx={{ width: 40 }} /> {/* Checkbox space */}
          <Skeleton variant="text" width="50%" sx={{ fontSize: '1rem' }} />
          <Skeleton variant="text" width="25%" sx={{ fontSize: '1rem', ml: 2 }} />
          <Skeleton variant="text" width="25%" sx={{ fontSize: '1rem', ml: 2 }} />
        </Stack>
      ))}
    </Stack>
  )
}
